import React from "react";
import { Chip, Divider } from "@mui/material";
import {
  SimpleLoader,
  SimpleTable,
  TransReportHeader as Header,
} from "components";
import { capitalize } from "lodash";
import { classNames, formatInputNum } from "utils";
import { useGetFiatProviderBalance, useTransReportWithdrawal } from "api";
import { useSelector } from "react-redux";

const columns = [
  { label: "Currency", id: "currency" },
  { label: "Count", id: "count", align: "right" },
  {
    label: "Amount",
    id: "amount",
    align: "right",
  },
];
export default function WithdrawalSummary({ platform }) {
  const { transReportDateRange } = useSelector((state) => state.util);

  const { calendarType } = transReportDateRange || {};

  const [isSubmitting, isSubmitted, data, fiatProviderList] =
    useTransReportWithdrawal(platform);

  const [paymentTotal, isSubmittingTot, isSubmittedTot] =
    useGetFiatProviderBalance(platform);

  const calendarTypeFormat = {
    day: "daily",
    week: "weekly",
    month: "monthly",
  };

  return (
    <div>
      {data ? (
        <div>
          <div className="mt-1 mb-3 border-b dark:border-slate-700" />

          <div className="grid grid-cols-3 gap-4">
            {[
              {
                name: "esetech",
                rows: ["naira", "cedis", "cefa"].map((i) => ({
                  currency: i,
                  count: data?.[`${i}Count`] || 0,
                  amount: formatInputNum(data?.[`${i}Amount`] || 0, 2),
                  charges: formatInputNum(data?.[`${i}Charges`] || 0, 2),
                  align: "right",
                })),
                columns: [
                  { label: "Currency", id: "currency" },
                  { label: "Count", id: "count", align: "right" },
                  { label: "Charges", id: "charges", align: "right" },
                  { label: "Amount", id: "amount", align: "right" },
                ],
              },
            ].map((item) => (
              <div className="col-span-2" key={item?.name}>
                <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                  <SimpleTable
                    columns={item?.columns}
                    rows={item?.rows}
                    // disableColumns
                  />
                </div>
              </div>
            ))}
            <div className="col-span-1">
              <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                <Header header={"Provider Total (NGN)"} />

                <div className="">
                  {!isSubmittingTot ? (
                    <>
                      {paymentTotal && isSubmittedTot ? (
                        paymentTotal?.map((item, index, arr) => (
                          <div
                            className={classNames(
                              "flex items-center justify-between p-4 text-sm dark:text-white",
                              index !== arr.length - 1
                                ? "border-b dark:border-gray-700"
                                : ""
                            )}
                            key={item?.provider}
                          >
                            <div>{capitalize(item?.provider)}</div>
                            {item?.total ? (
                              <div>{formatInputNum(item?.total, 2)}</div>
                            ) : (
                              <div className="text-gray-400">unavailable</div>
                            )}
                          </div>
                        ))
                      ) : (
                        <div className="p-4 text-sm dark:text-white">
                          Transaction total cannot be retrieved for{" "}
                          {calendarTypeFormat?.[calendarType]} report
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="p-4 text-sm dark:text-white">Loading..</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="my-4">
            <Divider
              light
              sx={{
                "&::before, &::after": {
                  borderColor: "secondary.light",
                },
              }}
            >
              <Chip
                label="Payment method"
                classes={{
                  root: "dark:!text-white dark:bg-gray-800",
                }}
              />
            </Divider>
          </div>
          <div className="grid grid-cols-2 gap-4">
            {[
              {
                label: "automatic",
                subData: {
                  col: [
                    { label: "Provider", id: "provider" },
                    { label: "Count", id: "count", align: "right" },
                    { label: "Amount", id: "amount", align: "right" },
                  ],
                  row: [...fiatProviderList, ""].map((i) => ({
                    provider: i,
                    count: data?.[`isAutomaticCount${i}`] || 0,
                    amount: formatInputNum(
                      data?.[`isAutomaticAmount${i}`] || 0,
                      2
                    ),
                    align: "right",
                  })),
                },
              },
              {
                label: "manual",
                data: ["naira", "cedis", "cefa"].map((i) => ({
                  currency: i,
                  count: data?.[`isManualCount${capitalize(i)}`] || 0,
                  amount: formatInputNum(
                    data?.[`isManualAmount${capitalize(i)}`] || 0,
                    2
                  ),
                  align: "right",
                })),
              },
            ].map((item) => (
              <div className="col-span-1" key={item?.label}>
                <div
                  className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800"
                  key={item?.label}
                >
                  <Header header={capitalize(item?.label)} />

                  {item?.subData ? (
                    <SimpleTable
                      columns={item?.subData?.col}
                      rows={item?.subData?.row}
                      // disableColumns
                    />
                  ) : (
                    <SimpleTable
                      columns={columns}
                      rows={item?.data}
                      // disableColumns
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex h-[200px] items-center justify-center">
          <SimpleLoader />
        </div>
      )}
    </div>
  );
}

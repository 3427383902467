import React from "react";

import { Form } from "components";
import { AuthContainer } from "shared";
import { loginValidationSchema } from "utils/formSchema";
import { useLogin } from "api";

export default function Login() {
  const [isLoading, isLoaded, error, login] = useLogin();

  const fieldList = [
    {
      name: "email",
      type: "email",
      label: "Email",
      inputType: "textfield",
      placeholder: "Email",
    },
    {
      name: "password",
      type: "password",
      label: "Password",
      inputType: "textfield",
      placeholder: "Password",
    },
  ];
  const initialFieldValues = { email: "", password: "" };

  const handleSubmit = (values) => {
    const { email, password } = values;
    login(email, password);
  };

  return (
    <AuthContainer title="Login">
      <div className="grid grid-cols-1 gap-4">
        <Form
          fieldList={fieldList}
          initialFieldValues={initialFieldValues}
          buttonText="Login"
          buttonLoading={isLoading}
          handleSubmit={handleSubmit}
          validationSchema={loginValidationSchema}
          fullWidth
        />
      </div>
    </AuthContainer>
  );
}

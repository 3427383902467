import { NumericFormat } from "react-number-format";
import getSymbolFromCurrency from "currency-symbol-map";
import { Store } from "react-notifications-component";
import copy from "copy-to-clipboard";
import { capitalize, startCase, toLower } from "lodash";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import calendar from "dayjs/plugin/calendar";

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(calendar);

const calendarFormats = {
  // sameDay: "[Today] at hh:mma",
  sameDay: "hh:mma",
  nextDay: "[Tomorrow]",
  nextWeek: "dddd",
  lastDay: "[Yesterday]",
  // lastWeek: "[Last] dddd",
  lastWeek: "DD/MM/YYYY",
  sameElse: "DD/MM/YYYY",
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const truncate = (str, len) =>
  str?.length <= len ? `${str?.slice(0, len)}` : `${str?.slice(0, len)}...`;

export const formatDate = (date, format = "Do MMM, YYYY hh:mm a") => {
  let d;
  if (date instanceof Object) {
    d = date.seconds * 1000;
  } else if (date instanceof Date) {
    d = new Date(date);
  } else if (typeof date === "number") {
    d = new Date(date);
  } else if (typeof date === "string") {
    const dd = date.replace(" ", "T");
    d = dd;
  } else {
    d = date;
  }
  return format ? dayjs(d).format(format) : dayjs(d);
};

export const momentDate = (d, formatD) => dayjs(d).format(formatD);

export const formatDateFromNow = (date) => {
  const d = formatDate(date, null);

  return date ? dayjs(d).calendar(null, calendarFormats) : "...";
};

export const formatDateToValue = (date, type) => {
  const d =
    type === "start" ? dayjs(date).startOf("day") : dayjs(date).endOf("day");
  return d.valueOf();
};

export const getFirstName = (name) => name?.split(" ")[0];

export const formatMoney = (num, suffix = "NGN", decimalScale = 2) => (
  <NumericFormat
    value={num}
    displayType="text"
    thousandSeparator
    // {...(sym === "visible" && { suffix })}
    decimalScale={decimalScale}
    fixedDecimalScale
    renderText={(value) => (
      <span>
        {value} {suffix}
      </span>
    )}
  ></NumericFormat>
);

export const formatMoney2 = (num, decimalScale = 2) => {
  const formattedValue = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: decimalScale,
    maximumFractionDigits: decimalScale,
  }).format(num);

  return formattedValue;
};

export const formatMoneyNoDec = (num, prefix = "₦") => (
  <NumericFormat
    value={num}
    displayType="text"
    thousandSeparator
    prefix={prefix}
    decimalScale={0}
    // fixedDecimalScale
  >
    {num}
  </NumericFormat>
);

export const formatInputNum = (amt, fixed = 0) =>
  amt !== ""
    ? parseFloat(amt)
        .toFixed(fixed)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    : "";

export const formatCrypto = (amt) =>
  amt !== "" ? `${parseFloat(amt).toFixed(8)}` : "";

export const formatCurrency = (amt, currency = "₦", toFixed = 2) =>
  amt !== ""
    ? `${currency}${parseFloat(amt)
        .toFixed(toFixed)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
    : "";

export const symToCur = (cur) => getSymbolFromCurrency(cur);

export const copyText = async (text, msg) => {
  const copied = copy(text);
  if (copied) {
    notification("success", msg);
  } else {
    notification("danger", "Failed to copy!", "error");
  }
};

export const notification = (type, message, title) => {
  return Store.addNotification({
    title: capitalize(title || type),
    message,
    type,
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const toFixed = (num, fixed = 0) => {
  try {
    const s =
      Number.isNaN(num) || num === "NaN" || num === 0
        ? ""
        : `${parseFloat(num)?.toFixed(fixed)}`;
    return s;
  } catch (error) {
    return "";
  }
};

export const trimName = (name) =>
  startCase(
    toLower(
      name
        .replace(/[^a-zA-Z ]/g, "")
        .replace(/\s\s+/g, " ")
        .trim()
    )
  );

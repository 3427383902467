import React, { useState, useEffect } from "react";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { useSelector } from "react-redux";

const KaapoMetaCallToAction = () => {
  const [facebookData, setFacebookData] = useState([]);
  const [instagramData, setInstagramData] = useState([]);
  const { metaCallToActionInsightInsta, metaCallToActionInsightFacebook } =
    useSelector((state) => state.marketing);

  useEffect(() => {
    setFacebookData(metaCallToActionInsightFacebook);
    // setInstagramData(metaCallToActionInsightInsta);
  }, [metaCallToActionInsightFacebook, metaCallToActionInsightInsta]);

  // console.log(instagramData, "insta");

  return (
    <div className="h-[500px] w-full overflow-scroll rounded-md bg-white p-3 dark:bg-slate-700">
      <div className="mt-4 w-full rounded-md border-2 bg-green-900/30 p-3 dark:border-slate-400">
        <h3 className="mx-auto py-6 text-center font-sans text-lg font-semibold capitalize text-slate-600 drop-shadow-md dark:text-white">
          facebook
        </h3>
        <div className="grid grid-cols-3 gap-3 ">
          {facebookData?.map((d, i) => {
            return (
              <div
                key={i}
                className={`backdrop-blur-200 z-50 mx-auto mb-5 h-fit 
                w-full rounded-md p-3 shadow-sm drop-shadow-md
                ${
                  i === 0
                    ? "col-span-3 grid grid-cols-3 gap-3 "
                    : "col-span-3 grid grid-cols-3 gap-3"
                }
                `}
              >
                {d?.map((fd, i) => {
                  return (
                    <div>
                      <div
                        key={i}
                        className="flex h-full flex-col justify-between rounded-md bg-white p-3 shadow-sm drop-shadow-md dark:bg-slate-800"
                      >
                        <p className="text-start leading-2 mb-0 w-full py-2 font-sans text-base font-semibold capitalize leading-6 text-slate-600 drop-shadow-md first-letter:capitalize dark:text-white">
                          {
                            fd?.title
                              .replace("Consumptions", "Clicks")
                              .split(" By Type")[0]
                          }
                        </p>
                        <hr />
                        {fd?.values?.map((v, i) => {
                          if (typeof v.value === "object") {
                            return (
                              <div key={i}>
                                {i === 1
                                  ? Object.entries(v?.value)?.map((t, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="grid grid-cols-2"
                                        >
                                          <div className="col-span-1 mx-auto w-32 ">
                                            <p className="whitespace-no-wrap leading-2 mb-0 w-fit break-words py-2 text-center font-sans text-base font-semibold capitalize oldstyle-nums text-slate-600 drop-shadow-md first-letter:capitalize dark:text-white">
                                              {t[0]}
                                            </p>
                                          </div>

                                          <div className="">
                                            <p className="whitespace-no-wrap leading-2 mb-0 ml-6 w-fit break-words py-2 text-center font-sans text-base font-semibold capitalize oldstyle-nums text-slate-600 drop-shadow-md first-letter:capitalize dark:text-white">
                                              {t[1]}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </div>
                            );
                          } else {
                            return (
                              <p
                                className="text-start p-0.5 px-3 font-sans text-base
                            font-bold oldstyle-nums text-gray-600 dark:text-white"
                              >
                                {i === 1 ? Object.values(v)[0] : ""}
                              </p>
                            );
                          }
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default KaapoMetaCallToAction;

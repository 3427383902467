import React, { Fragment } from "react";
import {
  SimpleLoader,
  SimpleTable,
  TransReportHeader as Header,
} from "components";
import { capitalize, startCase, toLower } from "lodash";
import { formatCrypto, formatInputNum } from "utils";
import { useTransReportCryptoEse } from "api";
import { useSelector } from "react-redux";
import { SimpleTableDensed } from "components/Table";
import { Chip, Divider } from "@mui/material";

export default function CryptoSummary() {
  const { transReportDateRange } = useSelector((state) => state.util);

  const {
    calendarType,
    startDate,
    endDate,
    startDatePrev1,
    endDatePrev1,
    startDatePrev2,
    endDatePrev2,
  } = transReportDateRange || {};

  const [isSubmitting, isSubmitted, data] = useTransReportCryptoEse();

  const { tradeD, tradeDTotal, receiveD, receiveUserD } = data || {};

  const calendarTypeFormat = {
    day: "daily",
    week: "weekly",
    month: "monthly",
  };

  const duration = calendarTypeFormat?.[calendarType];

  const dateAllFormat = {
    all: "",
    current: `(${startDate?.format("DD/MM/YY")} - ${endDate?.format(
      "DD/MM/YY"
    )})`,
    prevOne: `(${startDatePrev1?.format("DD/MM/YY")} - ${endDatePrev1?.format(
      "DD/MM/YY"
    )})`,
    prevTwo: `(${startDatePrev2?.format("DD/MM/YY")} - ${endDatePrev2?.format(
      "DD/MM/YY"
    )})`,
    past: "",
  };

  return (
    <div>
      {data ? (
        <div>
          <div className="mt-1 mb-3 border-b dark:border-slate-700" />
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-1 gap-4">
              <div className="col-span-1">
                <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                  <Header header="Receive" />
                  {[
                    {
                      name: "esetech",
                      rows: [
                        "BTC",
                        "ETH",
                        "USDT",
                        // "USDT_TRON",
                        "BSC",
                        "LTC",
                        "Total",
                      ].map((i) => ({
                        coin: i,
                        count: receiveD?.[`receive${i}`]?.count || 0,
                        amountUSD: formatInputNum(
                          receiveD?.[`receive${i}`]?.amountUSD || 0,
                          2
                        ),
                        amountCrypto:
                          receiveD?.[`receive${i}`]?.amountCrypto === "-"
                            ? "-"
                            : formatCrypto(
                                receiveD?.[`receive${i}`]?.amountCrypto || 0,
                                2
                              ),

                        align: "right",
                      })),
                      columns: [
                        { label: "Coin", id: "coin" },
                        { label: "Count", id: "count", align: "right" },
                        {
                          label: "Amount(USD)",
                          id: "amountUSD",
                          align: "right",
                        },
                        {
                          label: "Amount(Crypto)",
                          id: "amountCrypto",
                          align: "right",
                        },
                      ],
                    },
                  ].map((item) => (
                    <div className="" key={item?.name}>
                      <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                        <SimpleTable
                          columns={item?.columns}
                          rows={item?.rows}
                          // disableColumns
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                  <Header
                    header={`${capitalize(duration)} Users Info - Receive`}
                  />
                  {[
                    {
                      name: "esetech",
                      rows: [
                        "all",
                        "current",
                        "prevOne",
                        "prevTwo",
                        "past",
                      ].map((i) => ({
                        userType: `${startCase(`${i} ${calendarType}`)} ${
                          dateAllFormat[i]
                        }`,
                        count: receiveUserD?.[i]?.count || 0,
                        uniqCount: receiveUserD?.[i]?.uniqCount || 0,
                        totalCount: receiveUserD?.[i]?.allUserCount || 0,
                        amount: formatInputNum(
                          receiveUserD?.[i]?.amount || 0,
                          2
                        ),
                        perc: `${formatInputNum(
                          ((receiveUserD?.[i]?.amount || 0) /
                            (receiveUserD?.["all"]?.amount || 0)) *
                            100,
                          2
                        )}%`,
                        align: "right",
                      })),
                      columns: [
                        { label: "User Type", id: "userType" },
                        {
                          label: "Trans Count",
                          id: "count",
                          align: "right",
                        },
                        {
                          label: "Unique User",
                          id: "uniqCount",
                          align: "right",
                        },
                        {
                          label: "Total User",
                          id: "totalCount",
                          align: "right",
                        },
                        {
                          label: "Amount Traded",
                          id: "amount",
                          align: "right",
                        },
                        {
                          label: "Percentage Traded",
                          id: "perc",
                          align: "right",
                        },
                      ],
                    },
                  ].map((item) => (
                    <div className="" key={item?.name}>
                      <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                        <SimpleTable
                          columns={item?.columns}
                          rows={item?.rows}
                          // disableColumns
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="my-4">
              <Divider
                light
                sx={{
                  "&::before, &::after": {
                    borderColor: "secondary.light",
                  },
                }}
              >
                <Chip
                  label="All Trades"
                  classes={{
                    root: "dark:!text-white dark:bg-gray-800",
                  }}
                />
              </Divider>
            </div>
            {["sell", "buy", "convert", "send"].map((itemT, indexT) => (
              <div className="grid grid-cols-2 gap-4" key={indexT}>
                {["NGN", "GHS", "XOF"].map((itemF, indexF) => {
                  const isDisplayed = !(
                    ["convert", "send"].includes(itemT) &&
                    ["GHS", "XOF"].includes(itemF)
                  );

                  const headerT = ["convert", "send"].includes(itemT)
                    ? ""
                    : `| ${itemF}`;

                  const tot = tradeDTotal?.[itemT]?.[itemF];
                  return (
                    <Fragment key={indexF}>
                      {isDisplayed ? (
                        <div className="col-span-1" key={indexF}>
                          <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                            <Header
                              header={`${capitalize(itemT)} ${headerT}`}
                            />
                            {tradeD?.[itemT]?.[itemF] ? (
                              <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                                <SimpleTableDensed
                                  columns={{
                                    headerColumn: {
                                      id: "coin",
                                      label: "Coin",
                                    },
                                    data: [
                                      itemT !== "convert" && {
                                        label: "Rate",
                                        id: "rate",
                                        align: "right",
                                      },
                                      {
                                        label: "Count",
                                        id: "count",
                                        align: "right",
                                      },
                                      {
                                        label: "Amount(USD)",
                                        id: "amountUSD",
                                        align: "right",
                                      },

                                      {
                                        label: "Amount(Crypto)",
                                        id: "amountCrypto",
                                        align: "right",
                                      },

                                      ...(itemT === "convert"
                                        ? [
                                            {
                                              label: "Fees(USD)",
                                              id: "fees",
                                              align: "right",
                                            },
                                            {
                                              label: "Amount(Total)",
                                              id: "amountConvert",
                                              align: "right",
                                            },
                                          ]
                                        : [
                                            {
                                              label: `Amount(${itemF})`,
                                              id: `amount${itemF}`,
                                              align: "right",
                                            },
                                          ]),
                                    ],
                                  }}
                                  rows={[
                                    ...Object.entries(
                                      tradeD?.[itemT]?.[itemF]
                                    )?.map((i) => {
                                      const label = toLower(startCase(i[0]));
                                      const dataObj = i[1];
                                      return {
                                        label,
                                        data: Object.entries(dataObj).map(
                                          (i) => ({
                                            rate: i[0],
                                            count: i[1]?.count || 0,
                                            amountUSD: formatInputNum(
                                              i[1]?.amount || 0,
                                              2
                                            ),
                                            amountCrypto: formatCrypto(
                                              i[1]?.amountCrypto || 0
                                            ),

                                            [`amount${itemF}`]: formatInputNum(
                                              i[1]?.[`amount${itemF}`] || 0,
                                              2
                                            ),
                                            fees: formatInputNum(
                                              i[1]?.fees || 0,
                                              2
                                            ),

                                            amountConvert: formatInputNum(
                                              i[1]?.amountConvert || 0,
                                              2
                                            ),

                                            align: "right",
                                          })
                                        ),
                                      };
                                    }),
                                    {
                                      label: "Total",
                                      data: [
                                        {
                                          rate: "-",
                                          count: tot?.count || 0,
                                          amountUSD: formatInputNum(
                                            tot?.amount || 0,
                                            2
                                          ),
                                          amountCrypto: "-",
                                          [`amount${itemF}`]: formatInputNum(
                                            tot?.[`amount${itemF}`] || 0,
                                            2
                                          ),
                                          fees: formatInputNum(
                                            tot?.fees || 0,
                                            2
                                          ),

                                          amountConvert: formatInputNum(
                                            tot?.amountConvert || 0,
                                            2
                                          ),
                                        },
                                      ],
                                    },
                                  ]}
                                />
                              </div>
                            ) : (
                              <div className="p-6 text-sm dark:text-white">
                                No data to show
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </Fragment>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex h-[200px] items-center justify-center">
          <SimpleLoader />
        </div>
      )}
    </div>
  );
}

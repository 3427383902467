import React, { useState } from "react";
import { PageContainer } from "shared";
import { FullWidthTabs, SectionContent } from "components";
import ProfileImage from "./ProfileImage";
import Account from "./Account";
import Security from "./Security";

const tabList = [
  { label: "Account", Icon: "PersonOutline", Comp: Account },
  { label: "Security", Icon: "LockOutlined", Comp: Security },
];

export default function Index() {
  const [currentTab, setCurrentTab] = useState(tabList[0]);

  return (
    <PageContainer>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-7">
        <div className="col-span-1 md:col-span-2">
          <ProfileImage />
        </div>

        <div className="col-span-1 md:col-span-5">
          <SectionContent>
            <div className="rounded bg-box p-0 shadow-card-box dark:bg-gray-800">
              <FullWidthTabs
                {...{ currentTab, setCurrentTab, tabList }}
                Comp={<currentTab.Comp {...currentTab} />}
              />
            </div>
          </SectionContent>
        </div>
      </div>
    </PageContainer>
  );
}

export {
  EmailAuthProvider,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  updateProfile,
} from "firebase/auth";
export {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  collectionGroup,
  deleteDoc,
  deleteField,
  doc,
  documentId,
  getCountFromServer,
  getDoc,
  getDocs,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
export {
  deleteObject,
  getDownloadURL,
  getMetadata,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";

export {
  getMessaging,
  getToken as getTokenPushNotification,
  onMessage,
} from "firebase/messaging";

export {
  getMessaging as getMessagingBackground,
  onBackgroundMessage,
} from "firebase/messaging/sw";

export { getToken } from "firebase/app-check";

export { useEffect } from "react";
export {
  auth,
  auth2,
  db,
  db2,
  firebaseAppCheck,
  firebaseAppCheck2,
  storage,
  storage2,
} from "../config/firebaseConfig";

export { default as axios } from "axios";

export const baseURL_KP = "https://api.kaapo.ng/v1";
// export const baseURL_KP = "http://192.168.0.170:8083/v1";
// export const baseURL_KP = "http://192.168.0.170:8083/v1"; // my-Fi
// export const baseURL_KP = "http://192.168.1.43:8083/v1"; // Dotmac

export const baseURL_ET = "https://api.esetech.com.ng/v1";
// export const baseURL_ET = "http://192.168.0.170:8080/v1";
// export const baseURL_ET = "http://192.168.0.170:8080/v1"; // my-Fi
// export const baseURL_ET = "http://192.168.1.43:8080/v1"; // Dotmac

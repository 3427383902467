import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { classNames } from "utils";
import { SimpleIcon } from "./Icon";

export const HeaderBar = ({ title, actionButton, handleToggleClose }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar classes={{ root: "!min-h-[48px]" }}>
          <div className="flex w-full flex-row items-center justify-between">
            <h5 className="">{title}</h5>
            <div>
              <div className="flex flex-row">
                {actionButton &&
                  actionButton?.map((item) => (
                    <div className={classNames("ml-2")} key={item.label}>
                      <Button
                        color="inherit"
                        variant="outlined"
                        onClick={item.handleClick}
                      >
                        {item.label}
                      </Button>
                    </div>
                  ))}
              </div>
              {handleToggleClose && (
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleToggleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              )}
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export const TransReportHeader = ({ header }) => {
  return (
    <div className="flex flex-row items-center border-b p-3 dark:border-gray-700">
      <div className="flex h-6 w-6 items-center justify-center rounded-full bg-primary/50">
        <SimpleIcon fontSize={15} icon="ViewAgenda" />
      </div>
      <div className="ml-2 text-sm dark:text-white">{header}</div>
    </div>
  );
};

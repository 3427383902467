import React from "react";
import {
  SimpleLoader,
  SimpleTable,
  TransReportHeader as Header,
} from "components";
import { capitalize } from "lodash";
import { formatInputNum } from "utils";
import { useTransReportUtility } from "api";

export default function UtilitySummary({ platform }) {
  const [isSubmitting, isSubmitted, data] = useTransReportUtility(platform);

  const { utilityD, utilityConvtAirt } = data || {};

  // console.log(data, "data");

  return (
    <div>
      {data ? (
        <div>
          <div className="mt-1 mb-3 border-b dark:border-slate-700" />

          <div className="grid grid-cols-2 gap-4">
            {[
              {
                label: "utilities",
                columns: [
                  { label: "Product", id: "product" },
                  { label: "Count", id: "count", align: "right" },
                  { label: "Amount(NGN)", id: "amountNGN", align: "right" },
                ],
                rows: [
                  "airtime",
                  "data-bundle",
                  ...(platform === "kaapo" ? ["tv-cable", "electricity"] : []),
                  "total",
                ].map((i) => ({
                  product: i,
                  count: utilityD?.[`${i}`]?.count || 0,
                  amountNGN: formatInputNum(
                    utilityD?.[`${i}`]?.amountNGN || 0,
                    2
                  ),
                  align: "right",
                })),
              },
            ].map((item) => (
              <div className="col-span-1" key={item?.label}>
                <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                  <Header header={capitalize(item?.label)} />
                  <SimpleTable
                    columns={item?.columns}
                    rows={item?.rows}
                    // disableColumns
                  />
                </div>
              </div>
            ))}
            {platform === "kaapo" && (
              <div className="col-span-1">
                <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                  <Header header={capitalize("convert-airtime")} />
                  <SimpleTable
                    columns={[
                      { label: "Product", id: "product" },
                      { label: "Count", id: "count", align: "right" },
                      {
                        label: "AmountBuy(NGN)",
                        id: "amountNGNBuy",
                        align: "right",
                      },
                      {
                        label: "AmountSell(NGN)",
                        id: "amountNGNSell",
                        align: "right",
                      },
                    ]}
                    rows={["convert-airtime", "total"].map((item) => ({
                      product: item,
                      count: utilityConvtAirt?.[item]?.count || 0,
                      amountNGNBuy: formatInputNum(
                        utilityConvtAirt?.[item]?.amountNGNBuy || 0,
                        2
                      ),
                      amountNGNSell: formatInputNum(
                        utilityConvtAirt?.[item]?.amountNGNSell || 0,
                        2
                      ),
                      align: "right",
                    }))}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex h-[200px] items-center justify-center">
          <SimpleLoader />
        </div>
      )}
    </div>
  );
}

import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  SectionContent,
  SimpleIcon,
  PopOver,
  BackdropSection,
} from "components";
import { PageContainer } from "shared";
import { capitalize, toLower } from "lodash";

import RatePopup from "./subpage/RatePopup";
import { UpdateGiftcardFac } from "./components/AddGiftcardFac";
import EditGiftcard from "./components/EditGiftcard";
import AddGiftcard from "./components/AddGiftcard";
import DeleteGiftcard from "./components/DeleteGiftcard";

export default function Giftcard() {
  const [open, setOpen] = useState(false);
  const [currentGiftcard, setCurrentGiftcard] = useState(null);
  const [currentCardOnMouseOver, setCurrentCardOnMouseOver] = useState(null);

  const [currentRate, setCurrentRate] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState("NGN");

  // const [search, setSearch] = useState("");

  const { giftcards, giftcardFac, giftcardProps } = useSelector(
    (state) => state.giftcard
  );

  const {
    user_details: { position },
  } = useSelector((state) => state.profile);

  const getEntriesKeys = (rate) => Object.keys(rate || {});

  const { id, currency } = currentRate || {};

  const typeList = useCallback(() => {
    const t = giftcards && giftcards.find((i) => i.id === id)?.rate?.[currency];

    return getEntriesKeys(t);
  }, [giftcards, currency, id])();

  // const giftcardsFil = useCallback(() => {
  //   return (
  //     giftcards &&
  //     giftcards.filter((i) => toLower(i.name).startsWith(toLower(search)))
  //   );
  // }, [giftcards, search])();

  // const handleSearchChange = (e) => {
  //   setSearch(e.target.value);
  //   setCurrentRate(null);
  //   // setOpenAccordion(false);
  //   setOpenPopup(false);
  // };

  useEffect(() => {
    if (!openPopup) {
      setCurrentRate(null);
      setDefaultCurrency("NGN");
    }
  }, [openPopup]);

  return (
    <PageContainer>
      <UpdateGiftcardFac />

      <AddGiftcard />

      <SectionContent>
        <div className="rounded-lg bg-box p-0 shadow-card-box dark:bg-gray-900">
          <div className="m-0">
            <>
              {giftcards && giftcardFac && giftcardProps ? (
                <div className="grid grid-cols-2 gap-2">
                  {giftcards.length ? (
                    giftcards
                      .slice()
                      ?.sort(
                        (a, b) =>
                          b.dateCreated - a.dateCreated &&
                          a.title.localeCompare(b.title)
                      )
                      ?.map((item) => {
                        const { id, name, rate } = item;
                        // console.log(id, name);
                        return (
                          <div className="col-span-1" key={id}>
                            <div className="rounded-lg bg-box p-2 shadow-md dark:bg-gray-800">
                              <div className="w-full rounded border border-slate-100 bg-box p-2 shadow-sm dark:border-slate-700 dark:bg-gray-900">
                                <div className="mb-2 flex flex-row items-center justify-between rounded bg-white p-2 dark:bg-gray-800">
                                  <div className="flex flex-row items-center justify-start">
                                    <div className="flex h-8 w-8 flex-row items-center justify-center rounded bg-slate-50 dark:bg-slate-600">
                                      <SimpleIcon
                                        icon="ViewAgenda"
                                        // frontColor="text-gray-500"
                                        fontSize={18}
                                      />
                                    </div>
                                    <div>
                                      <div className="ml-2">
                                        <p className="font-bold  dark:text-gray-50">
                                          {capitalize(name)}
                                        </p>
                                        <p className="truncate text-sm text-gray-500 dark:text-gray-200">
                                          Edit {name} rate
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  {[
                                    "Supervisor",
                                    "Chief Executive Officer",
                                  ].includes(position) && (
                                    <div
                                      className="flex flex-row items-center justify-center gap-2"
                                      onMouseEnter={() =>
                                        setCurrentCardOnMouseOver(id)
                                      }
                                      onMouseLeave={() =>
                                        setCurrentCardOnMouseOver(null)
                                      }
                                    >
                                      {currentCardOnMouseOver === id && (
                                        <DeleteGiftcard cardId={id} />
                                      )}
                                      <Button
                                        size="small"
                                        color="success"
                                        label="Edit"
                                        handleClick={() => {
                                          setOpen(true);
                                          setCurrentGiftcard(id);
                                        }}
                                        // otherClass="!min-w-0"
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="flow-root">
                                  <div className="mt-1 mb-3 border-b dark:border-slate-700" />
                                  <div className="-mb-2 py-0" key={item.id}>
                                    {/* space-x-2 */}
                                    <div className="flex flex-wrap items-center">
                                      {getEntriesKeys(rate)?.length ? (
                                        getEntriesKeys(rate)
                                          ?.sort()
                                          .map((curr, index) => {
                                            const imgL = toLower(
                                              curr.slice(0, 2)
                                            );

                                            return (
                                              <PopOver
                                                key={index}
                                                placement="right"
                                                PopoverHandlerComponent={() => (
                                                  <div>
                                                    <button
                                                      className="mr-2 mb-2 flex  flex-row items-center rounded-lg border border-gray-200 bg-gray-100 px-2 py-2 dark:border-gray-600 dark:bg-gray-800 dark:text-white"
                                                      onClick={() => {
                                                        setCurrentRate({
                                                          title: item.title,
                                                          id: item.id,
                                                          currency: curr,
                                                        });
                                                      }}
                                                    >
                                                      <div className="font-semibold text-gray-500 dark:text-white">
                                                        {curr}
                                                      </div>
                                                    </button>
                                                  </div>
                                                )}
                                                PopoverContentComponent={() => (
                                                  <ul className="-my-3 -mx-1 divide-y divide-gray-200 dark:divide-gray-600 dark:bg-slate-800">
                                                    {typeList?.length ? (
                                                      typeList.map(
                                                        (type, index) => (
                                                          <li
                                                            key={index}
                                                            className="w-full py-2"
                                                          >
                                                            <button
                                                              className="w-full rounded bg-box p-2 text-left dark:bg-gray-900 dark:text-white"
                                                              onClick={() => {
                                                                setOpenPopup(
                                                                  true
                                                                );
                                                                setCurrentRate(
                                                                  (prevS) => ({
                                                                    ...prevS,
                                                                    type,
                                                                  })
                                                                );
                                                              }}
                                                            >
                                                              {type}
                                                            </button>
                                                          </li>
                                                        )
                                                      )
                                                    ) : (
                                                      <li className="w-full py-2">
                                                        No data
                                                      </li>
                                                    )}
                                                  </ul>
                                                )}
                                              />
                                            );
                                          })
                                      ) : (
                                        <div className="mb-2 rounded-lg border border-gray-200 bg-gray-100 px-2 py-2 text-sm font-semibold text-gray-500 dark:border-gray-500 dark:bg-gray-800">
                                          No data to show
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <div className="col-span-2">
                      <div className="flex h-40 w-full flex-row items-center justify-center dark:bg-slate-800">
                        No data found
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="relative flex h-48 w-full flex-row items-center justify-center rounded bg-box p-4 dark:bg-slate-800">
                  <div className="w-full p-4 text-center">
                    <BackdropSection open={true} />
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </SectionContent>
      <RatePopup
        openPopupRate={openPopup}
        setOpenPopupRate={setOpenPopup}
        {...{ currentRate, giftcardFac, defaultCurrency, setDefaultCurrency }}
      />
      <EditGiftcard
        {...{ open, setOpen, currentGiftcardId: currentGiftcard }}
      />
    </PageContainer>
  );
}

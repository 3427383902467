import React from "react";
import NewCustomers from "./NewCustomers";
import Traded from "./Traded";

const Referred = () => {
  return (
    <div>
      <div className="w-full px-5 py-10  h-fit">
        <div>
          <NewCustomers />
        </div>

        <div>{/* <Traded /> */}</div>
      </div>
    </div>
  );
};

export default Referred;

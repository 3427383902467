import React, { useState, useEffect } from "react";
import { BackdropSection, Button, IconBox, SectionContent } from "components";
import { classNames, formatDate } from "utils";
import { useFetchUsers, useFullDrawerUserDetails } from "api";

export default function Users({ platform }) {
  const [userId, setUserId] = useState();
  const [input, setInput] = useState("");
  const [usersDetails] = useFetchUsers(platform, userId);
  const [fullDrawerUserDetails] = useFullDrawerUserDetails();

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      setUserId(input);
    }
  };

  useEffect(() => {
    setUserId();
    setInput("");
  }, [platform]);

  return (
    <>
      <SectionContent>
        <div className="w-full rounded border border-slate-100 bg-box p-0 shadow-sm dark:border-slate-700 dark:bg-gray-800">
          <div className="relative overflow-x-auto p-2 shadow-md  sm:rounded-lg">
            <div className="mb-4 flex items-start justify-between bg-white dark:bg-gray-900">
              <div className="w-full">
                <input
                  value={input}
                  className="h-full w-full border-none bg-transparent p-4 outline-none dark:text-white placeholder:dark:text-gray-300"
                  placeholder="Input User ID or Email"
                  onKeyDown={handleOnKeyDown}
                  onChange={(e) => setInput(e.target.value)}
                />
              </div>
            </div>
            {usersDetails ? (
              <table className="w-full text-left text-gray-500 dark:text-gray-400">
                <thead className="bg-gray-50 text-sm uppercase  dark:bg-gray-700 dark:text-gray-200">
                  <tr>
                    {[
                      "Name",
                      "Phone Number",
                      "Status",
                      "Email Verified",
                      "Date Joined",
                      "Action",
                    ].map((item) => (
                      <th scope="col" className="py-2 px-6">
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {usersDetails?.length ? (
                    usersDetails.map((item) => (
                      <tr
                        className="cursor-pointer border-b bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:hover:bg-gray-600"
                        onClick={() => {
                          fullDrawerUserDetails({
                            userId: item.id,
                            platform,
                          });
                        }}
                        key={item.id}
                      >
                        {/* whitespace-nowrap  */}
                        <th
                          scope="row"
                          className="flex items-center py-4 px-6 dark:text-white"
                        >
                          <div className="flex h-10 w-10 flex-row items-center justify-center rounded-full bg-gray-200 dark:bg-gray-300">
                            <img
                              className="h-full w-full p-2"
                              src={item.avatarUrl}
                              alt={item.displayName}
                            />
                          </div>
                          <div className="pl-3">
                            <div className="text-base font-semibold">
                              {item.displayName}
                            </div>
                            <div className="font-normal text-gray-500 dark:text-gray-400">
                              {item.email}
                            </div>
                          </div>
                        </th>
                        <td className="py-4 px-6 dark:text-white">
                          +{item.phoneNumber}
                        </td>
                        <td className="py-4 px-6">
                          <div
                            className={classNames(
                              "flex items-center justify-center rounded-full p-2 text-sm",
                              item.accountStatus === "enabled"
                                ? "bg-green-100/20 text-green-400"
                                : "bg-red-100/20 text-red-400"
                            )}
                          >
                            {item.accountStatus}
                          </div>
                        </td>
                        <td className="py-4 px-6">
                          <div
                            className={classNames(
                              "flex items-center justify-center rounded-full p-2 text-sm",
                              item.emailVerified
                                ? "bg-green-100/20 text-green-400"
                                : "bg-red-100/20 text-red-400"
                            )}
                          >
                            {item.emailVerified ? "verified" : "unverified"}
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-4 px-6 dark:text-white">
                          {formatDate(item.dateCreated)}
                        </td>

                        <td className="py-4 px-6">
                          <div className="flex flex-row items-center gap-4">
                            <Button
                              label="View"
                              color="info"
                              size="small"
                              handleClick={() => {
                                fullDrawerUserDetails({
                                  userId: item.id,
                                  platform: "esetech",
                                });
                              }}
                              otherClass="!min-w-0"
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="h-32 w-full rounded bg-white p-4 dark:bg-gray-800">
                      <td colSpan={6} className="w-full p-4 text-center">
                        <div className="mt-0 flex h-full flex-col items-center justify-center text-sm text-gray-400">
                          <IconBox icon={"DeveloperBoardOff"} gradient />
                          <div className="mt-4 font-semibold">
                            {"No data to display"}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              <div className="relative h-48 w-full rounded bg-white p-4 dark:bg-gray-800">
                <div className="w-full p-4 text-center">
                  <BackdropSection open={true} />
                </div>
              </div>
            )}
          </div>
        </div>
      </SectionContent>
    </>
  );
}

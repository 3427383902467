import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  todoList: null,
};

export const todoSlice = createSlice({
  name: 'todo',
  initialState,
  reducers: {
    getTodoList: (state, { payload }) => {
      state.todoList = payload;
    },
  },
});

export const { getTodoList } = todoSlice.actions;

export default todoSlice.reducer;

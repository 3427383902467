import React, { useState } from "react";
import KaapoTraded from "../../components/ReferredTraders/kaapoTraded";
import EsetechTraded from "../../components/ReferredTraders/EsetechTraded";
import { FullWidthTabs } from "components";

const Traded = () => {
  const tabList = [
    {
      label: "Esetech",
      Comp: EsetechTraded,
      itemCount: 1 || 0,
      // Icon: "FacebookIcon",
    },
    {
      label: "Kaapo",
      Comp: KaapoTraded,
      itemCount: 1 || 0,
      // Icon: "FacebookIcon",
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabList[0]);

  return (
    <div>
      <FullWidthTabs
        {...{ currentTab, setCurrentTab, tabList }}
        Comp={<currentTab.Comp {...currentTab} />}
      />
    </div>
  );
};

export default Traded;

import React from "react";
import { useSelector } from "react-redux";
import {
  columns_alt,
  giftcards,
  convertFiat,
  withdrawFiat,
  crypto,
  utilities,
  fundFiat,
  transferFiat,
  transferBonus,
} from "utils/transactionProps";
import {
  useGetUserTransactionEse,
  useGetUserTransactionKap,
} from "api/actions/users";
import { formatDate } from "utils";
import { BackdropSection, TableData } from "components";

export default function Transactions({ platform }) {
  const { userDetails } = useSelector((state) => state.users);

  let transactionFunc =
    platform === "esetech"
      ? useGetUserTransactionEse
      : platform === "kaapo"
      ? useGetUserTransactionKap
      : useGetUserTransactionEse;

  const [getTransactions] = transactionFunc(userDetails?.userId);

  const rowsObj = (product) => {
    switch (product) {
      case "giftcard":
        return giftcards()?.rows;
      case "convert-fiat":
        return convertFiat()?.rows;
      case "payout":
        return withdrawFiat()?.rows;
      case "crypto":
        return crypto()?.rows;
      case "utility":
        return utilities()?.rows;
      case "deposit":
        return fundFiat()?.rows;
      case "transfer-fiat":
        return transferFiat()?.rows;
      case "transfer-bonus":
        return transferBonus()?.rows;
      default:
        break;
    }
  };

  const rows = getTransactions
    ?.sort((a, b) =>
      formatDate(b?.dateCreated, null) > formatDate(a?.dateCreated, null)
        ? 1
        : -1
    )
    ?.map((item, index) => rowsObj(item?.product)(item, index));

  return (
    <div className="w-full rounded border border-gray-300 p-2 dark:border-gray-700 dark:bg-gray-800">
      {getTransactions ? (
        <TableData
          rows={rows}
          columns={columns_alt}
          // onRowClick={handleRowlick}
          displayExport
        />
      ) : (
        <div className="relative h-48 w-full rounded bg-white p-4 dark:bg-gray-800">
          <div className="w-full p-4 text-center">
            <BackdropSection open={true} />
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { SimpleIcon } from "components";
import { Popover } from "@mui/material";

export default function MessageMoreOption({
  setOnEditMsg,
  messageDetails,
  handleDeleteMessage,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <button className="absolute right-2 top-[10%]" onClick={handleClick}>
        <div className="flex h-8 w-8 items-center justify-center rounded-full hover:bg-[rgba(0,0,0,0.4)]">
          <SimpleIcon icon="MoreVert" fontSize={16} />
        </div>
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "dark:!bg-gray-900 !min-w-[120px]",
        }}
      >
        <ul className="min-w-32 list-none p-2">
          {[
            {
              label: "Edit",
              id: "edit",
              handleClick: () =>
                setOnEditMsg({
                  edit: true,
                  editId: messageDetails?.id,
                  ...messageDetails,
                }),
            },
            {
              label: "Delete",
              id: "delete",
              handleClick: () => handleDeleteMessage(messageDetails),
            },
          ].map((item) => (
            <li className="hover:bg-gray-800/50" key={item.id}>
              <button
                className="w-full p-1 text-left text-sm dark:text-white"
                onClick={item.handleClick}
              >
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      </Popover>
    </>
  );
}

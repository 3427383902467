import React, { useState } from "react";
import { PageContainer } from "shared";
import { WeekTab } from "components/Tab";
import { useGetFilterDate } from "api";
import AllTransactions from "../components/AllTransactions";
import { useGetLencoWithdrawalESE } from "api/actions/transactionEse";
import { Button, CheckboxInputs, SectionContent } from "components";
import { PopoverAlt } from "components/Dialog";

export default function DailyTransaction() {
  const [currentD, setCurrentD] = useState(new Date());
  const [getTransCount, setGetTransCount] = useState(0);
  const [getFilterDate] = useGetFilterDate();
  const [beforeFilter, setBeforeFilter] = useState({
    status: "approved",
  });
  const [filter, setFilter] = useState(beforeFilter);

  // const [isSubmitting, isSubmitted, getData] = useGetLencoWithdrawalESE();

  const handleChange = (e, type) => {
    setBeforeFilter((prevS) => ({ ...prevS, [type]: e.target.value }));
  };

  const PopoverContent = (close) => (
    <div className="w-[300px]">
      <div className="grid grid-cols-2 gap-2">
        <div className="col-span-1">
          <div className="rounded p-2 dark:bg-slate-700 dark:text-white">
            <div className="text-sm">Filter by status</div>
            <CheckboxInputs
              handleChange={(e) => handleChange(e, "status")}
              checkList={[
                {
                  label: "Approved",
                  value: "approved",
                  checked: beforeFilter?.status === "approved",
                },
                {
                  label: "Declined",
                  value: "declined",
                  checked: beforeFilter?.status === "declined",
                },
              ]}
            />
          </div>
        </div>
        <div className="col-span-1">
          <div className="rounded p-2 dark:bg-slate-700 dark:text-white">
            <div className="text-sm">Filter by platform</div>
            <CheckboxInputs
              handleChange={(e) => handleChange(e, "platform")}
              checkList={[
                {
                  label: "Esetech",
                  value: "esetech",
                  checked: beforeFilter?.platform === "esetech",
                },
                {
                  label: "Kaapo",
                  value: "kaapo",
                  checked: beforeFilter?.platform === "kaapo",
                },
              ]}
            />
          </div>
        </div>
        {/* <div className="col-span-1">
        <div className="p-2 rounded dark:bg-slate-700 dark:text-white">
          <div className="text-sm">Filter by product</div>
          <CheckboxInputs
            checkList={[
              { label: "Payout" },
              { label: "Giftcards" },
              { label: "Crypto" },
            ]}
          />
        </div>
      </div> */}
        <div className="col-span-2 flex justify-end">
          <div className="flex flex-row gap-2">
            <Button
              label="Clear"
              color="info"
              handleClick={() => {
                setFilter({
                  status: "approved",
                });
                close();
              }}
            />
            <Button
              label="Filter"
              color="success"
              handleClick={() => {
                setFilter(beforeFilter);
                close();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <PageContainer>
      <div className="relative">
        <div className="absolute top-0 left-0">
          <PopoverAlt
            placement="bottom-start"
            PopoverHandlerComponent={() => (
              <SectionContent>
                <div className="rounded-lg p-0 shadow-card-box">
                  <div className="relative rounded bg-white p-2 dark:bg-gray-800">
                    <div className="absolute top-0 right-0">
                      <div className="flex h-6 w-6 flex-row items-center justify-center rounded-full bg-primary text-white">
                        {Object.keys(filter)?.length}
                      </div>
                    </div>
                    <div className="my-2 flex flex-row items-center justify-end">
                      <div className="px-4 dark:text-white">
                        Filter Transactions and more
                      </div>
                    </div>
                  </div>
                </div>
              </SectionContent>
            )}
            PopoverContentComponent={(close) => PopoverContent(close)}
          />
        </div>
        <WeekTab
          {...{ currentD, setCurrentD, getFilterDate }}
          countNum={getTransCount}
        />
        <div className="mt-2">
          <AllTransactions
            type="declined"
            setGetTransCount={setGetTransCount}
            currentD={currentD}
            filter={filter}
          />
        </div>
      </div>
    </PageContainer>
  );
}

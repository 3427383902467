import { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import * as i from "../lib";
import { notification } from "utils";
import { getStaff, getStaffTeam } from "redux/reducers/staffReducers";
import dayjs from "dayjs";
import { toLower } from "lodash";
import { axiosCall } from "api/helpers";

const isoWeek = require("dayjs/plugin/isoWeek");
const dayOfYear = require("dayjs/plugin/dayOfYear");
dayjs.extend(isoWeek);
dayjs.extend(dayOfYear);

const auth = i.getAuth();

export const useGetUsersCount = () => {
  const [getCount, setCount] = useState({ eseUsers: 0, kapUsers: 0, staff: 0 });

  useEffect(() => {
    const getCount = async () => {
      try {
        const collEse = i.collection(i.db, "users");
        const collKap = i.collection(i.db2, "users");
        const collEseStaff = i.query(
          i.collection(i.db, "staff"),
          i.where("accountStatus", "==", "enabled")
        );

        const snapshotEse = await i.getCountFromServer(collEse);
        const snapshotKap = await i.getCountFromServer(collKap);
        const snapshotEseStaff = await i.getCountFromServer(collEseStaff);

        const { count: countEse } = snapshotEse.data();
        const { count: countKap } = snapshotKap.data();
        const { count: countEseStaff } = snapshotEseStaff.data();

        setCount((prevS) => ({
          ...prevS,
          eseUsers: countEse,
          kapUsers: countKap,
          staff: countEseStaff,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getCount();
  }, []);
  return [getCount];
};

export const useCreateStaffTeam = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const createTeam = async ({ teamName, teamId, type }) => {
    if (!Boolean(teamName) || teamName.length <= 3 || !type) {
      notification("warning", "Invalid Input");
      return;
    }
    setUpdating(true);
    try {
      if (type === "create") {
        await i.setDoc(i.doc(i.collection(i.db, "staffTeam")), {
          teamName,
          memberUids: [],
          dateCreated: Date.now(),
          active: true,
        });
      }

      if (type === "edit") {
        if (!teamId) return;
        const docRef = i.doc(i.db, "staffTeam", teamId);

        await i.updateDoc(docRef, {
          teamName,
        });
      }
      setUpdating(false);
      setUpdated(true);
      notification("success", `Team successfully ${type}ed.`);
    } catch (error) {
      console.log(error);
      setUpdating(false);
      notification("danger", "An error occured.", "error");
    }
  };
  return [isUpdating, isUpdated, createTeam];
};

export const useGetStaffTeam = () => {
  const dispatch = useDispatch();
  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    let unsub;
    const db = i.db;
    try {
      if (userUid) {
        let q = i.query(
          i.collection(db, "staffTeam"),
          i.orderBy("dateCreated", "desc")
        );
        unsub = i.onSnapshot(q, (querySnapshot) => {
          const t = [];
          querySnapshot.forEach((doc) => {
            t.push({ id: doc.id, ...doc.data() });
          });

          dispatch(getStaffTeam(t));
        });
      }
    } catch (error) {
      console.log(error);
    }
    return unsub;
  }, [dispatch, userUid]);
};

export const useAddStaffToTeam = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  let actionType;
  const addStaffToTeam = async ({ staffId, teamId, action }) => {
    actionType = action;
    setUpdating(true);
    try {
      if (!(staffId || teamId || action)) {
        return;
      }
      if (action === "added") {
        await Promise.all([
          i.updateDoc(i.doc(i.db, "staffTeam", teamId), {
            memberUids: i.arrayUnion(staffId),
          }),
          i.updateDoc(i.doc(i.db, "staff", staffId), {
            teamId,
          }),
        ]);
      }

      if (action === "deleted") {
        await Promise.all([
          i.updateDoc(i.doc(i.db, "staffTeam", teamId), {
            memberUids: i.arrayRemove(staffId),
          }),
          i.updateDoc(i.doc(i.db, "staff", staffId), {
            teamId: i.deleteField(),
          }),
        ]);
      }

      setUpdating(false);
      setUpdated(true);
      notification("success", `Team member successfully ${action}.`);
    } catch (error) {
      console.log(error);
      setUpdating(false);
      notification("danger", "An error occured.", "error");
    }
  };
  return [isUpdating, isUpdated, addStaffToTeam, actionType];
};

export const useDeleteStaffTeam = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const deleteTeam = async ({ teamId }) => {
    setUpdating(true);
    try {
      await i.deleteDoc(i.doc(i.db, "staffTeam", teamId));
      setUpdating(false);
      setUpdated(true);
      notification("success", "Team successfully deleted.");
    } catch (error) {
      setUpdating(false);
      notification("danger", "An error occured.", "error");
    }
  };
  return [isUpdating, isUpdated, deleteTeam];
};

export const useAllStaff = ({ type = "enabled" }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsub;
    if (auth?.currentUser) {
      try {
        const q = i.query(
          i.collection(i.db, "staff"),
          i.where("accountStatus", "==", type),
          // i.where("email", "not-in", [
          //   "admin.esetech@gmail.com",
          //   "ese@esetech.com.ng",
          // ]),
          i.orderBy("email"),
          i.orderBy("accountCreated", "desc")
        );

        unsub = i.onSnapshot(q, (querySnapshot) => {
          const t = [];
          querySnapshot.forEach((doc) => {
            t.push({ id: doc.id, ...doc.data() });
          });

          dispatch(getStaff(t));
        });
      } catch (error) {
        console.log(error, "error");
        dispatch(getStaff(null));
      }
    } else {
      dispatch(getStaff(null));
    }
    return unsub;
  }, [dispatch, auth?.currentUser]);
};

export const useUpdateStaffProfile = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  useEffect(() => {
    if (isUpdated) {
      setTimeout(() => {
        setUpdated(false);
      }, 500);
    }
  }, [isUpdated]);

  const updateProfile = async (values, staffId, type) => {
    try {
      if (
        !Object.values(values || {}).every((i) => Boolean(i)) &&
        type !== "role"
      ) {
        notification("warning", "Invalid inputs!");
      }
      setUpdating(true);
      setUpdated(false);
      // await i.updateProfile(staffId, values);

      await Promise.all([
        i.updateDoc(i.doc(i.db, "staff", staffId), values),
        i.updateDoc(i.doc(i.db2, "staff", staffId), values),
      ]);
      setUpdating(false);
      setUpdated(true);
      notification(
        "success",
        `Staff ${type === "role" ? "Role" : "Profile"} successfully updated.`
      );
    } catch (error) {
      console.log(error);
      setUpdating(false);
      notification("danger", "An error occured. Try again later!", "error");
    }
  };

  return [isUpdating, isUpdated, updateProfile];
};

export const useGetCurrentTeam = () => {
  const { staffTeam } = useStore().getState()?.staff || {};

  const [getWeekD, setGetWeekD] = useState(null);
  const [currentD, setCurrentD] = useState(new Date());
  const [currentWorkingTeam, setCurrentWorkingTeam] = useState(null);
  const startOfWeek = dayjs(currentD).startOf("isoWeek");

  const num = {
    0: 1,
    1: -1,
    2: -1,
    3: 0,
    4: 0,
    5: 1,
  };

  const teams = staffTeam?.reduce((acc, item, index) => {
    acc[item?.id] = {
      num: index * 2,
      name: toLower(item?.teamName),
      id: item?.id,
    };
    return acc;
  }, {});

  const getSchedules = () => {
    const schedules =
      getWeekD &&
      getWeekD?.reduce((acc, item, index) => {
        const date1 = dayjs("2021-05-13");
        const diff = dayjs(item).diff(date1, "day");
        const currentDayOfYear = diff;

        const obj = Object?.values(teams || {})?.reduce((acc1, item1) => {
          acc1[num?.[(currentDayOfYear + item1?.num) % 6]] = item1?.id;
          return acc1;
        }, {});

        acc[index] = obj;
        return acc;
      }, {});

    return schedules;
  };

  const teamSchedules = getSchedules();

  const handleWeekNav = (dayNav) => {
    const current = dayjs(currentD);
    const formatD =
      dayNav === "add" ? current.add(7, "d") : current.subtract(7, "d");
    setCurrentD(formatD.format("YYYY/MM/DD"));
  };

  useEffect(() => {
    const weekD = [...Array(7)].map((_, ind) => {
      const weekClone = startOfWeek.clone().add(ind, "d");
      return weekClone.format("YYYY/MM/DD");
    });
    setGetWeekD(weekD);
  }, [currentD]);

  useEffect(() => {
    let getCurrentTeam;
    const getCurrentTime = dayjs().format("YYYY/MM/DD");

    const getWeekIndex = getWeekD?.findIndex((i) => i === getCurrentTime);
    const currentSchedule = teamSchedules?.[getWeekIndex];
    const isDayTime = dayjs().get("hour") > 6 && dayjs().get("hour") < 18;

    if (isDayTime) {
      getCurrentTeam = currentSchedule?.["0"];
    } else {
      getCurrentTeam = currentSchedule?.["1"];
    }
    setCurrentWorkingTeam(getCurrentTeam);
  }, [getWeekD, teamSchedules]);

  return [
    getWeekD,
    teamSchedules,
    teams,
    handleWeekNav,
    currentD,
    setCurrentD,
    currentWorkingTeam,
    staffTeam,
  ];
};

export const useSendStaffEmail = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setUpdated(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [isUpdated]);

  const sendEmail = async (values) => {
    const { to, from, subject, html } = values;
    if (!(to && from && subject && html)) {
      notification("warning", "Invalid Input");
      return;
    }

    setUpdating(true);
    try {
      const valueFormat = {
        ...values,
        agentId: auth.currentUser.uid,
        platform: "esetech",
      };
      await axiosCall(
        `${i.baseURL_ET}/staff/send_staff_email`,
        valueFormat,
        true,
        "esetech"
      );

      setUpdating(false);
      setUpdated(true);
      notification("success", `Email successfully sent`);
    } catch (error) {
      console.log(error);
      setUpdating(false);
      notification("danger", "An error occured.", "error");
    }
  };
  return [isUpdating, isUpdated, sendEmail];
};

import { Loader } from "shared";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

export const UserIsNotAuthenticated = ({ children }) => {
  const {
    auth: { current_user, isLoaded, isLoading, isEmpty },
    profile,
    auth,
  } = useSelector((state) => state);

  if (!current_user && !isLoaded) {
    return <Loader />;
  }

  if (!current_user && isLoaded) {
    return <>{children}</>;
  }

  if (current_user && isLoaded) {
    return <Navigate to="/" />;
  }
  return <Loader />;
};

export const UserIsAuthenticated = ({ children }) => {
  const {
    auth: { current_user, isLoaded, isLoading, isEmpty },
    profile: { user_details },
  } = useSelector((state) => state);

  if (!current_user && !isLoaded && !user_details) {
    return <Loader fullPage />;
  }

  if (!current_user && isLoaded) {
    return <Navigate to="/login" />;
  }

  if (current_user && isLoaded && user_details) {
    return <>{children}</>;
  }

  return <Loader fullPage />;
};

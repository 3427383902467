import React from "react";
import { classNames } from "utils";

export default function Container({ children, otherClass, disableFullHeight }) {
  return (
    <div
      className={classNames(
        disableFullHeight ? "" : "h-full",
        "rounded bg-card p-2 shadow-drawer"
      )}
    >
      <div
        className={classNames(
          otherClass || "",
          disableFullHeight ? "" : "h-full",
          "rounded bg-white text-text-color"
        )}
      >
        {children}
      </div>
    </div>
  );
}

export function SectionContent({ children, otherClass }) {
  return (
    <div
      className={classNames(
        "rounded border border-slate-200 bg-white p-2 shadow-md dark:border-slate-700 dark:bg-gray-900",
        otherClass
      )}
    >
      {children}
    </div>
  );
}

import React from "react";
import { useGetUserCryptoAddress } from "api/actions/users";
import { BackdropSection } from "components";
import { startCase } from "lodash";
import { useSelector } from "react-redux";

export default function CryptoAddress() {
  const { userDetails } = useSelector((state) => state.users);

  const [addresses] = useGetUserCryptoAddress(userDetails?.userId);

  return (
    <div className="w-full rounded border border-gray-300 p-2 dark:border-gray-700 dark:bg-gray-800">
      {addresses ? (
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            {addresses === "null" ? (
              <div className="rounded bg-gray-900">
                <div className="p-4 dark:text-white">
                  No crypto wallet address generated
                </div>
              </div>
            ) : (
              <>
                {Object.keys(addresses?.cryptoAddresses)?.length && (
                  <>
                    <div className="grid-col-1 grid gap-2">
                      {Object.entries(addresses?.cryptoAddresses)?.map(
                        (item) => (
                          <>
                            {item[1] && (
                              <div className="cols-span-1 shadow-sm">
                                <div className="rounded bg-box p-4 dark:bg-gray-900">
                                  <div className="mb-2 rounded bg-white p-2 dark:bg-gray-800 dark:text-white">
                                    {startCase(item[0])}
                                  </div>
                                  <div className="space-y-2">
                                    <div className="flex flex-row items-center justify-between  dark:text-white">
                                      <div className="text-sm">
                                        {"Wallet Address"}
                                      </div>
                                      <div>{item[1]?.address}</div>
                                    </div>
                                    <div className="flex flex-row items-center justify-between dark:text-white">
                                      <div className="text-sm">
                                        {"Wallet UID"}
                                      </div>
                                      <div>{item[1]?.uid}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )
                      )}
                    </div>
                    <div className="grid-col-1 mt-4 grid gap-2">
                      {Object.entries(addresses?.otherInfo)?.map((item) => (
                        <div className="cols-span-1 rounded bg-box p-4 dark:bg-gray-900">
                          <div className="flex flex-row items-center justify-between dark:text-white">
                            <div>{startCase(item[0])}</div>
                            <div>{item[1]}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="col-span-1">
            <div className="rounded bg-gray-900">
              <div className="p-4 dark:text-white">No Fiat Account Details</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative h-48 w-full rounded bg-white p-4 dark:bg-gray-800">
          <div className="w-full p-4 text-center">
            <BackdropSection open={true} />
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { PageContainer } from "shared";
import { FullWidthTabs, SectionContent } from "components";
import EsetechTrans from "./EsetechTrans";
import KaapoTrans from "./KaapoTrans";

const tabList = [
  { label: "Esetech", Icon: "WebOutlined", Comp: EsetechTrans },
  { label: "Kaapo", Icon: "WebOutlined", Comp: KaapoTrans },
];

export default function Index() {
  const [currentTab, setCurrentTab] = useState(tabList[0]);

  return (
    <PageContainer>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-1">
        <div className="col-span-1 md:col-span-1">
          <SectionContent>
            <div className="rounded-lg bg-box p-0 shadow-card-box dark:bg-gray-800">
              <FullWidthTabs
                {...{ currentTab, setCurrentTab, tabList }}
                Comp={<currentTab.Comp {...currentTab} />}
              />
            </div>
          </SectionContent>
        </div>
      </div>
    </PageContainer>
  );
}

import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  CheckboxInputs,
  Loader,
  RadioInputs,
  SectionContent,
  TextField,
} from "components";
import {
  useUserSendPushNotification,
  useUserSavePushNotification,
  useGetPushNotification,
} from "api";
import { useSelector } from "react-redux";
import { capitalize } from "lodash";
import { classNames, formatDate } from "utils";
import {
  useSendPushNotifVerfiyEmailOtp,
  useUserEditPushNotification,
} from "api/actions/users";
import PasswordDialog from "components/PasswordDialog";
import { listContentUpdate } from "utils/constants";

const tagOptions = [
  { title: "Ready to send", value: "readyToSend", id: "ready" },
  { title: "Leave as draft", value: "leaveAsDraft", id: "draft" },
];

const contentNotifications = [
  {
    title: "Completed Notifications",
    id: "ready",
    noContentText: "No content to display",
  },
  {
    title: "Draft Notifications",
    id: "draft",
    noContentText: "No draft to display",
  },
];

export default function NotificationContent({ platform }) {
  const [content, setContent] = useState(null);
  const [userType, setUserType] = useState(null);
  const [saveInCol, setSaveInCol] = useState(false);
  const [tags, setTags] = useState(null);
  const [specificUserIds, setSpecificUserIds] = useState(null);
  const [populatedContent, setPopulatedContent] = useState(null);

  const [openSavePopup, setOpenSavePopup] = useState(false);

  const [currentDragged, setCurrentDragged] = useState(null);
  const [getPassField, setPassField] = useState({
    password: "",
    code: "",
  });

  const { userPushNotification } = useSelector((state) => state.users || {});
  const { user_details } = useSelector((state) => state.profile);

  const [isSubmittingSend, isSubmittedSend, sendNotif] =
    useUserSendPushNotification();
  const [isSubmittingSave, isSubmittedSave, saveNotif] =
    useUserSavePushNotification();
  const [isSubmittingEdit, isSubmittedEdit, editNotif] =
    useUserEditPushNotification();

  const [_, __, sendVerifyOtp] = useSendPushNotifVerfiyEmailOtp();

  useGetPushNotification(platform);

  const handleNotificationContent = (e) => {
    let val = e.target.value;
    const name = e.target.name;

    const wordC = name === "message" ? 200 : 40;

    if (val.length >= wordC) {
      setContent((prevS) => ({
        ...prevS,
        [name]: val.slice(0, wordC),
      }));
    } else {
      setContent((prevS) => ({ ...prevS, [name]: val }));
    }
  };

  const handleSetSpecificUserIds = (e) => {
    let val = e.target.value;
    val = val.replace(/\s+/g, "");
    setSpecificUserIds(val);
  };

  const handleSendNotification = () => {
    const callback = () => {
      setOpenSavePopup(false);
    };
    sendNotif(
      { content, userType, specificUserIds, saveInCol, platform },
      { ...getPassField, tags: populatedContent.tags },
      callback
    );
  };

  const handleSaveNotification = () => {
    saveNotif({
      title: content?.title,
      message: content?.message,
      id: content?.id || `${platform}_notif_00${userPushNotification?.length}`,
      userType,
      specificUserIds,
      saveInCol,
      tags: tags?.id,
      platform,
      ...(!content?.id
        ? { dateCreated: Date.now() }
        : { dateUpdated: Date.now() }),
    });
  };

  const handleSaveOnEdit = (id, category) => {
    const getTag = tagOptions?.find((item) => item.id === category)?.id;
    editNotif({
      id,
      tags: getTag,
      platform,
      dateUpdated: Date.now(),
    });
  };

  const handleOnDragStart = (e, data) => {
    e.dataTransfer.setData("id", JSON.stringify(data));
  };

  const handleOnDrop = (e, category) => {
    e.preventDefault();
    let data = e.dataTransfer.getData("id");
    const dataF = JSON.parse(data);
    const { id, category: categoryFrom } = dataF;

    if (categoryFrom !== category) {
      handleSaveOnEdit(id, category);
    }
  };

  const handleClearForm = () => {
    setContent(null);
    setUserType(null);
    setSaveInCol(false);
    setSpecificUserIds(null);
    setPopulatedContent(null);
    setTags(null);
    setPassField({
      password: "",
      code: "",
    });
  };

  useEffect(() => {
    if (populatedContent) {
      const getTag = tagOptions?.find(
        (item) => item.id === populatedContent?.tags
      );

      setContent((prevS) => ({
        ...prevS,
        title: populatedContent?.title,
        message: populatedContent?.message,
        id: populatedContent?.id,
      }));
      setUserType(populatedContent?.userType);
      setSaveInCol(populatedContent?.saveInCol);
      setSpecificUserIds(populatedContent?.specificUserIds);
      setTags(getTag);
    }
  }, [populatedContent]);

  useEffect(() => {
    handleClearForm();
  }, [isSubmittedSend, isSubmittedSave, platform]);

  useEffect(() => {
    if (userType === "all") setSpecificUserIds(null);
  }, [userType]);

  const getContentCount = `${
    (Boolean(content?.message) ? content?.message?.split(" ") : [])?.length
  } words / ${content?.message?.length || 0} chars`;

  const getNotification = (tags) => {
    return userPushNotification
      ?.filter((item) => item?.tags === tags)
      ?.sort((a, b) => b?.dateUpdated - a?.dateUpdated);
  };

  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="col-span-1">
        <SectionContent>
          <div className="rounded bg-box p-4 shadow-card-box dark:bg-gray-800">
            <div className="mb-2 flex flex-row items-end justify-between">
              <h4 className="text-lg text-primary dark:text-light-primary">
                Push Notification - {capitalize(platform)}
              </h4>
              <div className="flex flex-row gap-2">
                <Button
                  label="Save"
                  color="info"
                  isSubmitting={isSubmittingSave}
                  handleClick={handleSaveNotification}
                />
                {populatedContent?.tags === "ready" &&
                listContentUpdate.includes(user_details.position) &&
                tags?.id === "ready" ? (
                  <Button
                    label="Send"
                    gradient
                    handleClick={() => {
                      const callback = () => {};
                      sendVerifyOtp(callback);
                      setOpenSavePopup(true);
                    }}
                  />
                ) : null}
              </div>
            </div>

            <hr className="mb-6 border-t dark:border-gray-500" />
            <div>
              <div className="mb-6">
                <TextField
                  label="Title"
                  placeholder="Title"
                  value={content?.title || ""}
                  name="title"
                  handleChange={handleNotificationContent}
                />
              </div>

              <div>
                <TextField
                  label="Write Content"
                  multiline
                  multilinerows={4}
                  placeholder="Write here!"
                  value={content?.message || ""}
                  name="message"
                  handleChange={handleNotificationContent}
                />
              </div>

              <div className="text-right text-sm dark:text-gray-500">
                {getContentCount}
              </div>
            </div>
            <div className="mt-4">
              <div className="mb-4">
                <h5 className="text-base text-primary dark:text-light-primary">
                  Select tag
                </h5>
              </div>
              <Autocomplete
                options={tagOptions}
                inputLabel="Select Tag"
                handleChange={(e) => {
                  setTags(e?.[0]);
                }}
                valueAlt={tags ? [tags] : []}
                setValueAlt={(e) => {
                  setTags(e?.[0]);
                }}
              />
            </div>

            <div className="mt-4">
              <div className="mb-1">
                <h5 className="text-base text-primary dark:text-light-primary">
                  Select users options
                </h5>
              </div>
              <RadioInputs
                radioList={[
                  {
                    label: "Specific users",
                    value: "specific",
                    checked: userType === "specific",
                    TextComponent:
                      userType === "specific" ? (
                        <div className="my-4">
                          <TextField
                            label="Users IDs"
                            multiline
                            placeholder="Input users IDs separated with commas"
                            value={specificUserIds || ""}
                            handleChange={handleSetSpecificUserIds}
                          />
                        </div>
                      ) : null,
                  },
                  {
                    label: "All users",
                    value: "all",
                    checked: userType === "all",
                  },
                ]}
                handleChange={(e) => setUserType(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <div className="mb-1">
                <h5 className="text-base text-primary dark:text-light-primary">
                  Select save options
                </h5>
              </div>
              <CheckboxInputs
                checkList={[
                  {
                    label: "Save in notification collection",
                    value: "saveInCol",
                    checked: saveInCol,
                  },
                ]}
                handleChange={(e) => setSaveInCol(!saveInCol)}
              />
            </div>
            <div className="mt-4 text-right">
              <Button
                label="Clear"
                color="info"
                handleClick={handleClearForm}
              />
            </div>
          </div>
        </SectionContent>
      </div>

      {/* <NotificationContent1
        {...{
          platform,
          handleSaveOnEdit,
          handleClearForm,
        }}
      /> */}
      <div className="col-span-1">
        <div className="grid grid-cols-1 gap-6">
          {contentNotifications.map((item) => (
            <div className="col-span-1" key={item.id}>
              <div
                // onDragStart={() => {}}
                onDragOver={(e) => {
                  e.preventDefault();
                  setCurrentDragged(item.id);
                }}
                onDragEnter={(e) => {
                  // e.preventDefault();
                  setCurrentDragged(item.id);
                }}
                onDragLeave={(e) => {
                  e.preventDefault();
                  setCurrentDragged(null);
                }}
                onDragEnd={(e) => {
                  // e.preventDefault();
                  setCurrentDragged(null);
                }}
                onDrop={(e) => {
                  handleOnDrop(e, item.id);
                  setCurrentDragged(null);
                  setPopulatedContent(null);
                  handleClearForm();
                }}
              >
                <SectionContent>
                  <div
                    className={classNames(
                      "rounded border-2 bg-box p-4 shadow-card-box dark:bg-gray-800",
                      currentDragged === item.id
                        ? "border-dashed dark:border-gray-400"
                        : "border-transparent"
                    )}
                  >
                    <h4 className="mb-2 text-lg text-primary dark:text-light-primary">
                      {item?.title}
                    </h4>

                    <hr className="mb-6 border-t dark:border-gray-500" />
                    <div className="flex flex-col gap-4">
                      {getNotification(item.id) ? (
                        <>
                          {getNotification(item.id)?.length ? (
                            getNotification(item.id).map((itemNotif) => (
                              <button
                                key={itemNotif.id}
                                draggable
                                onDragStart={(e) => {
                                  handleOnDragStart(e, {
                                    id: itemNotif?.id,
                                    category: item.id,
                                  });
                                }}
                                className={classNames(
                                  "w-full rounded border bg-box p-2 text-left dark:border-gray-600 dark:bg-gray-700 dark:text-white",
                                  itemNotif?.id === populatedContent?.id &&
                                    populatedContent?.tags === item.id
                                    ? "border-2 dark:border-gray-400"
                                    : ""
                                )}
                                onClick={() => setPopulatedContent(itemNotif)}
                              >
                                <div className="mb-2 flex flex-row items-center justify-between">
                                  <div className="font-bold">
                                    {itemNotif?.title}
                                  </div>
                                  <div className="text-sm">
                                    {formatDate(
                                      itemNotif?.dateUpdated ||
                                        itemNotif?.dateCreated
                                    )}
                                  </div>
                                </div>
                                <div className="">{itemNotif?.message}</div>
                                <div className="mt-2 flex items-start">
                                  <div
                                    className={classNames(
                                      "rounded-lg p-1 text-sm text-white",
                                      item?.id === "ready"
                                        ? "bg-emerald-500"
                                        : "bg-cyan-500"
                                    )}
                                  >
                                    {capitalize(itemNotif?.tags)}
                                  </div>
                                </div>
                              </button>
                            ))
                          ) : (
                            <div className="w-full rounded bg-box p-4 text-left dark:bg-gray-700 dark:text-white">
                              {item.noContentText}
                            </div>
                          )}
                        </>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>
                </SectionContent>
              </div>
            </div>
          ))}
        </div>
      </div>
      <PasswordDialog
        {...{
          title: "Send Notification",
          open: openSavePopup,
          setOpen: setOpenSavePopup,
          getField: getPassField,
          setField: setPassField,
          isSubmitting: isSubmittingSend,
          handleClick: handleSendNotification,
        }}
      />
    </div>
  );
}

import { CircularProgress } from "@mui/material";
import { SimpleIcon } from "components";
import { decode } from "js-base64";
import { toLower } from "lodash";
import React from "react";
import { classNames, formatDate } from "utils";
import MessageMoreOption from "./MessageMoreOption";
import { EditMessageInput } from "./MessageTextInput";

export default function SingleMessageChat({
  msgItem,
  onEditMsg,
  setOnEditMsg,
  handleDeleteMessage,
  handleEditMessage,
  arrIndexCurrent,
  arrIndexPrev,
}) {
  const { isSender, senderImage, sender, isEdited, isDeleted, isDelivered } =
    msgItem;
  const isSameUserImg = arrIndexCurrent?.sender !== arrIndexPrev?.sender;
  const isMessageEditable = !(
    onEditMsg?.edit && onEditMsg?.editId === msgItem.id
  );

  return (
    <div
      key={msgItem.id}
      className={classNames("flex-start flex", isSender ? "justify-end" : "")}
    >
      {isSameUserImg ? (
        <img
          src={require(`assets/avatar/avatar-${
            toLower(senderImage) || "female"
          }.png`)}
          alt={sender}
          className={classNames(
            "mt-6 h-6 w-6 rounded-full",
            isSender ? "order-2" : ""
          )}
        />
      ) : (
        <div
          className={classNames(
            "h-6 w-6 rounded-full",
            isSender ? "order-2" : ""
          )}
        />
      )}

      {isMessageEditable ? (
        <div
          className={classNames(
            "flex w-[40%] flex-col",
            isSender ? "mr-2" : "ml-2"
          )}
        >
          <div className="mt-1 text-right text-sm text-gray-400">
            {formatDate(msgItem?.timeSent, "hh:mm A")}
          </div>
          <div
            className={classNames(
              "bg-gradient-to-r",

              isSender
                ? !isDeleted
                  ? "rounded-l-2xl rounded-br-2xl from-emerald-800 to-dark_bg_gradient_from"
                  : "rounded-l-2xl rounded-br-2xl from-emerald-800/40 to-dark_bg_gradient_to/40"
                : !isDeleted
                ? "rounded-r-2xl rounded-bl-2xl from-slate-100 to-slate-200 dark:from-slate-800/70 dark:to-gray-800"
                : "rounded-r-2xl rounded-bl-2xl from-box to-slate-200/50 dark:from-slate-900/30 dark:to-gray-900/50"
            )}
          >
            {!isDeleted ? (
              <div className="relative overflow-hidden">
                <div
                  className={classNames(
                    "p-4",
                    isSender
                      ? "mr-0 text-gray-200"
                      : "text-gray-600 dark:text-gray-200"
                  )}
                >
                  <div className="whitespace-pre-wrap ">
                    {decode(msgItem?.message)}
                  </div>
                  <div className="mt-1 flex items-center justify-end">
                    {isDelivered && msgItem?.timeSent ? (
                      <SimpleIcon
                        icon={isDelivered ? "DoneAll" : "Done"}
                        fontSize={16}
                        frontColor=" text-gray-400"
                      />
                    ) : (
                      <CircularProgress
                        size={10}
                        thickness={2}
                        sx={{ color: "white" }}
                      />
                    )}
                  </div>
                </div>

                {isSender && isDelivered && (
                  <MessageMoreOption
                    setOnEditMsg={setOnEditMsg}
                    messageDetails={{
                      id: msgItem?.id,
                      message: msgItem?.message,
                      senderId: msgItem?.sender,
                      messageId: msgItem?.id,
                    }}
                    handleDeleteMessage={handleDeleteMessage}
                  />
                )}
              </div>
            ) : (
              <div className="p-4 italic text-gray-400">Message deleted</div>
            )}
          </div>

          {isEdited && !isDeleted && (
            <div className="flex justify-end">
              <span className="text-xs dark:text-gray-400">edited</span>
            </div>
          )}
        </div>
      ) : (
        <EditMessageInput
          setOnEditMsg={setOnEditMsg}
          currentMessage={onEditMsg?.message}
          handleEditMessage={handleEditMessage}
        />
      )}
    </div>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import { Popover, TextField } from "@mui/material";
import { SimpleIcon } from "components";
import { useSendChatMessage } from "api";
import { ChatContext } from "../ChatContext";
import EmojiPicker from "emoji-picker-react";
import { decode } from "js-base64";
import useSound from "use-sound";
import audioSound from "assets/audio/beep2.mp3";

export default function MessageTextInput({ currentChatUserDetails }) {
  const [message, setMessage] = useState("");
  const [getEmoji, setEmoji] = useState("");
  const inputRef = useRef(null);

  const [sendChatMessage, isSubmitting, isSubmitted] = useSendChatMessage();

  const { scrollToTheBottom } = useContext(ChatContext);

  const [play, { stop }] = useSound(audioSound, {
    interrupt: true,
    volume: 1,
  });

  const handleSendMessage = (e, type) => {
    const msg = message.trim();
    if (!Boolean(msg)) {
      return;
    }

    if (type === "keydown") {
      const eventCode = e.keyCode === 13 && !e.shiftKey;
      if (eventCode) {
        sendChatMessage(msg, currentChatUserDetails);
        setMessage("");
        play();
      }
    } else {
      sendChatMessage(msg, currentChatUserDetails);
      setMessage("");
    }
  };

  useEffect(() => {
    if (getEmoji) {
      const { selectionStart, selectionEnd } = inputRef.current;
      const text =
        message.slice(0, selectionStart) +
        getEmoji +
        message.slice(selectionEnd);

      setMessage(text);
      setEmoji(null);
    }
  }, [getEmoji, message]);

  useEffect(() => {
    if (isSubmitted) scrollToTheBottom();
  }, [isSubmitted, scrollToTheBottom]);

  return (
    <>
      <div className="absolute bottom-0 right-0 w-full bg-box p-4 dark:bg-slate-900">
        <div className="w-full rounded-xl border p-4 dark:border-gray-700">
          <div className="flex items-end gap-3">
            <EmojiButton setEmoji={setEmoji} />
            <TextField
              inputRef={inputRef}
              size="medium"
              label=""
              multiline
              maxRows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyUp={(e) => handleSendMessage(e, "keydown")}
              placeholder="Type here"
              classes={{
                root: "!w-full",
              }}
              InputProps={{
                classes: {
                  input: "!text-gray-500 dark:!text-gray-100",
                  placeholder: "dark:!text-gray-100",
                  root: "!p-0 !mx-2",
                  notchedOutline: "!border-none",
                },
              }}
            />
            <button>
              <SimpleIcon
                icon="AttachFileOutlined"
                fontSize={20}
                frontColor="dark:text-gray-400 hover:dark:text-white"
                otherClass="duration-500 ease-in"
              />
            </button>

            <button onClick={(e) => handleSendMessage(e, "click")}>
              <SimpleIcon
                icon="Send"
                fontSize={20}
                // frontColor="text-primary"
                otherClass="duration-500 ease-in"
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export const EmojiButton = ({ setEmoji }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  return (
    <>
      <button onClick={handleClick}>
        <SimpleIcon
          icon="AddReactionOutlined"
          fontSize={20}
          frontColor="dark:text-gray-400 hover:dark:text-white"
          otherClass="duration-500 ease-in"
        />
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          classes: { root: "dark:!bg-gray-900 !w-[280px]" },
        }}
      >
        <EmojiPicker
          onEmojiClick={(e) => {
            setEmoji(e.emoji);
            handleClose();
          }}
          autoFocusSearch={false}
          theme={"dark"}
          height={350}
          width="100%"
          searchDisabled
        />
      </Popover>
    </>
  );
};

export function EditMessageInput({
  setOnEditMsg,
  currentMessage,
  handleEditMessage,
}) {
  const inputRef = useRef(null);

  const [message, setMessage] = useState(decode(currentMessage));
  const [getEmoji, setEmoji] = useState("");

  const handleEdit = () => {
    const msg = message.trim();

    if (!Boolean(msg)) {
      return;
    }

    handleEditMessage(msg);
  };

  useEffect(() => {
    if (getEmoji) {
      const { selectionStart, selectionEnd } = inputRef.current;
      const text =
        message.slice(0, selectionStart) +
        getEmoji +
        message.slice(selectionEnd);

      setMessage(text);
      setEmoji(null);
    }
  }, [getEmoji, message]);

  return (
    <div className="mr-2 w-[40%] rounded-l-2xl rounded-br-2xl border bg-box p-4 dark:border-gray-700 dark:bg-slate-800">
      <div className="flex items-end gap-3">
        <EmojiButton setEmoji={setEmoji} />
        <TextField
          inputRef={inputRef}
          size="medium"
          label=""
          multiline
          maxRows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Text here"
          classes={{
            root: "!w-full",
          }}
          InputProps={{
            classes: {
              input: "!text-gray-500 dark:!text-gray-100",
              placeholder: "dark:!text-gray-100",
              root: "!p-0 !mx-2",
              notchedOutline: "!border-none",
            },
          }}
        />
        <button onClick={() => setOnEditMsg({ edit: false })}>
          <SimpleIcon
            icon="HighlightOff"
            fontSize={22}
            frontColor="dark:text-red-500 hover:dark:text-red-700"
            otherClass="duration-500 ease-in"
          />
        </button>
        <button onClick={handleEdit}>
          <SimpleIcon
            icon="CheckCircleOutline"
            fontSize={22}
            otherClass="duration-500 ease-in"
          />
        </button>
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  crypto: null,
  fiatRate: null,
};

export const rateSlice = createSlice({
  name: "rate",
  initialState,
  reducers: {
    getCrypto: (state, { payload }) => {
      state.crypto = payload;
    },
    getFiatRate: (state, { payload }) => {
      state.fiatRate = { ...state.fiatRate, ...payload };
    },
  },
});

export const { getCrypto, getFiatRate } = rateSlice.actions;

export default rateSlice.reducer;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetCurrentChatUser, useSendStaffEmail } from "api";
import { Button, RichTextEditor, SimpleIcon } from "components";

export default function EmailBox() {
  const [editorContent, setEditorContent] = useState();
  const [subjectContent, setSubjectContent] = useState();

  const { currentChatUserDetails } = useSelector((state) => state.chat || {});

  const { user_details } = useSelector((state) => state.profile || {});

  const [isUpdating, isUpdated, sendEmail] = useSendStaffEmail();

  const [getCurrentChat] = useGetCurrentChatUser();

  useEffect(() => {
    getCurrentChat(null);

    return () => {
      getCurrentChat(null);
    };
  }, []);

  useEffect(() => {
    if (isUpdated) {
      setSubjectContent("");
      setEditorContent("");
    }
  }, [isUpdated]);

  const handleSendEmail = () => {
    sendEmail({
      from: "adaorachi@esetech.com.ng",
      // from: user_details?.email,
      to: "esetechdevteam@gmail.com",
      // to: currentChatUserDetails?.email,
      // bcc: user_details?.email,
      subject: subjectContent,
      html: editorContent,
    });
  };

  return (
    <div className="relative h-full border-r-0 p-2">
      <div className="relative z-[2] h-full">
        {currentChatUserDetails ? (
          <div className="h-full overflow-hidden rounded-lg">
            <div className="header bg-primary p-4 dark:bg-dark-primary">
              <div className="flex items-center">
                <SimpleIcon icon="Send" frontColor="text-white" fontSize={16} />
                <div className="text-md ml-2 text-white">
                  Email from {user_details?.email}
                </div>
              </div>
            </div>
            <div className="body text-md h-full px-4 dark:bg-gray-800">
              <div className="flex gap-2 border-b py-4 dark:border-gray-700 dark:text-white">
                <div className="text-gray-400">To</div>
                <div className="">{currentChatUserDetails?.email}</div>
              </div>
              <div className="flex gap-2 border-b py-4 dark:border-gray-700 dark:text-white">
                <div className="text-gray-400">Subject</div>
                <div className="h-full w-full">
                  <input
                    className="h-full w-full border-0 bg-transparent outline-0"
                    onChange={(e) => setSubjectContent(e.target.value)}
                    value={subjectContent || ""}
                  />
                </div>
              </div>
              <div className="relative mb-12 h-[calc(100%-240px)] overflow-auto py-4">
                <RichTextEditor
                  setEditorContent={setEditorContent}
                  isEmailSent={isUpdated}
                />
              </div>
              <div className="absolute bottom-0 left-0 w-full border-t p-4 text-right dark:border-gray-600">
                <Button
                  label="Send Email"
                  handleClick={handleSendEmail}
                  isSubmitting={isUpdating}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex h-full w-full flex-col items-center justify-center">
            <div className="flex h-32 w-32 items-center justify-center rounded-full bg-emerald-800">
              <SimpleIcon fontSize={52} icon="Email" frontColor="text-white" />
            </div>
            <div className="mt-2 dark:text-white">
              Click on user to send email
            </div>
          </div>
        )}
      </div>
      <div className="absolute top-0 right-0 left-0 h-full w-full bg-hero-pattern opacity-[0.05]" />
    </div>
  );
}

import React from "react";
import { useFullDrawerUserDetails } from "api";
import UserDrawer from "pages/Users/components/UserDrawer";
import { useSelector } from "react-redux";
import { Dialog } from "./Dialog";
import { capitalize } from "lodash";

export default function UserDetailsDrawer() {
  const { userDetails } = useSelector((state) => state.users);
  const [fullDrawerUserDetails] = useFullDrawerUserDetails();

  const setOpen = () => {
    fullDrawerUserDetails(false);
  };

  return (
    <Dialog
      title={`User Details - ${capitalize(userDetails?.platform)}`}
      open={Boolean(userDetails)}
      setOpen={setOpen}
      fullScreen
    >
      <UserDrawer />
    </Dialog>
  );
}

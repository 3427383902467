import React from "react";
import { useSelector } from "react-redux";

import { Form } from "components";
import {
  changePasswordSchema,
  verifyPasswordSchema,
  changePasswordSchemaAlt,
} from "utils/formSchema";
import {
  changePassFormList,
  verifyPassFormList,
  changePassFormListAlt,
} from "utils/constants";
import { useVerifyPass, useChangePass } from "api";
import { useVerifyPassAlt } from "api/actions/profile";

const verifyPassInitialVal = {
  currentPass: "",
};

const changePassInitialVal = {
  newPass: "",
  confirmNewPass: "",
  code: "",
};

const changePassInitialValAlt = {
  currentPass: "",
  newPass: "",
  confirmNewPass: "",
};

export default function ChangePass() {
  const {
    user_details: { email, displayName },
  } = useSelector((state) => state.profile);

  const [isUpdating, isUpdated, verifyPass] = useVerifyPass();
  const [isUpdatingPass, isUpdatedPass, changePass] = useChangePass();

  const verifyPassfield = verifyPassFormList;
  const changePassfield = changePassFormList(email);

  const handleVerify = (values) => {
    verifyPass({ ...values, displayName });
  };

  const handleSubmit = (values, resetForm) => {
    changePass({ ...values, sentEmail: true }, resetForm);
  };

  return (
    <div className="mt-4">
      <div className="grid grid-cols-1 gap-6">
        <Form
          fieldList={verifyPassfield}
          initialFieldValues={verifyPassInitialVal}
          buttonText="Verify"
          buttonLoading={isUpdating}
          handleSubmit={handleVerify}
          validationSchema={verifyPasswordSchema}
          disableVisibilty={isUpdated}
          // fullWidth
        />
        {isUpdated && (
          <>
            <div className="mt-6" />
            <Form
              fieldList={changePassfield}
              initialFieldValues={changePassInitialVal}
              buttonText="Change Password"
              buttonLoading={isUpdatingPass}
              handleSubmit={handleSubmit}
              validationSchema={changePasswordSchema}
              disableAutoComplete
              // fullWidth
            />
          </>
        )}
      </div>
    </div>
  );
}

export function ChangePassAlt() {
  const [isUpdatingPass, isUpdatedPass, changePass] = useVerifyPassAlt();

  const handleSubmit = (values, resetForm) => {
    changePass(values, resetForm);
  };

  return (
    <div className="mt-4">
      <div className="grid grid-cols-1 gap-6">
        <Form
          fieldList={changePassFormListAlt}
          initialFieldValues={changePassInitialValAlt}
          buttonText="Change Password"
          buttonLoading={isUpdatingPass}
          handleSubmit={handleSubmit}
          validationSchema={changePasswordSchemaAlt}
          isDisabled={isUpdatingPass}
        />
      </div>
    </div>
  );
}

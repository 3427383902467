import { uploadImage } from "api/helpers";
import { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { getCrypto, getFiatRate } from "redux/reducers/rateReducers";
import { notification } from "utils";
import * as i from "../lib";

const auth = i.getAuth();

/// change db to esetech database
export const useUpdateGiftcardRate = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const updateRate = async (value, id, type, callback) => {
    try {
      setUpdating(true);

      const { key, val } = value;
      if (
        (Number.isNaN(val) ||
          typeof parseFloat(val) !== "number" ||
          parseFloat(val) <= 0) &&
        type.includes("rate")
      ) {
        throw new Error("Value is Invalid");
      }

      const l = ["delete_rate", "delete_type"];

      const db = i.db2;

      const sfDocRef = i.doc(db, "giftcardRate1", id);

      await i.runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(sfDocRef);
        if (!sfDoc.exists()) {
          throw new Error("Document does not exist!");
        }

        await transaction.update(sfDocRef, {
          [key]: l.includes(type) ? i.deleteField() : val,
          dateUpdated: Date.now(),
        });

        const successNotif =
          type === "update_rate"
            ? "Rate successfully updated"
            : type === "add_rate"
            ? "Rate successfully added"
            : type === "delete_rate"
            ? "Rate successfully deleted"
            : type === "add_type"
            ? "Card Type successfully added"
            : type === "delete_type"
            ? "Card Type successfully deleted"
            : type === "add_currency"
            ? "Card Currency successfully added"
            : type === "update_popmsg"
            ? "Pop Message successfully updated"
            : type === "update_tradeTime"
            ? "Trade Time successfully updated"
            : type === "disable_currency"
            ? "Currency successfully disabled"
            : type === "delete_currency"
            ? "Currency successfully deleted"
            : "Action success.";
        notification("success", successNotif);
        setUpdating(false);
        setUpdated(true);
        if (callback) {
          callback();
        }
      });
    } catch (error) {
      setUpdating(false);
      notification(
        "danger",
        error.message === "Connection failed."
          ? "Internet connection failed."
          : error.message === "Value is Invalid"
          ? "Rate value is invalid"
          : "An error occured. Try again later.",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, updateRate];
};

export const useUpdateGiftcardFac22 = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const updateFac = async (value) => {
    try {
      setUpdating(true);

      const vals = Object.values(value);
      const isInValid = vals.some((item) => {
        return (
          Number.isNaN(item) ||
          typeof parseFloat(item) !== "number" ||
          parseFloat(item) <= 0
        );
      });

      if (isInValid) {
        throw new Error("Value is Invalid");
      }

      const sfDocRef = i.doc(i.db, "giftcardRateFactors", "factors");

      await i.runTransaction(i.db, async (transaction) => {
        const sfDoc = await transaction.get(sfDocRef);

        if (!sfDoc.exists()) {
          throw new Error("Document does not exist!");
        }

        await transaction.update(sfDocRef, value);

        setUpdating(false);
        setUpdated(true);
        notification("success", "Rate factor successfully updated");
      });
    } catch (error) {
      setUpdating(false);
      notification(
        "danger",
        error.message === "Connection failed."
          ? "Internet connection failed."
          : error.message === "Value is Invalid"
          ? "Factor value is invalid"
          : "An error occured. Try again later.",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, updateFac];
};

export const useAddGiftcard = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setUpdated(false);
    }, 500);
  }, [isUpdated]);

  const addGiftcard = async (value, images, callback) => {
    try {
      setUpdating(true);

      const db = i.db2;

      if (!images.length || !value) {
        throw new Error("Value is Invalid");
      }

      let sfDocRef = i.getDocs(
        i.query(
          i.collection(db, "giftcardRate1"),
          i.where("name", "==", value.name),
          i.limit(1)
        )
      );

      await i.runTransaction(db, async (transaction) => {
        const sfDoc = await sfDocRef;

        if (!sfDoc.empty) {
          throw new Error("Document already exist");
        }

        const addDocRef = i.doc(i.collection(db, "giftcardRate1"));

        const fileName = value?.name
          .replace("-", " ")
          .split(" ")[0]
          .toLowerCase();

        const imageUrl = await uploadImage(
          images,
          "cards",
          fileName,
          "kaapo",
          0.5
        );

        transaction.set(addDocRef, {
          ...value,
          dateCreated: Date.now(),
          dateUpdated: Date.now(),
          disabledCurrency: [],
          rate: {},
          tradeTime: {
            enableTime: false,
            tradeEnd: "00.00",
            tradeStart: "00.00",
          },
          imageUrl: imageUrl[0],
        });

        return Promise.resolve("completed");
      });
      setUpdating(false);
      setUpdated(true);
      if (callback) {
        callback();
      }
      notification("success", "Gift card successfully added");
    } catch (error) {
      console.log(error);

      setUpdating(false);
      notification(
        "danger",
        error.message === "Connection failed."
          ? "Internet connection failed."
          : error.message === "Document already exist"
          ? "Giftcard already added!"
          : error.message === "Value is Invalid"
          ? "Giftcard value is Invalid!"
          : "An error occured. Try again later.",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, addGiftcard];
};

export const useDeleteGiftcard = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const deleteGiftcard = async (value, callback) => {
    try {
      setUpdating(true);

      const db = i.db2;

      if (!value?.id) {
        throw new Error("Value is invalid");
      }

      const deleteDocRef = i.doc(i.db2, "giftcardRate1", value?.id);

      await i.runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(deleteDocRef);

        if (!sfDoc.exists()) {
          throw new Error("Document does not exist");
        }

        const { rate } = sfDoc?.data() || {};

        if (Object.keys(rate)?.length) {
          return Promise.reject({
            message: "Delete warning",
            type: "warning",
          });
        }
        transaction.delete(deleteDocRef);
        return Promise.resolve();
      });
      setUpdating(false);
      setUpdated(true);
      if (callback) {
        callback();
      }
      notification("success", "Giftcard successfully deleted");
    } catch (error) {
      console.log(error);

      setUpdating(false);
      notification(
        error?.type === "warning" ? error.type : "danger",

        error.message === "Connection failed."
          ? "Internet connection failed."
          : error.message === "Document already exist"
          ? "Giftcard already added!"
          : error.message === "Value is invalid"
          ? "Giftcard value is Invalid!"
          : error.message === "Delete warning"
          ? "Delete card currency to proceed"
          : "An error occured. Try again later.",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, deleteGiftcard];
};

export const useEditGiftcardCurrency = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setUpdated(false);
    }, 500);
  }, [isUpdated]);

  const editGiftcardCurrency = async (value, callback) => {
    try {
      setUpdating(true);

      const db = i.db2;

      const { id, currency, type } = value || {};

      if (!id || !currency || !["add", "delete"].includes(type)) {
        throw new Error("Params are Invalid");
      }

      const docRef = i.doc(i.db2, "giftcardRate1", value?.id);

      await i.runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(docRef);

        if (!sfDoc.exists()) {
          throw new Error("Document does not exist");
        }

        if (type === "add") {
          transaction.update(docRef, {
            [`rate.${currency}`]: {},
          });
        }

        if (type === "delete") {
          const { rate } = sfDoc?.data() || {};
          const currencyList = Object.keys(rate?.[currency]);

          if (currencyList.length) {
            return Promise.reject({
              message: "Delete warning",
              type: "warning",
            });
          }
          transaction.update(docRef, {
            [`rate.${currency}`]: i.deleteField(),
          });
        }

        return Promise.resolve("completed");
      });
      setUpdating(false);
      setUpdated(true);
      if (callback) {
        callback();
      }
      notification("success", `Gift card currency successfully edited`);
    } catch (error) {
      console.log(error);

      setUpdating(false);
      notification(
        error?.type === "warning" ? error.type : "danger",
        error.message === "Connection failed."
          ? "Internet connection failed."
          : error.message === "Document already exist"
          ? "Giftcard already added!"
          : error.message === "Value is Invalid"
          ? "Giftcard value is Invalid!"
          : error.message === "Delete warning"
          ? "Delete card types to proceed."
          : "An error occured. Try again later.",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, editGiftcardCurrency];
};

export const useEditGiftcardType = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setUpdated(false);
    }, 500);
  }, [isUpdated]);

  const editGiftcardType = async (value, callback) => {
    try {
      setUpdating(true);

      const db = i.db2;

      const { id, currency, currencyType, type } = value || {};

      if (
        !id ||
        !currency ||
        !currencyType ||
        !["add", "delete"].includes(type)
      ) {
        throw new Error("Params are Invalid");
      }

      const docRef = i.doc(i.db2, "giftcardRate1", value?.id);

      await i.runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(docRef);

        if (!sfDoc.exists()) {
          throw new Error("Document does not exist");
        }

        if (type === "add") {
          transaction.update(docRef, {
            [`rate.${currency}.${currencyType}`]: {},
          });
        }

        if (type === "delete") {
          const { rate } = sfDoc?.data() || {};
          const typeList = Object.keys(rate?.[currency]?.[currencyType]);

          if (typeList?.length) {
            return Promise.reject({
              message: "Delete warning",
              type: "warning",
            });
          }
          transaction.update(docRef, {
            [`rate.${currency}.${currencyType}`]: i.deleteField(),
          });
        }

        return Promise.resolve("completed");
      });

      setUpdating(false);
      setUpdated(true);
      if (callback) {
        callback();
      }
      notification("success", `Gift card currency successfully edited`);
    } catch (error) {
      console.log(error);

      setUpdating(false);
      notification(
        error?.type === "warning" ? error.type : "danger",
        error.message === "Connection failed."
          ? "Internet connection failed."
          : error.message === "Document already exist"
          ? "Giftcard already added!"
          : error.message === "Value is Invalid"
          ? "Giftcard value is Invalid!"
          : error.message === "Delete warning"
          ? "Delete card rates to proceed"
          : "An error occured. Try again later.",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, editGiftcardType];
};

export const useCreateCrypto = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const createCrypto = async (value) => {
    if (!Boolean(value)) {
      notification("warning", "Invalid Crypto Input");
      return;
    }
    setUpdating(true);
    try {
      await i.setDoc(i.doc(i.db, "cryptoRate1", value?.id), {
        ...value,
        name: value?.value,
        dateCreated: Date.now(),
      });

      setUpdating(false);
      setUpdated(true);
      notification("success", `Crypto successfully added.`);
    } catch (error) {
      console.log(error);
      setUpdating(false);
      notification("danger", "An error occured.", "error");
    }
  };
  return [isUpdating, isUpdated, createCrypto];
};

export const useCryptoRate = () => {
  const dispatch = useDispatch();
  const { user_details } = useStore().getState().profile || {};

  useEffect(() => {
    let unsub;
    if (auth?.currentUser) {
      try {
        const q = i.query(i.collection(i.db, "cryptoRate1"));

        unsub = i.onSnapshot(q, (querySnapshot) => {
          const t = [];
          querySnapshot.forEach((doc) => {
            t.push({ id: doc.id, ...doc.data() });
          });

          dispatch(getCrypto(t));
        });
      } catch (error) {
        dispatch(getCrypto(null));
      }
    } else {
      dispatch(getCrypto(null));
    }
    return unsub;
  }, [dispatch, auth?.currentUser]);
};

export const useFiatRate = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsub = () => {};
    let unsub2 = () => {};
    if (auth?.currentUser) {
      try {
        const q1 = i.doc(i.db, "utils", "fiatRate");
        const q2 = i.doc(i.db2, "utils", "fiatRate");

        unsub = i.onSnapshot(q1, (doc) => {
          dispatch(getFiatRate({ esetech: doc?.data() }));
        });

        unsub2 = i.onSnapshot(q2, (doc) => {
          dispatch(getFiatRate({ kaapo: doc?.data() }));
        });
      } catch (error) {
        dispatch(getFiatRate(null));
      }
    } else {
      dispatch(getFiatRate(null));
    }

    // if (doc1.exists && doc2.exists && auth?.currentUser) {
    //   console.log();
    //   dispatch(
    //     getFiatRate({
    //       esetech: doc1?.data(),
    //       kaapo: doc2?.data(),
    //     })
    //   );
    // }

    return () => {
      unsub();
      unsub2();
    };
  }, [dispatch, auth?.currentUser]);
};

export const useUpdateCryptoRate = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  useEffect(() => {
    if (isUpdated) {
      setTimeout(() => {
        setUpdated(false);
      }, 500);
    }
  }, [isUpdated]);

  const updateRate = async (value, cryptoId) => {
    try {
      setUpdating(true);

      const [key, val] = Object.entries(value)?.[0];
      if (
        Number.isNaN(val) ||
        typeof parseFloat(val) !== "number" ||
        parseFloat(val) <= 0
      ) {
        throw new Error("Value is Invalid");
      }

      const docRef = i.doc(i.db, "cryptoRate1", cryptoId);

      const [rate, fiat, type] = Object.keys(value)[0]?.split(".");
      const docGetR = await i.getDoc(docRef);

      const dbRateValueSell = docGetR.data()?.[rate]?.[fiat]?.["sell"];
      const dbRateValueBuy = docGetR.data()?.[rate]?.[fiat]?.["buy"];

      if (type === "sell") {
        if (Math.abs(dbRateValueSell - val) > 100) {
          throw new Error("Rate is over");
        }
        if (dbRateValueBuy && val - dbRateValueBuy >= 0) {
          throw new Error("Buy rate is low");
        }
      } else if (type === "buy") {
        if (dbRateValueBuy && Math.abs(dbRateValueBuy - val) > 200) {
          throw new Error("Rate is over");
        }
        if (dbRateValueBuy && val - dbRateValueSell <= 0) {
          throw new Error("Sell rate is high");
        }
      }

      await i.updateDoc(docRef, value);

      setUpdating(false);
      setUpdated(true);
      notification("success", "Rate successfully updated.");
    } catch (error) {
      setUpdating(false);
      const errorMsg = error.message;

      let msg;
      switch (errorMsg) {
        case "Connection failed.":
          msg = "Internet connection failed.";
          break;
        case "Value is Invalid":
          msg = "Rate value is invalid";
          break;
        case "Rate is over":
          msg = "Check rate. It seems over high/low";
          break;
        case "Buy rate is low":
          msg = "Check rate. Buy Rate seems lower";
          break;
        case "Sell rate is high":
          msg = "Check rate. Sell Rate seems higher";
          break;
        default:
          msg = "An error occured. Try again later.";
          break;
      }
      console.log(errorMsg, msg);
      notification("danger", msg, "error");
    }
  };

  return [isUpdating, isUpdated, updateRate];
};

export const useUpdateGiftcardFac = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  useEffect(() => {
    if (isUpdated) {
      setTimeout(() => {
        setUpdated(false);
      }, 500);
    }
  }, [isUpdated]);

  const updateFac = async (value) => {
    try {
      setUpdating(true);

      const { data, doc: document } = value;

      const [, val] = Object.entries(data)?.[0];

      if (
        !["sellFactors", "buyFactors"].includes(document) ||
        Number.isNaN(val) ||
        typeof parseFloat(val) !== "number" ||
        parseFloat(val) <= 0
      ) {
        throw new Error("Value is Invalid");
      }

      const sfDocRef = i.doc(i.db2, "giftcardRateFactors", document);

      await i.runTransaction(i.db2, async (transaction) => {
        const sfDoc = await transaction.get(sfDocRef);

        if (!sfDoc.exists()) {
          throw new Error("Document does not exist!");
        }

        await transaction.update(sfDocRef, data);

        setUpdating(false);
        setUpdated(true);
        notification("success", "Rate factor successfully updated");
      });
    } catch (error) {
      console.log(error);
      setUpdating(false);
      notification(
        "danger",
        error.message === "Connection failed."
          ? "Internet connection failed."
          : error.message === "Value is Invalid"
          ? "Factor value is invalid"
          : "An error occured. Try again later.",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, updateFac];
};

export const useUpdateFiatRate = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  useEffect(() => {
    if (isUpdated) {
      setTimeout(() => {
        setUpdated(false);
      }, 500);
    }
  }, [isUpdated]);

  const updateFac = async (value, platform) => {
    try {
      setUpdating(true);

      const [key, val] = Object.entries(value)?.[0];
      if (
        Number.isNaN(val) ||
        typeof parseFloat(val) !== "number" ||
        parseFloat(val) <= 0 ||
        !["esetech", "kaapo"].includes(platform)
      ) {
        throw new Error("Value is Invalid");
      }

      const db = platform === "esetech" ? i.db : i.db2;

      const sfDocRef = i.doc(db, "utils", "fiatRate");

      await i.runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(sfDocRef);

        if (!sfDoc.exists()) {
          throw new Error("Document does not exist!");
        }

        await transaction.update(sfDocRef, value);

        setUpdating(false);
        setUpdated(true);
        notification("success", "Fiat Rate successfully updated");
      });
    } catch (error) {
      console.log(error);
      setUpdating(false);
      notification(
        "danger",
        error.message === "Connection failed."
          ? "Internet connection failed."
          : error.message === "Value is Invalid"
          ? "Factor value is invalid"
          : "An error occured. Try again later.",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, updateFac];
};

export const useUpdateFiatProvider = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  useEffect(() => {
    if (isUpdated) {
      setTimeout(() => {
        setUpdated(false);
      }, 500);
    }
  }, [isUpdated]);

  const updatePaymentProvider = async (value, platform) => {
    try {
      setUpdating(true);

      const db = platform === "esetech" ? i.db : i.db2;

      const sfDocRef = i.doc(db, "utils", "automaticPayment");

      await i.runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(sfDocRef);

        if (!sfDoc.exists()) {
          throw new Error("Document does not exist!");
        }

        await transaction.update(sfDocRef, value);

        setUpdating(false);
        setUpdated(true);
        notification("success", "Fiat Provider successfully updated");
      });
    } catch (error) {
      console.log(error);
      setUpdating(false);
      notification(
        "danger",
        error.message === "Connection failed."
          ? "Internet connection failed."
          : error.message === "Value is Invalid"
          ? "Factor value is invalid"
          : "An error occured. Try again later.",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, updatePaymentProvider];
};

export const useMarketCryptoRate = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  useEffect(() => {
    let unsub = () => {};

    if (auth?.currentUser) {
      try {
        const q = i.doc(i.db, "utils", "realTimeMarketCryptoPrice");

        unsub = i.onSnapshot(q, (doc) => {
          setData(doc.data());
        });
      } catch (error) {
        setData(null);
      }
    } else {
      setData(null);
    }
    return () => {
      unsub();
    };
  }, [dispatch, auth?.currentUser]);

  return [data];
};

/* eslint-disable react-hooks/exhaustive-deps */
import {
  useAllChatUser,
  useCheckReadChat,
  useFetchCurrentChatMessages,
} from "api";
import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PageContainer } from "shared";
import { ChatContext } from "./ChatContext";
import ChatUserList from "./components/ChatUserList";
import EmailBox from "./components/EmailBox";
import MessageBox from "./components/MessageBox";

export default function Index() {
  const [tabValue, setTabValue] = useState(0);

  const { currentChatMessagesDetails } = useSelector(
    (state) => state.chat || {}
  );

  const scrollToviewRef = useRef();

  const scrollToTheBottom = useCallback(() => {
    const scrollEl = scrollToviewRef.current;
    scrollEl?.scroll({
      top: scrollEl?.scrollHeight,
      // behavior: "smooth",
    });
  }, [currentChatMessagesDetails]);

  useAllChatUser();

  return (
    <PageContainer>
      <div className="h-[calc(100vh-130px)] rounded-2xl border border-slate-200 bg-white shadow-md dark:border-slate-800 dark:bg-slate-900">
        {/* <div className="flex h-[60px] items-center border-b p-4 dark:border-slate-700">
          <h5 className="text-xl font-bold dark:text-white">Messaging</h5>
        </div> */}
        <ChatContext.Provider
          value={{
            scrollToTheBottom,
            scrollToviewRef,
            tabValue,
            setTabValue,
          }}
        >
          <div className="grid h-[calc(100%-0px)] grid-cols-11 gap-0 p-2">
            <div className="col-span-3 h-full overflow-hidden">
              <ChatUserList />
            </div>
            <div className="col-span-8">
              {tabValue !== 2 ? <MessageBox /> : <EmailBox />}
            </div>
          </div>
        </ChatContext.Provider>
      </div>
    </PageContainer>
  );
}

import React, { useCallback } from "react";
import {
  SimpleLoader,
  SimpleTable,
  TransReportHeader as Header,
} from "components";
import { capitalize, startCase } from "lodash";
import { formatInputNum } from "utils";
import { useTransReportGiftcard } from "api";
import { useSelector } from "react-redux";
import { Chip, Divider } from "@mui/material";

const columns = [
  { label: "Product", id: "product" },
  { label: "Trans Count", id: "count", align: "right" },
  {
    label: "Count/Card",
    id: "countByCard",
    align: "right",
  },
  {
    label: "Amount(NGN)",
    id: "amountNGN",
    align: "right",
  },
];
export default function GiftcardSummary() {
  const { transReportDateRange } = useSelector((state) => state.util);

  const {
    calendarType,
    startDate,
    endDate,
    startDatePrev1,
    endDatePrev1,
    startDatePrev2,
    endDatePrev2,
  } = transReportDateRange || {};

  const [isSubmitting, isSubmitted, data] = useTransReportGiftcard();

  const { tradeD, tradeTotalD, tradeUserD } = data || {};

  const calendarTypeFormat = {
    day: "daily",
    week: "weekly",
    month: "monthly",
  };
  const duration = calendarTypeFormat?.[calendarType];

  const dateAllFormat = {
    all: "",
    current: `(${startDate?.format("DD/MM/YY")} - ${endDate?.format(
      "DD/MM/YY"
    )})`,
    prevOne: `(${startDatePrev1?.format("DD/MM/YY")} - ${endDatePrev1?.format(
      "DD/MM/YY"
    )})`,
    prevTwo: `(${startDatePrev2?.format("DD/MM/YY")} - ${endDatePrev2?.format(
      "DD/MM/YY"
    )})`,
    past: "",
  };

  // console.log(data, "data");

  const tradeLength = useCallback(() => {
    return Object?.entries(tradeD || {});
  }, [tradeD])();

  return (
    <div>
      {data ? (
        <div>
          <div className="mt-1 mb-3 border-b dark:border-slate-700" />
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-1 gap-4">
              <div className="col-span-1">
                <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                  <Header header="Trades" />
                  <div className="">
                    <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                      <SimpleTable
                        columns={columns}
                        rows={[
                          {
                            product: "giftcards",
                            count: tradeTotalD?.["total"]?.count || 0,
                            countByCard:
                              tradeTotalD?.["total"]?.countByCard || 0,
                            amountNGN: formatInputNum(
                              tradeTotalD?.["total"]?.amountNGN || 0,
                              2
                            ),

                            align: "right",
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                  <Header
                    header={`${capitalize(duration)} Users Info - Trade`}
                  />
                  {[
                    {
                      name: "esetech",
                      rows: [
                        "all",
                        "current",
                        "prevOne",
                        "prevTwo",
                        "past",
                      ].map((i) => ({
                        userType: `${startCase(`${i} ${calendarType}`)} ${
                          dateAllFormat[i]
                        }`,
                        count: tradeUserD?.[i]?.count || 0,
                        uniqCount: tradeUserD?.[i]?.uniqCount || 0,
                        totalCount: tradeUserD?.[i]?.allUserCount || 0,
                        amount: formatInputNum(tradeUserD?.[i]?.amount || 0, 2),
                        perc: `${formatInputNum(
                          ((tradeUserD?.[i]?.amount || 0) /
                            (tradeUserD?.["all"]?.amount || 0)) *
                            100,
                          2
                        )}%`,
                        align: "right",
                      })),
                      columns: [
                        { label: "User Type", id: "userType" },
                        {
                          label: "Trans Count",
                          id: "count",
                          align: "right",
                        },
                        {
                          label: "Unique User",
                          id: "uniqCount",
                          align: "right",
                        },
                        {
                          label: "Total User",
                          id: "totalCount",
                          align: "right",
                        },
                        {
                          label: "Amount Traded",
                          id: "amount",
                          align: "right",
                        },
                        {
                          label: "Percentage Traded",
                          id: "perc",
                          align: "right",
                        },
                      ],
                    },
                  ].map((item) => (
                    <div className="" key={item?.name}>
                      <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                        <SimpleTable
                          columns={item?.columns}
                          rows={item?.rows}
                          // disableColumns
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="my-4">
              <Divider
                light
                sx={{
                  "&::before, &::after": {
                    borderColor: "secondary.light",
                  },
                }}
              >
                <Chip
                  label="All Trades By Card"
                  classes={{
                    root: "dark:!text-white dark:bg-gray-800",
                  }}
                />
              </Divider>
            </div>
            <div className="grid grid-cols-2 gap-4">
              {[...Array(Math.ceil(tradeLength?.length / 2))].map(
                (itemT, index) => {
                  return (
                    <div className="col-span-1">
                      <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                        <SimpleTable
                          columns={columns}
                          rows={
                            tradeLength.slice(index * 2, (index + 1) * 2)
                              ?.length
                              ? tradeLength
                                  .slice(index * 2, (index + 1) * 2)
                                  .map((it) => {
                                    return {
                                      product: it[0],
                                      count: it[1]?.count || 0,
                                      countByCard: it[1]?.countByCard || 0,
                                      amountNGN: formatInputNum(
                                        it[1]?.amountNGN || 0,
                                        2
                                      ),
                                      align: "right",
                                    };
                                  })
                              : []
                          }
                        />
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex h-[200px] items-center justify-center">
          <SimpleLoader />
        </div>
      )}
    </div>
  );
}

import { createTheme } from "@mui/material/styles";
const colors = require("tailwindcss/colors");

export const muiTheme = createTheme({
  palette: {
    palette: {
      mode: "dark",
    },
    primary: {
      // main: colors.indigo['600'],
      light: "#e8f5e9",
      light_10: "#d5e8d6",
      main: "#388951",
      dark: "#2e7d32",
      contrastText: "#fff",
    },
    default: {
      main: colors.gray["300"],
      contrastText: "#475f7b",
    },
    secondary: {
      // main: '#f44336',
      main: "#2e7d32",
      contrastText: "#fff",
    },
    neutral: {
      // main: "#959292",
      main: "#e0e0e0",
      contrastText: "#333",
    },
    text: {
      main: "#444",
      primary: "#475f7b",
      dark: "#357d33",
    },
    background: {
      main: "#fafaff",
    },
  },
  typography: {
    fontFamily: [
      "Fira Sans",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    color: "#fff",
  },
});

// palette: {
//   primary: {
//     light: '#e8f5e9',
//     light_10: '#d5e8d6',
//     main: '#388951',
//     main_1: '#43A047',
//     main_2: '#009666',
//     // main_2: '#64886f',
//     dark: '#2e7d32',
//     contrastText: '#fff',
//   },
//   secondary: {
//     light: '#ff7961',
//     // main: '#f44336',
//     main: '#2e7d32',
//     dark: '#ba000d',
//     contrastText: '#fff',
//   },
//   background: {
//     paper: '#fff',
//     main: '#eaeff6',
//     main_1: '#f5f5f5',
//   },
//   text: {
//     main: '#444',
//     main_1: '#777',
//     primary: '#475f7b',
//     dark: '#357d33',
//     // secondary: '#fff',
//   },
// },

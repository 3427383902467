import React from "react";
import { useFormik } from "formik";

import { Button, TextField } from "./";

export default function Form({
  fieldList,
  initialFieldValues,
  buttonText,
  buttonLoading,
  handleSubmit,
  validationSchema,
  disableVisibilty,
  fullWidth,
  extraInfo,
}) {
  const formik = useFormik({
    enableReintialize: true,
    initialValues: initialFieldValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm);
    },
  });

  return (
    // <form onSubmit={formik.handleSubmit}>
    <>
      {fieldList.map((f) => (
        <div className="col-span-1" key={f.name}>
          {f.inputType === "textfield" && (
            <>
              {f.helperText_alt && (
                <div className="mb-3 text-xs text-gray-400">
                  {f.helperText_alt}
                </div>
              )}
              <TextField
                name={f.name}
                type={f.type}
                label={f.label}
                value={formik.values?.[f.name]}
                handleChange={(e) => {
                  formik.handleChange(e);
                  // console.log(e);
                }}
                touched={formik.touched?.[f.name]}
                error={formik.errors?.[f.name]}
                handleBlur={formik.handleBlur}
                helperText={f.helperText}
                {...f.otherProps}
                placeholder={f.placeholder}
              />
            </>
          )}
        </div>
      ))}

      {extraInfo && extraInfo}

      {!disableVisibilty && (
        <div className="col-span-1">
          <Button
            type="submit"
            label={buttonText}
            disabled={buttonLoading}
            isSubmitting={buttonLoading}
            fullWidth={fullWidth}
            handleClick={formik.handleSubmit}
            gradient
          />
        </div>
      )}
    </>
    // </form>
  );
}

import React, { Fragment } from "react";
import { SimpleIcon } from "components";
import { toLower } from "lodash";
import { useSelector } from "react-redux";
import MessageChatList from "./MessageChatList";
import MessageTextInput from "./MessageTextInput";

export default function MessageBox() {
  const { currentChatUserDetails } = useSelector((state) => state.chat || {});

  return (
    <div className="relative h-full border-r-0 dark:border-gray-700">
      <div className="relative h-full rounded-tr-xl rounded-br-xl bg-box p-2 dark:bg-gray-900">
        {currentChatUserDetails ? (
          <Fragment>
            <div className="flex h-[60px] items-center border-b p-3 dark:border-gray-700">
              <img
                src={require(`assets/avatar/avatar-${
                  toLower(currentChatUserDetails?.gender) || "female"
                }.png`)}
                alt={currentChatUserDetails.displayName}
                className="h-6 w-6 rounded-full"
              />
              <div className="ml-2 dark:text-white">
                <div>{currentChatUserDetails?.displayName}</div>
                <div className="text-sm dark:text-gray-400">
                  {currentChatUserDetails?.position}
                </div>
              </div>
            </div>
            <MessageChatList />
            <MessageTextInput currentChatUserDetails={currentChatUserDetails} />
          </Fragment>
        ) : (
          <div className="flex h-full w-full flex-col items-center justify-center">
            <div className="absolute h-full w-full bg-hero-pattern opacity-[0.1] dark:opacity-[0.05]" />
            <div className="flex h-32 w-32 items-center justify-center rounded-full bg-emerald-800">
              <SimpleIcon fontSize={52} icon="Forum" frontColor="text-white" />
            </div>
            <div className="mt-2 dark:text-white">
              Click on user to start chat
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  staffTeam: null,
  staffDetails: null,
  currentWorkingTeam: null,
};

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    getStaffTeam: (state, { payload }) => {
      state.staffTeam = payload;
    },
    getStaff: (state, { payload }) => {
      state.staffDetails = payload;
    },
  },
});

export const { getStaffTeam, getStaff } = staffSlice.actions;

export default staffSlice.reducer;

import React from "react";
import { PageContainer } from "shared";
import Engagement from "./subpage/Engagement";
import Referred from "./subpage/Referred";
import Impressions from "./subpage/Impressions";

const index = () => {
  return (
    <PageContainer>
      <Referred />
      <Impressions />
      <Engagement />
    </PageContainer>
  );
};

export default index;

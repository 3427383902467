import React, { useEffect, useState } from "react";
import { Button, Loader, SectionContent, SimpleIcon } from "components";
import { capitalize } from "lodash";
import { classNames } from "utils";
import { Slide as MuiSlide } from "@mui/material";
import ReportSummary from "./ReportSummary";

export default function ReportList({ summaryList, platform }) {
  const [openSummary, setOpenSummary] = useState(false);
  const [summaryPage, setSummaryPage] = useState(null);

  useEffect(() => {
    setOpenSummary(false);
  }, [platform]);

  return (
    <div className="">
      <SectionContent>
        <div
          className={classNames(
            "relative rounded bg-box p-4 shadow-card-box dark:bg-gray-800",
            openSummary ? "h-screen" : ""
          )}
        >
          <div className="">
            {summaryList ? (
              <div className="grid grid-cols-3 gap-4">
                {summaryList?.map((item) => (
                  <div className="col-span-1" key={item.id}>
                    <button
                      className="relative w-full rounded border-none bg-slate-200 p-4 outline-none dark:bg-gray-700"
                      onClick={() => {
                        setOpenSummary(!openSummary);
                        setSummaryPage(item?.id);
                      }}
                    >
                      <div className="relative flex items-center justify-between">
                        <div
                          className={classNames(
                            "flex h-10 w-10 items-center justify-center rounded",
                            item?.bgColor
                          )}
                        >
                          <SimpleIcon
                            icon="Assessment"
                            frontColor="text-white"
                          />
                        </div>
                        <div className="flex items-center justify-end">
                          <div className="mr-2 text-right dark:text-white">
                            <div className="">{capitalize(item?.id)}</div>
                            <div className="text-sm dark:text-gray-300">
                              Click to view summary
                            </div>
                          </div>
                          <div className=" right-0">
                            <SimpleIcon
                              icon="KeyboardArrowRight"
                              fontSize={18}
                              frontColor="dark:text-white text-primary"
                            />
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <Loader />
            )}
          </div>
          <MuiSlide
            direction="left"
            in={openSummary}
            mountOnEnter
            unmountOnExit
          >
            <div className="absolute top-0 left-0 w-full overflow-auto rounded bg-box p-0 shadow-drawer dark:bg-gray-900">
              <ReportSummary
                summaryPage={summaryPage}
                setOpenSummary={setOpenSummary}
                platform={platform}
              />
            </div>
          </MuiSlide>
        </div>
      </SectionContent>
    </div>
  );
}

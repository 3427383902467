import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { pick, toLower } from "lodash";
import Draggable from "react-draggable";
import Wobble from "react-reveal/Wobble";
import { Slide } from "react-reveal";
import { SimpleIcon } from "./Icon";
import { useAllTrans } from "utils/allTransactionProps";
import Button from "./Button";
import { pendingNotifTransObj } from "utils/transactionProps";
import { useClaimTrade } from "api";

export const TradeNotification = () => {
  const [state, setState] = useState(true);

  const {
    profile: { user_details },
  } = useSelector((state) => state || {});
  const { roles, position } = user_details || {};

  const accessNotification = [
    "Customer Care Agent",
    "Supervisor",
    // "Chief Executive Officer",
  ]?.includes(position);

  const [_, isSubmitted, __] = useClaimTrade();

  const trans = useAllTrans().filter(
    (item) =>
      toLower(item?.id)?.includes("pending") &&
      !toLower(item?.id)?.includes("crypto")
  );

  const getTransByType = (product, platform) =>
    trans?.find((td) => td.idType === `${product}${platform}`);

  const singleNotif =
    trans &&
    trans
      ?.reduce((acc, item) => {
        acc.push(...(item?.data ? item?.data : []));
        return acc;
      }, [])
      .map((item) => {
        const getTrans = getTransByType(item.product, item.platform);
        const otherValues = pick(getTrans, [
          "id",
          "buttonText",
          "platform",
          "title",
          "handleOnClick",
          "isSubmitting",
        ]);

        return {
          ...pendingNotifTransObj(item),
          otherValues,
          objectSummary: getTrans?.objectSummary(item),
        };
      })
      ?.sort((b, c) => c?.dateCreated - b?.dateCreated)?.[0];

  const notifString = [...Array(3)].reduce((acc, item) => {
    acc += `${singleNotif?.notification}. `;
    return acc;
  }, "");

  const alertSpeak = window.speechSynthesis;
  const msg = new SpeechSynthesisUtterance(notifString);
  const voices = alertSpeak.getVoices();
  msg.voice = voices[0];
  msg.lang = "en-US";

  useEffect(() => {
    // && roles?.giftcard && roles?.payout
    if (
      accessNotification &&
      (roles?.giftcard || roles?.payout) &&
      singleNotif
    ) {
      if (!state) {
        alertSpeak.cancel();
        return;
      }

      alertSpeak.speak(msg);
    }
    return () => {
      alertSpeak.cancel();
    };
  }, [singleNotif, roles, position]);

  useEffect(() => {
    setState(true);
  }, []);

  const handleClose = () => {
    alertSpeak.cancel();
    setState(false);
  };

  const {
    title,
    product,
    productType,
    amount,
    beneficiary,
    otherValues,
    objectSummary,
  } = singleNotif || {};

  const transClaimItem = objectSummary;

  return (
    <>
      {singleNotif && state && accessNotification && (
        <Draggable
          axis="both"
          handle=".notificationModal"
          positionOffset={{ x: "50%", y: 0 }}
          position={null}
          grid={[25, 25]}
          scale={1}
        >
          <div className="notificationModal fixed top-4 right-[50%] z-[2000]">
            <Slide right duration={500}>
              <div className="min-w-[350px] rounded bg-box shadow-dialog dark:bg-gray-700">
                <div className="relative flex flex-row items-center justify-between">
                  <div className="absolute -top-2 -left-2">
                    <Wobble forever>
                      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-dialog dark:bg-slate-900">
                        <SimpleIcon
                          icon="Notifications"
                          frontColor="text-red-500"
                          otherClass="iconShadow-alt"
                        />
                      </div>
                    </Wobble>
                  </div>
                  <div className="relative flex h-full flex-col justify-start p-4 dark:text-white">
                    <div className="">
                      <h6 className="">{title}</h6>
                    </div>

                    <div className="mt-1 text-sm">
                      Product Details — <span>{product}/</span>
                      <span>{productType}/</span>
                      <span>{amount}</span>
                    </div>
                    <div className="mt-1 text-sm">
                      Customer Details — {beneficiary}
                    </div>
                  </div>
                  <hr className="absolute right-[95px] h-full w-[1px] bg-slate-300 dark:bg-gray-500" />
                  <div className="action-button flex flex-col items-center justify-between">
                    <div className="w-full p-2">
                      <Button
                        fullWidth
                        color={
                          ["Claim", "Re-claim"].includes(
                            otherValues?.buttonText
                          )
                            ? "warning"
                            : "success"
                        }
                        label={otherValues?.buttonText}
                        size="small"
                        {...(otherValues?.handleOnClick && {
                          handleClick: () => {
                            otherValues?.handleOnClick(transClaimItem);
                            if (isSubmitted) {
                              handleClose();
                            }
                          },
                        })}
                        {...(otherValues?.isSubmitting && {
                          isSubmitting:
                            otherValues?.isSubmitting(transClaimItem),
                        })}
                      />
                    </div>
                    <div className="h-[1px] w-full bg-slate-300 dark:bg-gray-500" />
                    <div className="w-full p-2">
                      <Button
                        fullWidth
                        label="Dismiss"
                        color="default"
                        handleClick={handleClose}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
        </Draggable>
      )}
    </>
  );
};

import React, { useState } from "react";
import SettingsDrawer from "../../components/SettingsDrawer";
import SettingsContent from "../../components/SettingsContent";
import { checkTransactions } from "utils/transactionProps";

export default function EsetechTrans() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const {
    checkFiatWithdrawalData,
    checkCryptoTransData,
    checkFiatTransData,
    checkUtilityTransData,
    checkCryptoAddress,
    checkFiatAccount,
    checkCryptoWithdrawal,
  } = checkTransactions();

  const tabList = [
    {
      label: "Fiat Withdrawal",
      subtitle: "Check withdrawal status",
      dataRow: checkFiatWithdrawalData,
      dataType: ["transactionId"],
      id: "check_fiat_withdrawal",
    },
    // {
    //   label: "Fiat Deposit",
    //   subtitle: "Check fiat wallet funding",
    //   dataRow: checkFiatTransData,
    //   dataType: ["accountNumber"],
    //   id: "check_fiat_deposit_trans",
    //   requestData: (e) => ({ transId: e.target.value }),
    // },
    // {
    //   label: "Fiat Bank Account",
    //   subtitle: "Verify fiat bank account",
    //   dataRow: checkFiatAccount,
    //   dataType: ["accountNumber"],
    //   id: "check_fiat_account",
    // },
    // {
    //   label: "Crypto Deposit",
    //   subtitle: "Check crypto deposit",
    //   dataRow: checkCryptoTransData,
    //   dataType: ["address", "assetType"],
    //   id: "check_crypto_receive_trans",
    // },
    // {
    //   label: "Crypto Wallet Address",
    //   subtitle: "Verify crypto wallet address",
    //   dataRow: checkCryptoAddress,
    //   dataType: ["address", "assetType"],
    //   id: "check_crypto_address",
    // },
    {
      label: "Crypto Withdrawal",
      subtitle: "Verify crypto sent out",
      dataRow: checkCryptoWithdrawal,
      dataType: ["transactionId", "assetType"],
      id: "check_crypto_send_trans",
    },

    {
      label: "Airtime & Data Utilities",
      subtitle: "Check airtime & data utilities",
      dataRow: checkUtilityTransData,
      dataType: ["transactionId"],
      id: "check_utilities",
    },
  ];

  const pageContent = tabList?.[selectedIndex];

  return (
    <div className="m-2">
      <div className="grid grid-cols-1 gap-6">
        <div className="col-span-1">
          <div className="">
            <div className="relative overflow-hidden rounded bg-white shadow-md dark:bg-gray-800">
              <div className="flex flex-row overflow-hidden">
                <SettingsDrawer
                  {...{
                    selectedIndex,
                    setSelectedIndex,
                    tabList,
                  }}
                />
                <SettingsContent
                  pageContent={pageContent}
                  selectedIndex={selectedIndex}
                  platform="esetech"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useReduceUserBalance } from "api/actions/profile";
import { useGetUserWalletBalances } from "api/actions/users";
import { BackdropSection, Button, SimpleIcon } from "components";
import PasswordDialog from "components/PasswordDialog";
import { startCase } from "lodash";
import React, { useState } from "react";
import { useSelector, useStore } from "react-redux";
import { classNames, formatCrypto, formatMoney, formatMoney2 } from "utils";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";

export default function WalletBalances({ platform }) {
  const { userDetails } = useSelector((state) => state.users);
  const [getData, setGetData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [openPass, setOpenPass] = useState(false, 0);
  const { user_details } = useStore().getState().profile || {};

  const [getPassField, setPassField] = useState({
    password: "",
  });
  const [
    isUpdatingReduceUserBalance,
    isUpdatedReduceUserBalance,
    reduceUserBalance,
  ] = useReduceUserBalance();

  const [balances] = useGetUserWalletBalances(userDetails?.userId, platform);

  // console.log(balances, "balances", user_details);

  const symValue = {
    nairaWallet: "NGN",
    nairaBonusWallet: "NGN",
    cedisWallet: "GHS",
    cedisBonusWallet: "GHS",
    cefaWallet: "XOF",
    cefaBonusWallet: "XOF",
    bitcoinWallet: "BTC",
    ethereumWallet: "ETH",
    tetherWallet: "USDT",
    tetherTronWallet: "USDT_TRON",
    binanceWallet: "BNB",
  };

  const handleReduceUserBalance = (data, i) => {
    reduceUserBalance({
      UIDToReduce: userDetails?.userId,
      currentPass: getPassField?.password,
      newAmount: data?.newAmount,
      wallet: data?.wallet,
    });
  };

  return (
    <div className="w-full rounded border border-gray-300 p-2 dark:border-gray-700 dark:bg-gray-800">
      {balances ? (
        <div className="">
          <div className="float-right">
            {/* <Button
              label={"reduce fiat balances"}
              otherClass={"!bg-red-500"}
              handleClick={() => setOpenPass(true)}
            /> */}
          </div>

          {Object.entries(balances).map((item) => (
            <div className="border-b p-4 dark:border-gray-600" key={item[0]}>
              <div className="mb-4 dark:text-white">{startCase(item[0])}</div>
              <div className="grid grid-cols-3 gap-4">
                {Object.entries(item[1])?.map((itemOne, index) => (
                  <div
                    key={index}
                    className="col-span-1 flex flex-row rounded bg-box p-4 shadow-sm dark:bg-gray-700"
                  >
                    <div className="flex h-8 w-8 items-center justify-center rounded-full bg-slate-200 p-2 dark:bg-slate-900">
                      <SimpleIcon
                        fontSize={18}
                        icon="Wallet"
                        otherClass="iconShadow-alt"
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm dark:text-gray-400">
                        {startCase(itemOne?.[0])}
                      </div>
                      <div className="text-lg text-gray-600 dark:text-white">
                        {["fiatWallets", "fiatBonusWallets"].includes(
                          item[0]
                        ) && (
                          <div className="flex ">
                            <h3 className="pr-1">{symValue?.[itemOne?.[0]]}</h3>
                            {user_details?.position ===
                            "Chief Executive Officer" ? (
                              <input
                                className={classNames(
                                  "w-full border-none bg-transparent text-left outline-none dark:text-white"
                                  // {
                                  //   "pr-0":
                                  //     isEditing[item[0]] !== undefined &&
                                  //     isEditing[item[0]] === index,
                                  // }
                                )}
                                defaultValue={formatMoney2(
                                  Number(itemOne?.[1]) || 0.0
                                )}
                                placeholder="0.00"
                                // onFocus={() =>
                                //   setIsEditing((prev) => ({
                                //     ...prev,
                                //     [item[0]]: index,
                                //   }))
                                // }
                                // onBlur={() =>
                                //   setIsEditing((prev) => {
                                //     const newState = { ...prev };
                                //     delete newState[item[0]];
                                //     return newState;
                                //   })
                                // }
                                onChange={(e) => {
                                  setGetData({
                                    newAmount: e.target.value,
                                    wallet: itemOne[0],
                                  });
                                }}
                              />
                            ) : (
                              <span>
                                {formatMoney2(Number(itemOne?.[1]) || 0.0)}
                              </span>
                            )}

                            {/* {isEditing[item[0]] === index && ( */}
                            {/* <div className="px-2 py-1 text-xs bg-red-500 dark:bg-gray-700">
                              Old Value: {formatMoney2(Number(itemOne?.[1]))}
                            </div> */}
                            {/* )} */}
                            <div className="top-0 right-0 h-[100%] w-[300px]">
                              {user_details.position ===
                              "Chief Executive Officer" ? (
                                <>
                                  <LibraryAddIcon
                                    // fontSize={18}
                                    className="iconShadow-alt float-right w-20 !cursor-pointer text-green-400 transition-colors duration-300 focus:bg-green-600"
                                    onClick={() => setOpenPass(true)}
                                  />
                                  <PasswordDialog
                                    key={`password-dialog-${index}`}
                                    index={index}
                                    {...{
                                      title: "Update user's balance",
                                      open: openPass,
                                      setOpen: setOpenPass,
                                      getField: getPassField,
                                      setField: setPassField,
                                      isSubmitting: isUpdatingReduceUserBalance,
                                      isSubmitted: isUpdatedReduceUserBalance,
                                      handleClick: () =>
                                        handleReduceUserBalance(getData, index),
                                      buttonObj: {
                                        label: "update",
                                        color: "success",
                                      },
                                    }}
                                  />
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                            {/* )} */}
                          </div>
                        )}
                        {item[0] === "cryptoWallets" && (
                          <>
                            {formatCrypto(itemOne?.[1])}{" "}
                            {symValue?.[itemOne?.[0]]}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="relative h-48 w-full rounded bg-white p-4 dark:bg-gray-800">
          <div className="w-full p-4 text-center">
            <BackdropSection open={true} />
          </div>
        </div>
      )}
    </div>
  );
}

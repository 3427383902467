import React, { useState } from "react";
import { useDeleteGiftcard } from "api";
import { Alert, Button, SimpleIcon } from "components";

export default function DeleteGiftcard({ cardId }) {
  const [openAlert, setOpenAlert] = useState(false);
  const [isUpdating, isUpdated, deleteGiftcard] = useDeleteGiftcard();

  return (
    <div className="transition duration-1000">
      <Button
        size="small"
        color="error"
        label="Delete"
        handleClick={() => setOpenAlert(!openAlert)}
        // handleClick={() => deleteGiftcard({ id: cardId })}
        isSubmitting={isUpdating}
      />

      <Alert
        {...{
          openAlert,
          setOpenAlert,
          title: "Are you sure you want to delete card?",
          isSubmitting: isUpdating,
          handleSubmit: () => deleteGiftcard({ id: cardId }),
        }}
      />
    </div>
  );
}

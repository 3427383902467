import React, { useState } from "react";
import { BackdropSection, DrawerSide, SimpleIcon } from "components";
import { omit, startCase } from "lodash";

export default function CheckResult({
  columns,
  rows,
  getData,
  isLoading,
  isError,
}) {
  const [open, setOpen] = useState(false);
  const [currentData, setCurrentData] = useState(null);

  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        {!isLoading ? (
          <>
            {!isError ? (
              <>
                {getData && columns ? (
                  <>
                    {getData.length ? (
                      <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
                        <thead className="bg-gray-50 text-xs uppercase  dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            {columns.map((item) => (
                              <th
                                scope="col"
                                className="py-3 px-6"
                                key={item.id}
                              >
                                {item.title}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {getData.map((item, index) => (
                            <tr
                              className="border-b bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                              key={index}
                            >
                              {columns.map((i) => {
                                const dd = rows(item);
                                return (
                                  <td className="">
                                    <button
                                      className="flex-start flex w-full flex-row justify-start whitespace-nowrap border-none py-4 px-6 outline-0"
                                      onClick={() => {
                                        setOpen(true);
                                        const d = omit(item, [
                                          "customerDetails",
                                        ]);

                                        setCurrentData({
                                          ...d,
                                          ...item?.customerDetails,
                                        });
                                      }}
                                    >
                                      {dd?.[i.id]}
                                    </button>
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="bg-white p-4 dark:bg-gray-800 dark:text-white">
                        <div className="text-sm">
                          Transaction record not found.
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="bg-white p-4  dark:bg-gray-800 dark:text-white">
                    <div className="text-sm">No data to show.</div>
                  </div>
                )}
              </>
            ) : (
              <div className="bg-white p-4  dark:bg-gray-800 dark:text-white">
                <div className="text-sm text-red-500">{isError}</div>
              </div>
            )}
          </>
        ) : (
          <div className="h-[200px] w-full bg-white dark:bg-gray-800">
            <BackdropSection open={!getData} />
          </div>
        )}
      </div>
      <DrawerSide {...{ open, setOpen }}>
        <div className="p-4">
          <h5 className="mb-4 inline-flex items-center text-base font-semibold text-gray-500 dark:text-gray-400">
            Transaction Details
          </h5>
          <div>
            <div className="flow-root">
              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {currentData &&
                  Object.entries(currentData || {}).map((item, index) => {
                    return (
                      <li className="py-3 sm:py-4" key={index}>
                        <div className="flex flex-row items-start space-x-4">
                          <SimpleIcon fontSize={16} icon="CheckCircle" />
                          <div className="ml-2 min-w-0 flex-1">
                            <p className="truncate text-sm font-medium  dark:text-white">
                              {startCase(item[0])}
                            </p>
                            <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                              {String(item[1])}
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </DrawerSide>
    </div>
  );
}

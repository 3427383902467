import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  useDeleteAdvertImage,
  useGetAdvertPopupDetails,
  useGetStorageFiles,
  useSaveAdvertPopup,
  useUploadAdvertImage,
} from "api";
import {
  Button,
  ImageUploadSingle,
  SectionContent,
  SimpleIcon,
  Switch,
} from "components";
import { truncate } from "utils";
import {
  CircularProgress,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { startCase } from "lodash";
import { listContentUpdate } from "utils/constants";

export default function AdvertPopup({ platform }) {
  const { user_details } = useSelector((state) => state.profile);
  const [getCurrentImage, setCurrentImage] = useState(null);
  const [images, setImages] = useState([]);

  const [advertPopupD, setAdvertPopupD] = useState();

  const handleImageChange = (imageList) => {
    setImages(imageList);
  };

  const [isUpdatingUpload, isUpdatedUpload, uploadImage] =
    useUploadAdvertImage();

  const [isUpdatingDelete, isUpdatedDelete, deleteImage] =
    useDeleteAdvertImage();

  const [isUpdatingDetails, getPopupDetails] =
    useGetAdvertPopupDetails(platform);

  const [isUpdating, isUpdated, saveAdvertDetails] = useSaveAdvertPopup();

  const [getUrls] = useGetStorageFiles(
    platform,
    isUpdatedUpload || isUpdatedDelete
  );

  const handleUploadImage = () => {
    const callback = () => setImages([]);
    uploadImage(
      {
        images,
        fileName: `advert-popup-${getUrls?.length + 1}`,
      },
      platform,
      callback
    );
  };

  const handleDeleteImage = (pathName) => {
    deleteImage(
      {
        pathName,
      },
      platform
    );
  };

  const handleSaveAdvertDetails = () => {
    saveAdvertDetails(advertPopupD, platform);
  };

  useEffect(() => {
    setAdvertPopupD(getPopupDetails);
  }, [getPopupDetails]);

  return (
    // <PageContainer>
    <div className="grid grid-cols-2 gap-6">
      <div className="col-span-1">
        <SectionContent>
          <div className="rounded bg-box p-4 shadow-card-box dark:bg-gray-800">
            <div className="mb-2 flex flex-row items-center justify-between">
              <h4 className="text-lg text-primary dark:text-light-primary">
                Update Advert Popup
              </h4>
              {listContentUpdate.includes(user_details.position) && (
                <Button
                  label="Save"
                  isSubmitting={isUpdating}
                  handleClick={handleSaveAdvertDetails}
                />
              )}
            </div>

            <hr className="mb-6 border-t dark:border-gray-500" />
            {advertPopupD ? (
              <div>
                <div className="flex flex-row items-center gap-4">
                  <div className="h-[200px] w-[200px]">
                    <img
                      className="h-full w-full rounded-sm object-cover"
                      src={advertPopupD?.imagePath}
                      alt="advert-popup"
                    />
                  </div>
                  <div className="flex flex-col gap-6">
                    {[
                      {
                        title: "landing site",
                        isDisplay: advertPopupD?.target?.mainSite,
                        key: "mainSite",
                      },
                      {
                        title: "dashboard site",
                        isDisplay: advertPopupD?.target?.userDashboard,
                        key: "userDashboard",
                      },
                      {
                        title: "mobile app",
                        isDisplay: advertPopupD?.target?.mobile,
                        key: "mobile",
                      },
                    ]?.map((item) => (
                      <div className="" key={item?.key}>
                        <div className="mb-1 text-sm dark:text-white">
                          Display on {item?.title}
                        </div>
                        <label className="flex inline-flex cursor-pointer flex-row items-center">
                          <span className="mr-2 text-sm font-bold dark:text-white">
                            No
                          </span>
                          <Switch
                            defaultChecked={item?.isDisplay}
                            // checked={item?.isDisplay}
                            onChange={() => {
                              setAdvertPopupD((prevS) => ({
                                ...prevS,
                                target: {
                                  ...prevS?.target,
                                  [item?.key]:
                                    !advertPopupD?.target?.[item?.key],
                                },
                              }));
                            }}
                          />
                          <span className="ml-2 text-sm font-bold dark:text-white">
                            Yes
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mt-8 flex flex-row gap-6">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {[
                      {
                        title: "dateFrom",
                        time: advertPopupD?.time?.dateFrom,
                        key: "dateFrom",
                      },
                      {
                        title: "dateTo",
                        time: advertPopupD?.time?.dateTo,
                        key: "dateTo",
                      },
                    ].map((item) => (
                      <DateTimePicker
                        key={item?.title}
                        label={`Select ${startCase(item?.title)}`}
                        value={dayjs(item?.time)}
                        onChange={(e) => {
                          setAdvertPopupD((prevS) => ({
                            ...prevS,
                            time: {
                              ...prevS?.time,
                              [item?.key]: dayjs(e).format("YYYY-MM-DDTHH:mm"),
                            },
                          }));
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              size="small"
                              label={`Select ${startCase(item?.title)}`}
                              InputProps={{
                                ...params?.InputProps,

                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    {...params?.InputProps?.endAdornment?.props
                                      ?.children?.props}
                                    classes={{
                                      root: "cursor-pointer",
                                    }}
                                  >
                                    <SimpleIcon
                                      icon="DateRange"
                                      fontSize={18}
                                    />
                                  </InputAdornment>
                                ),

                                classes: {
                                  input:
                                    "!text-gray-500 dark:!text-gray-100 !text-sm",
                                  placeholder: "dark:!text-gray-100",
                                  root: "!rounded",
                                  notchedOutline: "dark:!border-gray-500",
                                },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: "dark:!text-white",
                                },
                              }}
                            />
                          );
                        }}
                      />
                    ))}
                  </LocalizationProvider>
                </div>
              </div>
            ) : (
              <div className="flex h-20 w-full flex-row items-center justify-center">
                <CircularProgress size={25} thickness={10} />
              </div>
            )}
          </div>
        </SectionContent>
      </div>

      <div className="col-span-1">
        <SectionContent>
          <div className="rounded bg-box p-4 shadow-card-box dark:bg-gray-800">
            <h4 className="mb-2 text-lg text-primary dark:text-light-primary">
              Select From Uploads
            </h4>

            <hr className="mb-6 border-t dark:border-gray-500" />
            {advertPopupD && getUrls?.length ? (
              <div>
                <div className="grid grid-cols-4 gap-4">
                  {getUrls?.length
                    ? getUrls?.map((item) => (
                        <div className="relative col-span-1" key={item?.name}>
                          <Tooltip title={item?.name} placement="top-start">
                            <div className="relative flex flex-col items-center">
                              {isUpdatingDelete &&
                                getCurrentImage === item?.name && (
                                  <div className="absolute top-[30%] z-[30]">
                                    <CircularProgress
                                      size={25}
                                      thickness={10}
                                    />
                                  </div>
                                )}

                              <button
                                className="relative z-[10] flex h-20 w-20 flex-col items-center rounded-lg"
                                key={item?.name}
                                onClick={() => {
                                  setAdvertPopupD((prevS) => ({
                                    ...prevS,
                                    imagePath: item?.url,
                                  }));
                                }}
                              >
                                {getPopupDetails?.imagePath !== item?.url && (
                                  <div
                                    className="iconShadow-alt absolute -top-2 -right-2 z-[20] flex cursor-pointer flex-row items-center justify-center rounded-full"
                                    onClick={() => {
                                      handleDeleteImage(item?.fullPath);
                                      setCurrentImage(item?.name);
                                    }}
                                  >
                                    <SimpleIcon
                                      fontSize={20}
                                      icon="Cancel"
                                      frontColor="text-red-500"
                                    />
                                  </div>
                                )}
                                {advertPopupD?.imagePath === item?.url && (
                                  <div className="absolute top-0 left-0 h-full w-full rounded border-4 border-primary" />
                                )}
                                <img
                                  src={item?.url}
                                  alt={item?.name}
                                  className="h-full w-full rounded-lg object-cover"
                                />
                              </button>
                              <div className="mt-2 text-xs dark:text-white">
                                {truncate(item?.name, 12)}
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      ))
                    : null}
                </div>
                <div className="mt-6">
                  <ImageUploadSingle
                    {...{ images, handleChange: handleImageChange }}
                  />

                  {images?.length ? (
                    <div className="mt-2 flex flex-row items-center justify-end gap-2">
                      <Button
                        label="Save"
                        otherClass="!min-w-0"
                        handleClick={() => {
                          handleUploadImage();
                        }}
                        isSubmitting={isUpdatingUpload}
                        // disabled={isUpdatingUpload || !images?.length}
                      />
                      <Button
                        label="Cancel"
                        color="default"
                        otherClass="!min-w-0"
                        disabled={isUpdatingUpload}
                        isSubmitting={isUpdatingUpload}
                        handleClick={() => {
                          setImages([]);
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="flex h-20 w-full flex-row items-center justify-center">
                <CircularProgress size={25} thickness={10} />
              </div>
            )}
          </div>
        </SectionContent>
      </div>
    </div>
    // </PageContainer>
  );
}

import React from "react";
import "./index.css";

const index = () => {
  return (
    <div className="bouncing-loader">
      <div className="bg-green-400"></div>
      <div className="bg-green-500"></div>
      <div className="bg-green-600"></div>
    </div>
  );
};

export default index;

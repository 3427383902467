import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import dayjs from "dayjs";
import SwapVertIcon from "@mui/icons-material/SwapVert";

ChartJS.register(ArcElement, Tooltip, Legend);

const MetaImpressions = () => {
  const [pageImpresions, setPageImpresions] = useState([]);
  const [paidImpresions, setPaidImpresions] = useState([]);
  const [organicImpressions, setOrganicImpressions] = useState([]);

  useEffect(() => {
    const getFaceBookInsights = async () => {
      const page_ID = process.env.REACT_APP_KAAPO_PAGE_ID;
      const access_token = process.env.REACT_APP_KAAPO_ACCESS_TOKEN;
      try {
        const response = await axios.get(
          `https://graph.facebook.com/1025961717484030/insights?metric=page_impressions_paid_unique,page_impressions_organic_v2,page_fans_by_like_source_unique,page_actions_post_reactions_like_total,page_views_by_referers_logged_in_unique,page_views_external_referrals,page_video_views_by_paid_non_paid,page_impressions_by_country_unique,page_impressions_unique,page_engaged_users,page_consumptions_unique,page_cta_clicks_logged_in_total,page_website_clicks_logged_in_unique&access_token=${access_token}`
        );

        const result = response.data.data;

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const impressions = result.filter((obj) => {
          return obj.name === "page_impressions_unique";
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const ImpressionsPaid = result.filter((obj) => {
          return obj.name === "page_impressions_paid_unique";
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const ImpressionsOrganic = result.filter((obj) => {
          return obj.name === "page_impressions_organic_v2";
        });

        setPageImpresions(impressions);
        setPaidImpresions(ImpressionsPaid);
        setOrganicImpressions(ImpressionsOrganic);
      } catch (error) {
        console.log(error);
      }
    };

    getFaceBookInsights();
  }, []);

  const pageImpressionFigures = pageImpresions.map((imp) => imp.values);

  const paidImpressionFigures = paidImpresions.map((imp) => imp.values);

  const organicImpressionFigures = organicImpressions.map((imp) => imp.values);

  let ChartDates = pageImpressionFigures
    .reduce((c, v) => c.concat(v), [])
    .map((o) => {
      return dayjs().format(o.end_time);
    });

  let paidImpDates = paidImpressionFigures
    .reduce((c, v) => c.concat(v), [])
    .map((o) => {
      return dayjs().format(o.end_time);
    });

  let organicImpDates = organicImpressionFigures
    .reduce((c, v) => c.concat(v), [])
    .map((o) => {
      return dayjs().format(o.end_time);
    });

  let chartValues = pageImpressionFigures
    .reduce((c, v) => c.concat(v), [])
    .map((o) => o.value);

  let paidImpValues = paidImpressionFigures
    .reduce((c, v) => c.concat(v), [])
    .map((o) => o.value);

  let organicImpValues = organicImpressionFigures
    .reduce((c, v) => c.concat(v), [])
    .map((o) => o.value);

  const firstEndDate = chartValues.filter((e, i) => (i % 2) - 1);
  const secondEndDate = chartValues.filter((e, i) => i % 2);

  const paidImpFirstEndDate = paidImpValues.filter((e, i) => (i % 2) - 1);
  const paidImpSecondEndDate = paidImpValues.filter((e, i) => i % 2);

  const organicImpFirstEndDate = organicImpValues.filter((e, i) => (i % 2) - 1);
  const organicImpSecondEndDate = organicImpValues.filter((e, i) => i % 2);

  const firstEndTime = ChartDates.filter((e, i) => (i % 2) - 1);
  const secondEndTime = ChartDates.filter((e, i) => i % 2);

  const paidImpSecondEndTime = paidImpDates.filter((e, i) => i % 2);

  const organicImpSecondEndTime = organicImpDates.filter((e, i) => i % 2);

  const endTime1 = firstEndTime.map((x) => dayjs().format(x.slice(0, 10)));
  const endTime2 = secondEndTime.map((x) => dayjs().format(x.slice(0, 10)));

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Facebook page Reach",
      },
    },
  };

  const totalData = [
    {
      name: "paid",
      daily1: paidImpSecondEndDate[0],
      daily2: paidImpFirstEndDate[0],
      weekly1: paidImpSecondEndDate[1],
      weekly2: paidImpFirstEndDate[1],
      monthly1: paidImpSecondEndDate[2],
      monthly2: paidImpFirstEndDate[2],
    },
    {
      name: "organic",
      daily1: organicImpSecondEndDate[0],
      daily2: organicImpFirstEndDate[0],
      weekly1: organicImpSecondEndDate[1],
      weekly2: organicImpFirstEndDate[1],
      monthly1: organicImpSecondEndDate[2],
      monthly2: organicImpFirstEndDate[2],
    },

    {
      name: "total",
      daily1: secondEndDate[0],
      daily2: firstEndDate[0],
      weekly1: secondEndDate[1],
      weekly2: firstEndDate[1],
      monthly1: secondEndDate[2],
      monthly2: firstEndDate[2],
    },
  ];

  const data = {
    labels: ["Daily", "Weekly", "Monthly"],
    datasets: [
      {
        label: endTime1[0],
        data: firstEndDate,
        backgroundColor: [
          "rgba(108, 209, 105, 0.918)",
          "rgb(39, 161, 65)",
          "rgba(3, 102, 20, 0.986)",
        ],
        borderColor: [
          "rgba(29, 184, 23, 0.918)",
          "rgb(44, 129, 64)",
          "rgba(3, 102, 20, 0.986)",
        ],
        borderWidth: 1,
      },
      {
        label: endTime2[1],
        data: secondEndDate,
        backgroundColor: [
          "rgba(190, 231, 188, 0.918)",
          "rgb(60, 175, 87)",
          "rgba(56, 129, 69, 0.986)",
        ],
        borderColor: [
          "rgba(29, 184, 23, 0.918)",
          "rgb(44, 129, 64)",
          "rgba(37, 112, 49, 0.986)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div
        className="mx-auto my-auto h-[500px] w-full
       overflow-scroll rounded-md bg-white py-5 px-3 shadow-sm
       drop-shadow-md dark:bg-slate-700"
      >
        <div className="mx-auto w-[500px] text-slate-600 dark:text-white">
          {/* <h3
            className="pb-0 mx-48 mb-0 text-xl font-bold text-gray-500 capitalize bg-violet-400"
          >
            Facebook page reach
          </h3> */}
          <Doughnut
            title="Facebook page reach"
            className="mt-[0px]  h-[350px] w-[500px] p-8 
            text-xl text-slate-600 dark:text-white"
            data={data}
            options={options}
          />
        </div>
        <div className="mt-4 w-full rounded-md border-2 bg-green-900/30 p-3 dark:border-slate-400">
          <div className="z-40 mx-auto flex w-full justify-around rounded-lg bg-slate-100 px-3 py-10 text-gray-600 shadow-sm drop-shadow-sm dark:bg-slate-800 dark:text-white">
            {totalData.map((td) => {
              return (
                <div className="col-span-3 w-60 bg-slate-100 shadow-sm drop-shadow-sm dark:bg-slate-800">
                  <h3 className="text-center text-base font-bold capitalize text-gray-600 outline-green-200 dark:text-white">
                    {td.name}
                  </h3>
                  <hr className="my-1" />
                  <div className="grid grid-cols-2 gap-2">
                    <p className="break-keep w-full text-base font-medium capitalize oldstyle-nums text-gray-600 dark:text-white">
                      Daily:
                    </p>
                    <p className="break-keep m-0 w-fit text-base font-medium capitalize oldstyle-nums text-gray-600 dark:text-white">
                      {td.daily1}
                      <SwapVertIcon
                        className={
                          td.daily1 > td.daily2
                            ? "text-green-600"
                            : "text-red-500"
                        }
                      />
                    </p>
                  </div>
                  <hr className="my-1" />
                  <div className="grid grid-cols-2 gap-2">
                    <p className="break-keep w-full text-base font-medium capitalize oldstyle-nums text-gray-600 dark:text-white">
                      weekly
                    </p>
                    <p className="break-keep w-full text-base font-medium capitalize oldstyle-nums text-gray-600 dark:text-white">
                      {td.weekly1}
                      <SwapVertIcon
                        className={
                          td.weekly1 > td.weekly2
                            ? "text-green-600"
                            : "text-red-500"
                        }
                      />
                    </p>
                  </div>
                  <hr className="my-1" />
                  <div className="grid grid-cols-2 gap-2">
                    <p className="break-keep w-full text-base font-medium capitalize oldstyle-nums text-gray-600 dark:text-white">
                      monthly
                    </p>
                    <p className="break-keep w-full text-base font-medium capitalize oldstyle-nums text-gray-600 dark:text-white">
                      {td.monthly1?.toLocaleString()}

                      <SwapVertIcon
                        className={
                          td.monthly1 > td.monthly2
                            ? "text-green-600"
                            : "text-red-500"
                        }
                      />
                    </p>
                  </div>
                  <hr className="my-1" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MetaImpressions;

import React from "react";
import { useSelector } from "react-redux";
import { TextField } from "components";
import { formatDate } from "utils";
import { capitalize, startCase } from "lodash";

export default function Account() {
  const {
    profile: { user_details },
    auth: { current_user_details: currUser },
  } = useSelector((state) => state);

  const roles = (roles) =>
    roles && Object.entries(roles)?.some((i) => i[1] === true)
      ? Object.entries(roles)
          ?.filter((item) => item[1])
          ?.reduce((acc, item, index, arr) => {
            if (item[1]) {
              acc += `${startCase(item[0])}${
                arr?.length - 1 !== index ? " | " : ""
              }`;
            }
            return acc;
          }, "")
      : "No roles";

  return (
    <div className="mt-4 p-2">
      <div className="grid grid-cols-1 gap-y-8 gap-x-4 md:grid-cols-2">
        <div className="col-span-1">
          <TextField
            name="displayName"
            type="text"
            label="Full Name"
            value={user_details?.displayName}
            readOnly
          />
        </div>

        <div className="col-span-1">
          <TextField
            name="email"
            type="email"
            label="Email"
            value={user_details?.email}
            readOnly
          />
        </div>
        <div className="col-span-1">
          <TextField
            name="phoneNumber"
            type="text"
            label="Phone Number"
            value={user_details?.phoneNumber}
            readOnly
          />
        </div>
        <div className="col-span-1">
          <TextField
            name="status"
            type="text"
            label="Visibility Status"
            value="Online"
            readOnly
          />
        </div>
        <div className="col-span-1">
          <TextField
            name="position"
            type="text"
            label="Position"
            value={user_details?.position}
            readOnly
          />
        </div>

        <div className="col-span-1">
          <TextField
            name="status"
            type="text"
            label="Account Status"
            value={capitalize(user_details?.status)}
            readOnly
          />
        </div>
        <div className="col-span-1">
          <TextField
            name="dateJoined"
            type="text"
            label="Date Joined"
            value={formatDate(
              user_details?.accountCreated,
              "dddd, MMM Do YYYY hh:mm a"
            )}
            readOnly
          />
        </div>
        <div className="col-span-1">
          <TextField
            name="lastLogin"
            type="text"
            label="Last Login"
            value={formatDate(
              parseInt(currUser?.metadata?.lastLoginAt),
              "dddd, MMM Do YYYY hh:mm a"
            )}
            readOnly
          />
        </div>
        <div className="col-span-2">
          <TextField
            name="role"
            type="text"
            label="Roles"
            value={roles(user_details?.roles)}
            readOnly
          />
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as i from "../lib";

import { getFiatRate } from "redux/reducers/rateReducers";
import { getUserDetails } from "redux/reducers/usersReducers";
import {
  appDialog,
  appDialogContent,
  appDrawer,
  getCardDeclineReasons,
  getCardDeferReasons,
  getCurrentSideTransTab,
  getFiatProvider,
  getModeTheme,
  getRefreshSideInfo,
  getTransFilterDate,
  getTransReportDateRange,
  setDrawerDocDetails,
  setFullPageDocDetails,
  sideDrawerInfo,
} from "redux/reducers/utilReducers";

const auth = i.getAuth();

export const useToggleAppDialog = () => {
  const dispatch = useDispatch();

  const toggleAppDialog = (isOpen) => {
    dispatch(appDialog(isOpen));
  };
  return [toggleAppDialog];
};

export const useAppDialogContent = () => {
  const dispatch = useDispatch();

  const dialogContent = (content) => {
    dispatch(appDialogContent(content));
  };
  return [dialogContent];
};

export const useToggleAppDrawer = () => {
  const dispatch = useDispatch();

  const toggleAppDrawer = (isOpen) => {
    dispatch(appDrawer(isOpen));
  };
  return [toggleAppDrawer];
};

export const useToggleSideDrawerInfo = () => {
  const dispatch = useDispatch();

  const toggleSideDrawerInfo = (isOpen) => {
    dispatch(sideDrawerInfo(isOpen));
  };
  return [toggleSideDrawerInfo];
};

export const useDrawerDocDetails = () => {
  const dispatch = useDispatch();

  const drawerDocDetails = (data) => {
    dispatch(setDrawerDocDetails(data));
  };
  return [drawerDocDetails];
};

export const useFullPageDocDetails = () => {
  const dispatch = useDispatch();

  const fullPageDocDetails = (data) => {
    dispatch(setFullPageDocDetails(data));
  };
  return [fullPageDocDetails];
};

export const useGetFilterDate = () => {
  const dispatch = useDispatch();

  const getFilterDate = (data) => {
    dispatch(getTransFilterDate(data));
  };
  return [getFilterDate];
};

export const useGiftcardUtils = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getUtils = async () => {
      const doc1 = await i.getDoc(
        i.doc(i.db2, "utils", "giftcardDeclineReasons")
      );
      const doc2 = await i.getDoc(
        i.doc(i.db2, "utils", "giftcardDeferReasons")
      );
      if (doc1.exists && doc2.exists && auth?.currentUser) {
        dispatch(getCardDeclineReasons(doc1.data()?.reasons));
        dispatch(getCardDeferReasons(doc2.data()?.reasons));
      }
    };

    getUtils();

    return () => {};
  }, [dispatch, auth?.currentUser]);
};

export const useFiatRateUtils = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getUtils = async () => {
      const doc1 = await i.getDoc(i.doc(i.db, "utils", "fiatRate"));
      const doc2 = await i.getDoc(i.doc(i.db2, "utils", "fiatRate"));

      if (doc1.exists && doc2.exists && auth?.currentUser) {
        dispatch(
          getFiatRate({
            esetech: doc1?.data(),
            kaapo: doc2?.data(),
          })
        );
      }
    };

    getUtils();

    return () => {};
  }, [dispatch, auth?.currentUser]);
};

export const useFiatPaymentProvider = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getUtils = async () => {
      const doc1 = await i.getDoc(i.doc(i.db, "utils", "automaticPayment"));
      const doc2 = await i.getDoc(i.doc(i.db2, "utils", "automaticPayment"));

      if (doc1.exists && doc2.exists && auth?.currentUser) {
        dispatch(
          getFiatProvider({
            esetech: {
              paymentProvider: doc1?.data()?.usePaymentProvider,
              isAutomatic: doc1?.data()?.isAutomatic,
            },
            kaapo: {
              paymentProvider: doc2?.data()?.usePaymentProvider,
              isAutomatic: doc2?.data()?.isAutomatic,
            },
          })
        );
      }
    };

    getUtils();

    return () => {};
  }, [dispatch, auth?.currentUser]);
};

const allowedIDs = [
  "j2FClTIVhYgfuC7nM5OuJpxyQPz1",
  "2aeb7d42cb17a3200372740e3667f7",
  "730b405329db95d9f6bd9cba6bfeb7",
];

export const appCheck = async (platform) => {
  return new Promise(async (resolve, reject) => {
    try {
      const firebaseAppCheck =
        platform === "esetech" ? i.firebaseAppCheck : i.firebaseAppCheck2;

      if (allowedIDs.includes(auth?.currentUser?.uid)) {
        const appCheckTokenResponse = await i.getToken(firebaseAppCheck, true);
        return resolve(appCheckTokenResponse.token);
      }

      return resolve(true);
    } catch (err) {
      console.log(err);
      return reject("token was not retrieved");
    }
  });
};

export const useFullDrawerUserDetails = () => {
  const dispatch = useDispatch();

  const fullDrawerUserDetails = (data) => {
    dispatch(getUserDetails(data));
  };
  return [fullDrawerUserDetails];
};

export const useGetThemeMode = () => {
  const dispatch = useDispatch();

  const getTheme = (type) => {
    dispatch(getModeTheme(type));
  };
  return [getTheme];
};

export const useRefreshSideInfo = () => {
  const dispatch = useDispatch();
  const [info, setInfo] = useState({
    transInfo: false,
    accountBalance: false,
    automatedTrans: false,
  });

  const refreshSideInfo = (type) => {
    const dd = { ...info, [type]: !info?.[type] };
    setInfo(dd);

    dispatch(getRefreshSideInfo(dd));
  };
  return [refreshSideInfo];
};

export const useGetCurrentSideTransTab = () => {
  const dispatch = useDispatch();

  const getCurrentTab = (tab) => {
    dispatch(getCurrentSideTransTab(tab));
  };
  return [getCurrentTab];
};

export const useGetTransReportDateRange = () => {
  const dispatch = useDispatch();

  const getReportDateRange = (range) => {
    dispatch(getTransReportDateRange(range));
  };
  return [getReportDateRange];
};

import React, { useEffect, useState } from "react";
import { FullWidthTabs } from "components";
import { PageContainer } from "shared";
import ReportList from "./components/ReportList";

const tabList = [
  {
    label: "Esetech",
    Icon: "Assessment",
    platform: "esetech",
    Comp: ReportList,
    summaryList: [
      {
        id: "withdrawal",
        bgColor: "bg-pink-600",
      },
      { id: "crypto", bgColor: "bg-teal-600" },
      { id: "utilities", bgColor: "bg-yellow-600" },
      { id: "bonus", bgColor: "bg-cyan-600" },
    ],
  },
  {
    label: "Kaapo",
    Icon: "Assessment",
    platform: "kaapo",
    Comp: ReportList,
    summaryList: [
      {
        id: "withdrawal",
        bgColor: "bg-pink-600",
      },
      { id: "giftcard", bgColor: "bg-teal-600" },
      { id: "utilities", bgColor: "bg-yellow-600" },
      { id: "bonus", bgColor: "bg-cyan-600" },
      // { id: "fund", bgColor: "bg-red-600" },
    ],
  },
];

export default function Index() {
  const [currentTab, setCurrentTab] = useState(tabList[0]);
  const [currentTabF, setCurrentTabF] = useState(null);

  useEffect(() => {
    setCurrentTabF(null);

    setTimeout(() => {
      setCurrentTabF(currentTab);
    }, 1000);
  }, [currentTab]);

  return (
    <PageContainer>
      <FullWidthTabs
        {...{ currentTab, setCurrentTab, tabList }}
        Comp={<currentTab.Comp {...currentTabF} />}
      />
    </PageContainer>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import bcrypt from "bcryptjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button } from ".";
import { classNames } from "utils";

function OTPInput({ inputClassName, otpType, onChange, OTPLength, secure }) {
  const [otp, setOtp] = useState(new Array(OTPLength).fill(""));

  const handleChange = (e, ind) => {
    const ele = e.target;
    if (isNaN(ele.value)) return false;

    const eleV = ind === OTPLength - 1 ? ele.value[0] : ele.value;
    setOtp([...otp.map((d, idx) => (idx === ind ? eleV : d))]);
  };

  const handleKeyup = (e) => {
    if ([8, 46].includes(e.keyCode)) {
      if (e.target.previousSibling) e.target.previousSibling.focus();
    } else if (e.target.nextSibling && Boolean(e.target.value)) {
      e.target.nextSibling.focus();
    }
  };

  useEffect(() => {
    onChange(otp.join(""));
  }, [otp]);

  return (
    <div className="my-1">
      <div className="flex items-center justify-start gap-2">
        {otp.map((item, index) => (
          <input
            // type={otpType}
            type={secure ? "password" : otpType}
            inputMode="numeric"
            key={index}
            value={item}
            onFocus={(e) => e.target.select()}
            onChange={(e) => handleChange(e, index)}
            onKeyUp={handleKeyup}
            className={classNames(
              "h-8 w-8 text-center outline-none",
              "flex items-center justify-center",
              inputClassName
            )}
            maxLength="1"
            autoComplete="off"
          />
        ))}
      </div>
    </div>
  );
}

export default function EnterPIN({ processSubmit, btnText, isSubmitting }) {
  const [OTP, setOTP] = useState("");
  const [otpError, setOtpError] = useState();
  const [secure, setSecure] = useState(true);
  const { user_details } = useSelector((state) => state.profile);

  const transactionPIN = user_details?.transactionPIN;
  // const transactionPIN = "222222";
  const handleComparePIN = (e) => {
    if (transactionPIN) {
      setOTP(e);

      if (e?.length === 6) {
        const pin = transactionPIN;
        setOtpError(bcrypt.compareSync(e, pin));
        // setOtpError(e === pin);
      } else {
        setOtpError();
      }
    }
  };

  return (
    <div className="w-full p-0">
      {transactionPIN ? (
        <div className="text-center dark:text-white">
          <p>Enter your PIN to complete transaction</p>
          <div className="flex flex-col items-center">
            <button onClick={() => setSecure(!secure)}>
              {!secure ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </button>
            <div>
              <OTPInput
                value={OTP}
                onChange={handleComparePIN}
                OTPLength={6}
                otpType={"number"}
                disabled={false}
                secure={secure}
                inputClassName="border rounded-sm border-gray-500 text-gray-500 !m-1 focus:border-color-main outline-none focus:border-2 focus:rounded-sm text-[16px] dark:bg-gray-900 dark:border-gray-600"
              />
              {otpError !== undefined ? (
                <>
                  {!otpError ? (
                    <span className="error-message text-xs text-red-500">
                      PIN does not match
                    </span>
                  ) : null}
                </>
              ) : null}
            </div>
            <div className="action mt-2 w-full">
              <div className="action-buttons flex flex-row items-center justify-center space-x-2">
                <Button
                  label="Reset PIN"
                  link="/settings"
                  state={{ page: 3 }}
                  color="default"
                  disabled={isSubmitting}
                />
                <Button
                  // label="Complete Transaction"
                  label={btnText}
                  gradient
                  disabled={isSubmitting || !otpError || OTP?.length < 6}
                  handleClick={processSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div>You need to set your PIN to continue!</div>
          <div className="mt-2">
            <Button
              label="Set PIN"
              link="/settings"
              color="info"
              // disabled={isSubmitting}
            />
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useCallback, useState } from "react";
import { AppBar, Box, Toolbar, IconButton, Badge } from "@mui/material";
import { classNames } from "utils";
import { Link, useNavigate } from "react-router-dom";
import Wobble from "react-reveal/Wobble";

import { useGetThemeMode, useLogout, useSetMessageNotification } from "api";
import { DrawerSide, SimpleIcon } from "components";
import { toLower, truncate, upperCase } from "lodash";
import { useSelector } from "react-redux";
import { Divider } from "@mui/material";
import { TradeNotification } from "components/TradeNotification";
import { MessageNotification } from "components/MessageNotification";

export default function Navbar({
  // openDrawer,
  // setOpenDrawer,
  drawerWidth,
  openDialog,
}) {
  const navigate = useNavigate();
  const [, , , logout] = useLogout();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [getTheme] = useGetThemeMode();

  const { user_details } = useSelector((state) => state.profile);
  const { modeTheme } = useSelector((state) => state.util || {});
  const { currentUserUnread } = useSelector((state) => state.chat || {});

  const themeMode = useCallback(() => {
    return localStorage?.theme;
  }, [modeTheme])();

  useSetMessageNotification();

  const initials = upperCase(user_details?.displayName.split("")[0]);

  const handleChangeMode = (type) => {
    if (type === "light") {
      localStorage.theme = "light";
    }

    if (type === "dark") {
      localStorage.theme = "dark";
    }

    if (type === "system") {
      localStorage.removeItem("theme");
    }
    getTheme(type);
  };
  return (
    <Box
      sx={{ flexGrow: 1 }}
      classes={{
        root: "!dark:bg-gray-800",
      }}
    >
      <TradeNotification />
      <MessageNotification />
      <AppBar
        position={openDialog ? "sticky" : "fixed"}
        className={classNames(
          drawerWidth,
          "right-0 top-0 bg-gradient-to-r from-bg_gradient_from to-bg_gradient_to !shadow-none !transition-all !duration-500 dark:from-dark_bg_gradient_from dark:to-dark_bg_gradient_to",
          openDialog ? "!left-[16%]" : ""
        )}
      >
        <Toolbar>
          <div
            className={
              "relative flex h-[64px] items-center justify-center bg-primary"
            }
          >
            <Link
              to="/"
              className={classNames(
                "absolute left-1 flex flex-row items-center text-white duration-100"
              )}
            >
              <img
                src={require("../assets/e-logo-white.svg").default}
                className="h-5 w-5 text-white "
                alt="logo"
              />
              <div className="ml-2 text-2xl">esetech</div>
            </Link>
          </div>

          <Box sx={{ flexGrow: 1 }} />
          <div className="flex flex-row gap-1">
            <button className="relative">
              <div
                className="flex h-12 w-12 items-center justify-center rounded-full duration-300 ease-in hover:bg-transparent-main"
                onClick={() => navigate("/chatbox")}
              >
                <SimpleIcon icon="Mail" frontColor="!text-white" />
              </div>

              <div className="absolute -top-0 -right-0">
                <Wobble
                  forever={Boolean(currentUserUnread)}
                  spy={currentUserUnread}
                >
                  {currentUserUnread ? (
                    <div className="flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-xs text-white">
                      {currentUserUnread}
                    </div>
                  ) : (
                    <div />
                  )}
                </Wobble>
              </div>
            </button>
            <button
              onClick={() => setOpenDrawer(true)}
              className="rounded-full hover:bg-transparent-main"
            >
              <div className="flex h-12 w-12 items-center justify-center rounded-full duration-300 ease-in hover:bg-transparent-main">
                <SimpleIcon icon="AccountCircle" frontColor="!text-white" />
              </div>
            </button>
          </div>
        </Toolbar>
      </AppBar>
      <DrawerSide
        {...{
          open: openDrawer,
          setOpen: setOpenDrawer,
          customWidth: "w-[350px]",
        }}
      >
        {/* h-auto */}
        <div className="relative min-h-full overflow-auto bg-box p-2 dark:bg-slate-900">
          <div className="relative min-h-full rounded bg-white p-2 shadow-none dark:bg-gray-800">
            <div className="relative flex w-full justify-center">
              <div className="header">
                <div className="avatar mx-auto flex h-[100px] w-[100px] flex-col items-center justify-center bg-box dark:bg-gray-900">
                  <div className="flex h-16 w-16 items-center justify-center rounded-full bg-background-main shadow-drawer dark:bg-slate-600">
                    <h5 className="text-3xl font-bold text-primary dark:text-light-primary">
                      {initials}
                    </h5>
                  </div>
                </div>
                <div
                  className={classNames(
                    "mt-2 text-center delay-200 dark:text-white"
                  )}
                >
                  <h6 className="text-lg">
                    {truncate(user_details?.displayName, {
                      length: 20,
                    })}
                  </h6>
                  <div className="text-sm">
                    {truncate(toLower(user_details?.email, { length: 28 }))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <div className="mb-2 text-xs font-bold uppercase dark:text-white">
                Mode
              </div>
              <div className="rounded border bg-box shadow-md dark:border-gray-600 dark:bg-gray-900">
                <ul className="grid grid-cols-3">
                  {[
                    {
                      title: "Light",
                      id: "light",
                      icon: "LightMode",
                      isTheme: ["light"]?.includes(themeMode),
                    },
                    {
                      title: "System",
                      id: "system",
                      icon: "SettingsBrightness",
                      isTheme: !["light", "dark"]?.includes(themeMode),
                    },
                    {
                      title: "Dark",
                      id: "dark",
                      icon: "DarkMode",
                      isTheme: ["dark"]?.includes(themeMode),
                    },
                  ].map((item, index, arr) => (
                    <li className="col-span-1 flex flex-row" key={item.id}>
                      <button
                        className={classNames(
                          "flex h-full w-full flex-row items-center gap-2 px-4 py-3 dark:text-white",
                          item?.isTheme
                            ? "rounded border border-gray-400 bg-primary font-bold text-white shadow-md dark:bg-dark-primary"
                            : ""
                        )}
                        onClick={() => {
                          handleChangeMode(item?.id);
                        }}
                      >
                        <SimpleIcon
                          icon={item?.icon}
                          fontSize={18}
                          frontColor={
                            item?.isTheme ? "text-white" : "text-primary"
                          }
                        />
                        {item?.title}
                      </button>
                      {index !== arr.length - 1 && (
                        <Divider
                          orientation="vertical"
                          flexItem
                          classes={{ root: "dark:!border-gray-600" }}
                        />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 w-full p-2">
            <hr className="mb-0 border-t dark:border-gray-500" />

            <ul className="w-full p-2">
              <li className="flex flex-row items-center gap-1 p-2 dark:text-white hover:dark:bg-gray-700">
                <SimpleIcon fontSize={18} icon="Settings" />
                <button onClick={() => navigate("/settings")}>Settings</button>
              </li>
              <li className="flex flex-row items-center gap-1 p-2 dark:text-white hover:dark:bg-gray-700">
                <SimpleIcon fontSize={18} icon="Logout" />
                <button onClick={logout}>Logout</button>
              </li>
            </ul>
          </div>
        </div>
      </DrawerSide>

      {/* {renderMenu} */}
    </Box>
  );
}

export function NavbarF() {
  const { current_user, isLoaded } = useSelector((state) => state.auth);
  const { user_details } = useSelector((state) => state.profile);

  if (current_user && isLoaded && user_details) {
    return <Navbar />;
  } else {
    return null;
  }
}

import React, { Fragment } from "react";
import { Button, Loader, NoData, SectionContent, SimpleIcon } from "components";
import { truncate } from "utils";
import { getIsLoadedTrans, useAllTrans } from "utils/allTransactionProps";

export default function DeferredTransaction({ count: transLength }) {
  const trans = useAllTrans().filter((item) => item?.id.includes("deferred"));

  const isLoaded = getIsLoadedTrans(trans);

  return (
    <Fragment>
      {isLoaded ? (
        <div className="grid grid-cols-1 gap-4">
          {trans && transLength ? (
            trans?.map((transItem) => {
              return (
                <>
                  {transItem?.data && transItem?.data?.length ? (
                    <div className="col-span-1">
                      <SectionContent>
                        <div className="w-full rounded border border-slate-100 bg-box p-0 shadow-sm dark:border-slate-600 dark:bg-gray-700">
                          <div className="relative overflow-x-auto p-2 shadow-md">
                            <div className="rounded bg-white p-2 dark:bg-slate-800">
                              <h6 className="mb-1 p-1 font-semibold dark:text-white">
                                {transItem.title}
                              </h6>
                              <ul>
                                {transItem?.data?.map((itemD) => {
                                  const item = transItem?.objectSummary(itemD);
                                  const itemValues = Object.values(item);
                                  return (
                                    <li
                                      key={item.id}
                                      className="my-1 flex flex-row items-center justify-between rounded bg-link-primary/50 p-2 dark:bg-gray-900"
                                    >
                                      <div className="flex flex-row items-center justify-start">
                                        <SimpleIcon icon="MoreHoriz" />
                                        <div className="ml-2 text-text-main dark:text-light-primary">
                                          <span>
                                            Deferred - {itemValues[2]?.value} /{" "}
                                            {itemValues[3]?.value} /{" "}
                                            {truncate(itemValues[4]?.value, 25)}{" "}
                                            - {itemValues[6]?.value}
                                          </span>
                                        </div>
                                      </div>
                                      <Button
                                      color={
                                        ["Claim", "Re-claim"].includes(
                                          transItem?.buttonText
                                        )
                                          ? "warning"
                                          : "success"
                                      }
                                        
                                        label={transItem?.buttonText}
                                        size="small"
                                        // otherClass="!min-w-0"
                                        {...(transItem?.handleOnClick && {
                                          handleClick: () =>
                                            transItem?.handleOnClick(item),
                                        })}
                                        {...(transItem?.isSubmitting && {
                                          isSubmitting:
                                            transItem?.isSubmitting(item),
                                        })}
                                      />
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </SectionContent>
                    </div>
                  ) : null}
                </>
              );
            })
          ) : (
            <NoData text="No deferred transaction at the moment. Enjoy! 😊" />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
}

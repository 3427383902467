import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openAppDialog: false,
  openAppDrawer: false,
  dialogContent: { content: () => null, title: "" },
  openTransDrawer: false,
  drawerDocDetails: null,
  fullPageDocDetails: null,
  loadingAllTransKap: false,
  loadedAllTransKap: false,
  openSideDrawerInfo: true,
  fiatRate: null,
  fullPageUserDetails: null,
  refreshSideInfo: false,
  currentSideTransTab: "tradeInfo",
  transReportDateRange: null,
};

export const utilSlice = createSlice({
  name: "util",
  initialState,
  reducers: {
    appDialog: (state, { payload }) => {
      state.openAppDialog = payload;
    },
    appDialogContent: (state, { payload }) => {
      state.dialogContent = payload;
    },
    appDrawer: (state, { payload }) => {
      state.openAppDrawer = payload;
    },
    sideDrawerInfo: (state, { payload }) => {
      state.openSideDrawerInfo = payload;
    },

    transactionDrawer: (state, { payload }) => {
      state.openTransDrawer = payload;
    },
    setDrawerDocDetails: (state, { payload }) => {
      state.drawerDocDetails = payload;
    },
    setFullPageDocDetails: (state, { payload }) => {
      state.fullPageDocDetails = payload;
    },
    setFullDrawerUserDetails: (state, { payload }) => {
      state.fullPageUserDetails = payload;
    },
    isLoadingAllTransKap: (state, { payload }) => {
      state.loadingAllTransKap = payload;
    },
    getTansDate: (state, { payload }) => {
      state.loadedAllTransKap = payload;
    },
    getTransFilterDate: (state, { payload }) => {
      state.transFilterDate = payload;
    },
    getCardDeclineReasons: (state, { payload }) => {
      state.giftcardDeclineReasons = payload;
    },
    getCardDeferReasons: (state, { payload }) => {
      state.giftcardDeferReasons = payload;
    },
    getFiatProvider: (state, { payload }) => {
      state.fiatProvider = payload;
    },
    getModeTheme: (state, { payload }) => {
      state.modeTheme = payload;
    },
    getRefreshSideInfo: (state, { payload }) => {
      state.refreshSideInfo = payload;
    },
    getCurrentSideTransTab: (state, { payload }) => {
      state.currentSideTransTab = payload;
    },
    getTransReportDateRange: (state, { payload }) => {
      state.transReportDateRange = payload;
    },
  },
});

export const {
  appDialog,
  appDrawer,
  sideDrawerInfo,
  appDialogContent,
  transactionDrawer,
  setDrawerDocDetails,
  setFullPageDocDetails,
  isLoadingAllTransKap,
  isLoadedAllTransKap,
  getTransFilterDate,
  getCardDeclineReasons,
  getCardDeferReasons,
  getFiatProvider,
  setFullDrawerUserDetails,
  getModeTheme,
  getRefreshSideInfo,
  getCurrentSideTransTab,
  getTransReportDateRange,
} = utilSlice.actions;

export default utilSlice.reducer;

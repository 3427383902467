import React, { useState, useEffect, Fragment, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { SimpleIcon } from "components";
import { classNames } from "utils";
import { drawerLinks } from "utils/constants";
import { useSelector } from "react-redux";
import { Fade, Popper } from "@mui/material";

export default function Drawer({ logout }) {
  const [getOpenSubLinks, setGetOpenSubLinks] = useState(null);
  const popoverRef = useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const {
    user_details: { roles, position },
    user_details: userD,
  } = useSelector((state) => state.profile);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleSubLinkPopup = (event, link) => {
    setAnchorEl(event.currentTarget);
    setGetOpenSubLinks(link);
  };

  const handleClickOutside = (event) => {
    try {
      if (
        event &&
        event?.target?.className?.includes(`drawer-sublink-popover`)
      ) {
        setOpen(event.type === "mouseover" ? true : !open);
      } else if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    ["click", "mouseover"].map((item) => {
      return document.addEventListener(item, handleClickOutside, true);
    });

    return () => {
      ["click", "mouseover"].map((item) => {
        return document.removeEventListener(item, handleClickOutside, true);
      });
    };
  }, [open]);

  return (
    <div
      className={classNames(
        "fixed left-0 top-0 z-[1000] h-screen w-[7%] overflow-auto bg-background-main shadow-drawer duration-200 hover:shadow-drawer dark:bg-slate-900"
      )}
    >
      <div
        className={
          "relative flex h-[64px] items-center justify-center bg-primary dark:bg-dark-primary"
        }
      >
        <h1
          className={classNames(
            // openDrawer ? "" : "invisible",
            "flex flex-row items-center text-white duration-100"
          )}
        >
          <img
            src={require("../assets/e-logo.svg").default}
            className="h-5 w-5"
            alt="logo"
          />
          <div className="ml-2 text-2xl">esetech</div>
        </h1>
      </div>

      <ul className="drawer-links my-1">
        {userD &&
          drawerLinks(position, roles)?.map((l, index) => {
            // const isLink = pathname?.includes(stripPathName(l.linkTo));
            const isLink = pathname === l.linkTo;
            const isSubLink = (l.subLink || [])
              ?.map((i) => i.linkTo)
              ?.includes(pathname);

            return (
              <Fragment key={index}>
                {l.display ? (
                  <li
                    className={classNames(
                      "relative w-full overflow-hidden rounded"
                    )}
                    key={l.name}
                  >
                    <button
                      className={classNames(
                        l.subLink ? "drawer-sublink-popover" : "",
                        isLink || isSubLink
                          ? "border-primary bg-link-primary dark:border-dark-primary dark:bg-light-primary/20"
                          : "hover:bg-slate-300/50 dark:border-slate-900 hover:dark:bg-light-primary/20",
                        "flex w-full flex-row items-center justify-start justify-center border-r-4 p-3 "
                      )}
                      onClick={(e) => {
                        if (l.subLink) {
                          handleSubLinkPopup(e, l.subLink);
                          // setCurrentLink(l.name);
                        } else if (l.handleLogout) {
                          logout();
                        } else {
                          navigate(l.linkTo);
                          setOpen(false);
                        }
                      }}
                      onMouseOver={(e) => {
                        if (l.subLink) handleSubLinkPopup(e, l.subLink);
                      }}
                      // onMouseLeave={(e) => {
                      //   if (l.subLink) setOpen(false);
                      // }}
                    >
                      <div className="pointer-events-none relative flex w-full flex-col items-center">
                        <div className="relative">
                          <div className="link-icon flex h-9 w-9 flex-row items-center justify-center rounded-full bg-box dark:bg-gray-800">
                            <SimpleIcon
                              icon={l.icon}
                              fontSize={20}
                              otherClass="dark:text-light-primary"
                            />
                          </div>
                          {l.subLink && (
                            <div className="absolute -right-6 top-1">
                              <SimpleIcon icon="ArrowRight" />
                            </div>
                          )}
                        </div>
                        {/* whitespace-nowrap */}
                        <div className="mt-1 px-2 text-sm dark:text-gray-200">
                          {l.name}
                        </div>
                      </div>
                    </button>
                  </li>
                ) : null}
              </Fragment>
            );
          })}
      </ul>
      <Popper
        open={open}
        placement={"right-end"}
        anchorEl={anchorEl}
        transition
        sx={{
          zIndex: "3000 !important",
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div
              ref={popoverRef}
              className="drawer-sublink-popover min-w-[200px] pl-0"
            >
              <div className="drawer-sublink-popover ml-1 rounded-md bg-box shadow-dialog dark:bg-slate-800">
                <ul className="flex flex-col">
                  {getOpenSubLinks &&
                    getOpenSubLinks.map((lSub) => {
                      const isSubLink = pathname === lSub.linkTo;

                      return (
                        <Fragment key={lSub.name}>
                          {lSub?.display && (
                            <li
                              className={classNames(
                                isSubLink
                                  ? "bg-link-primary dark:bg-light-primary/20"
                                  : "",
                                "hover:bg-link-primary hover:dark:bg-light-primary/20"
                              )}
                            >
                              <button
                                className="flex h-full w-full flex-row items-center p-4"
                                onClick={() => {
                                  navigate(lSub.linkTo);
                                }}
                              >
                                <SimpleIcon fontSize={20} icon={lSub?.icon} />
                                <span className="ml-2 text-[15px] dark:text-gray-200">
                                  {lSub?.name}
                                </span>
                              </button>
                            </li>
                          )}
                        </Fragment>
                      );
                    })}
                </ul>
              </div>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

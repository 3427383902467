import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  getMetaAdsInsightsKaapo,
  getMetaAdsInsightsEsetech,
  getMetaCallToActionInsightInsta,
  getMetaCallToActionInsightFacebook,
  getMetaEngagement,
  getEsetechMetaCallToActionInsightFacebook,
} from "../../redux/reducers/marketingReducer";

export const useGetMetaAdsData = async () => {
  const access_token = process.env.REACT_APP_ESETECH_AD_ACC_ACCESS_TOKEN;
  const adAccountIdKaapo = "988672261692207";
  const adAccountEsetech = "357702703280687";

  const dispatch = useDispatch();

  useEffect(() => {
    const getIDs = async () => {
      try {
        const responseKaapo = await axios.get(
          `https://graph.facebook.com/v18.0/act_988672261692207/adsets?fields=id,name,created_time,lifetime_budget,targeting,budget_remaining,insights.date_preset(maximum){clicks,cost_per_unique_click,date_start,inline_link_click_ctr,inline_link_clicks,spend,actions}`,
          {
            params: {
              access_token:
                "EAAWvqB0Tx8kBO70EvCY28vFoohqUnXEYKSvve04xgRXWZAWbLA4OGROPuHUbZBLd0QRNqnHFlJ2pkAZBHEKgok5S2pZB2jlUi1wIXzrkmS7p1VxGTZBRTnH4BIYCZBZASXfWPK6sHN8SCJY1JJZCIBFVI7SXZB4LIPNIxM1pXijbkbxbX6UcBCuJVgfw2EF5XYkErFiUtF0BFIO4L2faB",
            },
          }
        );

        // console.log(responseKaapo, "vvvvvvvvvbcbcbcbb");

        const responseEsetech = await axios.get(
          `https://graph.facebook.com/v18.0/act_988672261692207/adsets?fields=id,name,created_time,lifetime_budget,targeting,budget_remaining,insights.date_preset(maximum){clicks,cost_per_unique_click,date_start,inline_link_click_ctr,inline_link_clicks,spend,actions}`,
          {
            params: {
              access_token:
                "EAAPGnrKrgosBO8lB7OLJdkxuMv4DeCfEDPj4ZBYYdbYsbDX8PgvlBSg6dAAIOalm1nV983pNoKfcnRn4mepTG5o7V6P2fPXz9R3Y5ttcAN73LyeiAI6ZCgvStFnNfyK3GQOjyqQNlcgsCGOjXpScy9MVtWLB16yTgLl9ui4YTYHqIU81fMxIUgJbfdmZBsDNkTDaftncWeFuQ4ZD",
            },
          }
        );

        //kaapo
        const adSetsKaapo = responseKaapo.data.data;
        // console.log(adSetsKaapo, "kaaaaaaaapo");
        dispatch(getMetaAdsInsightsKaapo(adSetsKaapo));

        //esetech
        const adSetsEsetech = responseEsetech.data.data;
        // console.log(adSetsEsetech, "adSetsEsetech");
        dispatch(getMetaAdsInsightsEsetech(adSetsEsetech));
      } catch (error) {
        console.log(error);
      }
    };

    getIDs();

    return;
  }, [access_token, dispatch]);
};

export const useKaapoMetaCallToAction = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getFaceBookInsights = async () => {
      const page_ID = process.env.REACT_APP_KAAPO_PAGE_ID;
      const access_token = process.env.REACT_APP_KAAPO_ACCESS_TOKEN;
      const instagramPage_ID = process.env.REACT_APP_INSTA_PAGE_ID;
      const instagramAccessToken = process.env.REACT_APP_INSTA_ACCESS_TOKEN;

      try {
        const response = await axios.get(
          `https://graph.facebook.com/${page_ID}/insights?metric=page_consumptions_by_consumption_type_unique,page_website_clicks_logged_in_unique&access_token=${access_token}`
        );

        // console.log(response.data, "response vitetetetetete");

        // const res = await axios.get(
        //   `https://graph.facebook.com/v15.0/${instagramPage_ID}/insights/website_clicks,phone_call_clicks,text_message_clicks`,
        //   {
        //     params: {
        //       period: "day",
        //       access_token: instagramAccessToken,
        //     },
        //   }
        // );

        // console.log(res.data.data, ".................");
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const clicksByTypes = response.data.data.filter((obj) => {
          return obj.name === "page_consumptions_by_consumption_type_unique";
        });
        // console.log(clicksByTypes, "clicksByTypes");

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const PageebsiteClicks = response.data.data.filter((obj) => {
          return obj.name === "page_website_clicks_logged_in_unique";
        });

        let allData = [];

        allData.push(clicksByTypes);
        allData.push(PageebsiteClicks);
        // const instaData = res.data.data;

        // console.log(instaData, "data");
        // allData.push(allClickEngagement);
        dispatch(getMetaCallToActionInsightFacebook(allData));
        // dispatch(getMetaCallToActionInsightInsta(instaData));
      } catch (error) {
        console.log(error);
      }
    };

    getFaceBookInsights();
  }, []);
};

export const useEsetechMetaCallToAction = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getFaceBookInsights = async () => {
      const page_ID = process.env.REACT_APP_ESETECH_PAGE_ID;
      const access_token = process.env.REACT_APP_ESETECH_ACCESS_TOKEN;
      const instagramPage_ID = process.env.REACT_APP_INSTA_PAGE_ID;
      const instagramAccessToken = process.env.REACT_APP_INSTA_ACCESS_TOKEN;

      try {
        const response = await axios.get(
          `https://graph.facebook.com/${page_ID}/insights?metric=page_consumptions_by_consumption_type_unique,page_website_clicks_logged_in_unique&access_token=${`EAAPGnrKrgosBO8lB7OLJdkxuMv4DeCfEDPj4ZBYYdbYsbDX8PgvlBSg6dAAIOalm1nV983pNoKfcnRn4mepTG5o7V6P2fPXz9R3Y5ttcAN73LyeiAI6ZCgvStFnNfyK3GQOjyqQNlcgsCGOjXpScy9MVtWLB16yTgLl9ui4YTYHqIU81fMxIUgJbfdmZBsDNkTDaftncWeFuQ4ZD`}`
        );

        // console.log(response.data, "response Esetech");

        // const res = await axios.get(
        //   `https://graph.facebook.com/v15.0/${instagramPage_ID}/insights/website_clicks,phone_call_clicks,text_message_clicks`,
        //   {
        //     params: {
        //       period: "day",
        //       access_token: instagramAccessToken,
        //     },
        //   }
        // );

        // console.log(res.data.data, ".................");
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const clicksByTypes = response.data.data.filter((obj) => {
          return obj.name === "page_consumptions_by_consumption_type_unique";
        });
        // console.log(clicksByTypes, "clicksByTypes Esetech");

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const PageebsiteClicks = response.data.data.filter((obj) => {
          return obj.name === "page_website_clicks_logged_in_unique";
        });

        let allData = [];

        allData.push(clicksByTypes);
        allData.push(PageebsiteClicks);
        // const instaData = res.data.data;

        // console.log(instaData, "data");
        // allData.push(allClickEngagement);
        dispatch(getEsetechMetaCallToActionInsightFacebook(allData));
        // dispatch(getMetaCallToActionInsightInsta(instaData));
      } catch (error) {
        console.log(error);
      }
    };

    getFaceBookInsights();
  }, []);
};

export const useMetaEngagement = async () => {
  const dispatch = useDispatch();
  let impressions;
  useEffect(() => {
    const getFaceBookInsights = async () => {
      const page_ID = "1025961717484030";
      const access_token = process.env.REACT_APP_ACCESS_TOKEN;

      try {
        const response = await axios.get(
          `https://graph.facebook.com/1025961717484030/insights?metric=page_engaged_users&access_token=EAAPGnrKrgosBO8lB7OLJdkxuMv4DeCfEDPj4ZBYYdbYsbDX8PgvlBSg6dAAIOalm1nV983pNoKfcnRn4mepTG5o7V6P2fPXz9R3Y5ttcAN73LyeiAI6ZCgvStFnNfyK3GQOjyqQNlcgsCGOjXpScy9MVtWLB16yTgLl9ui4YTYHqIU81fMxIUgJbfdmZBsDNkTDaftncWeFuQ4ZD`
        );

        const result = response.data.data;

        // console.log(result, "kkkkkkkkkk");

        // eslint-disable-next-line react-hooks/exhaustive-deps
        impressions = result.filter((obj) => {
          return obj.name === "page_engaged_users";
        });

        dispatch(getMetaEngagement(impressions));
      } catch (error) {
        console.log(error);
      }
    };

    getFaceBookInsights();
  }, []);
};

import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  SectionContent,
  Select,
  SimpleIcon,
  TextField,
} from "components";
import { positionObj, departmentObj, genderObj } from "utils/constants";
import { capitalize } from "lodash";
import { useRegisterStaff } from "api";
import { PageContainer } from "shared";

const Icon = ({ icon }) => (
  <SimpleIcon icon={icon} frontColor="text-gray-300" fontSize={18} />
);

export default function InviteStaff() {
  const [userData, setUserData] = useState(null);
  const [department, setDepartment] = useState(null);
  const [position, setPosition] = useState(null);
  const [gender, setGender] = useState(null);

  const [isLoading, isLoaded, register] = useRegisterStaff();

  const handleDetails = (e) => {
    setUserData((prevS) => ({ ...prevS, [e.target.name]: e.target.value }));
  };

  const handleInviteStaff = () => {
    register(userData);
  };

  const getUserData = useCallback(() => {
    return userData;
  }, [userData])();

  useEffect(() => {
    if (isLoaded) {
      setUserData(null);
      setDepartment("");
      setPosition("");
      setGender("");
    }
  }, [isLoaded]);

  return (
    <PageContainer>
      <SectionContent>
        <div className="w-full rounded border border-slate-100 bg-box p-2 shadow-sm dark:border-gray-500 dark:bg-gray-700">
          <div className="rounded p-0 shadow-sm">
            <div className="grid grid-cols-2 gap-2">
              <div className="col-span-1 rounded bg-white p-4 dark:bg-gray-900">
                <div className="grid grid-cols-1 gap-6">
                  <div className="col-span-1">
                    <TextField
                      label="First Name"
                      placeholder="First Name"
                      name="firstName"
                      value={getUserData?.firstName || ""}
                      handleChange={handleDetails}
                      startAdornment={<Icon icon="Person" />}
                      disableAutoComplete
                    />
                  </div>
                  <div className="col-span-1">
                    <TextField
                      label="Last Name"
                      placeholder="Last Name"
                      name="lastName"
                      value={getUserData?.lastName || ""}
                      handleChange={handleDetails}
                      startAdornment={<Icon icon="Person" />}
                      disableAutoComplete
                    />
                  </div>

                  <div className="col-span-1">
                    <TextField
                      label="Email"
                      placeholder="Email"
                      name="email"
                      value={getUserData?.email || ""}
                      handleChange={handleDetails}
                      startAdornment={<Icon icon="AlternateEmail" />}
                      disableAutoComplete
                    />
                  </div>
                  <div className="col-span-1">
                    <TextField
                      label="Phone Number"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={getUserData?.phoneNumber || ""}
                      handleChange={handleDetails}
                      startAdornment={<Icon icon="Phone" />}
                      disableAutoComplete
                    />
                  </div>
                  <div className="col-span-1">
                    <Select
                      inputLabel="Position"
                      name="position"
                      dropDown={positionObj}
                      handleChange={(e) =>
                        setUserData((prevS) => ({
                          ...prevS,
                          position: e.value,
                        }))
                      }
                      valueAlt={position || ""}
                      setValueAlt={setPosition}
                      startAdornment={<Icon icon="Work" />}
                    />
                  </div>
                  <div className="col-span-1">
                    <Select
                      inputLabel="Department"
                      name="department"
                      dropDown={departmentObj}
                      handleChange={(e) =>
                        setUserData((prevS) => ({
                          ...prevS,
                          department: e.value,
                        }))
                      }
                      valueAlt={department || ""}
                      setValueAlt={setDepartment}
                      startAdornment={<Icon icon="Work" />}
                    />
                  </div>
                  <div className="col-span-1">
                    <Select
                      inputLabel="Gender"
                      name="gender"
                      dropDown={genderObj}
                      handleChange={(e) =>
                        setUserData((prevS) => ({ ...prevS, gender: e.id }))
                      }
                      valueAlt={capitalize(gender) || ""}
                      setValueAlt={setGender}
                      startAdornment={<Icon icon="Wc" />}
                    />
                  </div>
                  <div className="col-span-1">
                    <Button
                      label="Invite"
                      fullWidth
                      gradient
                      isSubmitting={isLoading}
                      handleClick={handleInviteStaff}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SectionContent>
    </PageContainer>
  );
}

import { useGetUserProfile } from "api/actions/users";
import { BackdropSection, Button, SimpleIcon } from "components";
import React, { useState } from "react";
import { useSelector, useStore } from "react-redux";
import { formatDate } from "utils";
import { useDisableUser, useUpdateProfile, useUserProfile } from "api";
import PasswordDialog from "components/PasswordDialog";
import { useEffect } from "react";
import { getUserProfile } from "redux/reducers/profileReducers";
import { useDisableUserWithdrawal } from "api/actions/profile";

export default function Profile({ platform }) {
  const { userDetails } = useSelector((state) => state.users);

  const [profile, setProfile] = useGetUserProfile(
    userDetails?.userId,
    platform
  );

  const [openPass, setOpenPass] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const { user_details } = useStore().getState().profile || {};

  const [getPassField, setPassField] = useState({
    password: "",
  });
  const [isUpdatingDisable, isUpdatedDisable, disableUser] = useDisableUser();
  const [
    isUpdatingDisableWithdrawal,
    isUpdatedDisableWithdrawal,
    disableUserWithdrawal,
  ] = useDisableUserWithdrawal();

  const list = (item) => [
    {
      title: "DisplayName",
      value: item?.displayName,
      icon: "Person",
      iconColor: "text-emerald-600",
    },
    {
      title: "Email",
      value: item?.email,
      icon: "Email",
      iconColor: "text-cyan-600",
    },
    {
      title: "Phone Number",
      value: item?.phoneNumber,
      icon: "Phone",
      iconColor: "text-pink-600",
    },
    {
      title: "Email Verified",
      value: item?.emailVerified ? "Verified" : "Unverified",
      icon: "MarkEmailRead",
      iconColor: "text-red-600",
    },
    {
      title: "Phone Verified",
      value: item?.phoneVerified ? "Verified" : "Unverified",
      icon: "MobileFriendly",
      iconColor: "text-amber-600",
    },
    {
      title: "Account Status",
      value: item?.accountStatus ? "Enabled" : "Disabled",
      icon: "Work",
      iconColor: "text-purple-600",
    },
    {
      title: "DefaultCurrency",
      value: item?.defaultCurrency,
      icon: "Payment",
      iconColor: "text-fuchsia-600",
    },
    {
      title: "User Code",
      value: Boolean(item?.userCode)
        ? item?.userCode
        : "No user code generated",
      icon: "QrCode",
      iconColor: "text-red-600",
    },
    {
      title: "Referral Code",
      value: Boolean(item?.referralCode) ? item?.referralCode : "No referral",
      icon: "QrCode",
      iconColor: "text-stone-600",
    },
    {
      title: "User ID",
      value: userDetails?.userId,
      icon: "PermIdentity",
      iconColor: "text-yellow-600",
    },
    {
      title: "Last Login",
      value: formatDate(item?.recentDevices?.[0]?.time),
      icon: "FactCheck",
      iconColor: "text-teal-600",
    },
    {
      title: "Date Created",
      value: formatDate(item?.dateCreated),
      icon: "DateRange",
      iconColor: "text-violet-600",
    },
  ];

  const handleDisableUser = () => {
    disableUser({
      emailToDisable: profile?.email,
      UIDToDisable: userDetails?.userId,
      isDisabled: !profile?.disabled,
      currentPass: getPassField?.password,
    });
  };

  const handleDisableWithdrawal = () => {
    disableUserWithdrawal({
      UIDToDisable: userDetails?.userId,
      currentPass: getPassField?.password,
    });
  };

  return (
    <div className="w-2/3 rounded border border-gray-300 p-2 dark:border-gray-700 dark:bg-gray-800">
      {profile ? (
        <div className="">
          <div className="flex flex-row items-center justify-between border-b border-gray-200 p-4  dark:border-gray-600">
            <div className="flex items-center dark:text-white">
              <div className="flex h-12 w-12 flex-row items-center justify-center rounded-full bg-gray-200 dark:bg-gray-200">
                <img
                  className="h-full w-full p-2"
                  src={profile?.avatarUrl}
                  alt={profile?.displayName}
                />
              </div>
              <div className="pl-3">
                <div className="text-base font-semibold">
                  {profile?.displayName}
                </div>
                <div className="mt-2 font-normal text-gray-500 dark:text-gray-400">
                  {profile?.email}
                </div>
                <div className="font-normal text-gray-500 dark:text-gray-400">
                  {profile?.phoneNumber}
                </div>
              </div>
            </div>
            <div>
              {user_details?.position === "Chief Executive Officer" ||
              user_details?.position === "Supervisor" ? (
                <>
                  <Button
                    label={profile?.disabled ? "Disabled" : "Enabled"}
                    otherClass={
                      profile?.disabled ? "!bg-red-500" : "!bg-primary"
                    }
                    handleClick={() => setOpenPass(true)}
                  />
                  <PasswordDialog
                    {...{
                      title:
                        profile?.accountStatus === "enabled"
                          ? "Disable user"
                          : "Enable user",
                      open: openPass,
                      setOpen: setOpenPass,
                      getField: getPassField,
                      setField: setPassField,
                      isSubmitting: isUpdatingDisable,
                      isSubmitted: isUpdatedDisable,
                      handleClick: handleDisableUser,
                      buttonObj: {
                        label:
                          profile?.accountStatus === "enabled"
                            ? "disable"
                            : "enable",
                        color:
                          profile?.disabled === false ? "error" : "success",
                      },
                    }}
                  />
                </>
              ) : (
                <Button
                  label={profile?.disabled ? "Disabled" : "Enabled"}
                  otherClass={profile?.disabled ? "!bg-red-500" : "!bg-primary"}
                />
              )}
            </div>
          </div>
          <div className="m-4 grid grid-cols-2 gap-6">
            {/* <div>
              <Button
                label={
                  profile?.disabled
                    ? "Withdrawal Disabled"
                    : "Withdrawal Enabled"
                }
                otherClass={profile?.disabled ? "!bg-red-500" : "!bg-primary"}
                handleClick={() => setOpenPass(true)}
              />
              <PasswordDialog
                {...{
                  title: "Disable User Withdrawal",
                  open: openPass,
                  setOpen: setOpenPass,
                  getField: getPassField,
                  setField: setPassField,
                  isSubmitting: isUpdatingDisableWithdrawal,
                  isSubmitted: isUpdatedDisableWithdrawal,
                  handleClick: handleDisableWithdrawal,
                  buttonObj: {
                    label:
                      profile?.accountStatus === "enabled"
                        ? "disable withdrawal"
                        : "enable withdrawal",
                    color: profile?.disabled === false ? "error" : "success",
                  },
                }}
              />
            </div> */}
            {list(profile)?.map((item) => (
              <div
                className="col-span-1 flex flex-row items-center border-b p-2 dark:border-gray-700"
                key={item.title}
              >
                <div className="flex items-center justify-center rounded-full bg-slate-200 p-2 dark:bg-slate-700">
                  <SimpleIcon
                    icon={item.icon}
                    fontSize={18}
                    frontColor={item.iconColor}
                    otherClass="iconShadow-alt"
                  />
                </div>
                <div className="ml-4">
                  <div className="dark:text-white">{item.value}</div>
                  <div className="text-sm text-gray-600 dark:text-gray-400">
                    {item.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="relative h-48 w-full rounded bg-white p-4 dark:bg-gray-800">
          <div className="w-full p-4 text-center">
            <BackdropSection open={true} />
          </div>
        </div>
      )}
    </div>
  );
}

import { useEffect, useState } from "react";
import { notification } from "utils";
import { getStoragePlatform, uploadImage } from "api/helpers";
import * as i from "../lib";

export const useGetStorageFiles = (platform, isUpdatedUpload) => {
  const [getUrls, setGetUrls] = useState([]);

  useEffect(() => {
    setGetUrls([]);
    const storage = getStoragePlatform(platform);

    const getUrls = async () => {
      const listRef = i.ref(storage, "advertPopup");
      try {
        const res = await i.listAll(listRef);

        const getDetails = await Promise.all(
          res.items.map(async (itemRef) => {
            try {
              const metadata = await i.getMetadata(itemRef);

              const url = await i.getDownloadURL(
                i.ref(storage, metadata?.fullPath)
              );
              //   console.log(url, "auqweuwqe");

              return {
                url,
                name: metadata?.name,
                fullPath: metadata?.fullPath,
              };
            } catch (error) {
              console.log(error);
            }
          })
        );
        setGetUrls(getDetails);
      } catch (error) {
        console.log(error);
      }
    };
    getUrls();
    return () => {};
  }, [isUpdatedUpload, platform]);

  return [getUrls];
};

export const useUploadAdvertImage = () => {
  const [isUpdatingUpload, setUpdatingUpload] = useState(false);
  const [isUpdatedUpload, setUpdatedUpload] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (isUpdatedUpload) setUpdatedUpload(false);
    }, 500);
  }, [isUpdatedUpload]);

  const uploadAdvertImage = async (values, platform, callback) => {
    const { images, fileName } = values;
    try {
      setUpdatingUpload(true);
      setUpdatedUpload(false);

      await uploadImage(images, `advertPopup`, fileName, platform);

      setUpdatingUpload(false);
      setUpdatedUpload(true);
      if (callback) callback();
      notification("success", "Image successfully uploaded");
    } catch (error) {
      console.log(error);
      notification("danger", "An error occured. Try again later!", "error");
      setUpdatingUpload(false);
    }
  };

  return [isUpdatingUpload, isUpdatedUpload, uploadAdvertImage];
};

export const useDeleteAdvertImage = () => {
  const [isUpdatingDelete, setUpdatingDelete] = useState(false);
  const [isUpdatedDelete, setUpdatedDelete] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (isUpdatedDelete) setUpdatedDelete(false);
    }, 500);
  }, [isUpdatedDelete]);

  const deleteImage = async (values, platform) => {
    const { pathName } = values;
    try {
      const storage = getStoragePlatform(platform);

      setUpdatingDelete(true);
      // await new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     resolve(true);
      //   }, 5000);
      // });
      const deleteRef = i.ref(storage, pathName);
      await i.deleteObject(deleteRef);

      setUpdatingDelete(false);
      setUpdatedDelete(true);
      notification("success", "Image successfully deleted");
    } catch (error) {
      console.log(error);
      notification("danger", "An error occured. Try again later!", "error");
      setUpdatingDelete(false);
    }
  };

  return [isUpdatingDelete, isUpdatedDelete, deleteImage];
};

export const useGetAdvertPopupDetails = (platform) => {
  const [isUpdatingDetails, setUpdatingDetails] = useState(false);
  const [getPopupDetails, setGetPopupDetails] = useState(null);

  useEffect(() => {
    let unsub = () => {};
    const DB = platform === "esetech" ? i.db : i.db2;

    try {
      setUpdatingDetails(true);
      setGetPopupDetails(null);
      const q = i.doc(DB, "utils", "siteAdvertPopup");

      unsub = i.onSnapshot(q, (doc) => {
        setGetPopupDetails(doc.data());
        setUpdatingDetails(false);
      });
    } catch (error) {
      console.log(error);
    }
    return () => {
      unsub();
    };
  }, [platform]);

  return [isUpdatingDetails, getPopupDetails];
};

export const useSaveAdvertPopup = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const saveAdvertDetails = async (value, platform) => {
    if (!value || !["esetech", "kaapo"]?.includes(platform)) {
      notification("warning", "Invalid Input");
      return;
    }
    const DB = platform === "esetech" ? i.db : i.db2;

    try {
      setUpdating(true);
      const docRef = i.doc(DB, "utils", "siteAdvertPopup");
      await i.updateDoc(docRef, value);

      setUpdating(false);
      setUpdated(true);
      notification("success", `Advert Popup succesfully saved`);
    } catch (error) {
      console.log(error);
      setUpdating(false);
      notification("danger", "An error occured.", "error");
    }
  };
  return [isUpdating, isUpdated, saveAdvertDetails];
};

import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import dayjs from "dayjs";
import SwapVertIcon from "@mui/icons-material/SwapVert";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MetaEngagementEsetech = () => {
  const [pageEngagement, setPageEngagement] = useState([]);
  let impressions;
  useEffect(() => {
    const getFaceBookInsights = async () => {
      const page_ID = process.env.REACT_APP_ESETECH_PAGE_ID;
      const access_token = process.env.REACT_APP_ESETECH_ACCESS_TOKEN;

      try {
        const response = await axios.get(
          `https://graph.facebook.com/${page_ID}/insights?metric=page_engaged_users&access_token=EAAPGnrKrgosBO8lB7OLJdkxuMv4DeCfEDPj4ZBYYdbYsbDX8PgvlBSg6dAAIOalm1nV983pNoKfcnRn4mepTG5o7V6P2fPXz9R3Y5ttcAN73LyeiAI6ZCgvStFnNfyK3GQOjyqQNlcgsCGOjXpScy9MVtWLB16yTgLl9ui4YTYHqIU81fMxIUgJbfdmZBsDNkTDaftncWeFuQ4ZD`
        );

        const result = response.data.data;

        // console.log(result, "graaaaaaaaa");

        // eslint-disable-next-line react-hooks/exhaustive-deps
        impressions = result.filter((obj) => {
          return obj.name === "page_engaged_users";
        });

        setPageEngagement(impressions);
      } catch (error) {
        console.log(error);
      }
    };

    getFaceBookInsights();
  }, []);

  const pageImpressionFigures = pageEngagement.map((imp) => imp.values);

  let ChartDates = pageImpressionFigures
    .reduce((c, v) => c.concat(v), [])
    .map((o) => {
      return dayjs().format(o.end_time);
    });

  let chartValues = pageImpressionFigures
    .reduce((c, v) => c.concat(v), [])
    .map((o) => o.value);

  const firstEndDate = chartValues.filter((e, i) => (i % 2) - 1);
  const secondEndDate = chartValues.filter((e, i) => i % 2);

  const firstEndTime = ChartDates.filter((e, i) => (i % 2) - 1);
  const secondEndTime = ChartDates.filter((e, i) => i % 2);

  const endTime1 = firstEndTime.map((x) => dayjs().format(x.slice(0, 10)));
  const endTime2 = secondEndTime.map((x) => dayjs().format(x.slice(0, 10)));

  const options = {
    responsive: true,
    legend: {
      position: "bottom",
      labels: {
        fontColor: "#fff",
        fontSize: 18,
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Total Engaged Users",
      },
      // legend: {
      //   position: "bottom",
      //   labels: {
      //     fontColor: "#fff",
      //     fontSize: 18,
      //   },
      // },
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: "#fff",
              fontSize: 18,
              stepSize: 1,
              beginAtZero: true,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              fontColor: "red",
              fontSize: 18,
              stepSize: 1,
              beginAtZero: true,
            },
          },
        ],
      },
    },
  };

  const labels = ["Daily", "Weekly", "Monthly"];

  const data = {
    labels,
    datasets: [
      {
        label: endTime1[0],
        data: firstEndDate,
        backgroundColor: "rgba(29, 184, 23, 0.918)",
      },
      {
        label: endTime2[0],
        data: secondEndDate,
        backgroundColor: "rgb(44, 129, 64)",
      },
    ],
  };

  const engagement = [
    {
      id: 1,
      title: "Daily Page Engaged Users",
      previousDay: firstEndDate[0],
      today: secondEndDate[0],
    },
    {
      id: 2,
      title: "weekly Page Engaged Users",
      previousDay: firstEndDate[1],
      today: secondEndDate[1],
    },
    {
      id: 3,
      title: "28 days Page Engaged Users",
      previousDay: firstEndDate[2],
      today: secondEndDate[2],
    },
  ];

  return (
    <div className="h-[500px] w-full rounded-md bg-white p-3 dark:bg-slate-700 dark:text-white">
      <div className="mx-auto w-[550px] text-slate-600 dark:text-white">
        <Bar
          className="mx-auto h-[600px] w-[550px] text-white dark:text-white"
          options={options}
          redraw={true}
          data={data}
        />
      </div>
      <div className="mt-4 mb-3 w-full rounded-md border-2 bg-green-900/30 p-3 dark:border-slate-400">
        <div className="backdrop-blur-200 col-span-1 grid grid-cols-3 gap-2 rounded-md px-1 py-4 drop-shadow-md ">
          {engagement.map((en) => {
            return (
              <div className="rounded-md border-r-gray-50 bg-white p-2 px-1 py-4 text-center font-sans font-semibold capitalize oldstyle-nums text-slate-600 drop-shadow-md dark:bg-slate-800 dark:text-white">
                <p>{en.title}</p>
                <hr className="pb-3" />
                <p>
                  {en.today}
                  <SwapVertIcon
                    className={
                      en.today > en.previousDay
                        ? "text-green-600"
                        : "text-red-500"
                    }
                  />
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MetaEngagementEsetech;

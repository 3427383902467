import React from "react";
import { useSelector } from "react-redux";
import { ChangePassAlt } from "./component/ChangePass";
import { CreatePinAlt } from "./component/CreatePin";

export default function Security() {
  const { user_details } = useSelector((state) => state.profile);

  return (
    <div>
      {/* {user_details?.position === "Customer Care Agent" && ( */}
      <div className="m-2 rounded bg-gray-50/50 p-2 dark:bg-slate-800">
        <h4 className="mb-2 text-lg text-primary">Create/Reset PIN</h4>
        <hr className="mb-6 border-t dark:border-gray-600" />
        <CreatePinAlt />
      </div>
      {/* )} */}

      <div className="m-2">
        <div className="m-2 rounded bg-gray-50/50 p-2 dark:bg-slate-800">
          <h4 className="mb-2 text-lg text-primary">Change Password</h4>
          <hr className="mb-6 border-t dark:border-gray-600" />
          <ChangePassAlt />
        </div>
      </div>
    </div>
  );
}

import { AppBar, Checkbox } from "@mui/material";
import {
  useCompleteTrans,
  useDeferTrans,
  useFullPageDocDetails,
  useGetSingleTrans,
  useGiftcardUtils,
} from "api";
import {
  Button,
  EnterPIN,
  ImageUpload,
  Loader,
  SectionContent,
  TextField,
} from "components";
import { capitalize, isEmpty, startCase } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PageContainer } from "shared";
import { classNames } from "utils";
import { summarySingleTrans } from "utils/transactionProps";

export default function ProcessTransaction() {
  const [getStatus, setGetStatus] = useState(null);
  const [getDeclineReason, setGetDeclineReason] = useState(null);
  const [getDeferReason, setGetDeferReason] = useState(null);
  const [reconfirm, setReconfirm] = useState(null);
  const [getApproveCode, setGetApproveCode] = useState(null);
  const [images, setImages] = useState([]);

  const [selectError, setSelectError] = useState(null);
  const selectRef = useRef(null);

  const navigate = useNavigate();

  const { state } = useLocation();
  const { collection, collectionTo, docId, type, id } = state || {};

  useGetSingleTrans(collection, docId, type);
  useGiftcardUtils();

  const [isSubmitting, isSubmitted, completeTrade] = useCompleteTrans();
  const [isSubmittingDefer, isSubmittedDefer, deferTrade] = useDeferTrans();

  const [setFullPageDocDetails] = useFullPageDocDetails();

  const { singleTrans, isSingleTransLoading: isLoading } = useSelector(
    (state) => state.transaction
  );
  const {
    giftcardDeclineReasons: declineReason,
    giftcardDeferReasons: deferReason,
  } = useSelector((state) => state.util);

  const { user_details } = useSelector((state) => state.profile);
  // const { position } = user_details || {};
  const position = "Customer Care Agent";

  const handleImageChange = (imageList) => {
    setImages(imageList);
  };

  const handleSelect = () => {
    if (selectRef.current) {
      const inputRef = selectRef.current.querySelector("input");
      if (inputRef) {
        const value = inputRef.value;
        if (!value) {
          return false;
        }

        return true;
      }
    }
    return true;
  };

  const handleSelectScroll = () => {
    if (selectRef.current) {
      const isValid = handleSelect();
      if (!isValid) {
        const offsetTop = selectRef.current.getBoundingClientRect().top;
        window.scrollTo({
          top: offsetTop + window.scrollY - 100,
          behavior: "smooth",
        });
        setSelectError("Please select a platform");
      } else {
        setSelectError(null);
      }
    }
  };

  useEffect(() => {
    if (selectError) {
      const isValid = handleSelect();
      if (isValid) {
        setSelectError(null);
      } else {
        setSelectError("Please select a platform");
      }
    }
  }, [singleTrans, selectError]);

  useEffect(() => {
    setGetStatus(null);
  }, [state, singleTrans, isLoading]);

  useEffect(() => {
    setGetDeclineReason(null);
    setGetDeferReason(null);
    setImages([]);
    setReconfirm(null);
    setGetApproveCode("");
  }, [getStatus?.id]);

  useEffect(() => {
    setImages([]);
    setReconfirm(null);
    setGetApproveCode("");
  }, [getDeclineReason]);

  useEffect(() => {
    setReconfirm(null);
    setGetApproveCode("");
  }, [images]);

  useEffect(() => {
    const scroll = window.scrollTo(5000, 5000);
    return scroll;
  }, [
    getStatus?.id,
    getDeclineReason,
    getDeferReason,
    images?.length,
    reconfirm,
    getApproveCode?.length > 20,
  ]);

  useEffect(() => {
    if (isSubmitted || isSubmittedDefer) {
      navigate("/transaction");
    }
  }, [isSubmitted, isSubmittedDefer]);

  useEffect(() => {
    return () => {
      setFullPageDocDetails(null);
    };
  }, []);

  const handleProcessTrans = () => {
    const {
      customerId,
      id: transactionId,
      imageUID,
      amount,
      status: transStatus,
      manualFiatTransferProvider,
    } = singleTrans;

    if (["approved", "declined"].includes(getStatus?.id)) {
      const value = {
        customerId,
        transactionId,
        amount,
        transCode: Boolean(getApproveCode) ? getApproveCode : null,
        status: getStatus?.id,
        declineReason: getDeclineReason,
        collectionFrom: collection,
        collectionTo,
        platform: type,
        manualFiatTransferProvider,
      };
      completeTrade(value, images, imageUID);
    }

    if (getStatus?.id === "deferred") {
      const value = {
        transactionId,
        amount,
        deferReason: getDeferReason,
        status: transStatus,
        collectionFrom: collection,
        platform: type,
      };
      deferTrade(value);
    }
  };

  const isPayout = singleTrans?.product?.toLowerCase() === "payout";

  return (
    <PageContainer>
      {singleTrans &&
      !isLoading &&
      Boolean(declineReason) &&
      Boolean(deferReason) ? (
        <div className="p-3 bg-white rounded dark:bg-gray-900" r>
          <AppBar
            sx={{ position: "sticky" }}
            classes={{
              root: "h-16 bg-gradient-to-r from-bg_gradient_from to-bg_gradient_to shadow !sticky flex flex-row justify-center p-6 !z-[900] dark:from-dark_bg_gradient_from dark:to-dark_bg_gradient_from",
            }}
          >
            <div className="text-lg font-semibold">
              Process {capitalize(id)} Trade
            </div>
          </AppBar>

          {singleTrans &&
            Object.entries(
              summarySingleTrans(singleTrans, id, selectError, selectRef)
            ).map((it) => (
              <>
                {!isEmpty(it[1]) && (
                  <div className="mb-4">
                    <SectionContent>
                      <div className="p-6 rounded bg-box shadow-card-box dark:bg-gray-800">
                        <div className="pb-2 mb-4 font-semibold border-b dark:border-gray-600 dark:text-white">
                          {startCase(it[0])}
                        </div>
                        <div className="rounded bg-link-primary/10">
                          {Object.entries?.(it[1])?.map((item) => (
                            <>
                              {!item[1]?.value ||
                              item[1]?.disableDisplay ? null : (
                                <>
                                  <div
                                    className={`bg-primary/2 mb-2 ${
                                      item[0] === "paymentPlatform"
                                        ? "grid grid-cols-5"
                                        : "flex"
                                    } flex-row justify-between rounded-sm border-b border-gray-200 p-4 dark:border-gray-500`}
                                  >
                                    <div className="col-span-3 dark:text-white">
                                      {startCase(item[0])}
                                    </div>
                                    <div className="col-span-2 dark:text-white">
                                      {item[1]?.value}
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </SectionContent>
                  </div>
                )}
              </>
            ))}

          {singleTrans?.status === "active" &&
            position === "Customer Care Agent" && (
              <div
                className={`mb-4 grid ${
                  isPayout ? "grid-cols-2" : "grid-cols-3"
                } gap-x-4 rounded`}
              >
                {[
                  {
                    title: "Approve Transaction",
                    bgcolor: "!bg-emerald-400",
                    color: "success",
                    id: "approved",
                    text: "approve",
                    textColor: "!text-white",
                    textColor_alt: "!text-emerald-500",
                  },
                  {
                    title: "Decline Transaction",
                    bgcolor: "!bg-red-400 disabled:!bg-gray-300",
                    color: "error",
                    id: "declined",
                    text: "decline",
                    textColor: "!text-white disabled:!text-gray-100",
                    textColor_alt: "!text-red-500",
                  },
                  {
                    title: "Defer Transaction",
                    bgcolor: "!bg-cyan-400",
                    color: "info",
                    id: "deferred",
                    text: "defer",
                    textColor: "!text-white",
                    textColor_alt: "!text-cyan-500",
                  },
                ].map((item, index) =>
                  (isPayout && item.text !== "decline") || !isPayout ? (
                    <div className="col-span-1" key={index}>
                      <SectionContent>
                        <div className="flex flex-row justify-between w-full p-2 rounded bg-box shadow-card-box dark:bg-gray-800">
                          <div
                            className={classNames(
                              " m-0 flex flex-1 flex-row items-center justify-between rounded border bg-link-primary/5 p-2 dark:border-gray-500 dark:bg-gray-700",
                              item.id === getStatus?.id
                                ? "rounded border-4 border-primary dark:border-light-primary"
                                : ""
                            )}
                          >
                            <div className="peer-has-[:disabled]:text-gray-200 dark:text-white">
                              {item.title}
                            </div>
                            <div className="ml-2 peer">
                              <Button
                                label={capitalize(item.text)}
                                // color={item.color}
                                // variant="outlined"
                                size={"small"}
                                handleClick={() => {
                                  const isValid = handleSelect();
                                  if (!isValid) {
                                    handleSelectScroll();
                                  } else {
                                    setGetStatus(item);
                                  }
                                }}
                                otherClass={`${item.bgcolor} ${item.textColor}`}
                              />
                            </div>
                          </div>
                        </div>
                      </SectionContent>
                    </div>
                  ) : null
                )}
              </div>
            )}

          {getStatus?.id === "declined" && !selectError && (
            <>
              <div className="mb-4">
                <SectionContent>
                  <div className="p-2 rounded bg-box shadow-card-box dark:bg-gray-800">
                    <div className="pb-2 mb-6 font-semibold border-b dark:border-gray-500 dark:text-white">
                      Select Reason for Declining Transaction
                    </div>
                    <div className="flex flex-row justify-between">
                      <div>
                        {Object.entries(declineReason).map((item) => (
                          <div className="flex flex-row items-start p-2">
                            <Checkbox
                              checked={getDeclineReason === item[0]}
                              size="small"
                              onChange={() => {
                                setGetDeclineReason(item[0]);
                              }}
                              classes={{ root: "!p-0 dark:!text-white" }}
                            />
                            <div className="flex flex-col ml-2">
                              <div className="dark:text-white">
                                {startCase(item[0])}
                              </div>
                              <div className="text-sm dark:text-gray-400">
                                {item[1]}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </SectionContent>
              </div>

              {getDeclineReason && id === "giftcard" && !selectError && (
                <div className="mb-4">
                  <SectionContent>
                    <div className="p-2 rounded bg-box shadow-card-box dark:bg-gray-800">
                      <div className="pb-2 mb-6 font-semibold border-b dark:border-gray-500 dark:text-white">
                        Upload Proof for Declining Transaction
                      </div>
                      <div className="flex flex-row justify-between">
                        <ImageUpload
                          images={images}
                          handleChange={handleImageChange}
                          className="flex flex-wrap p-2 bg-white rounded dark:bg-gray-900"
                        />
                      </div>
                    </div>
                  </SectionContent>
                </div>
              )}
            </>
          )}

          {getStatus?.id === "approved" && !selectError && (
            <div className="mb-4">
              <SectionContent>
                <div className="p-2 rounded bg-box shadow-card-box dark:bg-gray-800">
                  <div className="pb-2 mb-6 font-semibold border-b dark:border-gray-500 dark:text-white">
                    Input Transaction Code
                  </div>
                  <TextField
                    size="medium"
                    label={"Code"}
                    type="text"
                    placeholder="Input Transaction Code"
                    disableAutoComplete
                    handleChange={(e) => setGetApproveCode(e.target.value)}
                  />
                </div>
              </SectionContent>
            </div>
          )}

          {getStatus?.id === "deferred" && (
            <>
              <div className="mb-4">
                <SectionContent>
                  <div className="p-2 rounded bg-box shadow-card-box dark:bg-gray-800">
                    <div className="pb-2 mb-6 font-semibold border-b dark:border-gray-500 dark:text-white">
                      Select Reason for Deferring Transaction
                    </div>
                    <div className="flex flex-row justify-between ">
                      <div>
                        {Object.entries(deferReason).map((item) => (
                          <div className="flex flex-row items-start p-2">
                            <Checkbox
                              checked={getDeferReason === item[0]}
                              size="small"
                              onChange={() => {
                                setGetDeferReason(item[0]);
                              }}
                              classes={{ root: "!p-0 dark:!text-white" }}
                            />
                            <div className="flex flex-col ml-2">
                              <div className="dark:text-white">
                                {startCase(item[0])}
                              </div>
                              <div className="text-sm dark:text-gray-400">
                                {item[1]}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </SectionContent>
              </div>
            </>
          )}

          {getStatus?.id && (
            <>
              {(getDeclineReason && (images?.length || id !== "giftcard")) ||
              getApproveCode.length > 20 ||
              getDeferReason ? (
                <>
                  <div className="mb-4">
                    <SectionContent>
                      <div className="p-2 rounded bg-box shadow-card-box dark:bg-gray-800">
                        <div className="pb-2 mb-6 font-semibold border-b dark:border-gray-500 dark:text-white">
                          Reconfirm Transaction Action
                        </div>
                        <div className="dark:text-white">
                          <div>
                            Kindly reconfirm that you want to{" "}
                            <span
                              className={`font-bold uppercase text-red-500 ${getStatus?.textColor_alt}`}
                            >
                              {getStatus?.text}
                            </span>{" "}
                            this transaction
                          </div>
                          <div className="flex flex-row items-center mt-4 space-x-2">
                            <Button
                              gradient
                              label="Yes"
                              handleClick={() => setReconfirm(true)}
                            />
                            <Button
                              color="default"
                              label="No"
                              handleClick={() => {
                                setReconfirm(false);
                                setGetStatus(null);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </SectionContent>
                  </div>

                  {reconfirm ? (
                    <div className="mb-4">
                      <SectionContent>
                        <div className="p-2 rounded bg-box shadow-card-box dark:bg-gray-800">
                          <div className="pb-2 mb-6 font-semibold border-b dark:border-gray-500 dark:text-white">
                            Complete Transaction Action
                          </div>
                          <div className="p-4 bg-white rounded dark:bg-gray-800">
                            <EnterPIN
                              {...{
                                processSubmit: handleProcessTrans,
                                btnText: `${
                                  getStatus?.id === "deferred"
                                    ? "Defer"
                                    : "Complete"
                                } Transaction`,
                                isSubmitting: isSubmitting || isSubmittingDefer,
                              }}
                            />
                          </div>
                        </div>
                      </SectionContent>
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </div>
      ) : (
        <div className="relative flex items-center justify-center w-full h-40 p-6 rounded bg-box shadow-card-box dark:bg-gray-900">
          <Loader />
        </div>
      )}
    </PageContainer>
  );
}

import React from "react";
import {
  SimpleLoader,
  SimpleTable,
  TransReportHeader as Header,
} from "components";
import { startCase } from "lodash";
import { formatInputNum } from "utils";
import { useTransReportBonus } from "api";

export default function BonusSummary({ platform }) {
  const [isSubmitting, isSubmitted, data] = useTransReportBonus(platform);

  return (
    <div>
      {data ? (
        <div>
          <div className="mt-1 mb-3 border-b dark:border-slate-700" />

          <div className="grid grid-cols-2 gap-4">
            {["bonusReceive", "bonusWithdraw"].map((itemB) => (
              <div className="col-span-1" key={itemB}>
                {[
                  {
                    label: itemB,
                    columns: [
                      { label: "Product", id: "product" },
                      { label: "Count", id: "count", align: "right" },
                      { label: "Amount(NGN)", id: "amountNGN", align: "right" },
                    ],
                    rows: [...Object.keys(data?.[itemB])].map((i) => ({
                      product: i,
                      count: data?.[itemB]?.[`${i}`]?.count || 0,
                      amountNGN: formatInputNum(
                        data?.[itemB]?.[`${i}`]?.amountNGN || 0,
                        2
                      ),
                      align: "right",
                    })),
                  },
                ].map((item) => (
                  <div key={item?.label}>
                    <div className="flex flex-col gap-0 rounded-lg bg-white p-0 dark:bg-gray-800">
                      <Header header={startCase(item?.label)} />
                      <SimpleTable
                        columns={item?.columns}
                        rows={item?.rows}
                        // disableColumns
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex h-[200px] items-center justify-center">
          <SimpleLoader />
        </div>
      )}
    </div>
  );
}

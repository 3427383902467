import React, { Fragment, useState } from "react";
import { Divider } from "@mui/material";
import { classNames, formatCrypto, formatMoney } from "utils";
import { useGetUserFiatBalances } from "api/actions/users";
import { BackdropSection, Button, TableData } from "components";
import { useFullDrawerUserDetails } from "api";

export default function KaapoBalances() {
  const [currentId, setCurrentId] = useState(null);

  const [balance, totalAmount, totalBonusAmount] = useGetUserFiatBalances(
    currentId,
    "kaapo"
  );

  const [fullDrawerUserDetails] = useFullDrawerUserDetails();

  const fiatList = [
    {
      id: "naira",
      title: "Naira",
      type: "fiat",
      wallet: "nairaWallet",
      bonusCol: "nairaBonus",

      sym: "NGN",
    },
    {
      id: "cedis",
      title: "Cedis",
      type: "fiat",
      wallet: "cedisWallet",
      bonusCol: "cedisBonus",
      sym: "GHS",
    },
    {
      id: "cefa",
      title: "Cefa",
      type: "fiat",
      wallet: "cefaWallet",
      bonusCol: "cefaBonus",
      sym: "XOF",
    },
  ];

  const list = {
    fiatList,
  };

  const columns = [
    {
      field: "nos",
      headerName: "NOS",
      width: 60,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      renderCell: (params) => {
        const { email } = params.row;
        return (
          <div className="pl-3">
            <div className="text-base font-semibold">{params.value}</div>
            <div className="text-gray-500 dark:text-gray-400">{email}</div>
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Naira Amount",
      width: 200,
      renderCell: (params) => {
        const { sym, type } = params.row;
        return (
          <span className="">
            {type === "fiat" ? (
              <>{formatMoney(params.value, sym)}</>
            ) : (
              <>
                {formatCrypto(params.value)} {sym}
              </>
            )}
          </span>
        );
      },
    },
    currentId?.type === "fiat" && {
      field: "bonusAmount",
      headerName: "Bonus Amount",
      width: 200,
      renderCell: (params) => {
        const { sym } = params.row;
        return <span className="">{formatMoney(params.value, sym)}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        const { customerId } = params.row;
        return (
          <Button
            label="Profile"
            color="success"
            size="small"
            handleClick={() => {
              fullDrawerUserDetails({
                userId: customerId,
                platform: "kaapo",
              });
            }}
          />
        );
      },
    },
  ];

  const rows = (arr) =>
    arr?.map((item, index) => ({
      nos: index + 1,
      id: item.id,
      name: item?.customerDetails?.displayName,
      email: item?.customerDetails?.email,
      amount: item?.amount,
      ...(currentId?.type === "fiat" && { bonusAmount: item?.bonusAmount }),
      sym: currentId?.sym,
      type: currentId?.type,
      customerId: item?.customerId,
    }));

  return (
    <div className="my-4 mx-4">
      <div className="flex flex-row items-center justify-between">
        {Object.entries(list).map((item) => (
          <div className="shadow-md-0 rounded border bg-box dark:border-gray-600 dark:bg-gray-900">
            <ul className="flex flex-row">
              {item[1].map((item, index, arr) => (
                <li className="flex min-w-[80px] flex-row" key={item.id}>
                  <button
                    className={classNames(
                      "h-full w-full px-4 py-3 dark:text-white",
                      item?.id === currentId?.id
                        ? "rounded bg-primary font-bold text-white shadow-md dark:bg-dark-primary"
                        : ""
                    )}
                    onClick={() => {
                      setCurrentId(item);
                    }}
                  >
                    {item.title}
                  </button>
                  {index !== arr.length - 1 && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      classes={{ root: "dark:!border-gray-600" }}
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      {currentId ? (
        <Fragment>
          {balance ? (
            <Fragment>
              <div className="my-4 flex flex-row items-center justify-between rounded-lg border p-4 dark:border-gray-700 dark:text-white">
                <div>Total Amount</div>
                <div>
                  {currentId?.type === "fiat" ? (
                    <>{formatMoney(totalAmount, currentId?.sym)}</>
                  ) : (
                    <>
                      {formatCrypto(totalAmount)} {currentId?.sym}
                    </>
                  )}
                </div>
              </div>
              {currentId?.type === "fiat" && (
                <div className="my-4 flex flex-row items-center justify-between rounded-lg border p-4 dark:border-gray-700 dark:text-white">
                  <div>Total Bonus Amount</div>
                  <div>{formatMoney(totalBonusAmount, currentId?.sym)}</div>
                </div>
              )}
              <div className="my-4 rounded-lg border dark:border-gray-700">
                <TableData
                  rows={rows(balance)}
                  columns={columns}
                  rowHeight={84}
                />
              </div>
            </Fragment>
          ) : (
            <div className="my-4 rounded-lg border dark:border-gray-700">
              <div className="relative h-48 w-full rounded bg-white p-4 dark:bg-gray-800">
                <div className="w-full p-4 text-center">
                  <BackdropSection open={true} />
                </div>
              </div>
            </div>
          )}
        </Fragment>
      ) : (
        <div className="my-4 rounded-lg border dark:border-gray-700">
          <div className="relative h-48 w-full rounded bg-white p-4 dark:bg-gray-800">
            <div className="flex h-full w-full flex-row items-center justify-center p-4 text-center dark:text-white">
              Click on wallet tab to display.
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

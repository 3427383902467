import { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import * as i from "../lib";
import {
  getChatToUsersList,
  getChatUsers,
  getCurrentChatId,
  getCurrentChatMessages,
  getCurrentChatMessagesDetails,
  getCurrentChatUserDetails,
  getCurrentUserUnread,
  getLastChatMessage,
  setChatNotification,
} from "redux/reducers/chatReducers";
import { filter, orderBy, pick } from "lodash";
// import { axiosCall } from "api/helpers";
import { encode } from "js-base64";

const auth = i.getAuth();

// const url = (endpoint) => `${i.baseURL_ET}/staff/${endpoint}`;

const limit = 20;

export const useAllChatUser = () => {
  const dispatch = useDispatch();
  const currentUser = auth.currentUser.uid;

  useEffect(() => {
    let unsub;
    if (auth?.currentUser) {
      try {
        const q = i.query(
          i.collection(i.db, "staff"),
          i.where("accountStatus", "==", "enabled"),
          i.where("__name__", "!=", currentUser),
          i.orderBy("__name__")
        );

        unsub = i.onSnapshot(q, (querySnapshot) => {
          const t = [];
          querySnapshot.forEach((doc) => {
            const { displayName, email, gender, position, accountStatus } =
              doc.data();
            t.push({
              id: doc.id,
              displayName,
              email,
              gender,
              position,
              accountStatus,
            });
          });

          dispatch(getChatUsers(t));
        });
      } catch (error) {
        console.log(error, "error");
        dispatch(getChatUsers(null));
      }
    } else {
      dispatch(getChatUsers(null));
    }
    return unsub;
  }, [dispatch, currentUser]);
};

export const useGetCurrentChatUser = () => {
  const dispatch = useDispatch();

  const getCurrentChat = (userD, currentChatId) => {
    dispatch(getCurrentChatUserDetails(userD));
    dispatch(getCurrentChatId(currentChatId));
  };
  return [getCurrentChat];
};

export const useSendChatMessage = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const { user_details } = useStore().getState()?.profile;
  const { currentChatMessages } = useStore().getState()?.chat;
  const { chatUsers } = useStore().getState()?.chat;

  const currentUser = auth.currentUser.uid;

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      if (isSubmitted) {
        setSubmitted(false);
      }
    }, 500);
  }, [isSubmitted]);

  const sendChatMessage = async (msg, currentChatUserDetails, chatId) => {
    setSubmitting(true);
    try {
      const { id: toUserUid, participantList } = currentChatUserDetails;

      const message = encode(msg);
      if (!message || !toUserUid) {
        return;
      }
      const docId =
        chatId ||
        (currentUser > toUserUid
          ? `${currentUser}${toUserUid}`
          : `${toUserUid}${currentUser}`);

      const collection = "staffChats";
      // const collectionToken = "staffChats";

      // const getUserToken = i.doc(i.db, collectionToken, toUserUid);

      const chatRef = i.doc(i.db, collection, docId);
      const messageRef = i.doc(
        i.collection(i.db, collection, docId, "messages")
      );

      const chatSnap = await i.getDoc(chatRef);

      const allParticipantList = participantList || [toUserUid, currentUser];

      const incrementUnreadSubsq = filter(
        allParticipantList,
        (i) => i !== currentUser
      ).map((item) => ({
        [`participants.${item}.unread`]: i.increment(1),
      }));

      const incrementUnreadSubsqObj = Object.assign(
        {},
        ...incrementUnreadSubsq
      );

      const incrementUnreadInitial = allParticipantList.map((item) => ({
        [item]: {
          unread: item === currentUser ? 0 : 1,
          initiator: item === currentUser ? true : false,
        },
      }));

      const incrementUnreadInitialObj = Object.assign(
        {},
        ...incrementUnreadInitial
      );

      const senderImage = chatUsers?.find((i) => i.id === currentUser)?.gender;

      const sentMsg = {
        message,
        sender: currentUser,
        isDelivered: false,
        timeSent: Date.now(),
      };

      dispatch(
        getCurrentChatMessages([
          ...currentChatMessages,
          {
            ...sentMsg,
            isSender: true,
            senderImage,
          },
        ])
      );

      if (chatSnap.exists()) {
        await i.updateDoc(
          chatRef,
          {
            dateUpdated: i.serverTimestamp(),
            ...incrementUnreadSubsqObj,
            lastMessage: message,
            lastMessageSender: {
              displayName: user_details?.displayName,
              id: currentUser,
            },
          },
          { merge: true }
        );
        await i.setDoc(messageRef, {
          ...sentMsg,
          timeSent: i.serverTimestamp(),
          isDelivered: true,
        });
      } else {
        await i.setDoc(
          chatRef,
          {
            type: "one-on-one",
            dateCreated: i.serverTimestamp(),
            dateUpdated: i.serverTimestamp(),
            participants: incrementUnreadInitialObj,
            participantsList: allParticipantList,
            lastMessage: message,
          },
          { merge: true }
        );
        await i.setDoc(messageRef, {
          ...sentMsg,
          timeSent: i.serverTimestamp(),
          isDelivered: true,
        });
      }

      setSubmitting(false);
      setSubmitted(true);
    } catch (error) {
      dispatch(setChatNotification(null));
      console.log(error);
      setSubmitting(false);
      setSubmitted(false);
    }
  };

  return [sendChatMessage, isSubmitting, isSubmitted];
};

export const useEditChatMessage = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const currentUser = auth.currentUser.uid;
  const { currentChatId: chatId, currentChatMessages } =
    useStore().getState()?.chat;

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      if (isSubmitted) {
        setSubmitted(false);
      }
    }, 500);
  }, [isSubmitted]);

  const editChatMessage = async (editMessageObj) => {
    const { message: msg, senderId, messageId, editType } = editMessageObj;
    const message = encode(msg);

    setSubmitting(true);
    try {
      if (
        (editType === "edit" && !message) ||
        !chatId ||
        !messageId ||
        senderId !== currentUser
      ) {
        return;
      }

      const collection = "staffChats";

      const messageRef = i.doc(i.db, collection, chatId, "messages", messageId);

      const currentChatMessagesFormated = [...currentChatMessages];
      const findEditedIndex = currentChatMessagesFormated.findIndex(
        (obj) => obj.id === messageId
      );

      const updatedMsg = {
        ...currentChatMessagesFormated[findEditedIndex],
        message,
        ...(editType === "edit" ? { isEdited: true } : { isDeleted: true }),
      };

      const updatedCurrentChats = [
        ...currentChatMessagesFormated.slice(0, findEditedIndex),
        updatedMsg,
        ...currentChatMessagesFormated.slice(findEditedIndex + 1),
      ];

      dispatch(getCurrentChatMessages(updatedCurrentChats));

      if (editType === "edit") {
        await i.updateDoc(messageRef, {
          message,
          timeEdited: i.serverTimestamp(),
          isEdited: true,
        });
      }

      if (editType === "delete") {
        await i.updateDoc(messageRef, {
          timeDeleted: i.serverTimestamp(),
          isDeleted: true,
        });
      }

      setSubmitting(false);
      setSubmitted(true);
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      setSubmitted(false);
    }
  };

  return [editChatMessage, isSubmitting, isSubmitted];
};

export const useCheckReadChat = () => {
  const currentUser = auth.currentUser.uid;
  const {
    currentChatId: chatId,
    currentChatMessagesDetails,
    currentChatMessages,
  } = useStore().getState()?.chat;

  const { participants } = currentChatMessagesDetails || {};

  useEffect(() => {
    try {
      if (chatId && !Boolean(participants?.currentUser?.unread)) {
        const collection = "staffChats";

        const messageRef = i.doc(i.db, collection, chatId);

        i.updateDoc(messageRef, {
          [`participants.${currentUser}.unread`]: 0,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    chatId,
    currentUser,
    participants?.currentUser?.unread,
    currentChatMessages,
  ]);
};

export const useGetAllChatToUser = () => {
  const dispatch = useDispatch();
  const currentUser = auth?.currentUser?.uid;
  const { chatUsers } = useStore()?.getState()?.chat;

  useEffect(() => {
    let unsub;
    if (auth?.currentUser && chatUsers) {
      const chatUsersUid = chatUsers.map((i) => i.id);
      try {
        const q = i.query(
          i.collection(i.db, "staffChats"),
          i.where("participantsList", "array-contains", currentUser),
          i.orderBy("dateUpdated", "desc")
        );
        unsub = i.onSnapshot(q, (querySnapshot) => {
          const chatList = [];
          let unReadCount = 0;
          querySnapshot.forEach((doc) => {
            const { participants, participantsList } = doc.data();
            const otherUsersUID = filter(
              participantsList,
              (o) => o !== currentUser
            );

            if (chatUsersUid.includes(otherUsersUID[0])) {
              const otherUsersDetails = filter(
                chatUsers,
                (o) => o.id === otherUsersUID[0]
              );
              chatList.push({
                id: doc.id,
                ...doc.data(),
                otherUsersD: otherUsersUID,
                otherUsersFullD: otherUsersDetails,
                currentUserD: pick(participants, [currentUser])?.[currentUser],
              });
              unReadCount += participants?.[currentUser]?.unread;
            }
          });

          dispatch(getChatToUsersList(chatList));
          dispatch(getCurrentUserUnread(unReadCount));
        });

        return () => unsub;
      } catch (error) {
        console.log(error, "error");

        dispatch(getChatToUsersList(null));
      }
    } else {
      dispatch(getChatToUsersList(null));
    }
    return unsub;
  }, [dispatch, currentUser, chatUsers]);
};

export const useFetchCurrentChatMessages = () => {
  const dispatch = useDispatch();
  const currentUser = auth.currentUser.uid;
  const {
    chatUsers,
    currentChatId: chatId,
    currentChatUserDetails,
  } = useStore().getState()?.chat;
  const { staffDetails } = useStore().getState()?.staff;

  const { id: toUserUid } = currentChatUserDetails || {};

  const currentChatId =
    chatId ||
    (currentUser > toUserUid
      ? `${currentUser}${toUserUid}`
      : `${toUserUid}${currentUser}`);

  useEffect(() => {
    let unsubCurrentMsg = () => {},
      unsubCurrentMsgD = () => {};

    if (currentChatId) {
      const q = i.query(
        i.collection(i.db, "staffChats", currentChatId, "messages"),
        i.orderBy("timeSent", "desc"),
        i.limit(limit)
      );

      const qD = i.doc(i.db, "staffChats", currentChatId);

      unsubCurrentMsg = i.onSnapshot(q, (querySnapshot) => {
        const messageList = [];
        querySnapshot.forEach((doc) => {
          const { sender } = doc.data();
          const senderImage = staffDetails?.find(
            (i) => i.id === sender
          )?.gender;

          messageList.push({
            id: doc.id,
            ...doc.data(),
            isSender: sender === currentUser,
            senderImage,
          });
        });

        const messageListFormatted = orderBy(
          messageList,
          ["timeSent"],
          ["asc"]
        );

        dispatch(getCurrentChatMessages(messageListFormatted));
      });

      unsubCurrentMsgD = i.onSnapshot(qD, (doc) => {
        dispatch(getCurrentChatMessagesDetails(doc.data()));
      });
    }

    ////////
    const fetchMsg = async () => {
      dispatch(getCurrentChatMessages(null));

      if (auth?.currentUser) {
        try {
          const q = i.query(
            i.collection(i.db, "staffChats", currentChatId, "messages"),
            i.orderBy("timeSent", "desc"),
            i.limit(limit)
          );
          const dataSnapshots = await i.getDocs(q);

          dispatch(
            getLastChatMessage(
              dataSnapshots.docs[dataSnapshots.docs.length - 1]
            )
          );
        } catch (error) {
          console.log(error, "error");
          dispatch(getCurrentChatMessages(null));
        }
      } else {
        dispatch(getCurrentChatMessages(null));
      }
    };
    fetchMsg();
    return () => {
      unsubCurrentMsg();
      unsubCurrentMsgD();
    };
  }, [dispatch, currentUser, toUserUid, chatId, chatUsers]);
};

export const useFetchMoreCurrentChatMessages = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isDataEmpty, setDateEmpty] = useState(false);

  const dispatch = useDispatch();
  const currentUser = auth.currentUser.uid;
  const { chatUsers } = useStore().getState()?.chat;
  const { lastChatMessage, currentChatMessages, currentChatId } =
    useStore().getState()?.chat;

  useEffect(() => {
    setTimeout(() => {
      if (isSubmitted) setSubmitted(false);
    }, 500);
  }, [isSubmitted]);

  useEffect(() => {
    setSubmitting(false);
    setSubmitted(false);
    setDateEmpty(false);
  }, [currentChatId]);

  const fetch = async (currentChatId) => {
    // dispatch(getCurrentChatMessages(null));
    if (currentChatMessages?.length >= limit) {
      if (auth?.currentUser) {
        setSubmitted(false);
        setSubmitting(true);
        setDateEmpty(false);
        try {
          const q = i.query(
            i.collection(i.db, "staffChats", currentChatId, "messages"),
            i.orderBy("timeSent", "desc"),
            ...(lastChatMessage ? [i.startAfter(lastChatMessage)] : []),
            i.limit(limit)
          );

          const dataSnapshots = await i.getDocs(q);

          if (dataSnapshots.empty) {
            setDateEmpty(true);
            return;
          }

          const messageList = await Promise.all(
            dataSnapshots.docs.map((doc) => {
              const { sender } = doc.data();
              const senderImage = chatUsers?.find(
                (i) => i.id === sender
              )?.gender;

              return {
                id: doc.id,
                ...doc.data(),
                isSender: sender === currentUser,
                senderImage,
              };
            })
          );
          const messageListFormatted = orderBy(
            [...(currentChatMessages || []), ...messageList],
            ["timeSent"],
            ["asc"]
          );
          dispatch(getCurrentChatMessages(messageListFormatted));
          dispatch(
            getLastChatMessage(
              dataSnapshots.docs[dataSnapshots.docs.length - 1]
            )
          );
          setSubmitting(false);
          setSubmitted(true);
        } catch (error) {
          console.log(error, "error");
          setSubmitting(false);
          setSubmitted(false);
          setDateEmpty(false);
          dispatch(getCurrentChatMessages(null));
        }
      } else {
        dispatch(getCurrentChatMessages(null));
      }
    }
  };

  return [fetch, isSubmitting, isSubmitted, isDataEmpty];
};

export const useSetMessageNotification = () => {
  const dispatch = useDispatch();
  const currentUser = auth.currentUser.uid;
  const { staffDetails: chatUsers } = useStore().getState()?.staff;

  useEffect(() => {
    let unsub;
    if (auth?.currentUser) {
      try {
        const q = i.query(
          i.collection(i.db, "staffChats"),
          i.where("participantsList", "array-contains", currentUser),
          i.orderBy("dateUpdated", "desc")
        );

        unsub = i.onSnapshot(q, (querySnapshot) => {
          let t = 0;
          let lastMsgObj;
          [...querySnapshot.docs].forEach((doc, index) => {
            const { participants, participantsList } = doc.data() || {};
            const { unread } = participants?.[currentUser];
            if (unread > 0) {
              t += unread;
            }
            if (index === 0) {
              const otherUsersUID = filter(
                participantsList,
                (o) => o !== currentUser
              );

              const otherUsersDetails = filter(
                chatUsers,
                (o) => o.id === otherUsersUID[0]
              );

              lastMsgObj = {
                ...doc.data(),
                id: doc.id,
                otherUsersFullD: otherUsersDetails,
              };
            }
          });

          if (t > 0) {
            dispatch(
              setChatNotification({
                otherUserDetails: lastMsgObj?.otherUsersFullD[0],
                sender: lastMsgObj?.lastMessageSender?.displayName,
                participantsList: lastMsgObj?.participantsList,
                chatId: lastMsgObj?.id,
              })
            );
          }
        });
      } catch (error) {
        console.log(error, "error");
        dispatch(setChatNotification(null));
      }
    } else {
      dispatch(setChatNotification(null));
    }
    return unsub;
  }, [dispatch, currentUser, chatUsers]);
};

export const useClearMessageNotification = () => {
  const dispatch = useDispatch();

  const clearMsgNotifaction = () => {
    dispatch(setChatNotification(null));
  };
  return [clearMsgNotifaction];
};

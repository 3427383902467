import { FullWidthTabs } from "components";
import ReferredSignups from "pages/Marketing/components/ReferredSignups";
import React, { useState } from "react";

const NewCustomers = () => {
  const tabList = [
    {
      label: "Esetech",
      Comp: ReferredSignups,
      itemCount: 1 || 0,
      // Icon: "FacebookIcon",
    },

    {
      label: "Kaapo",
      Comp: ReferredSignups,
      itemCount: 1 || 0,
      // Icon: "FacebookIcon",
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabList[0]);

  return (
    <>
      <FullWidthTabs
        {...{ currentTab, setCurrentTab, tabList }}
        Comp={<currentTab.Comp {...currentTab} />}
      />
    </>
  );
};

export default NewCustomers;

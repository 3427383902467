import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { classNames } from 'utils';

export default function Loader({ fullPage }) {
  return (
    <div
      className={classNames(
        fullPage ? 'h-screen w-screen' : '',
        'flex items-center justify-center'
      )}
    >
      <ThreeDots color="#388955" height={120} width={120} />
    </div>
  );
}

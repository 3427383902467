export const listContentUpdate = [
  "Chief Executive Officer",
  "Digital Marketer",
  "IT Personnel",
];

const listP = [
  "Chief Executive Officer",
  "IT Personnel",
  "HR Manager",
  "Customer Care Agent",
  "Supervisor",
];

const listPaymentProvider = [
  "Chief Executive Officer",
  "Supervisor",
  "IT Personnel",
];

const listPTra = [
  "Chief Executive Officer",
  "IT Personnel",
  "HR Manager",
  "Customer Care Agent",
  "Accounting Officer",
  "Supervisor",
];

const listPAddAccOf = [
  "Chief Executive Officer",
  "IT Personnel",
  "Accounting Officer",
  "HR Manager",
  "Supervisor",
];

const listPNoCus = [
  "Chief Executive Officer",
  "IT Personnel",
  "HR Manager",
  "Supervisor",
];

const listPAddMarkt = [
  "Chief Executive Officer",
  "IT Personnel",
  "HR Manager",
  "Digital Marketer",
];

const listPOnlyAdmin = [
  "Chief Executive Officer",
  "IT Personnel",
  "HR Manager",
];
const listPAddAcct = [
  "Chief Executive Officer",
  "IT Personnel",
  "Accounting Officer",
  "HR Manager",
  "Software Engineer",
];

const listPReport = [
  "Chief Executive Officer",
  "IT Personnel",
  "HR Manager",
  "Digital Marketer",
  "Accounting Officer",
  "Supervisor",
];

export const drawerLinks = (position, roles) => [
  { name: "Dashboard", icon: "Dashboard", linkTo: "/dashboard", display: true },
  {
    name: "Transactions",
    icon: "InsertChart",
    linkTo: "/transaction",
    display: listPTra.includes(position),
    subLink: [
      {
        name: "All Transactions",
        linkTo: "/transaction",
        display: listP.includes(position),
        icon: "Addchart",
      },

      {
        name: "Check Transactions",
        linkTo: "/check-transaction",
        display:
          listPNoCus.includes(position) ||
          (["Customer Care Agent"].includes(position) &&
            roles?.["check-transaction"]),
        icon: "Troubleshoot",
      },
      {
        name: "Wallets Balances",
        linkTo: "/wallets-balances",
        display: listPAddAcct.includes(position),
        icon: "Wallet",
      },
      {
        name: "Automated Transactions",
        linkTo: "/automated-transactions",
        display:
          listPNoCus.includes(position) ||
          (["Customer Care Agent"].includes(position) && roles?.["payout"]),
        icon: "DataSaverOn",
      },
      {
        name: "Daily Transactions",
        linkTo: "/daily-transaction",
        display: listPAddAccOf.includes(position),
        icon: "TableChart",
      },
    ],
  },
  {
    name: "Reports",
    icon: "Report",
    linkTo: "/reports",
    display: listPReport.includes(position),
    subLink: [
      {
        name: "Transaction Reports",
        linkTo: "/transaction-reports",
        display: listPReport.includes(position),
        icon: "Assessment",
      },
      {
        name: "Marketing Reports",
        linkTo: "/marketing-reports",
        display: listPAddMarkt.includes(position),
        icon: "Addchart",
      },
    ],
  },
  {
    name: "Chats",
    icon: "Forum",
    linkTo: "/chatbox",
    // display: listPNoCus.includes(position),
    display: true,
  },
  {
    name: "Staff",
    icon: "AssignmentInd",
    linkTo: "/staff",
    display: listPNoCus.includes(position),
    subLink: [
      {
        name: "Staff Roles",
        linkTo: "/staff/staff-roles",
        display: listPNoCus.includes(position),
        icon: "AssignmentInd",
      },
      {
        name: "Manage Staff",
        linkTo: "/staff/manage-staff",
        display: listPNoCus.includes(position),
        icon: "ManageAccounts",
      },
      {
        name: "Add Team",
        linkTo: "/staff/add-team",
        display: listPNoCus.includes(position),
        icon: "GroupWork",
      },
      {
        name: "Invite Staff",
        linkTo: "/staff/invite-staff",
        display: listPNoCus.includes(position),
        icon: "PersonAdd",
      },
    ],
  },
  {
    name: "Users",
    icon: "SupervisedUserCircle",
    linkTo: "/users",
    display: listPAddAcct.includes(position),
  },
  {
    name: "Rate Update",
    icon: "PieChart",
    linkTo: "/rate-update",
    display:
      listPNoCus.includes(position) ||
      (["Customer Care Agent"].includes(position) && roles?.["rate-update"]),
    subLink: [
      {
        name: "Giftcard Update",
        linkTo: "/rate-update/giftcard",
        // display: true,
        display:
          listPNoCus.includes(position) ||
          (["Customer Care Agent"].includes(position) &&
            roles?.["rate-update"]),
        icon: "Redeem",
      },
      {
        name: "Crypto Update",
        linkTo: "/rate-update/crypto",
        display: listPOnlyAdmin.includes(position),
        icon: "Toll",
      },
      {
        name: "Fiat Update",
        linkTo: "/rate-update/fiat",
        display: listPOnlyAdmin.includes(position),
        icon: "Money",
      },
      {
        name: "Utils Update",
        linkTo: "/rate-update/utils",
        display: listPaymentProvider.includes(position),
        icon: "DynamicFeed",
      },
    ],
  },
  {
    name: "Content Update",
    icon: "Source",
    linkTo: "/content-update",
    display: listPAddMarkt.includes(position),
    subLink: [
      {
        name: "Site Advert Popup",
        linkTo: "/content-update/advert-popup",
        display: listPAddMarkt.includes(position),
        icon: "ViewCarousel",
      },
      {
        name: "Push Notification",
        linkTo: "/content-update/push-notification",
        display: listPAddMarkt.includes(position),
        icon: "EditNotifications",
      },
    ],
  },
  { name: "Settings", icon: "Settings", linkTo: "/settings", display: true },
  { name: "Logout", icon: "Logout", handleLogout: true, display: true },
];

let drawerLinkReduced = (position, roles) =>
  drawerLinks(position, roles).reduce((acc, item) => {
    if (item?.subLink) {
      acc.push(...item?.subLink);
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

export const drawerLinkReducedList = (position, roles) => [
  ...drawerLinkReduced(position, roles),
  {
    name: "Processed Transaction",
    linkTo: "/process-transaction",
    display: listP.includes(position),
  },
];

export const analysis = ([completedTransL, pendingTransL, deferredTransL]) => [
  {
    gradColors: "from-pink-900 to-pink-600",
    color: "text-pink-600",
    fig: completedTransL || 0,
    text: "Processed Transactions",

    icon: "TableChart",
    linkTo: "/",
  },
  {
    gradColors: "from-emerald-900 to-teal-600",
    color: "text-teal-600",
    fig: pendingTransL || 0,
    text: "Incoming Transactions",
    icon: "Addchart",
    linkTo: "/",
  },
  {
    gradColors: "from-sky-900 to-cyan-600",
    color: "text-cyan-600",
    fig: deferredTransL || 0,
    text: "Deferred Transactions",
    icon: "InsertChart",
    linkTo: "/",
  },
];

export const createPinFormList = [
  {
    name: "pin",
    type: "password",
    // type: "numeric",
    label: "New PIN",
    inputType: "textfield",
    placeholder: "Input New Pin",
  },
  {
    name: "confirmPin",
    type: "password",
    label: "Confirm New PIN",
    inputType: "textfield",
    placeholder: "Confirm New Pin",
  },
  {
    name: "password",
    type: "password",
    label: "Password",
    inputType: "textfield",
    helperText_alt: "Confirm your PIN reset with your password",
    placeholder: "Input Password",
  },
];

export const changePinFormList = (email) => [
  {
    name: "code",
    type: "text",
    label: "OTP Code",
    inputType: "textfield",
    helperText_alt: `Confirm your PIN change with the verification code send to the email - ${email}`,
    otherProps: {
      disableAutoComplete: true,
    },
    placeholder: "Input Code",
  },
];

export const verifyPassFormList = [
  {
    name: "currentPass",
    type: "password",
    label: "Current Password",
    inputType: "textfield",
    placeholder: "Input Current Password",
  },
];

export const changePassFormList = (email) => [
  {
    name: "newPass",
    type: "password",
    label: "New Password",
    inputType: "textfield",
    placeholder: "Input New Password",
  },
  {
    name: "confirmNewPass",
    type: "password",
    label: "Confirm New Password",
    inputType: "textfield",
    placeholder: "Input New Password",
  },
  {
    name: "code",
    type: "text",
    label: "Input OTP Code",
    inputType: "textfield",
    helperText_alt: `Confirm your password change with the verification code send to the email - ${email}`,
    otherProps: {
      disableAutoComplete: true,
    },
    placeholder: "Input Code",
  },
];

export const changePassFormListAlt = [
  {
    name: "currentPass",
    type: "password",
    label: "Current Password",
    inputType: "textfield",
    placeholder: "Input Current Password",
  },
  {
    name: "newPass",
    type: "password",
    label: "New Password",
    inputType: "textfield",
    placeholder: "Input New Password",
  },
  {
    name: "confirmNewPass",
    type: "password",
    label: "Confirm New Password",
    inputType: "textfield",
    placeholder: "Input New Password",
  },
];

export const positionObj = [
  { id: "customerCareAgent", value: "Customer Care Agent" },
  { id: "supervisor", value: "Supervisor" },
  { id: "accountingOfficer", value: "Accounting Officer" },
  { id: "softwareEngineer", value: "Software Engineer" },
  { id: "itPersonnel", value: "IT Personnel" },
  { id: "digitalMarketer", value: "Digital Marketer" },
];

export const departmentObj = [
  { id: "customerService", value: "Customer Service and Support" },
  { id: "it", value: "Information Technology" },
  { id: "devTeam", value: "Development Team" },
  { id: "accounting", value: "Accounting" },
  { id: "markerting", value: "Marketing" },
  { id: "management", value: "Management" },
];

export const genderObj = [
  { id: "male", value: "Male" },
  { id: "female", value: "Female" },
];

export const platformObj = [{ id: "kuda", value: "Kuda Platform" }];

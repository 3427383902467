import React, { useCallback, useState } from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { Button, SectionContent } from "components";
import { useSelector } from "react-redux";
import { pick } from "lodash";
import { useGetCurrentTeam } from "api";

const listP = [
  "Chief Executive Officer",
  "HR Manager",
  "Customer Care Agent",
  "Supervisor",
  "Accounting Officer",
];

const GetTeamList = ({ teamMembers, noDisplayText }) => {
  return (
    <ul className="mt-4">
      {teamMembers?.length ? (
        teamMembers?.map((item) => (
          <li
            key={item.id}
            className="w-full border-b bg-background-main-alt dark:border-gray-500 dark:bg-gray-900"
          >
            <div className="flex flex-row items-center justify-between p-3">
              <div className="flex w-full flex-row items-center justify-between">
                <div>
                  <div className="title dark:text-white">
                    {item.displayName}
                  </div>
                  <div className="subTitle text-sm text-color-main dark:text-gray-400">
                    Email —{" "}
                    <span className="text-gray-500 dark:text-gray-400">
                      {item.email}
                    </span>
                  </div>
                </div>
                <div>
                  <Button label="Chat" otherClass="min-w-0" />
                </div>
              </div>
            </div>
            <Divider />
          </li>
        ))
      ) : (
        <div className="w-full bg-background-main p-6 text-sm dark:bg-gray-900">
          {noDisplayText}
        </div>
      )}
    </ul>
  );
};

export default function Team() {
  const { staffDetails } = useSelector((state) => state.staff);
  const { user_details } = useSelector((state) => state.profile);

  const [, , , , , , currentWorkingTeam, staffT] = useGetCurrentTeam();

  const staffTeam = useCallback(() => {
    if (currentWorkingTeam) {
      const stafflist = [...staffT];
      const findI = stafflist?.findIndex((i) => i.id === currentWorkingTeam);
      const otherTeamList = stafflist.splice(findI, 1);
      stafflist.unshift(otherTeamList[0]);

      return stafflist;
    }
    return null;
  }, [currentWorkingTeam, staffT])();

  const { teamId } = user_details || {};

  const getTeamName = staffTeam?.find((item) => item.id === teamId)?.teamName;

  const teamMembers = staffDetails
    ?.filter(
      (item) =>
        Boolean(item?.teamId) &&
        item?.teamId === teamId &&
        item.position === "Customer Care Agent"
    )
    .map((item) => {
      return pick(item, [
        "id",
        "displayName",
        "email",
        "roles",
        "gender",
        "teamId",
      ]);
    });

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const memberUnderTeam = (teamId) =>
    staffDetails?.filter((t) => t.teamId === teamId);

  return (
    <>
      {listP?.includes(user_details?.position) && (
        <SectionContent>
          <div className="rounded bg-box p-4 shadow-card-box dark:bg-gray-800">
            <h4 className="mb-2 text-lg text-primary dark:text-light-primary">
              {user_details?.position === "Customer Care Agent"
                ? `Team Members ${getTeamName ? `- ${getTeamName}` : ""}`
                : "Teams - Customer Care"}
            </h4>
            <hr className="mb-4 border-t dark:border-gray-500" />
            {user_details?.position === "Customer Care Agent" ? (
              <GetTeamList
                teamMembers={teamMembers}
                noDisplayText="You do not have a team. Reach out to the admin!"
              />
            ) : (
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="">
                      {staffTeam?.map((team, index) => (
                        <Tab
                          key={team.teamName}
                          label={team.teamName}
                          value={(index + 1).toString()}
                          classes={{
                            root: "dark:!text-gray-400",
                          }}
                        />
                      ))}
                    </TabList>
                  </Box>
                  {staffTeam?.map((team, index) => (
                    <TabPanel
                      key={team?.id}
                      value={(index + 1).toString()}
                      classes={{
                        root: "!p-0",
                      }}
                    >
                      <GetTeamList
                        teamMembers={memberUnderTeam(team?.id)}
                        noDisplayText=" No team members to display"
                      />
                    </TabPanel>
                  ))}
                </TabContext>
              </Box>
            )}
          </div>
        </SectionContent>
      )}
    </>
  );
}

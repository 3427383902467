import React, { useState, useEffect } from "react";
import {
  Button,
  CheckboxInputs,
  Loader,
  RadioInputs,
  SectionContent,
  SimpleIcon,
} from "components";
import { PageContainer } from "shared";
import { useFiatPaymentProvider, useUpdateFiatProvider } from "api";
import { useSelector } from "react-redux";

export default function Fiat() {
  const [getDataEse, setGetDataEse] = useState(null);
  const [getDataKap, setGetDataKap] = useState(null);
  const [platform, setPlatform] = useState(null);

  const { fiatProvider } = useSelector((state) => state.util);
  useFiatPaymentProvider();

  const [isUpdating, isUpdated, updatePaymentProvider] =
    useUpdateFiatProvider();

  useEffect(() => {
    if (isUpdated) {
      setGetDataEse(null);
      setGetDataKap(null);
      setPlatform(null);
    }
  }, [isUpdated]);

  const handleUpdateValue = () => {
    // console.log(getData, platform);
    const getData = platform === "esetech" ? getDataEse : getDataKap;
    updatePaymentProvider(getData, platform);
  };

  const handleChange = (value, name, platform) => {
    if (platform === "esetech") {
      setGetDataEse((prevS) => ({ ...prevS, [name]: value }));
    } else {
      setGetDataKap((prevS) => ({ ...prevS, [name]: value }));
    }
    setPlatform(platform);
  };

  return (
    <PageContainer>
      <div className="mb-4">
        {fiatProvider ? (
          <SectionContent>
            <div className="flex flex-row items-center justify-between rounded bg-white p-2 dark:bg-slate-800">
              <div className="flex flex-row items-center justify-start">
                <div className="flex h-8 w-8 flex-row items-center justify-center rounded bg-slate-50 dark:bg-slate-600">
                  <SimpleIcon icon="ViewAgenda" fontSize={18} />
                </div>
                <div>
                  <div className="ml-2">
                    <p className="font-bold  dark:text-gray-50">
                      Payment Provider
                    </p>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-200">
                      Update Payment Provider
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2">
              <div className="grid grid-cols-2 gap-2">
                <div className="col-span-1 divide-y divide-gray-200 rounded bg-box p-2 dark:divide-gray-700 dark:bg-gray-800">
                  <div className="rounded p-4 dark:bg-slate-700 dark:text-white">
                    <div class="mb-2 flex flex-row items-center justify-between">
                      <h4 class="text-lg text-primary dark:text-light-primary">
                        Esetech
                      </h4>
                      <Button
                        label="Update"
                        gradient
                        disabled={!getDataEse || isUpdating}
                        handleClick={handleUpdateValue}
                        isSubmitting={isUpdating && platform === "esetech"}
                      />
                    </div>
                    <hr class="mb-2 border-t dark:border-gray-500" />
                    <div className="p-4">
                      <CheckboxInputs
                        handleChange={(e) =>
                          handleChange(
                            e?.target.checked,
                            "isAutomatic",
                            "esetech"
                          )
                        }
                        checkList={[
                          {
                            label: "Automatic Payment",
                            value: "automaticPayment",
                            // checked: fiatProvider?.esetech?.isAutomatic,
                          },
                        ]}
                        defaultValue={fiatProvider?.esetech?.isAutomatic}
                      />

                      <hr class="my-4 border-t dark:border-gray-500" />

                      <RadioInputs
                        handleChange={(e) =>
                          handleChange(
                            e?.target.value,
                            "usePaymentProvider",
                            "esetech"
                          )
                        }
                        radioList={[
                          {
                            label: "Paga",
                            value: "paga",
                          },
                          {
                            label: "Lenco",
                            value: "lenco",
                          },
                        ]}
                        defaultValue={fiatProvider?.esetech?.paymentProvider}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-1 divide-y divide-gray-200 rounded bg-box p-2 dark:divide-gray-700 dark:bg-gray-800">
                  <div className="rounded p-4 dark:bg-slate-700 dark:text-white">
                    <div class="mb-2 flex flex-row items-center justify-between">
                      <h4 class="text-lg text-primary dark:text-light-primary">
                        Kaapo
                      </h4>
                      <Button
                        label="Update"
                        gradient
                        disabled={!getDataKap || isUpdating}
                        handleClick={handleUpdateValue}
                        isSubmitting={isUpdating && platform === "kaapo"}
                      />
                    </div>
                    <hr class="mb-2 border-t dark:border-gray-500" />
                    <div className="p-4">
                      <CheckboxInputs
                        handleChange={(e) =>
                          handleChange(
                            e?.target.checked,
                            "isAutomatic",
                            "kaapo"
                          )
                        }
                        checkList={[
                          {
                            label: "Automatic Payment",
                            value: "automaticPayment",
                            // checked: fiatProvider?.esetech?.isAutomatic,
                          },
                        ]}
                        defaultValue={fiatProvider?.kaapo?.isAutomatic}
                      />

                      <hr class="my-4 border-t dark:border-gray-500" />

                      <RadioInputs
                        handleChange={(e) =>
                          handleChange(
                            e?.target.value,
                            "usePaymentProvider",
                            "kaapo"
                          )
                        }
                        radioList={[
                          {
                            label: "Monnify",
                            value: "monnify",
                          },
                        ]}
                        defaultValue={fiatProvider?.kaapo?.paymentProvider}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SectionContent>
        ) : (
          <Loader />
        )}
      </div>
    </PageContainer>
  );
}

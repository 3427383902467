import React from "react";
import { Fade } from "react-reveal";

import { SimpleIcon } from "components";
import { classNames } from "utils";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetUsersCount } from "api";
import { useAllTrans } from "utils/allTransactionProps";

const analysis = [
  {
    gradColors: "from-pink-900 to-pink-600 dark:from-pink-900 dark:to-pink-700",
    color: "text-pink-600 dark:text-pink-800",
  },
  {
    gradColors:
      "from-emerald-900 to-teal-600 dark:from-emerald-900 dark:to-teal-700",
    color: "text-teal-600 dark:text-teal-800",
  },
  {
    gradColors: "from-sky-900 to-cyan-600 dark:from-sky-900 dark:to-cyan-700",
    color: "text-cyan-600 dark:text-cyan-800",
  },
  {
    gradColors:
      "from-yellow-900 to-yellow-600 dark:from-yellow-900 dark:to-yellow-700",
    color: "text-yellow-600 dark:text-yellow-800",
  },
];

const listP = [
  "Chief Executive Officer",
  "HR Manager",
  "Customer Care Agent",
  "Supervisor",
];

export default function Analysis() {
  const useTrans = (id) =>
    useAllTrans().filter((item) => item?.id.includes(id) && item.data?.length);

  const useTransCount = (id) =>
    useTrans(id)?.reduce((acc, item) => {
      acc += item?.data?.length || 0;
      return acc;
    }, 0);

  const { user_details } = useSelector((state) => state?.profile || {});
  const [userCount] = useGetUsersCount();
  const { eseUsers, kapUsers, staff } = userCount || {};

  const { position } = user_details || {};

  // const position = "Customer Care Agent";

  const pendingTransL = useTransCount("pending");
  const deferredTransL = useTransCount("deferred");
  const activeTransL = useTransCount("active");

  const adminOb = [
    {
      text: "Total Staff",
      icon: "Work",
      fig: staff || 0,
      linkTo: "/staff",
    },
    {
      text: "Total Users - Esetech",
      icon: "Group",
      fig: eseUsers || 0,
      linkTo: "/users",
    },
    {
      text: "Total Users - Kaapo",
      icon: "Group",
      fig: kapUsers || 0,
      linkTo: "/users",
    },
  ];

  const accessObj = {
    "Customer Care Agent": [
      {
        text: "Incoming Transactions",
        icon: "Addchart",
        fig: pendingTransL || 0,
        linkTo: "/transaction",
      },
      {
        text: "Active Transactions",
        icon: "TableChart",
        fig: activeTransL || 0,
        linkTo: "/transaction",
      },
      {
        text: "Deferred Transactions",
        icon: "InsertChart",
        fig: deferredTransL || 0,
        linkTo: "/transaction",
      },
    ],

    Supervisor: [
      {
        text: "Total Staff",
        icon: "Work",
        fig: staff || 0,
        linkTo: "/staff",
      },
      {
        text: "Incoming Transactions",
        icon: "Addchart",
        fig: pendingTransL || 0,
        linkTo: "/transaction",
      },
      {
        text: "Active Transactions",
        icon: "InsertChart",
        fig: activeTransL || 0,
        linkTo: "/transaction",
      },
    ],

    "Chief Executive Officer": adminOb,
    "HR Manager": adminOb,
    "Accounting Officer": [
      {
        text: "Total Staff",
        icon: "Work",
        fig: staff || 0,
        linkTo: "/staff",
      },
      {
        text: "Today's Crypto Sales",
        icon: "Addchart",
        fig: 0,
        linkTo: "/transaction-reports",
      },
      {
        text: "Today's Giftcard Sales",
        icon: "InsertChart",
        fig: 0,
        linkTo: "/transaction-reports",
      },
      {
        text: "Today's Profit",
        icon: "InsertChart",
        fig: 0,
        linkTo: "/transaction-reports",
      },
    ],
  };

  return (
    <Fade>
      {accessObj?.[position] && (
        <div className="w-full rounded bg-box p-2 shadow-drawer dark:bg-gray-900">
          <div
            className={classNames(
              "grid gap-2",
              listP.includes(position) ? "grid-cols-3" : "grid-cols-4"
            )}
          >
            {accessObj?.[position].map((item, index) => {
              const { gradColors, color } = analysis?.[index];
              return (
                <div key={index} className="col-span-1">
                  <div
                    className={classNames(
                      gradColors,
                      "relative h-full overflow-hidden rounded bg-gradient-to-r p-6 "
                    )}
                  >
                    <div className="mb-6 flex flex-row items-end justify-between">
                      {/* p-2 */}
                      <div className="flex h-14 w-14 flex-row items-center justify-center rounded-lg bg-white dark:bg-gray-200">
                        <SimpleIcon
                          icon={item.icon}
                          fontSize="large"
                          frontColor={color}
                        />
                      </div>
                      <div className="desc text-right text-white">
                        <div className="mb-2 text-4xl font-bold">
                          {item.fig}
                        </div>
                        <div className="text-lg">{item.text}</div>
                      </div>
                    </div>
                    <div className="absolute bottom-0 left-0 w-full  bg-[rgba(0,0,0,0.2)] px-3 py-1 text-right">
                      <Link
                        to={item.linkTo}
                        className="flex flex-row items-center justify-end"
                      >
                        <div className="text-sm text-white">More Info</div>
                        <SimpleIcon
                          frontColor="text-white"
                          fontSize="small"
                          icon="ArrowForwardIos"
                          otherClass="!text-xs"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Fade>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  giftcards: null,
  giftcardFac: null,
  buyGiftcardFac: null,
  getCardProps: null,
};

export const giftcardSlice = createSlice({
  name: "giftcard",
  initialState,
  reducers: {
    getCards: (state, { payload }) => {
      state.giftcards = payload;
    },
    getCardFac: (state, { payload }) => {
      state.giftcardFac = payload;
    },
    getBuyCardFac: (state, { payload }) => {
      state.buyGiftcardFac = payload;
    },
    getCardProps: (state, { payload }) => {
      state.giftcardProps = payload;
    },
  },
});

export const { getCards, getCardFac, getBuyCardFac, getCardProps } =
  giftcardSlice.actions;

export default giftcardSlice.reducer;

import { CircularProgress } from "@mui/material";
import React from "react";
import { SectionContent } from "./Container";
import { IconBox } from "./Icon";

export function Loader() {
  return (
    // <SectionContent otherClass={"w-full"}>
    <div className="w-full rounded border border-slate-100 bg-box p-0 shadow-sm dark:border-slate-700 dark:bg-gray-700">
      <div className="relative overflow-x-auto p-2 shadow-md">
        <div className="flex flex-col items-center justify-center rounded bg-white p-2 dark:bg-slate-800">
          <CircularProgress size={25} thickness={7} />

          <div className="mt-4 font-bold text-gray-500 dark:text-gray-200">
            Loading..
          </div>
        </div>
      </div>
    </div>
    // </SectionContent>
  );
}

export function SimpleLoader() {
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <CircularProgress size={20} thickness={5} />
    </div>
  );
}
export function NoData({ text }) {
  return (
    <SectionContent>
      <div className="w-full rounded border border-slate-100 bg-box p-0 shadow-sm dark:border-slate-700 dark:bg-gray-700">
        <div className="relative overflow-x-auto p-2 shadow-md">
          <div className="flex flex-col items-center justify-center rounded bg-white p-2 dark:bg-slate-800">
            <IconBox icon="DeveloperBoardOff" gradient />
            <div className="mt-4 font-bold text-gray-500 dark:text-gray-200">
              {text}
            </div>
          </div>
        </div>
      </div>
    </SectionContent>
  );
}

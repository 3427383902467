import React, { useState } from "react";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";

import { IconButton, SectionContent, SimpleIcon } from "components";

import { useAddTodo, useEditTodo, useDeleteTodo } from "api";
import { formatDate } from "utils";

export default function Todolist() {
  const [todo, setTodo] = useState("");
  const [currTodo, setCurrTodo] = useState(null);

  const [isUpdatingAdd, isUpdatedAdd, addTodo] = useAddTodo();
  const [isUpdatingEdit, isUpdatedEdit, editTodo] = useEditTodo();
  const [isUpdatingDelete, isUpdatedDelete, deleteTodo] = useDeleteTodo();

  const { todoList } = useSelector((state) => state.todo);
  const completedTasks = todoList?.filter((i) => i.completed)?.length;

  return (
    <SectionContent>
      <div className="rounded bg-box p-4 shadow-card-box dark:bg-gray-800">
        <h4 className="mb-2 text-lg text-primary dark:text-light-primary">
          Todo List
        </h4>
        <hr className="mb-6 border-t dark:border-gray-500" />
        <div className="flex flex-row items-center justify-center">
          <input
            type="text"
            value={todo}
            className="mr-4 h-12 w-full border-primary bg-transparent outline-none placeholder:text-gray-400 focus:border-b-2 dark:text-gray-200"
            placeholder="What do you want to do today?"
            onChange={(e) => setTodo(e.target.value)}
            autoFocus
          />
          <div>
            <IconButton
              isLoading={isUpdatingAdd}
              icon="Add"
              handleClick={() => {
                if (todo.length > 3) {
                  addTodo({ todo });
                  setTodo("");
                }
              }}
            />
          </div>
        </div>

        <ul className="mt-4">
          {todoList?.length ? (
            todoList?.map((item) => (
              <li
                key={item.id}
                className="w-full bg-background-main-alt dark:bg-gray-900"
                onMouseOver={() => setCurrTodo(item.id)}
                onMouseLeave={() => setCurrTodo(null)}
              >
                <div className="flex flex-row items-center justify-between p-3">
                  <div className="flex flex-row items-center justify-start">
                    <Checkbox
                      className="dark:!text-white"
                      edge="start"
                      checked={item.completed}
                      onChange={() =>
                        editTodo({
                          todoId: item.id,
                          isCompleted: !item.completed,
                        })
                      }
                    />
                    <div>
                      <div className="title dark:text-white">{item.text}</div>
                      <div className="subTitle text-sm text-color-main dark:text-gray-300">
                        Created at —{" "}
                        <span className="text-gray-500 dark:text-gray-300">
                          {formatDate(
                            item.dateCreated,
                            "ddd, Do MMMM YYYY hh:mma"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {currTodo && currTodo === item.id && (
                    <button
                      onClick={() => {
                        deleteTodo({
                          todoId: item.id,
                        });
                      }}
                    >
                      <SimpleIcon
                        icon="RemoveCircle"
                        frontColor="text-red-500"
                      />
                    </button>
                  )}
                </div>
                <Divider />
              </li>
            ))
          ) : (
            <div className="w-full bg-background-main-alt p-6 text-sm dark:bg-gray-900">
              You do not have a todo. Add one!
            </div>
          )}
        </ul>

        <hr className="mb-3 border-t dark:border-gray-500" />
        <div className="flex flex-row justify-between">
          <p className="dark:text-gray-400">
            {completedTasks || "No"} completed{" "}
            {completedTasks > 1 ? "tasks" : "task"}
          </p>
        </div>
      </div>
    </SectionContent>
  );
}

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as i from "../lib";
import { getTodoList } from "redux/reducers/todoReducers";
import { notification } from "utils";

const auth = i.getAuth();

export const useGetTodo = () => {
  const dispatch = useDispatch();
  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    let unsub;
    const db = i.db;
    try {
      if (userUid) {
        let q = i.query(
          i.collection(db, "staffTodoList"),
          i.where("userId", "==", userUid),
          i.orderBy("dateCreated", "desc")
        );
        unsub = i.onSnapshot(q, (querySnapshot) => {
          const t = [];
          querySnapshot.forEach((doc) => {
            t.push({ id: doc.id, ...doc.data() });
          });
          dispatch(getTodoList(t));
        });
      }
    } catch (error) {
      console.log(error);
    }
    return unsub;
  }, [dispatch, userUid]);
};

export const useAddTodo = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const addTodo = async ({ todo }) => {
    const userUid = auth?.currentUser?.uid;

    setUpdating(true);
    try {
      await i.setDoc(i.doc(i.collection(i.db, "staffTodoList")), {
        text: todo,
        userId: userUid,
        dateCreated: Date.now(),
        completed: false,
      });

      setUpdating(false);
      setUpdated(true);
      notification("success", "Todo successfully added.");
    } catch (error) {
      console.log(error);
      setUpdating(false);
      notification("danger", "An error occured.", "error");
    }
  };
  return [isUpdating, isUpdated, addTodo];
};

export const useEditTodo = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const editTodo = async ({ todoId, isCompleted }) => {
    setUpdating(true);
    try {
      const docRef = i.doc(i.db, "staffTodoList", todoId);

      await i.updateDoc(docRef, {
        completed: isCompleted,
      });

      setUpdating(false);
      setUpdated(true);
      notification("success", "Todo successfully updated.");
    } catch (error) {
      setUpdating(false);
      notification("danger", "An error occured.", "error");
    }
  };
  return [isUpdating, isUpdated, editTodo];
};

export const useDeleteTodo = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const deleteTodo = async ({ todoId }) => {
    setUpdating(true);
    try {
      await i.deleteDoc(i.doc(i.db, "staffTodoList", todoId));
      setUpdating(false);
      setUpdated(true);
      notification("success", "Todo successfully deleted.");
    } catch (error) {
      setUpdating(false);
      notification("danger", "An error occured.", "error");
    }
  };
  return [isUpdating, isUpdated, deleteTodo];
};

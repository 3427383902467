import React, { useState } from "react";
import SettingsDrawer from "../../components/SettingsDrawer";
import SettingsContent from "../../components/SettingsContent";
import { checkTransactions } from "utils/transactionProps";

export default function KaapoTrans() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const {
    checkFiatWithdrawalData,
    checkUtilityTransData,
    checkCryptoWithdrawal,
  } = checkTransactions();

  const tabList = [
    {
      label: "Fiat Withdrawal",
      subtitle: "Check withdrawal status",
      dataRow: checkFiatWithdrawalData,
      dataType: ["transactionId"],
      id: "check_fiat_withdrawal",
    },
    {
      label: "Airtime & Data Utilities",
      subtitle: "Check airtime & data utilities",
      dataRow: checkUtilityTransData,
      dataType: ["transactionId"],
      id: "check_utilities",
    },
    {
      label: "Other Utilities",
      subtitle: "Verify other utilities",
      dataRow: checkCryptoWithdrawal,
      dataType: ["transactionId", "assetType"],
      id: "check_other_utility",
    },
  ];

  const pageContent = tabList?.[selectedIndex];

  return (
    <div className="m-2">
      <div className="grid grid-cols-1 gap-6">
        <div className="col-span-1">
          <div className="">
            <div className="relative overflow-hidden rounded bg-white shadow-md dark:bg-gray-800">
              <div className="flex flex-row overflow-hidden">
                <SettingsDrawer
                  {...{
                    selectedIndex,
                    setSelectedIndex,
                    tabList,
                  }}
                />
                <SettingsContent
                  pageContent={pageContent}
                  selectedIndex={selectedIndex}
                  platform="kaapo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  Button,
  DrawerSide,
  ImageUploadSingle,
  Select,
  SimpleIcon,
} from "components";
import { useSelector } from "react-redux";
import { useAddGiftcard } from "api";
import { difference } from "lodash";

export default function AddGiftcard() {
  const [open, setOpen] = useState(false);
  const [card, setCard] = useState(null);
  const [cardImg, setCardImg] = useState([]);

  const [isUpdating, isUpdated, addGiftcard] = useAddGiftcard();

  const { giftcards, giftcardProps } = useSelector(
    (state) => state.giftcard || {}
  );

  const {
    user_details: { position },
  } = useSelector((state) => state.profile);

  const { giftcardList } = giftcardProps || {};

  const giftcardListMap = giftcards?.map((item) => item.title);
  const giftcardListDropdownMap = giftcardList?.map((item) => item.title);

  const giftcardDropDown = difference(giftcardListDropdownMap, giftcardListMap);

  const handleAddGiftcard = () => {
    const values = card
      ? giftcardList.find((item) => item.title === card.id)
      : null;

    addGiftcard(values, cardImg);
  };

  useEffect(() => {
    if (isUpdated) {
      setCardImg([]);
      setCard(null);
      setOpen(false);
    }
  }, [isUpdated]);

  return (
    <div>
      {["Supervisor", "Chief Executive Officer"].includes(position) && (
        <div>
          <div className="my-4">
            <div className="rounded bg-box p-2 dark:bg-gray-900">
              <button
                className="relative flex h-20 w-full flex-row items-center justify-center rounded border-2 border-dashed bg-white shadow-sm dark:border-gray-500 dark:bg-gray-800"
                onClick={() => setOpen(true)}
              >
                <SimpleIcon
                  icon="AddCircleOutline"
                  otherClass="iconShadow-alt"
                  frontColor="text-gray-300"
                  fontSize={40}
                />
              </button>
            </div>
          </div>
          <DrawerSide {...{ open, setOpen }}>
            <div>
              <div className="border-b border-slate-100 p-4 dark:border-gray-500 dark:text-white">
                Add Giftcard
              </div>
              <div className="p-4">
                <div className="grid grid-cols-1 gap-6">
                  <div className="col-span-1">
                    <ImageUploadSingle
                      images={cardImg}
                      handleChange={setCardImg}
                    />
                  </div>
                  <div className="col-span-1">
                    <Select
                      inputLabel="Giftcard"
                      name="giftcard"
                      dropDown={giftcardDropDown?.map((item) => ({
                        value: item,
                        id: item,
                      }))}
                      value={card?.value || ""}
                      handleChange={(e) => {
                        setCard(e);
                      }}
                    />
                  </div>

                  <div className="col-span-1">
                    <Button
                      label="Add Giftcard"
                      handleClick={handleAddGiftcard}
                      isSubmitting={isUpdating}
                    />
                  </div>
                </div>
              </div>
            </div>
          </DrawerSide>
        </div>
      )}
    </div>
  );
}

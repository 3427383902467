import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalEsetech: null,
  totalKaapo: null,
  metaAdsInsightsEsetech: null,
  metaAdsInsightsKaapo: null,
  metaCallToActionInsightInsta: null,
  esetechMetaCallToActionInsightFacebook: null,
  metaCallToActionInsightFacebook: null,
  metaEngagement: null,
};

export const marketingSlice = createSlice({
  name: "marketing",
  initialState,
  reducers: {
    getTotalEsetech: (state, { payload }) => {
      state.totalEsetech = payload;
    },
    getTotalKaapo: (state, { payload }) => {
      state.totalKaapo = payload;
    },

    getMetaAdsInsightsEsetech: (state, { payload }) => {
      state.metaAdsInsightsEsetech = payload;
    },

    getMetaAdsInsightsKaapo: (state, { payload }) => {
      state.metaAdsInsightsKaapo = payload;
    },

    getMetaCallToActionInsightInsta: (state, { payload }) => {
      state.metaCallToActionInsightInsta = payload;
    },

    getMetaCallToActionInsightFacebook: (state, { payload }) => {
      state.metaCallToActionInsightFacebook = payload;
    },

    getEsetechMetaCallToActionInsightFacebook: (state, { payload }) => {
      state.esetechMetaCallToActionInsightFacebook = payload;
    },

    getMetaEngagement: (state, { payload }) => {
      state.metaEngagement = payload;
    },
  },
});

export const {
  getTotalEsetech,
  getTotalKaapo,
  getMetaAdsInsightsEsetech,
  getMetaAdsInsightsKaapo,
  getMetaCallToActionInsightInsta,
  getMetaCallToActionInsightFacebook,
  getEsetechMetaCallToActionInsightFacebook,
  getMetaEngagement,
} = marketingSlice.actions;

export default marketingSlice.reducer;

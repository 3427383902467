import React, { Fragment, useState } from "react";
import { Button, Select, SimpleIcon } from "components";
import { Slide } from "react-reveal";
import { Slide as MuiSlide } from "@mui/material";

import dayjs from "dayjs";
import { classNames } from "utils";
import localeData from "dayjs/plugin/localeData";
import updateLocale from "dayjs/plugin/updateLocale";
import weekOfYear from "dayjs/plugin/weekOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useGetTransReportDateRange } from "api";
import { useSelector } from "react-redux";

dayjs.extend(localeData);
dayjs.extend(updateLocale);
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);

export default function DatePicker({
  setOpenDateRange,
  getDate,
  setDate,
  handleSelectDate,
  calendarType,
  setCalendarType,
}) {
  const [openYearPopup, setOpenYearPopup] = useState(false);
  const [getCurrentMonth, setCurrentMonth] = useState(
    getDate?.startDate?.startOf("month")
  );
  const [getCurrentType, setCurrentType] = useState();

  const weekNames = dayjs.weekdaysMin();
  const monthsNames = dayjs.monthsShort();

  const startYear = dayjs("2021-05-12").startOf("year").get("year");
  const endYear = dayjs().startOf("year").get("year");
  const startYearMonth = dayjs("2021-05-12").get("month");
  const endYearMonth = dayjs().get("month");

  const currentYear = getCurrentMonth.get("year");
  const currentMonth = getCurrentMonth.get("month");
  const currentDate = dayjs().startOf("day").valueOf();

  const getCurrentMonths = () => {
    const format = "MMM YYYY";
    const prevMon = getCurrentMonth.subtract(1, "month");
    const nextMon = getCurrentMonth.add(1, "month");

    const prevMonStart = prevMon.startOf("month");
    const prevMonEnd = prevMon.endOf("month");
    const prevName = prevMon.endOf("month").format(format);

    const currentMonStart = getCurrentMonth.startOf("month");
    const currentMonEnd = getCurrentMonth.endOf("month");
    const currentName = getCurrentMonth.endOf("month").format(format);

    const nextMonStart = nextMon.startOf("month");
    const nextMonEnd = nextMon.endOf("month");
    const nextName = nextMon.endOf("month").format(format);

    return {
      prevMonStart,
      prevMonEnd,
      prevName,
      currentMonStart,
      currentMonEnd,
      currentName,
      nextMonStart,
      nextMonEnd,
      nextName,
    };
  };

  const {
    prevMonStart,
    prevMonEnd,
    prevName,
    currentMonStart,
    currentMonEnd,
    currentName,
    nextMonStart,
    nextMonEnd,
    nextName,
  } = getCurrentMonths();

  const getAllMonthDays = (day) => {
    const startMonthDate = getCurrentMonth.startOf("month").get("day");
    const getDateF = getCurrentMonth.date(day - startMonthDate);
    const getMonth = getDateF.get("month");
    const getDate = getDateF.get("date");
    const getDateD = getDateF.startOf("day").valueOf();
    const getWeek = getDateF.week();

    return {
      day: getDate,
      date: getDateF,
      week: getWeek,
      isCurrentmonth: getMonth === currentMonth,
      isCurrentDate: currentDate === getDateD,
    };
  };

  const handleChangeMonth = (type) => {
    let d;
    if (type === "add") {
      d = getCurrentMonth.add(1, "month");
    } else if (type === "subtract") {
      d = getCurrentMonth.subtract(1, "month");
    }

    setCurrentMonth(dayjs(d).startOf("month"));
    setCurrentType(type);
  };

  const handleClearDate = () => {
    setDate({
      startDate: dayjs().startOf("day"),
      endDate: dayjs().endOf("day"),
    });
    setCalendarType("day");
  };

  const formatMonth = getCurrentMonth.format("MMM YYYY");
  const getYearMonth = (year, month) => {
    const m = dayjs().year(year).month(month).startOf("month");
    setCurrentMonth(m);
    setOpenYearPopup(false);
  };
  const yearNum = getDate?.startDate?.get("year");
  const monthNum = getDate?.startDate?.get("month");
  const weekNum = getDate?.startDate?.get("week");

  return (
    <div className="">
      <div className="z-[20] min-h-[300px] min-w-[350px] overflow-hidden rounded border border-slate-200 bg-white shadow-drawer dark:border-slate-800 dark:bg-gray-800">
        <div className="p-4">
          <Select
            inputLabel="Calendar Type"
            dropDown={[
              { label: "Day", id: "day" },
              { label: "Week", id: "week" },
              { label: "Month", id: "month" },
              { label: "Custom", id: "custom" },
            ]}
            titleValue="label"
            handleChange={(e) => {
              setCalendarType(e.id);
            }}
            // value={calendarType}
            valueAlt={calendarType || ""}
            setValueAlt={(e) => {
              setCalendarType(e.id);
            }}
            defaultValue={"Day"}
            startAdornment={
              <SimpleIcon
                icon={"Work"}
                frontColor="text-gray-300"
                fontSize={18}
              />
            }
          />
        </div>

        <div className="border-t p-2 dark:border-gray-700">
          <div className="flex items-center justify-between p-2">
            <button
              className={classNames(
                "h-8 w-8 rounded bg-slate-200 duration-200 ease-in dark:bg-gray-400 dark:bg-transparent-main",
                openYearPopup ? "invisible" : ""
              )}
              onClick={() => handleChangeMonth("subtract")}
            >
              <SimpleIcon icon="KeyboardArrowLeft" fontSize={16} />
            </button>
            <button onClick={() => setOpenYearPopup(!openYearPopup)}>
              <div className="text-sm dark:text-white">{formatMonth}</div>
            </button>
            <button
              className={classNames(
                "h-8 w-8 rounded bg-slate-200 duration-200  ease-in dark:bg-gray-400 dark:bg-transparent-main",
                openYearPopup ? "invisible" : ""
              )}
              onClick={() => handleChangeMonth("add")}
            >
              <SimpleIcon icon="KeyboardArrowRight" fontSize={16} />
            </button>
          </div>
          {calendarType === "day" && (
            <>
              <div className="mt-2 grid grid-cols-7">
                {weekNames?.map((itemWeekN) => (
                  <span
                    className="col-span-1 flex items-center justify-center text-sm dark:text-gray-300"
                    key={itemWeekN}
                  >
                    {itemWeekN}
                  </span>
                ))}
              </div>
              <Slide
                right={getCurrentType === "add"}
                left={getCurrentType === "subtract"}
                spy={getCurrentMonth}
                duration={500}
              >
                <div className="grid grid-cols-7 gap-y-0">
                  {[...Array(42)]?.map((itemDay, index) => {
                    const { day, date, isCurrentmonth, isCurrentDate } =
                      getAllMonthDays(index + 1);

                    const isCurrentDay =
                      date.format("YYYY-MM-DD") ===
                      getDate?.startDate?.format("YYYY-MM-DD");
                    return (
                      <Fragment key={index}>
                        {isCurrentmonth ? (
                          <button
                            className="col-span-1 items-center justify-center"
                            onClick={() => {
                              setDate({
                                startDate: date.startOf("day"),
                                endDate: date.endOf("day"),
                              });
                              setOpenDateRange(false);
                            }}
                          >
                            <div
                              className={classNames(
                                "flex items-center justify-center rounded-full p-2 text-sm hover:bg-primary/20 hover:font-bold",
                                isCurrentmonth
                                  ? "dark:text-gray-300"
                                  : "invisible cursor-auto text-gray-500",
                                isCurrentDate ? "border border-primary" : "",
                                "hover:dark:text-primary",
                                isCurrentDay ? "bg-primary text-white" : ""
                              )}
                            >
                              {day}
                            </div>
                          </button>
                        ) : (
                          <div className="col-span-1 m-1 flex items-center justify-center" />
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </Slide>
            </>
          )}

          {calendarType === "week" && (
            <Slide
              right={getCurrentType === "add"}
              left={getCurrentType === "subtract"}
              spy={getCurrentMonth}
              duration={500}
            >
              <div className="">
                <div className="">
                  <div className="bg-transparent-main-0 h-full">
                    <div className="w-full">
                      {[...Array(5)]?.map((itemDay, indexD) => {
                        const { date: dayStart } = getAllMonthDays(
                          7 * indexD + 1
                        );
                        const { date: dayEnd, week } = getAllMonthDays(
                          7 * (indexD + 1)
                        );

                        const startD = (format) => dayStart.format(format);
                        const endD = (format) => dayEnd.format(format);

                        const getYear = dayStart.get("year");

                        const isCurrentWeek =
                          yearNum === getYear && weekNum === week;

                        return (
                          <div
                            className={classNames(
                              "items-center-0 flex items-center justify-start rounded border-t py-1 px-3 duration-500 ease-in hover:bg-primary/20 dark:border-gray-700",
                              isCurrentWeek ? "bg-primary/20" : ""
                            )}
                            key={indexD}
                          >
                            <div className="">
                              <div
                                className={classNames(
                                  "flex h-[45px] w-[45px] items-center justify-center rounded-xl duration-500 ease-in",
                                  isCurrentWeek ? "bg-primary/50" : ""
                                )}
                              >
                                <div className="flex flex-col items-center justify-center p-1 dark:text-white">
                                  <SimpleIcon icon="ViewDay" fontSize={16} />
                                </div>
                              </div>
                            </div>
                            <div className="w-full">
                              <button
                                className={classNames(
                                  "flex h-full w-full items-center justify-between rounded  border-b-0 p-[12px] text-sm dark:border-gray-700 dark:text-white"
                                )}
                                onClick={() => {
                                  setDate({
                                    startDate: dayStart?.startOf("day"),
                                    endDate: dayEnd?.endOf("day"),
                                  });
                                  setOpenDateRange(false);
                                }}
                              >
                                <div className="flex w-full flex-col items-start justify-between text-sm">
                                  <div>Week {week}</div>
                                  <div>
                                    {startD("ddd, Do MMM")} -{" "}
                                    {endD("ddd, Do MMM")}
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          )}

          {calendarType === "month" && (
            <Slide
              right={getCurrentType === "add"}
              left={getCurrentType === "subtract"}
              spy={getCurrentMonth}
              duration={500}
            >
              <div className="">
                <div className="">
                  <div className="bg-transparent-main-0 h-full">
                    <div className="w-full">
                      {[
                        {
                          id: "prev",
                          name: prevName,
                          monthStart: prevMonStart,
                          monthEnd: prevMonEnd,
                        },
                        {
                          id: "current",
                          name: currentName,
                          monthStart: currentMonStart,
                          monthEnd: currentMonEnd,
                        },
                        {
                          id: "next",
                          name: nextName,
                          monthStart: nextMonStart,
                          monthEnd: nextMonEnd,
                        },
                      ].map((itemMonth, indexM) => {
                        const format = "ddd, Do MMM";
                        const { monthStart, monthEnd } = itemMonth;
                        const monthStartFormatted = monthStart.format(format);
                        const monthEndFormatted = monthEnd.format(format);

                        const getYear = monthStart.get("year");
                        const getMonth = monthStart.get("month");

                        const isCurrentMonth =
                          getYear === yearNum && getMonth === monthNum;

                        return (
                          <div
                            className={classNames(
                              "items-center-0 flex items-center justify-start rounded border-t py-1 px-3 duration-500 ease-in hover:bg-primary/20 dark:border-gray-700",
                              isCurrentMonth ? "bg-primary/20" : ""
                            )}
                            key={indexM}
                          >
                            <div className="">
                              <div
                                className={classNames(
                                  "flex h-[45px] w-[45px] items-center justify-center rounded-xl duration-500 ease-in",
                                  isCurrentMonth ? "bg-primary/50" : ""
                                )}
                              >
                                <div className="flex flex-col items-center justify-center p-1 dark:text-white">
                                  <SimpleIcon icon="ViewDay" fontSize={16} />
                                </div>
                              </div>
                            </div>
                            <div className="w-full">
                              <button
                                className={classNames(
                                  "flex h-full w-full items-center justify-between rounded  border-b-0 p-[12px] text-sm dark:border-gray-700 dark:text-white"
                                )}
                                onClick={() => {
                                  setDate({
                                    startDate: monthStart?.startOf("day"),
                                    endDate: monthEnd?.endOf("day"),
                                  });
                                  setOpenDateRange(false);
                                }}
                              >
                                <div className="flex w-full flex-col items-start justify-between text-sm">
                                  <div>{itemMonth?.name}</div>
                                  <div>
                                    {monthStartFormatted} - {monthEndFormatted}
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          )}
        </div>
        {/* <div className="action-buttons absolute-0 bottom-0 right-0 flex w-full items-center justify-evenly gap-4 border-t p-3 p-4 dark:border-gray-700">
          <Button
            label="Cancel"
            color="default"
            otherClass="w-full !bg-transparent-main !text-white"
            handleClick={() => {
              handleClearDate();
            }}
          />
          <Button
            label="Confirm"
            primary
            otherClass="w-full"
            handleClick={() => {
              handleSelectDate();
              setOpenDateRange(false);
            }}
          />
        </div> */}
        <MuiSlide direction="up" in={openYearPopup} mountOnEnter unmountOnExit>
          <div className="absolute bottom-0 right-0 h-[90%] w-full overflow-auto rounded-xl bg-box p-4 dark:bg-slate-800">
            <div className="gap-4-0 flex flex-col">
              {[...Array(endYear - startYear + 1)].map((item, indY, arr) => {
                const year = startYear + indY;

                return (
                  <div
                    className={classNames(
                      "flex items-center justify-between py-4 px-0 text-sm dark:text-white",
                      indY !== arr.length - 1
                        ? "border-b dark:border-gray-700 "
                        : ""
                    )}
                    key={indY}
                  >
                    <div
                      className={classNames(
                        currentYear === year
                          ? "text-light-primary"
                          : "dark:text-white"
                      )}
                    >
                      {year}
                    </div>
                    <div className="grid grid-cols-6 gap-0">
                      {monthsNames.map((itemM, indM) => {
                        const isDisabledMonth =
                          (startYear === year && startYearMonth > indM) ||
                          (endYear === year && endYearMonth < indM);
                        const isMonth =
                          currentYear === year && indM === currentMonth;
                        return (
                          <button
                            className={classNames(
                              "col-span-1 m-[2px] rounded p-1 text-sm",
                              isMonth ? "bg-primary" : "",
                              isDisabledMonth
                                ? "text-gray-500 hover:bg-transparent"
                                : "hover:bg-transparent-main"
                            )}
                            onClick={() => getYearMonth(year, indM)}
                            disabled={isDisabledMonth}
                            key={indM}
                          >
                            {itemM}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </MuiSlide>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { BackdropSection, Button } from "components";
import { useCheckFiatWithdrawalESE, useCheckFiatWithdrawalKAP } from "api";
import CheckResult from "../subpage/checkTransaction/CheckResult";
import { startCase } from "lodash";
import { classNames } from "utils";

export default function SettingsContent({
  pageContent,
  selectedIndex,
  platform,
}) {
  const { dataRow, dataType, id, requestData } = pageContent;
  const [pageDelay, setPageDelay] = useState(false);
  const [getInput, setGetInput] = useState(null);

  let useCheckerFunc =
    platform === "kaapo"
      ? useCheckFiatWithdrawalKAP
      : useCheckFiatWithdrawalESE;

  const [
    isSubmitting,
    isSubmitted,
    getData,
    setData,
    checkTrans,
    isError,
    setError,
  ] = useCheckerFunc();

  useEffect(() => {
    setTimeout(() => {
      setPageDelay(true);
    }, 500);
    setPageDelay(false);
    setData(null);
    setError(false);
  }, [selectedIndex, setData, setError]);

  useEffect(() => {
    const checkAllEmpty = Object.values(getInput || [])?.every((i) =>
      Boolean(i)
    );
    if (!checkAllEmpty) {
      setData(null);
      setError(false);
    }
  }, [getInput]);

  const handleGetData = (e) => {
    setGetInput((prevS) => ({ ...prevS, [e.target.name]: e.target.value }));
  };

  return (
    <div className="relative w-full overflow-x-auto dark:bg-slate-900">
      <div className="h-full w-full">
        <div className="flex h-[60px] flex-row items-center border-b border-slate-100 px-4 dark:border-gray-500">
          {dataType.map((item, index) => (
            <input
              key={item}
              className={classNames(
                "h-full w-full border-0 px-2 outline-none dark:bg-gray-900 dark:text-white",
                index === 1
                  ? "w-[150px] border-l border-gray-50 dark:border-gray-500"
                  : ""
              )}
              placeholder={`Input ${startCase(item)}`}
              name={item}
              onChange={handleGetData}
              autoComplete="off"
            />
          ))}

          <Button
            label="Search"
            size="small"
            handleClick={() => checkTrans(getInput, id)}
            isSubmitting={isSubmitting}
          />
        </div>

        <div className="h-full bg-dark/5 dark:bg-gray-900">
          <div className="p-4">
            <div className="mb-4 rounded bg-cyan-50/50 p-4 dark:bg-gray-700">
              <div className="text-sm dark:text-white">
                Request for{" "}
                <span className="font-bold text-cyan-500">
                  {dataType.map((item, index) => (
                    <>
                      {index === 1 ? " &" : ""} {startCase(item)}
                    </>
                  ))}
                </span>{" "}
                to check transaction.
              </div>
            </div>
            {/* <Comp /> */}
            <CheckResult
              columns={dataRow()?.columns}
              rows={dataRow()?.rows}
              getData={getData}
              isLoading={isSubmitting}
              isError={isError}
            />
          </div>
        </div>
      </div>

      <BackdropSection open={!pageDelay} />
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatUsers: null,
  currentChatUserDetails: null,
  chatToUsersList: null,
  currentChatId: null,
  currentChatMessages: null,
  currentUserUnread: 0,
  chatNotification: null,
  currentChatMessagesDetails: null,
  lastChatMessage: null,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    getChatUsers: (state, { payload }) => {
      state.chatUsers = payload;
    },
    getCurrentChatUserDetails: (state, { payload }) => {
      state.currentChatUserDetails = payload;
    },
    getCurrentChatId: (state, { payload }) => {
      state.currentChatId = payload;
    },
    getChatToUsersList: (state, { payload }) => {
      state.chatToUsersList = payload;
    },
    getCurrentUserUnread: (state, { payload }) => {
      state.currentUserUnread = payload;
    },
    getCurrentChatMessages: (state, { payload }) => {
      state.currentChatMessages = payload;
    },
    getLastChatMessage: (state, { payload }) => {
      state.lastChatMessage = payload;
    },
    getCurrentChatMessagesDetails: (state, { payload }) => {
      state.currentChatMessagesDetails = payload;
    },
    setChatNotification: (state, { payload }) => {
      state.chatNotification = payload;
    },
  },
});

export const {
  getChatUsers,
  getCurrentChatUserDetails,
  getChatToUsersList,
  getCurrentChatId,
  getCurrentChatMessages,
  getCurrentChatMessagesDetails,
  getCurrentUserUnread,
  setChatNotification,
  getLastChatMessage,
} = chatSlice.actions;

export default chatSlice.reducer;

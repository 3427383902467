import React, { Fragment, useEffect, useState } from "react";
import {
  DrawerSide,
  Loader,
  SectionContent,
  SimpleIcon,
  Switch,
} from "components";
import { useSelector } from "react-redux";
import { classNames, momentDate } from "utils";
import { capitalize, startCase, toLower } from "lodash";
import { useGetCurrentTeam } from "api";

const listP = [
  "Chief Executive Officer",
  "HR Manager",
  "Customer Care Agent",
  "Supervisor",
  "Accounting Officer",
];

const WeekTab = ({ getWeekD, setCurrentD }) => {
  return (
    <ul
      className={
        "relative flex w-full flex-row items-center justify-between divide-x divide-gray-200 dark:divide-gray-600"
      }
    >
      {getWeekD &&
        getWeekD.map((l, index) => (
          <li
            key={index}
            className={classNames(
              "relative flex h-full w-full flex-row items-center justify-center py-8",
              momentDate(new Date(), "YYYY/MM/DD") === l
                ? "!border-2 !border-primary"
                : ""
            )}
          >
            <div
              onClick={() => setCurrentD(l)}
              className="flex h-full w-full flex-row items-center justify-center"
            >
              <div className="text-sm font-bold">
                {momentDate(l, "ddd, DD MMM")}
              </div>
            </div>
          </li>
        ))}
    </ul>
  );
};

export default function WorkSchedule() {
  const [dayNav, setDayNav] = useState(null);
  const [delay, setDelay] = useState(false);
  const [selectTeamDisplay, setSelectTeamDisplay] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [clickedTeamUid, setClickedTeamUid] = useState(null);

  const { user_details } = useSelector((state) => state.profile);
  const { staffTeam, staffDetails } = useSelector((state) => state.staff);

  const [getWeekD, teamSchedules, teams, handleWeekNav, currentD, setCurrentD] =
    useGetCurrentTeam();

  const staffTeamId = user_details?.teamId;

  const getClickedTeam = staffTeam?.find((i) => i.id === clickedTeamUid);

  const getClickedTeamMembersUid = getClickedTeam?.memberUids;
  const getClickedTeamName = getClickedTeam?.teamName;

  const getCurrentTeamMemberRoles = (roles) =>
    roles && Object.entries(roles)?.some((i) => i[1] === true)
      ? Object.entries(roles)
          ?.filter((item) => item[1])
          ?.reduce((acc, item, index, arr) => {
            if (item[1]) {
              acc += `${startCase(item[0])}${
                arr?.length - 1 !== index ? " | " : ""
              }`;
            }
            return acc;
          }, "")
      : "No roles";

  const getCurrentTeamMembersDetails =
    getClickedTeamMembersUid &&
    staffDetails.filter((item) => getClickedTeamMembersUid.includes(item?.id));

  const teamColors = {
    sephora: "bg-pink-900/20",
    amazon: "bg-emerald-900/20",
    itunes: "bg-sky-900/20",
  };

  useEffect(() => {
    setTimeout(() => {
      setDelay(true);
    }, 500);
    setDelay(false);
  }, [dayNav]);

  return (
    <>
      {listP?.includes(user_details?.position) && (
        <SectionContent>
          <div className="rounded bg-box p-4 shadow-card-box dark:bg-gray-800">
            <div className="flex flex-row items-center justify-between">
              <h4 className="mb-2 text-lg text-primary dark:text-light-primary">
                Work Schedule - Customer Care
              </h4>
              {user_details?.position === "Customer Care Agent" && (
                <label className="flex inline-flex cursor-pointer flex-row items-center">
                  <span className="mr-2 text-sm font-bold dark:text-white">
                    Team Only
                  </span>
                  <Switch
                    checked={selectTeamDisplay}
                    onChange={() => {
                      setSelectTeamDisplay(!selectTeamDisplay);
                    }}
                  />
                  <span className="ml-2 text-sm font-bold dark:text-white">
                    All Teams
                  </span>
                </label>
              )}
            </div>
            <hr className="mb-4 border-t dark:border-gray-500" />
            <div className="mb-4 w-full">
              <div className="flex w-full items-center justify-between">
                <button
                  className="flex h-8 w-8 flex-row items-center justify-center rounded-full border-0 bg-primary outline-0"
                  onClick={() => {
                    handleWeekNav("subtract");
                    setDayNav(currentD);
                  }}
                >
                  <SimpleIcon
                    icon="KeyboardArrowLeft"
                    frontColor="text-white"
                  />
                </button>

                <button
                  className="flex h-8 w-8 flex-row items-center justify-center rounded-full bg-primary"
                  onClick={() => {
                    handleWeekNav("add");
                    setDayNav(currentD);
                  }}
                >
                  <SimpleIcon
                    icon="KeyboardArrowRight"
                    frontColor="text-white"
                  />
                </button>
              </div>
            </div>

            <div className="relative flex flex-col divide-y divide-gray-200 rounded border dark:divide-gray-600 dark:border-gray-600 dark:text-white">
              <div className="flex flex-row items-center divide-x divide-gray-200 dark:divide-gray-600">
                <div className="w-[150px] px-4">{""}</div>

                <WeekTab
                  {...{
                    getWeekD,
                    setCurrentD,
                  }}
                />
              </div>

              {[
                {
                  name: "Morning Shift",
                  id: "morning_schedule",
                  idKey: 0,
                },
                {
                  name: "Night Shift",
                  id: "evening_schedule",
                  idKey: 1,
                },
                {
                  name: "Off Shift",
                  id: "off_schedule",
                  idKey: -1,
                },
              ].map((item, ind) => (
                <div
                  className="flex flex-row items-center divide-x divide-gray-200 dark:divide-gray-600"
                  key={item?.id}
                >
                  <div className="w-[150px] px-4 font-bold">{item?.name}</div>
                  <div className="flex w-full flex-row items-center justify-between divide-x divide-gray-200 dark:divide-gray-600">
                    {getWeekD?.map((itemA, index) => {
                      const teamS = teamSchedules?.[index];
                      const teamSId = teamS?.[item?.idKey];
                      const teamSName = Object?.values(teams || {})?.find(
                        (i) => i?.id === teamSId
                      )?.name;

                      const isDisplay =
                        (staffTeamId === teamSId && !selectTeamDisplay) ||
                        selectTeamDisplay ||
                        user_details?.position !== "Customer Care Agent";
                      return (
                        <button
                          key={index}
                          className={classNames(
                            "h-full w-full flex-1 break-all py-8 text-center text-sm",
                            teamColors?.[toLower(teamSName)],
                            momentDate(new Date(), "YYYY/MM/DD") === itemA
                              ? "!border-x-2 !border-primary"
                              : "",
                            momentDate(new Date(), "YYYY/MM/DD") === itemA &&
                              ind === 2
                              ? "!border-b-2"
                              : "",
                            !isDisplay ? "pointer-events-none" : ""
                          )}
                          onClick={() => {
                            if (isDisplay) setOpenDrawer(true);
                            setClickedTeamUid(teamSId);
                          }}
                        >
                          {isDisplay ? (
                            <>
                              Team
                              <br /> {capitalize(teamSName)}
                            </>
                          ) : (
                            <>
                              &nbsp;
                              <br /> &nbsp;
                            </>
                          )}
                        </button>
                      );
                    })}
                  </div>
                </div>
              ))}

              {!delay && (
                <div className="absolute h-full w-full rounded border border-slate-100 bg-box p-0 shadow-sm dark:border-slate-700 dark:bg-gray-700">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </SectionContent>
      )}
      <DrawerSide {...{ open: openDrawer, setOpen: setOpenDrawer }}>
        <div className="p-4">
          <h5 className="mb-4 inline-flex items-center text-base font-semibold text-gray-500 dark:text-gray-400">
            Team {capitalize(getClickedTeamName)}
          </h5>
          <div>
            <div className="flow-root">
              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {getCurrentTeamMembersDetails &&
                  getCurrentTeamMembersDetails?.map((item) => (
                    <li className="flex flex-row py-4 dark:text-white">
                      <img
                        className="h-8 w-8 rounded-full"
                        src={require(`assets/avatar/avatar-${
                          toLower(item?.gender) || "female"
                        }.png`)}
                        alt={item.displayName}
                      />
                      <div className="pl-3">
                        <div className="text-base font-semibold">
                          {item?.displayName}
                        </div>
                        <div className="font-normal text-gray-400">
                          {toLower(item?.email)}
                        </div>
                        <div className="mt-2 font-normal text-gray-200">
                          {getCurrentTeamMemberRoles(item?.roles)}
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </DrawerSide>
    </>
  );
}

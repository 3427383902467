import { useEffect } from "react";
import { useDispatch, useStore } from "react-redux";
import * as i from "../lib";

import {
  getBuyCardFac,
  getCardFac,
  getCardProps,
  getCards,
} from "redux/reducers/giftcardReducers";

const auth = i.getAuth();

export const useGiftcardRate = () => {
  const dispatch = useDispatch();
  const { user_details } = useStore().getState().profile || {};

  useEffect(() => {
    let unsub;
    // && user_details?.roles?.giftcard
    if (auth?.currentUser) {
      try {
        const q = i.query(i.collection(i.db2, "giftcardRate1"));

        unsub = i.onSnapshot(q, (querySnapshot) => {
          const t = [];
          querySnapshot.forEach((doc) => {
            t.push({ id: doc.id, ...doc.data() });
          });

          dispatch(getCards(t));
        });
      } catch (error) {
        dispatch(getCards(null));
      }
    } else {
      dispatch(getCards(null));
    }
    return unsub;
  }, [dispatch, auth?.currentUser, user_details]);
};

export const useGiftcardRateFac = () => {
  const dispatch = useDispatch();

  const { user_details } = useStore().getState().profile || {};

  useEffect(() => {
    let unsubSellFac = () => {};
    let unsubBuyFac = () => {};
    let unsubGiftCard = () => {};
    if (auth?.currentUser) {
      try {
        const qSellCardFac = i.doc(i.db2, "giftcardRateFactors", "sellFactors");
        const qBuyCardFac = i.doc(i.db2, "giftcardRateFactors", "buyFactors");
        const qGiftcard = i.doc(i.db2, "utils", "giftcard");

        unsubSellFac = i.onSnapshot(qSellCardFac, (doc) => {
          dispatch(getCardFac(doc.data()));
        });

        unsubBuyFac = i.onSnapshot(qBuyCardFac, (doc) => {
          dispatch(getBuyCardFac(doc.data()));
        });

        unsubGiftCard = i.onSnapshot(qGiftcard, (doc) => {
          dispatch(getCardProps(doc.data()));
        });
      } catch (error) {
        dispatch(getCardFac(null));
      }
    } else {
      dispatch(getCardFac(null));
    }
    return () => {
      unsubSellFac();
      unsubBuyFac();
      unsubGiftCard();
    };
  }, [dispatch, auth?.currentUser, user_details]);
};

export const useGiftcardList = () => {
  const dispatch = useDispatch();

  const { user_details } = useStore().getState().profile || {};

  useEffect(() => {
    let unsub;
    if (auth?.currentUser && user_details?.roles?.giftcard) {
      try {
        const q = i.doc(i.db, "utils", "giftcard");

        unsub = i.onSnapshot(q, (doc) => {
          dispatch(getCardFac(doc.data()));
        });
      } catch (error) {
        dispatch(getCardFac(null));
      }
    }
    return unsub;
  }, [dispatch, auth?.currentUser, user_details]);
};

export const useAdd = async () => {
  // const docRef = i.doc(i.db, "utils", "giftcard");
  // const docSnap = await i.getDoc(docRef);
  // if (docSnap.exists()) {
  //   const dd = docSnap.data();
  //   await i.setDoc(i.doc(i.db2, "utils", "giftcard"), dd, { merge: true });
  //   console.log("succesffuly");
  // } else {
  //   console.log("No such document!");
  // }
  // const docRef = i.doc(i.db2, "giftcardRate1", "ntfB0dxwhPodEDse7AM3");
  // const docSnap = await i.getDoc(docRef);
  // if (docSnap.exists()) {
  //   const dd = docSnap.data();
  //   const { CHE, SGP } = dd?.rate;
  //   await i.updateDoc(i.doc(i.db2, "giftcardRate1", "ntfB0dxwhPodEDse7AM3"), {
  //     "rate.CHF": CHE,
  //     // "rate.SGD": SGP,
  //   });
  //   console.log("completed");
  // } else {
  //   console.log("No such document!");
  // }
};

// export const addUtil = async ({ giftcardList, currencyList, typeList }) => {
//   await i.setDoc(i.doc(i.db, "utils", "giftcard"), {
//     giftcardList,
//     currencyList,
//     typeList,
//   });
//   console.log("{ giftcardList, currencyList, typeList }");
// };

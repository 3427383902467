import { FullWidthTabs } from "components";
import KaapoMetaCallToAction from "pages/Marketing/components/MetaCallToAction/KaapoMetaCallToAction";
import EsetechMetaCallToAction from "pages/Marketing/components/MetaCallToAction/EsetechMetaCallToAction";
import React, { useState } from "react";

const CTA = () => {
  const tabList = [
    {
      label: "Esetech",
      Comp: EsetechMetaCallToAction,
    },
    {
      label: "Kaapo",
      Comp: KaapoMetaCallToAction,
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabList[0]);

  return (
    <FullWidthTabs
      {...{ currentTab, setCurrentTab, tabList }}
      Comp={<currentTab.Comp {...currentTab} />}
    />
  );
};

export default CTA;

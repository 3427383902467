import React, { useState } from "react";
import { SimpleIcon } from "components";
import { classNames } from "utils";

export default function SettingsDrawer({
  tabList,
  selectedIndex,
  setSelectedIndex,
}) {
  const d = {
    0: "top-[0]",
    1: "top-[80px]",
    2: "top-[160px]",
    3: "top-[240px]",
    4: "top-[320px]",
    5: "top-[400px]",
    6: "top-[480px]",
    7: "top-[560px]",
    8: "top-[640px]",
  };

  return (
    <div className="relative h-full w-[260px] border-r border-slate-200 bg-white dark:border-gray-500 dark:bg-gray-900">
      <div className="z-1 absolute left-0 h-full w-full">
        <div
          className={classNames(
            d[selectedIndex],
            "absolute left-0 flex h-[80px] w-full justify-end duration-500"
          )}
        >
          <div className="h-full w-1 rounded-sm bg-gradient-to-b from-dark/60 to-dark shadow-md shadow-dark/30" />
        </div>
      </div>

      {tabList.map((item, index) => {
        const cur = selectedIndex === index;
        const curTextCol = cur
          ? "text-dark dark:text-light-primary"
          : "text-gray-600 dark:text-gray-300";
        return (
          <div key={item.label} className="nav-list h-[80px] w-[260px]">
            <div className="z-2 relative flex h-full w-full items-center px-2">
              <button
                className={classNames(
                  "relative flex w-full flex-row items-center justify-between rounded border-0 p-2 outline-none duration-500"
                )}
                onClick={() => {
                  setSelectedIndex(index);
                }}
              >
                <div className="mr-2 flex flex-col items-start justify-between">
                  <div className={classNames(curTextCol, "font-bold")}>
                    {item.label}
                  </div>
                  <div className="flex flex-col text-left">
                    <span
                      className={classNames("mt-1 text-sm dark:text-gray-500")}
                    >
                      {item.subtitle}
                    </span>
                  </div>
                </div>
                <div
                  className={classNames(
                    "flex h-6 w-6 items-center justify-center rounded-full bg-slate-50 shadow-md duration-500 dark:bg-gray-700"
                  )}
                >
                  <SimpleIcon
                    fontSize="small"
                    icon={"KeyboardArrowRightOutlined"}
                    frontColor={curTextCol}
                  />
                </div>
              </button>
            </div>
            <div className="z-0 border-b border-slate-200 dark:border-gray-500" />
          </div>
        );
      })}
    </div>
  );
}

import {
  Divider,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  gridVisibleSortedRowIdsSelector,
} from "@mui/x-data-grid";
import React, { Fragment, useEffect, useState } from "react";

import { classNames } from "utils";
import { IconBox } from "./Icon";

const CustomPagination = ({ setPage, count, page }) => {
  const handleChangePage = (event, newPage) => {
    setPage(parseInt(newPage) - 1);
  };

  return (
    <div className="flex items-center justify-center">
      <Pagination
        // variant="outlined"
        // shape="rounded"
        color="primary"
        count={count}
        page={page + 1}
        onChange={handleChangePage}
      />
    </div>
  );
};

export function TableData2({
  columns,
  rows,
  hideFooter,
  onRowClick,
  onRowHover,
  isLoading,
}) {
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = React.useState(0);

  const pageCount = rows?.length / pageSize;

  const handleRowOver = (e) => {
    const rowId = Number(e.currentTarget.dataset.id);
    const row = rows.find((el) => el.id === rowId);
    // console.log(rowId, row);
  };

  return (
    <div style={{}}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          classes={{
            root: "!text-[15px]",
            cell: "!outline-none",
            row: "cursor-pointer",
            columnHeader: "!outline-none !text-center !text-white",
            columnHeaders: "border-none !bg-primary !text-white",
            menuIconButton: "!text-white",
            sortIcon: "!text-white",
            footerContainer: "!text-gray-500",
            main: isLoading ? "!bg-gray-200" : "",
          }}
          columns={columns}
          rows={rows}
          {...(!hideFooter && {
            pageSize,
            page,
            onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
            rowsPerPageOptions: [10, 20, 50],
            pagination: true,
            hideFooterSelectedRowCount: true,
          })}
          hideFooter={pageCount > 1 ? hideFooter : true}
          autoHeight
          loading={isLoading}
          getRowHeight={() => 60}
          initialState={{ pinnedColumns: { right: ["action"] } }}
          onCellClick={(params, event) => {
            onRowClick(params.id, params.field);
          }}
          onRowMouseEnter={() => console.log("memme")}
          components={{
            ...(pageCount > 1
              ? {
                  Pagination: () => (
                    <CustomPagination
                      {...{ setPage, page }}
                      count={pageCount}
                    />
                  ),
                }
              : {
                  NoRowsOverlay: () => (
                    <div className="flex flex-col items-center justify-center h-full mt-0 text-sm text-gray-400">
                      <IconBox icon="DeveloperBoardOff" gradient />
                      <div className="mt-4 font-bold">No data to display</div>
                    </div>
                  ),
                }),
          }}
          componentsProps={{
            row: {
              onMouseEnter: (e) =>
                onRowHover(Number(e.currentTarget.dataset.id)),
              onMouseLeave: () => onRowHover(null),
            },
          }}
        />
      </div>
    </div>
  );
}

export function TableData({
  columns,
  rows,
  hideFooter,
  onRowClick,
  coloredHeader,
  defaultMsg,
  useField,
  noPageSize,
  displayExport,
  rowHeight = 58,
}) {
  const [pageSize, setPageSize] = useState(20);
  const [visibleRows, setVisibleRows] = useState(rows?.length);
  const [page, setPage] = useState(0);

  const pageCount = Math.ceil(rows?.length / pageSize);

  useEffect(() => {
    setPage(0);
  }, [visibleRows]);

  return (
    // height: "100%"
    <div style={{ display: "" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          onStateChange={(state) => {
            const newRows = gridVisibleSortedRowIdsSelector(state);
            setVisibleRows(newRows?.length);
          }}
          sx={[
            {
              "& .MuiDataGrid-cell": {
                borderColor: "rgba(224, 224, 224, 0.2) !important",
              },
            },
          ]}
          classes={{
            root: "!bg-white !rounded-lg !border-0 !text-base dark:!bg-gray-800",
            // !shadow-md
            main: "!rounded-lg",
            cell: "!outline-none !text-gray-500 dark:!text-gray-200 dark:!border-gray-600 !border-b",
            row: "!cursor-pointer dark:hover:bg-gray-600 dark:!border-gray-600",
            columnHeader:
              "!outline-none !text-center uppercase !h-[32px] !max-h-[32px] dark:!text-white",
            columnHeaders: coloredHeader
              ? "bg-primary text- !font-semibold"
              : "border-none !bg-gray-50 dark:!bg-gray-700 !text-sm !uppercase ! dark:!text-gray-400 dark:!border-gray-600 !font-semibold",
            sortIcon: coloredHeader ? "!text-white" : "dark:!text-white",
            menuIconButton: coloredHeader ? "!text-white" : "dark:!text-white",
            footerContainer:
              "!text-gray-500 !border-0 !outline-0 dark:!text-white",
          }}
          columns={columns}
          rows={rows}
          {...(!noPageSize && { pageSize, page })}
          // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          // rowsPerPageOptions={[10, 20, 50]}
          // pagination
          hideFooterSelectedRowCount
          hideFooter={pageCount > 1 ? hideFooter : true}
          autoHeight
          getRowHeight={() => rowHeight}
          initialState={{ pinnedColumns: { right: ["action"] } }}
          {...(useField
            ? {
                onCellClick: (params, event) => {
                  if (onRowClick) onRowClick(params.id, params.field);
                },
              }
            : {
                onRowClick: (params) => {
                  const { id, collection, platform, product } = params?.row;

                  if (onRowClick)
                    onRowClick({
                      docId: id,
                      collection,
                      collectionTo: collection,
                      type: platform,
                      id: product,
                    });
                },
              })}
          components={{
            ...(displayExport && {
              Toolbar: GridToolbar,
            }),

            ...(pageCount > 1
              ? {
                  Pagination: () => {
                    return (
                      <CustomPagination
                        {...{ setPage, page }}
                        // count={pageCount}
                        count={Math.ceil(visibleRows / pageSize)}
                      />
                    );
                  },
                }
              : {
                  NoRowsOverlay: () => (
                    <div className="flex flex-col items-center justify-center h-full mt-0 text-sm text-gray-400">
                      <IconBox icon={"DeveloperBoardOff"} gradient />
                      <div className="mt-4 font-semibold">
                        {defaultMsg || "No data to display"}
                      </div>
                    </div>
                  ),
                }),
          }}
          componentsProps={{
            toolbar: {
              csvOptions: {
                allColumns: true,
                fileName: `transactions_${new Date().toISOString()}`,
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export function SimpleTable({
  columns,
  rows,
  caption,
  disableColumns = false,
  handleClick,
}) {
  return (
    <div className="w-full overflow-auto rounded">
      {/* minWidth: 650 */}
      <TableContainer sx={{ maxHeight: 440 }}>
        {caption && (
          <div className="caption">
            <div className="p-5 text-[15px] text-gray-400">{caption}</div>
            <Divider />
          </div>
        )}
        <Table stickyHeader aria-label="sticky table">
          {!disableColumns && (
            <TableHead>
              <TableRow>
                {columns?.length &&
                  columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      classes={{
                        root: "dark:!bg-transparent dark:!text-white !text-sm !border-b dark:!border-gray-700",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {rows?.length
              ? rows.map((row, indexR, arrR) => {
                  return (
                    <TableRow
                      {...(handleClick && {
                        onClick: () => handleClick(row),
                        className: "cursor-pointer",
                      })}
                      key={indexR}
                    >
                      {columns.map((column, index, arrC) => {
                        const value = row[column.id];

                        return (
                          <TableCell
                            key={index}
                            align={column.align}
                            classes={{
                              root: classNames(
                                "!text-sm dark:!text-white",
                                indexR !== arrR?.length - 1
                                  ? "!border-b dark:!border-gray-700"
                                  : "!border-b-0"
                              ),
                            }}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export function SimpleTableDensed({
  columns,
  rows,
  caption,
  disableColumns = false,
}) {
  return (
    <div className="w-full overflow-auto rounded">
      <TableContainer>
        <Table sx={{ maxHeight: 440 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                classes={{
                  root: "dark:!bg-transparent dark:!text-white !text-sm !border-b dark:!border-gray-700",
                }}
              >
                {columns?.headerColumn?.label}
              </TableCell>
              {columns?.data?.length &&
                columns?.data?.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    classes={{
                      root: "dark:!bg-transparent dark:!text-white !text-sm !border-b dark:!border-gray-700",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length &&
              rows.map((row, indexR, arrR) => (
                <Fragment key={indexR}>
                  {row.data?.map((rowData, indexD) => {
                    return (
                      <TableRow key={indexD}>
                        {indexD === 0 && (
                          <TableCell
                            rowSpan={row.data?.length}
                            classes={{
                              root: classNames(
                                "!text-sm dark:!text-white",
                                indexR !== arrR?.length - 1
                                  ? "!border-b dark:!border-gray-700"
                                  : "!border-b-0"
                              ),
                            }}
                          >
                            {row?.label}
                          </TableCell>
                        )}

                        {columns?.data?.map((column, index) => {
                          const value = rowData?.[column.id];

                          return (
                            <TableCell
                              key={index}
                              align={column.align}
                              classes={{
                                root: classNames(
                                  "!text-sm dark:!text-white",
                                  indexR !== arrR?.length - 1
                                    ? "!border-b dark:!border-gray-700"
                                    : "!border-b-0"
                                ),
                              }}
                            >
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

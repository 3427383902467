import * as React from "react";
import {
  Dialog as MuiDialog,
  AppBar,
  Toolbar,
  IconButton,
  DialogTitle,
  Paper,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Backdrop as MuiBackdrop,
  CircularProgress,
} from "@mui/material";
import {
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import { Popover as HeadLessPopover } from "@headlessui/react";

import { Slide as SlideRR } from "react-reveal";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import Slide from "@mui/material/Slide";
import { Button } from ".";
import { classNames } from "utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export function Dialog(props) {
  const {
    open,
    setOpen,
    title,
    children,
    fullScreen,
    closeBtn,
    showHeader,
    maxWidth = "sm",
    onClose,
    AppBarButtonComponent,
    disableCloseOnOpen,
  } = props;

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <div className="relative">
      <MuiDialog
        open={open}
        {...(!disableCloseOnOpen && { onClose: handleClose })}
        // onClose={handleClose}
        fullWidth
        TransitionComponent={Transition}
        {...(!fullScreen
          ? {
              scroll: "body",
              "aria-labelledby": "scroll-dialog-title",
              "aria-describedby": "scroll-dialog-description",
              maxWidth,
              PaperComponent: PaperComponent,
              "aria-labelledby": "draggable-dialog-title",
            }
          : { fullScreen })}
        classes={{
          root: "!z-[2500]",
          paper: "dark:!bg-gray-900",
        }}
      >
        {fullScreen || showHeader ? (
          <AppBar
            position="fixed"
            classes={{
              root: "!sticky dark:!bg-dark-primary",
            }}
          >
            <Toolbar
              classes={{
                //
                root: "!min-h-[48px] justify-between items-center h-full",
              }}
            >
              <DialogTitle
                classes={{
                  root: "!font-normal text-center !text-base",
                }}
              >
                {title}
              </DialogTitle>
              <div className="flex flex-row items-center gap-x-4">
                {AppBarButtonComponent && AppBarButtonComponent()}
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        ) : (
          <>
            {title && (
              <DialogTitle
                classes={{
                  root: "!font-normal text-center !text-base cursor-move",
                }}
                id="draggable-dialog-title"
              >
                {title}
              </DialogTitle>
            )}
            {closeBtn && (
              <button
                className="m-3 flex w-12 items-center justify-center rounded bg-color-main p-2 text-white"
                onClick={() => setOpen(false)}
              >
                <CloseIcon />
              </button>
            )}
          </>
        )}
        {children}
      </MuiDialog>
    </div>
  );
}

export function Accordion({ title, content }) {
  return (
    <MuiAccordion
      elevation={0}
      square
      classes={{
        root: "border border-gray-400 rounded dark:!bg-gray-800 !min-w-[230px] dark:border-gray-500",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon classes={{ root: "dark:!text-white" }} />}
        aria-controls="panel-content"
        // id="panel1a-header"
      >
        <div className="text-sm text-gray-500 dark:text-white">{title}</div>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </MuiAccordion>
  );
}

export function Backdrop({ open, setOpen }) {
  return (
    <div>
      <MuiBackdrop
        sx={{ color: "#fff" }}
        classes={{ root: "z-100 relative" }}
        open={open}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </MuiBackdrop>
    </div>
  );
}

export function BackdropSection({ open }) {
  return (
    <>
      {open && (
        // bg-white dark:bg-gray-800
        <div className="absolute top-0 left-0 z-[40] h-full w-full rounded-lg dark:bg-gray-800">
          <div className="flex h-full w-full items-center justify-center">
            <CircularProgress color="success" size={30} />
          </div>
        </div>
      )}
    </>
  );
}

export function ScrollDialog({ open, setOpen, mainWidth, children }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      classes={{
        root: classNames(mainWidth, "!h-full !z-[900] duration-500"),
        paper:
          "!m-0 !block !h-full !w-full !max-w-[100%] !max-h-[100%] !bg-transparent !shadow-none px-6",
      }}
      BackdropComponent={() => null}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <div className="!mt-28 h-full w-[100%] rounded bg-white shadow-dialog">
        <SlideRR up duration={300}>
          <div className="h-full overflow-hidden rounded bg-white p-2">
            {children}
          </div>
        </SlideRR>
      </div>
    </MuiDialog>
  );
}

export function Alert({
  openAlert,
  setOpenAlert,
  btnDetails = { btnText: "Yes", btnColor: "error" },
  title,
  isSubmitting,
  handleSubmit,
}) {
  return (
    <>
      {openAlert && (
        <>
          <div className="fixed top-0 right-0 z-[2200] h-full w-full bg-black/50 dark:bg-gray-700/30" />
          <div className="fixed top-[50%] right-[50%] z-[2300] translate-x-1/2 -translate-y-1/2">
            <SlideRR duration={300} down>
              <div className="w-[350px] rounded bg-white shadow-dialog dark:bg-gray-900">
                <div className="p-4">
                  <div className="mb-3 text-center dark:text-white">
                    {title}
                  </div>
                  <div className="flex flex-row items-center justify-end pt-4">
                    <div className="grid grid-cols-2 gap-2 ">
                      <div className="cols-1">
                        <Button
                          label="Cancel"
                          color="neutral"
                          variant="contained"
                          handleClick={() => setOpenAlert(false)}
                        />
                      </div>
                      <div className="cols-1">
                        <Button
                          label={btnDetails.btnText}
                          color={btnDetails.btnColor}
                          handleClick={handleSubmit}
                          isSubmitting={isSubmitting}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SlideRR>
          </div>
        </>
      )}
    </>
  );
}

export const PopOver = ({
  PopoverHandlerComponent,
  PopoverContentComponent,
  placement = "bottom",
  handler,
}) => {
  return (
    <>
      <Popover
        placement={placement}
        handler={handler}
        dismiss={{ enabled: true }}
      >
        <PopoverHandler>{PopoverHandlerComponent()}</PopoverHandler>
        <PopoverContent className="z-[2000] dark:border-slate-600 dark:bg-slate-800">
          {PopoverContentComponent()}
        </PopoverContent>
      </Popover>
    </>
  );
};

export const PopoverAlt = ({
  PopoverHandlerComponent,
  PopoverContentComponent,
  placement = "bottom",
}) => {
  return (
    <HeadLessPopover className="relative">
      <HeadLessPopover.Button className="border-none outline-none">
        {PopoverHandlerComponent()}
      </HeadLessPopover.Button>

      <HeadLessPopover.Panel className="absolute z-[2100] mt-2 rounded border p-2 shadow-dialog duration-500 dark:border-gray-600 dark:border-slate-600 dark:bg-slate-800">
        {({ close }) => <>{PopoverContentComponent(close)}</>}
      </HeadLessPopover.Panel>
    </HeadLessPopover>
  );
};

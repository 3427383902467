import React, { useState, useEffect } from "react";
import { Button, DrawerSide, PopOver, SimpleIcon } from "components";
import { useSelector } from "react-redux";
import { difference, toLower } from "lodash";
import { useEditGiftcardCurrency, useEditGiftcardType } from "api";

export default function EditGiftcard({ open, setOpen, currentGiftcardId }) {
  const [pageDelay, setPageDelay] = useState(false);
  const [getCurrentCurr, setCurrentCurr] = useState(null);
  const [getCurrentType, setCurrentType] = useState(null);
  const [openPopover, setOpenPopover] = useState(true);
  const [currentCurrency, setCurrentCurrency] = useState(null);

  const { giftcards, giftcardProps } = useSelector((state) => state.giftcard);

  const currentGiftcard =
    giftcards && giftcards?.find((item) => item.id === currentGiftcardId);

  const { title, id } = currentGiftcard || {};

  const getCurr = currentGiftcard ? Object.keys(currentGiftcard?.rate) : null;
  const getTypes = currentGiftcard?.rate?.[getCurrentCurr]
    ? Object?.keys(currentGiftcard?.rate?.[getCurrentCurr])
    : null;

  const { currencyFlagList, typeList } = giftcardProps || [];
  const currencyList = Object?.keys(currencyFlagList || {});

  const currencyListDropdown = difference(currencyList, getCurr);
  const typeListDropdown = difference(typeList, getTypes);

  const [isUpdatingCurrency, isUpdatedCurrency, editGiftcardCurrency] =
    useEditGiftcardCurrency();

  const [isUpdatingType, isUpdatedType, editGiftcardType] =
    useEditGiftcardType();

  const handleEditCurrency = (item, type) => {
    editGiftcardCurrency({
      id,
      currency: item,
      type,
    });
  };

  const handleEditType = (currencyType, type) => {
    editGiftcardType({
      id,
      currency: getCurrentCurr,
      currencyType,
      type,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setPageDelay(true);
    }, 500);
    setPageDelay(false);
  }, [getCurrentCurr]);

  useEffect(() => {
    if (!open) {
      setPageDelay(false);
      setCurrentCurr(null);
      setCurrentType(null);
      setCurrentCurrency(null);
    }
  }, [open]);

  return (
    <DrawerSide {...{ open, setOpen }}>
      {currentGiftcard && currencyFlagList && (
        <div className="">
          <div className="border-b border-slate-100 p-4 dark:border-gray-500 dark:text-white">
            Edit Giftcard - {title}
          </div>
          <div className="p-4">
            <div className="grid grid-cols-5 gap-4">
              {/* Currency */}
              <div className="col-span-2">
                <div className="mb-4 border-b border-slate-100 p-2 text-sm dark:border-gray-500 dark:text-white">
                  Currency
                </div>
                <div className="w-full rounded border border-gray-700 bg-slate-200 p-0 dark:border-gray-500  dark:bg-gray-700">
                  <div className="flow-root">
                    <ul className="divide-y divide-gray-200 dark:divide-gray-500">
                      {getCurr?.length ? (
                        getCurr.map((item) => {
                          return (
                            <li
                              className="relative rounded p-2"
                              onMouseEnter={() => setCurrentCurrency(item)}
                              onMouseLeave={() => setCurrentCurrency(null)}
                            >
                              {item === getCurrentCurr && (
                                <div className="pointer-events-none absolute top-0 left-0 flex h-full w-full flex-row items-center justify-center bg-gray-100/20" />
                              )}

                              {currentCurrency === item && (
                                <button
                                  className="absolute top-4 right-4 duration-500 ease-in"
                                  onClick={() => {
                                    handleEditCurrency(item, "delete");
                                  }}
                                >
                                  <SimpleIcon
                                    icon="Cancel"
                                    fontSize={20}
                                    frontColor="text-red-500"
                                  />
                                </button>
                              )}

                              <button
                                className="flex h-full w-full items-center space-x-4 bg-slate-50 py-3 px-2 text-left dark:bg-gray-900"
                                onClick={() => {
                                  setCurrentCurr(item);
                                }}
                              >
                                <div className="truncate text-sm font-medium dark:text-white">
                                  {item}
                                </div>
                              </button>
                            </li>
                          );
                        })
                      ) : (
                        <li className="">
                          <div className="flex items-center py-3 px-2 dark:text-white">
                            No currency added
                          </div>
                        </li>
                      )}

                      {/* Currency Dropdown */}

                      <li className="">
                        <PopOver
                          open={openPopover}
                          setOpen={setOpenPopover}
                          PopoverHandlerComponent={() => (
                            <div
                              onClick={() => {
                                setOpenPopover(!openPopover);
                              }}
                            >
                              <Button
                                fullWidth
                                label="Add Currency"
                                isSubmitting={isUpdatingCurrency}
                                handleClick={() => {
                                  setOpenPopover(!openPopover);
                                }}
                                otherClass="!font-bold"
                              />
                            </div>
                          )}
                          PopoverContentComponent={() => (
                            <div className="min-w-[120px]">
                              <ul className="-my-3 -mx-1 divide-y divide-gray-200 overflow-auto dark:divide-gray-500">
                                {currencyListDropdown?.length ? (
                                  currencyListDropdown?.map((item) => (
                                    <li className="my-2 w-full">
                                      <button
                                        className="w-full rounded bg-box p-2 text-left dark:bg-gray-900"
                                        onClick={() =>
                                          handleEditCurrency(item, "add")
                                        }
                                      >
                                        <div className=" dark:text-gray-100">
                                          {item}
                                        </div>
                                      </button>
                                    </li>
                                  ))
                                ) : (
                                  <li className="my-0 w-full py-3 px-2">
                                    No data
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Types */}
              <div className="col-span-3">
                {getCurrentCurr && (
                  <div className="mb-4 border-b border-slate-100 p-2 text-sm dark:border-gray-500 dark:text-white">
                    Types - {getCurrentCurr}
                  </div>
                )}
                <div className="w-full rounded border border-gray-700 bg-slate-200 p-0 dark:border-gray-500  dark:bg-gray-700">
                  <div className="flow-root">
                    <ul className="divide-y divide-gray-200 dark:divide-gray-500 ">
                      {getTypes ? (
                        <>
                          {pageDelay ? (
                            <>
                              {getTypes?.length ? (
                                getTypes?.map((item) => {
                                  return (
                                    <li
                                      className="relative rounded p-2"
                                      onMouseEnter={() => setCurrentType(item)}
                                      onMouseLeave={() => setCurrentType(null)}
                                    >
                                      {item === getCurrentType && (
                                        <div className="pointer-events-none absolute top-0 left-0 flex h-full w-full flex-row items-center justify-center bg-gray-100/20" />
                                      )}

                                      {getCurrentType === item && (
                                        <button
                                          className="absolute top-4 right-4 duration-500 ease-in"
                                          onClick={() => {
                                            handleEditType(item, "delete");
                                          }}
                                        >
                                          <SimpleIcon
                                            icon="Cancel"
                                            fontSize={20}
                                            frontColor="text-red-500"
                                          />
                                        </button>
                                      )}

                                      <button
                                        className="flex h-full w-full items-center space-x-4 bg-slate-50 py-3 px-2 text-left dark:bg-gray-900 "
                                        // onClick={() => handleEditType()}
                                      >
                                        <div className="min-w-0 flex-1">
                                          <p className="truncate text-sm font-medium  dark:text-white">
                                            {item}
                                          </p>
                                        </div>
                                      </button>
                                    </li>
                                  );
                                })
                              ) : (
                                <li className="">
                                  <div className="flex items-center py-3 px-2 dark:text-gray-300">
                                    No types added
                                  </div>
                                </li>
                              )}
                            </>
                          ) : (
                            <li className="">
                              <div className="flex items-center p-2 dark:text-gray-300">
                                Loading ...
                              </div>
                            </li>
                          )}
                        </>
                      ) : (
                        <li className="">
                          <div className="flex items-center space-x-4 p-2 dark:text-gray-300">
                            Click to view
                          </div>
                        </li>
                      )}
                      {getTypes ? (
                        <li className="">
                          <PopOver
                            open={openPopover}
                            setOpen={setOpenPopover}
                            PopoverHandlerComponent={() => (
                              <div
                                onClick={() => {
                                  setOpenPopover(!openPopover);
                                }}
                              >
                                <Button
                                  fullWidth
                                  label="Add Type"
                                  isSubmitting={isUpdatingType}
                                  handleClick={() => {
                                    setOpenPopover(!openPopover);
                                  }}
                                />
                              </div>
                            )}
                            PopoverContentComponent={() => (
                              <div className="min-w-[120px]">
                                <ul className="-my-3 -mx-1 divide-y divide-gray-200 overflow-auto dark:divide-gray-500">
                                  {typeListDropdown?.length ? (
                                    typeListDropdown?.map((item) => (
                                      <li className="my-2 w-full">
                                        <button
                                          className="w-full rounded bg-box p-2 text-left dark:bg-gray-900"
                                          onClick={() =>
                                            handleEditType(item, "add")
                                          }
                                        >
                                          <div className=" dark:text-gray-100">
                                            {item}
                                          </div>
                                        </button>
                                      </li>
                                    ))
                                  ) : (
                                    <li className="my-0 w-full p-2 dark:text-white">
                                      No data
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          />
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </DrawerSide>
  );
}

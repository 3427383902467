import { capitalize, toLower } from "lodash";
import React, { useEffect } from "react";
import Button from "./Button";
import { Dialog } from "./Dialog";
import { TextField } from "./Input";

export default function PasswordDialog({
  title,
  open,
  index,
  setOpen,
  getField,
  setField,
  isSubmitting,
  isSubmitted,
  handleClick,
  buttonObj = {},
}) {
  const fields = Object.entries(getField || {});
  const fieldNulled = fields.reduce((acc, item) => {
    acc[item[0]] = "";
    return acc;
  }, {});

  useEffect(() => {
    setField(fieldNulled);
  }, [isSubmitted]);

  return (
    <Dialog
      title={title}
      id={index}
      showHeader
      open={open}
      setOpen={setOpen}
      disableCloseOnOpen
      onClose={() => {
        setField(fieldNulled);
      }}
      maxWidth="xs"
    >
      <div className="p-6">
        <div className="grid grid-cols-1 gap-6">
          {fields?.map((item) => {
            const name = item[0];
            const type = item[0] === "password" ? "password" : "text";
            const value = item[1];
            return (
              <div className="col-span-1">
                <TextField
                  label={capitalize(name)}
                  placeholder={`Input ${name}`}
                  value={value || ""}
                  type={type}
                  name={name}
                  handleChange={(e) =>
                    setField((prevS) => ({
                      ...prevS,
                      [name]: e.target.value,
                    }))
                  }
                />
              </div>
            );
          })}

          <div className="col-span-1">
            <Button
              isSubmitting={isSubmitting}
              handleClick={handleClick}
              label="Send"
              {...buttonObj}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

import { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import * as i from "../lib";
import { notification, trimName } from "utils";
import { getCurrentUser } from "redux/reducers/authReducers";
import { axiosCall } from "api/helpers";
import { isEmpty, omit } from "lodash";
import { registerStaffSchema, reinviteStaffSchema } from "utils/formSchema";

const auth = i.getAuth();
const auth2 = i.auth2;

const url = (endpoint) => `${i.baseURL_ET}/auth/${endpoint}`;

const teamEmail = "esetechdevteam@gmail.com";
const esetechEmailExt = "esetech.com.ng";
const kaapoEmailExt = "kaapo.ng";

export const useAuthStateChanged = () => {
  const dispatch = useDispatch();
  const { profile } = useStore().getState();

  useEffect(() => {
    const unsubscribe = i.onAuthStateChanged(auth, (user) => {
      if (user) {
        const { uid, displayName, email, metadata, phoneNumber, photoURL } =
          user;
        dispatch(
          getCurrentUser({
            current_user: uid,
            current_user_details: {
              uid,
              displayName,
              email,
              metadata,
              phoneNumber,
              photoURL,
            },
            isLoading: false,
            isLoaded: true,
          })
        );
      } else {
        dispatch(
          getCurrentUser({
            current_user: null,
            current_user_details: null,
            isLoading: false,
            isLoaded: true,
          })
        );
      }
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch, profile]);
};

const loginAuth = async (email, password, setIsLoading, setIsLoaded) => {
  setIsLoading(true);
  try {
    await i.setPersistence(auth, i.browserSessionPersistence);

    const [userCredential] = await Promise.all([
      i.signInWithEmailAndPassword(auth, email, password),
      i.signInWithEmailAndPassword(auth2, email, password),
    ]);

    const user = userCredential.user;

    await Promise.all([
      i.updateDoc(i.doc(i.db, "staff", user.uid), {
        lastLoginAt: i.serverTimestamp(),
      }),
      i.updateDoc(i.doc(i.db2, "staff", user.uid), {
        lastLoginAt: i.serverTimestamp(),
      }),
    ]);

    setIsLoading(false);
    setIsLoaded(true);
  } catch (error) {
    throw new Error(error.code);
  }
};

export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  // const navigate = useNavigate();

  const login = async (email, password) => {
    try {
      if (!email.includes(esetechEmailExt) && !email.includes(kaapoEmailExt)) {
        throw new Error("Reach out to the admin");
      }

      setIsLoaded(true);

      await loginAuth(email, password, setIsLoading, setIsLoaded);
    } catch (error) {
      console.log(error, "error");
      let message;
      if (error.response) {
        const messageRes = error.response?.data?.error;
        switch (messageRes) {
          case "There is no user record corresponding to the provided identifier.":
            message = "User does not exist!";
            break;
          default:
            message = messageRes;
        }
      } else {
        // const { code } = error;
        const code = error?.code || error?.message;
        switch (code) {
          case "auth/too-many-requests":
            message =
              "Access to this account has been temporarily disabled due to many failed login attempts. Try again later.";
            break;
          case "auth/wrong-password":
            message = "Invalid email/password.";
            break;
          case "auth/user-not-found":
            message = "Invalid email/password.";
            break;
          default:
            message = "Unable to log in. Try again later.";
        }
      }
      // console.log(error, error.message, error.code);
      setIsLoading(false);
      setIsLoaded(true);
      setError(true);
      notification("danger", message, "error");
    }
  };

  return [isLoading, isLoaded, error, login];
};

export const useRegisterStaff = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(false);
    }, 500);
  }, [isLoaded]);

  const register = async (values) => {
    const { email, firstName, lastName } = values;

    const avatarUrl =
      "https://firebasestorage.googleapis.com/v0/b/kaapo-app.appspot.com/o/avatar_esetech.png?alt=media&token=21589ce3-5c9f-466b-934c-80f6db590af6";

    try {
      setIsLoading(true);
      if (isEmpty(values)) {
        throw new Error("Invalid Params");
      }
      await registerStaffSchema.validate(values);

      if (!email.includes(kaapoEmailExt)) {
        throw new Error("Reach out to the admin");
      }

      const concatNames = `${firstName} ${lastName}`;

      const valuesFormat = {
        ...omit(values, ["firstName", "lastName"]),
        displayName: trimName(concatNames),
        accountStatus: "enabled",
        disabled: false,
        status: "verified",
        type: "staff",
        avatarUrl,
      };

      const configRegisterUser = { values: valuesFormat };

      await axiosCall(url("register_staff"), configRegisterUser, true);

      setIsLoading(false);
      setIsLoaded(true);
      notification("success", "Staff Successfully invited!");
    } catch (error) {
      let message = JSON.stringify(error?.message || error);

      // console.log(error?.message, error);
      setIsLoading(false);
      setError(true);
      notification("danger", message, "error");
    }
  };

  return [isLoading, isLoaded, register];
};

export const useReInviteStaff = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(false);
    }, 500);
  }, [isLoaded]);

  const reInvite = async (values) => {
    const { email, newEmail } = values;

    try {
      setIsLoading(true);
      if (isEmpty(values)) {
        throw new Error("Invalid Params");
      }
      await reinviteStaffSchema.validate(values);

      if (!email.includes(kaapoEmailExt) && !newEmail.includes(kaapoEmailExt)) {
        throw new Error("Reach out to the admin");
      }

      const valuesFormat = {
        ...values,
      };

      const configRegisterUser = { values: valuesFormat };

      await axiosCall(url("reinvite_staff"), configRegisterUser, true);

      setIsLoading(false);
      setIsLoaded(true);
      notification("success", "Staff Successfully invited!");
    } catch (error) {
      let message = JSON.stringify(error?.message || error);

      //console.log(error?.message, error);
      setIsLoading(false);
      setError(true);
      notification("danger", message, "error");
    }
  };

  return [isLoading, isLoaded, reInvite];
};

export const useLogout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  const logout = async () => {
    setIsLoading(true);
    try {
      await Promise.all([i.signOut(auth), i.signOut(auth2)]);

      setIsLoaded(true);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setIsLoaded(true);
      setIsLoading(false);
    }
  };
  return [isLoading, isLoaded, error, logout];
};

export const sendVerifyOtp = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const valuesFormat = {
        email: teamEmail,
        username: "Dev Team",
        isVerify: false,
      };
      const config = { values: valuesFormat };

      await axiosCall(url("verify_email_otp"), config, true);

      return resolve(true);
    } catch (error) {
      let message = error?.message || error;

      //console.log(message);

      return reject(message);
    }
  });
};

export const checkVerifyOtp = (code) => {
  return new Promise(async (resolve, reject) => {
    try {
      const valuesFormat = {
        email: teamEmail,
        code,
        isVerify: false,
      };
      const config = { values: valuesFormat };

      await axiosCall(url("check_email_otp"), config, true);
      return resolve(true);
    } catch (error) {
      let message = error?.message || error;

      // console.log(error?.message, error);
      return reject(message);
    }
  });
};

import React, { useState } from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { Dialog, SimpleIcon } from ".";
import { classNames } from "utils";
// import ReactImageUploading from 'react-images-uploading';
import ImageUploading from "react-images-uploading";
import { downloadUploadedImage } from "api/helpers";

export function ImageGallery({ images, type, handleChange, className }) {
  const [imageList, setImageList] = useState(images);
  const [currentImage, setImage] = useState(null);
  const [openDialog, setDialog] = useState(false);

  const Icon = ({ type, ...otherProps }) => {
    return (
      <>
        {type === "view" && <GridViewIcon {...otherProps} />}
        {type === "remove" && <DeleteIcon {...otherProps} />}
        {type === "download" && <CloudUploadIcon {...otherProps} />}
      </>
    );
  };

  const handleClick = (image, type) => {
    if (type === "view") {
      setImage(image);
      setDialog(true);
    } else if (type === "remove") {
      const filterImg = imageList.filter((i) => i !== image);
      setImageList(filterImg);
      handleChange(filterImg);
    } else if (type === "download") {
      downloadUploadedImage(image);
    }
  };

  return (
    <div className={classNames(className ? className : "")}>
      {imageList?.map((image, index) => (
        <div key={index} className="relative h-16 w-16 rounded">
          <img
            src={image}
            alt={image}
            className="h-full w-full rounded object-cover"
          />
          <div className="absolute -top-2 -right-2">
            {type.map((t, ind) => (
              <button
                key={ind}
                className={classNames(
                  t === "view" ? "bg-blue-400" : "bg-red-400",
                  "flex h-6 w-6 items-center justify-center rounded-full text-white shadow"
                )}
                onClick={() => handleClick(image, t)}
              >
                <Icon type={t} sx={{ fontSize: 16 }} />
              </button>
            ))}
          </div>
        </div>
      ))}

      <Dialog open={openDialog} setOpen={setDialog} closeBtn>
        <div className="relative">
          <img
            src={currentImage}
            alt="image"
            className="h-full w-full object-cover"
          />
        </div>
      </Dialog>
    </div>
  );
}

export function ImageUpload({
  images,
  handleChange,
  className,
  multiple = true,
}) {
  return (
    <ImageUploading
      multiple={multiple}
      value={images}
      onChange={handleChange}
      maxNumber={10}
      dataURLKey="data_url"
      acceptType={["jpg", "jpeg", "png"]}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        <div className={classNames(className ? className : "")}>
          {images.map((image, index) => (
            <div key={index} className="relative m-2 h-20 w-20 rounded">
              <img
                src={image["data_url"]}
                alt={image["file"]["name"]}
                className="h-full w-full rounded-lg object-cover"
              />
              <div className="absolute -top-2 -right-2">
                <button
                  className="flex h-6 w-6 items-center justify-center rounded-full bg-red-500 text-white shadow-lg"
                  onClick={() => onImageRemove(index)}
                >
                  <SimpleIcon
                    icon="Close"
                    frontColor="text-white"
                    fontSize="small"
                  />
                </button>
              </div>
            </div>
          ))}
          <button
            className={classNames(
              isDragging ? "border-red-200" : "",
              "border-primary-200 m-2 h-20 w-20 rounded-lg border border-dashed bg-slate-50 p-3 text-center text-text-color dark:border-gray-500 dark:bg-gray-900"
            )}
            onClick={onImageUpload}
            {...dragProps}
          >
            <div className="flex flex-col items-center">
              <SimpleIcon icon="CloudUpload" otherClass="iconShadow-alt" />
            </div>
          </button>
        </div>
      )}
    </ImageUploading>
  );
}

// export function ImageUpload({ images, handleChange, className, multiple = true, }) {
//   return (
//     <ImageUploading
//       multiple={multiple}
//       value={images}
//       onChange={handleChange}
//       maxNumber={10}
//       dataURLKey="data_url"
//       acceptType={["jpg", "jpeg", "png"]}
//     >
//       {({
//         imageList,
//         onImageUpload,
//         onImageRemoveAll,
//         onImageUpdate,
//         onImageRemove,
//         isDragging,
//         dragProps,
//       }) => (
//         <div className={classNames(className ? className : "")}>
//           {images.map((image, index) => (
//             <div key={index} className="relative m-2 h-20 w-20 rounded">
//               <img
//                 src={image["data_url"]}
//                 alt={image["file"]["name"]}
//                 className="h-full w-full rounded-lg object-cover"
//               />
//               <div className="absolute -top-2 -right-2">
//                 <button
//                   className="flex h-6 w-6 items-center justify-center rounded-full bg-red-500 text-white shadow-lg"
//                   onClick={() => onImageRemove(index)}
//                 >
//                   <SimpleIcon
//                     icon="Close"
//                     frontColor="text-white"
//                     fontSize={16}
//                   />
//                 </button>
//               </div>
//             </div>
//           ))}
//           <button
//             className={classNames(
//               isDragging ? "border-red-200" : "",
//               "border-primary-200 m-2 h-20 w-20 rounded-lg border border-dashed p-3 text-center text-text-color"
//             )}
//             onClick={onImageUpload}
//             {...dragProps}
//           >
//             <div className="flex flex-col items-center">
//               <SimpleIcon icon="CloudUpload" frontColor="#333" />
//             </div>
//           </button>
//         </div>
//       )}
//     </ImageUploading>
//   );
// }

export function ImageUploadSingle({ images, handleChange, className }) {
  return (
    <ImageUploading
      value={images}
      onChange={handleChange}
      maxNumber={1}
      dataURLKey="data_url"
      acceptType={["jpg", "jpeg", "png"]}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        <div className="rounded bg-box p-2 dark:bg-gray-900">
          <button
            className={classNames(
              "relative flex h-20 w-full flex-row items-center justify-center rounded border-2 border-dashed bg-white shadow-sm dark:border-gray-500 dark:bg-gray-800",
              isDragging ? "border-red-500" : "",
              className
            )}
            {...dragProps}
            onClick={onImageUpload}
          >
            {images.length ? (
              <img
                src={images[0]["data_url"]}
                alt={images[0]["file"]["name"]}
                className="h-full w-full rounded-lg object-cover"
              />
            ) : (
              <SimpleIcon
                icon="AddCircleOutline"
                otherClass="iconShadow-alt"
                frontColor="text-gray-300"
                fontSize={40}
              />
            )}
          </button>
        </div>

        // <button
        //   className={classNames(
        //     "relative h-24 w-24 rounded-lg border border-dashed bg-white p-3 shadow-sm dark:border-gray-500 dark:bg-gray-800",
        //     isDragging ? "border-red-500" : "",
        //     className
        //   )}
        //   {...dragProps}
        //   onClick={onImageUpload}
        // >
        //   {images.length ? (
        //     <img
        //       src={images[0]["data_url"]}
        //       alt={images[0]["file"]["name"]}
        //       className="h-full w-full rounded-lg object-cover"
        //     />
        //   ) : (
        //     <div className="flex flex-col items-center dark:text-white">
        //       <CloudUploadIcon />
        //     </div>
        //   )}
        // </button>
      )}
    </ImageUploading>
  );
}

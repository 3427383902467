/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import Wobble from "react-reveal/Wobble";
import { Slide } from "react-reveal";
import { SimpleIcon } from "./Icon";
import Button from "./Button";
import { useSelector } from "react-redux";
import { useClearMessageNotification, useGetCurrentChatUser } from "api";
import { useLocation, useNavigate } from "react-router-dom";

export const MessageNotification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(false);

  const { chatNotification, currentChatId } = useSelector(
    (state) => state.chat
  );

  const { currentLocation } = useSelector((state) => state.util);

  const [clearMsgNotifaction] = useClearMessageNotification();
  const [getCurrentChat] = useGetCurrentChatUser();

  const notifString = [...Array(1)].reduce((acc, item) => {
    acc += `You have a new message. `;
    return acc;
  }, "");

  const alertSpeak = window.speechSynthesis;
  const msg = new SpeechSynthesisUtterance(notifString);
  const voices = alertSpeak.getVoices();
  msg.voice = voices[0];
  msg.lang = "en-US";

  const isNotify = chatNotification && location?.pathname !== "/chatbox";

  useEffect(() => {
    const isNotify = chatNotification && location?.pathname !== "/chatbox";
    if (isNotify) {
      if (!state) {
        alertSpeak.cancel();
        return;
      }

      alertSpeak.speak(msg);
    }
    return () => {
      alertSpeak.cancel();
    };
  }, [chatNotification, currentChatId, currentLocation]);

  const handleClose = () => {
    alertSpeak.cancel();
    setState(false);
  };

  useEffect(() => {
    const isNotify = chatNotification && location?.pathname !== "/chatbox";
    if (isNotify) {
      setState(true);
    } else {
      clearMsgNotifaction();
    }
  }, [chatNotification, currentChatId, currentLocation]);

  return (
    <>
      {state && chatNotification && isNotify && (
        <Draggable
          axis="both"
          handle=".messageModal"
          positionOffset={{ x: "0%", y: 0 }}
          position={null}
          grid={[25, 25]}
          scale={1}
        >
          <div className="messageModal fixed bottom-4 right-4 z-[2000]">
            <Slide right duration={500}>
              <div className="max-w-[350px] rounded bg-box shadow-dialog dark:bg-gray-900">
                <div className="relative flex flex-row items-center justify-between p-2">
                  <div className="">
                    <Wobble forever>
                      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white shadow-dialog dark:bg-slate-800">
                        <SimpleIcon
                          icon="QuestionAnswer"
                          frontColor="text-red-500"
                          otherClass="iconShadow-alt"
                        />
                      </div>
                    </Wobble>
                  </div>
                  <div className="">
                    <div className="relative flex h-full flex-col justify-start p-2 dark:text-white">
                      <div className="font-bold">New Message</div>
                      <div className="text-sm dark:text-gray-300">
                        You have a new message from{" "}
                        <span className="font-bold">
                          {chatNotification?.sender}
                        </span>
                        !
                      </div>
                    </div>

                    <div className="action-button flex flex-row items-center justify-end">
                      <div className="p-1">
                        <Button
                          fullWidth
                          label={"chat"}
                          size="small"
                          handleClick={() => {
                            getCurrentChat(
                              {
                                ...chatNotification?.otherUserDetails,
                                participantsList:
                                  chatNotification?.participantsList,
                              },
                              chatNotification?.chatId
                            );
                            navigate("/chatbox");
                            handleClose();
                          }}
                        />
                      </div>

                      <div className="p-1">
                        <Button
                          fullWidth
                          size="small"
                          label="Dismiss"
                          color="default"
                          handleClick={handleClose}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
        </Draggable>
      )}
    </>
  );
};

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import * as i from "../../../../api/lib";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Bar, Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { DotLoader } from "components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ReferredSignups = () => {
  const [totalSignups, setTotalSignups] = useState("");
  const [totalEsetechSignups, setTotalEsetechSignups] = useState("");
  const [totalKaapoSignups, setTotalKaapoSignups] = useState();
  const [esetechMostRefferredFrom, setEsetechMostRefferredFrom] = useState("");
  const [esetechMostRefferredFromCount, setEsetechMostRefferredFromCount] =
    useState("");
  const [kaapoMostRefferredFrom, setKaapoMostRefferredFrom] = useState("");
  const [kaapoMostRefferredFromCount, setKaapoMostRefferredFromCount] =
    useState("");
  const { totalEsetech, totalKaapo } = useSelector((state) => state.marketing);
  const [traded, setTraded] = useState();
  const [refnCountEsetech, setRefnCountEsetech] = useState([]);
  const [refnCountKaapo, setRefnCountKaapo] = useState([]);
  const [esetechOneWeek, setEsetechaapoOneWeek] = useState("");
  const [kaapoOneWeek, setKaapoOneWeek] = useState("");

  const [esetechTwoWeeksAgo, setEsetechaapoTwoWeeksAgo] = useState("");
  const [kaapoTwoWeeksAgo, setKaapoTwoWeeksAgo] = useState("");

  const oneWeek = dayjs().subtract(1, "week");
  const milliseconds = oneWeek.valueOf();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timestamp = new Date(milliseconds);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const oneWeekAgoTimestamp = new Date(timestamp);
  oneWeekAgoTimestamp.setDate(oneWeekAgoTimestamp.getDate() - 7);

  useEffect(() => {
    const referrerCount = {};
    const kaapoRefCount = {};

    const aWeekQueryEsetech = i.query(
      i.collection(i.db, "userMarketingReferrals"),
      i.where("createdAt", ">=", timestamp)
    );

    const aTwoWeeksAgoQueryEsetech = i.query(
      i.collection(i.db, "userMarketingReferrals"),
      i.where("createdAt", ">=", oneWeekAgoTimestamp),
      i.where("createdAt", "<", timestamp)
    );

    const qE = i.query(i.collection(i.db, "userMarketingReferrals"));

    const getMarketingRef = async () => {
      const signupswithinaweekEsetech = await i.getDocs(aWeekQueryEsetech);
      const signupsOneWeekFromLastWeek = await i.getDocs(
        aTwoWeeksAgoQueryEsetech
      );

      setEsetechaapoOneWeek(signupswithinaweekEsetech.size);
      setEsetechaapoTwoWeeksAgo(signupsOneWeekFromLastWeek.size);

      const marketingRef = await i.getDocs(qE);
      setTotalEsetechSignups(marketingRef.size);

      marketingRef.forEach((doc) => {
        const referrer = doc.data().referredFrom;
        if (referrerCount[referrer]) {
          referrerCount[referrer] += 1;
        } else {
          referrerCount[referrer] = 1;
        }
      });

      const sortedReferrers = Object.entries(referrerCount).sort(
        (a, b) => b[1] - a[1]
      );

      let keyValuePairs = sortedReferrers.map(function (subArray) {
        return { [subArray[0]]: subArray[1] };
      });

      let keyValueObject = keyValuePairs.reduce(function (
        accumulator,
        currentValue
      ) {
        return Object.assign(accumulator, currentValue);
      },
      {});

      const referralMedium = Object.entries(keyValueObject).map(
        ([key, value], index) => {
          return key + " " + value;
        }
      );

      setRefnCountEsetech(referralMedium);

      setEsetechMostRefferredFrom(sortedReferrers[0][0]);
      setEsetechMostRefferredFromCount(sortedReferrers[0][1]);
    };

    const aWeekQueryKaapo = i.query(
      i.collection(i.db2, "userMarketingReferrals"),
      i.where("createdAt", ">=", timestamp)
    );

    const aTwoWeeksAgoQueryKaapo = i.query(
      i.collection(i.db2, "userMarketingReferrals"),
      i.where("createdAt", ">=", oneWeekAgoTimestamp),
      i.where("createdAt", "<", timestamp)
    );

    const qk = i.query(i.collection(i.db2, "userMarketingReferrals"));

    const getKaapoMarketingRef = async () => {
      const signupswithinaweekKaapo = await i.getDocs(aWeekQueryKaapo);
      setKaapoOneWeek(signupswithinaweekKaapo.size);

      const signupsOneWeekFromLastWeek = await i.getDocs(
        aTwoWeeksAgoQueryKaapo
      );
      setKaapoTwoWeeksAgo(signupsOneWeekFromLastWeek.size);

      const marketingRef = await i.getDocs(qk);
      setTotalKaapoSignups(marketingRef.size);

      marketingRef.forEach((doc) => {
        const referrer = doc.data().referredFrom;
        if (kaapoRefCount[referrer]) {
          kaapoRefCount[referrer] += 1;
        } else {
          kaapoRefCount[referrer] = 1;
        }
      });

      const sortedReferrers = Object.entries(kaapoRefCount).sort(
        (a, b) => b[1] - a[1]
      );

      let keyValuePairs = sortedReferrers.map(function (subArray) {
        return { [subArray[0]]: subArray[1] };
      });

      let keyValueObject = keyValuePairs.reduce(function (
        accumulator,
        currentValue
      ) {
        return Object.assign(accumulator, currentValue);
      },
      {});

      const referralMedium = Object.entries(keyValueObject).map(
        ([key, value], index) => {
          return key + " " + value;
        }
      );

      setRefnCountKaapo(referralMedium);

      setKaapoMostRefferredFrom(sortedReferrers[0][0]);
      setKaapoMostRefferredFromCount(sortedReferrers[0][1]);
    };

    const total = totalKaapoSignups + totalEsetechSignups;
    setTotalSignups(Number(total));

    const sum = totalKaapo + totalEsetech;
    setTraded(Number(sum));

    getKaapoMarketingRef();
    getMarketingRef();
  }, [
    totalKaapoSignups,
    totalEsetech,
    totalKaapo,
    traded,
    totalEsetechSignups,
    esetechMostRefferredFrom,
    kaapoMostRefferredFrom,
    esetechMostRefferredFromCount,
    kaapoMostRefferredFromCount,
    // timestamp,
    // oneWeekAgoTimestamp,
  ]);
  const newCustomerData = [
    {
      id: 1,
      title: "esetech",
      total: totalEsetechSignups,
      kaapo: totalKaapoSignups,
    },
    {
      id: 2,
      title: "kaapo",
      total: totalKaapoSignups,
    },
    {
      id: 3,
      title: "Total",
      total: totalSignups,
    },
    {
      id: 4,
      title: "Total Traded",
      total: traded || 0,
    },
    {
      id: 5,
      title: "Most ref Esetech",
      total: esetechMostRefferredFrom,
      mostFromCount: esetechMostRefferredFromCount,
    },
    {
      id: 6,
      title: "Most ref Kaapo",
      total: kaapoMostRefferredFrom,
      mostFromCount: kaapoMostRefferredFromCount,
    },
  ];

  const newSignups = esetechOneWeek + kaapoOneWeek;
  const newSignupstwoWeeksAgo = esetechTwoWeeksAgo + kaapoTwoWeeksAgo;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Seven days signup data",
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const labels = ["7 days data"];

  const data = {
    labels,
    datasets: [
      {
        label: `${String(oneWeekAgoTimestamp)
          .split(/\s/, 4)
          .join(" ")} - ${String(timestamp).split(/\s/, 4).join(" ")}`,
        data: labels.map(() => Number(newSignupstwoWeeksAgo)),
        borderColor: "rgba(3, 102, 20, 0.986)",
        backgroundColor: "rgba(3, 102, 20, 0.986)",
      },
      {
        label: `${String(timestamp).split(/\s/, 4).join(" ")} - ${String(
          new Date()
        )
          .split(/\s/, 4)
          .join(" ")}`,
        data: labels.map(() => Number(newSignups)),
        borderColor: "rgb(44, 129, 64)",
        backgroundColor: "rgb(44, 129, 64)",
      },
    ],
  };

  return (
    <div
      className="mx-auto my-auto h-[500px] w-full overflow-auto rounded-md
      bg-white py-5 px-3 shadow-sm drop-shadow-md dark:bg-slate-700"
    >
      <div>
        <div
          className="z-50 mx-auto h-[250px] w-[400px]
        text-slate-600 dark:text-white"
        >
          {!totalSignups ? (
            <div className="h-full w-full pt-32">
              <DotLoader />
            </div>
          ) : (
            <ReactSpeedometer
              fluidWidth={true}
              forceRender={true}
              maxSegmentLabels={1}
              customSegmentStops={[0, totalSignups, 6000]}
              segmentColors={["rgb(44, 129, 64)", "#e2e8f0"]}
              needleColor={"#15803d"}
              currentValueText={`Total Signups ${
                !totalSignups ? "loading..." : totalSignups
              }`}
              minValue={0}
              maxValue={6000}
              needleTransitionDuration={4000}
              needleTransition="easeElastic"
              value={totalSignups}
              textColor={"white"}
            />
          )}
        </div>
        <div>
          <div
            className="mx-auto flex justify-center rounded border-2
          px-5 py-2 align-middle text-white shadow-xl
          drop-shadow-md dark:border-gray-500 dark:bg-green-900/30"
          >
            <p
              className="flex text-center font-sans text-base font-semibold
              capitalize leading-10 text-slate-600 drop-shadow-md dark:text-white"
            >
              <p>New Signups : last 7 days &nbsp;</p>
              {!newSignups ? (
                <p className="text-green-600"> Loading...</p>
              ) : (
                newSignups
              )}
              <SwapVertIcon
                className={
                  newSignups > newSignupstwoWeeksAgo
                    ? "mt-2 text-green-600"
                    : "mt-2 text-red-500"
                }
              />
            </p>
          </div>
        </div>
        <div
          className="z-50 mx-auto my-5 h-[300px]
        w-[520px] rounded-md bg-slate-200 p-5
        text-slate-600 shadow-md drop-shadow-md dark:text-white"
        >
          <Bar options={options} data={data} />
        </div>

        <div
          className="mx-auto rounded-md border-2 bg-white p-2 shadow-sm
        dark:border-gray-400 dark:bg-green-900/30"
        >
          <div
            className="mx-auto grid h-[250px] w-full grid-cols-3 gap-2 
        "
          >
            {newCustomerData.map((d, i) => {
              return (
                <div
                  key={i}
                  className="backdrop-blur-200 col-span-1 rounded bg-slate-800 px-2 drop-shadow-md"
                >
                  <h3
                    className="text-center font-sans text-base 
              font-semibold capitalize leading-10 text-slate-600 drop-shadow-md dark:text-white"
                  >
                    {d.title}
                  </h3>
                  <hr className="pb-2" />

                  <p
                    className="text-center font-sans text-base font-semibold capitalize
              oldstyle-nums leading-5 text-slate-600 drop-shadow-md dark:text-white"
                  >
                    {!d.total ? "loading..." : d.total}&nbsp;
                    {d?.mostFromCount || ""}
                  </p>
                </div>
              );
            })}
          </div>
          <hr className="mt-5 dark:bg-gray-400" />
          <p className="mx-auto mt-1 mb-0 bg-transparent_header pt-3 text-center capitalize text-slate-100 dark:bg-slate-800">
            referral breakdown
          </p>
          <hr className="mt-1 dark:bg-gray-400" />
          <p className="mx-auto mt-3 mb-0 w-fit text-center capitalize text-slate-100">
            Esetech
            <hr className="mt-1 dark:bg-gray-400" />
          </p>
          <div
            className="backdrop-blur-200  mt-5 grid w-full 
        grid-cols-3 justify-around rounded bg-slate-800 px-3 drop-shadow-md"
          >
            {refnCountEsetech.map((x) => (
              <p className="col-span-1 m-0.5 mx-auto w-full py-3 text-left capitalize text-slate-600 dark:text-white">
                {x}
              </p>
            ))}
          </div>
          <p className="mx-auto mt-3 mb-0 w-fit text-center capitalize text-slate-100">
            Kaapo
            <hr className="mt-1 dark:bg-gray-800" />
          </p>
          <div
            className="backdrop-blur-200  mt-5 grid w-full 
        grid-cols-3 justify-around rounded bg-slate-800 px-3 drop-shadow-md"
          >
            {refnCountKaapo.map((x) => (
              <p
                className="col-span-1 m-0.5 mx-auto w-full py-3 text-left 
            capitalize text-slate-800 dark:text-white"
              >
                {x}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferredSignups;

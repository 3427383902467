import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  esetecUsersDetails: null,
  userDetails: null,
  userPushNotification: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    getUsers: (state, { payload }) => {
      state.esetecUsersDetails = payload;
    },
    getUserDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
    getUserPushNotification: (state, { payload }) => {
      state.userPushNotification = payload;
    },
  },
});

export const { getUsers, getUserDetails, getUserPushNotification } =
  usersSlice.actions;

export default usersSlice.reducer;

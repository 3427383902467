import React, { useState, useEffect } from "react";
import { Navigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ChevronRight } from "react-feather";
import { capitalize } from "lodash";
import { Box } from "@mui/material";

import { useLogout, useToggleAppDialog } from "api";
import { Drawer } from ".";
import { classNames } from "utils";
import {
  SimpleIcon,
  SideDrawerInfo,
  SideDrawerTrans,
  UserDetailsDrawer,
} from "components";
import { drawerLinkReducedList } from "utils/constants";

const listP = [
  "Chief Executive Officer",
  "HR Manager",
  "Customer Care Agent",
  "Supervisor",
];

export default function PageContainer({ children }) {
  const [offsetWidth, setOffsetWidth] = useState();
  const [mainWidth, setMainWidth] = useState();
  const [drawerWidth, setDrawerWidth] = useState();

  const [, , , logout] = useLogout();
  const { user_details } = useSelector((state) => state.profile);
  const { openSideDrawerInfo } = useSelector((state) => state.util);

  const {
    openAppDialog,
    openAppDrawer,
    // dialogContent: { content: DialogContent, title },
  } = useSelector((state) => state.util);

  const [toggleAppDialog] = useToggleAppDialog();

  const location = useLocation();
  const path = location.pathname.split("/").slice(1);

  useEffect(() => {
    // setOffsetWidth(!openAppDrawer ? "w-[7%]" : "w-[18%]");
    // setMainWidth(!openAppDrawer ? "ml-[7%] !w-[93%]" : "ml-[18%] !w-[82%]");
    // setDrawerWidth(!openAppDrawer ? "!w-full" : "!w-[82%]");
  }, [openAppDrawer]);

  useEffect(() => {
    toggleAppDialog(false);
  }, [location]);

  const drawerLinkReduced = drawerLinkReducedList(
    user_details?.position,
    user_details?.roles
  );

  const getCurrentRoute = drawerLinkReduced.find(
    (item) => item?.linkTo === location.pathname
  );

  if (!getCurrentRoute?.display) {
    return <Navigate to="/dashboard" />;
  }

  const accessUser = listP?.includes(user_details?.position);

  return (
    <div className="page-container relative dark:bg-gray-800">
      <Drawer logout={logout} />

      <header
        className={classNames(
          "header fixed top-0 h-[224px] w-full bg-primary-alt duration-500 dark:bg-[#112a11]"
        )}
      />
      <div className="fixed top-0 z-[1050] h-[64px] w-full bg-transparent shadow-drawer" />
      <main
        className={classNames("main-content relative ml-[7%] duration-500")}
      >
        <div
          className={classNames(
            openAppDialog ? "mt-[15px]" : "mt-[80px]",
            "px-4"
          )}
        >
          <div className="relative flex flex-row">
            <div
              className={classNames(
                openSideDrawerInfo && accessUser ? "w-[73%]" : "w-[100%]",
                "relative duration-500"
              )}
            >
              <div className="mx-auto mb-2 flex justify-between px-4">
                <ul className="items-center justify-center text-sm text-white md:flex">
                  <SimpleIcon
                    icon="Home"
                    frontColor="text-white"
                    fontSize="small"
                  />
                  <li className="ml-1 flex items-center">
                    <Link to="/" className="underline underline-offset-2">
                      App
                    </Link>
                    <ChevronRight strokeWidth="2px" size={16} />
                  </li>
                  {path.map((i) => {
                    const d = i !== path[path.length - 1];
                    const Block = d ? Link : Box;
                    return (
                      <li className="flex items-center" key={i}>
                        <Block
                          to={`/${i}`}
                          className={d ? "underline underline-offset-2" : ""}
                        >
                          {capitalize(i)}
                        </Block>
                        {i !== path[path.length - 1] && (
                          <ChevronRight strokeWidth="2px" size={16} />
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="mx-auto rounded p-2">{children}</div>
            </div>

            <SideDrawerTrans />
            <SideDrawerInfo />
            <UserDetailsDrawer />
          </div>
        </div>
      </main>
    </div>
  );
}

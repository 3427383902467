import { FullWidthTabs } from "components";
import MetaImpKaapo from "../../components/MetaImpressions/MetaImpKaapo";
import React, { useState } from "react";
import CTA from "./CTA";
import MetaImpEsetech from "pages/Marketing/components/MetaImpressions/MetaImpEsetech";

const Impressions = () => {
  const tabList = [
    {
      label: "Esetech",
      Comp: MetaImpEsetech,
    },
    {
      label: "Kaapo",
      Comp: MetaImpKaapo,
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabList[0]);
  return (
    <div>
      <div className="grid h-fit w-full grid-cols-2 gap-5 px-5 py-10">
        <FullWidthTabs
          {...{ currentTab, setCurrentTab, tabList }}
          Comp={<currentTab.Comp {...currentTab} />}
        />
        <CTA />
      </div>
    </div>
  );
};

export default Impressions;

import { Accordion, Loader, SectionContent, TableData } from "components";
import { Fragment, useEffect } from "react";
import { getIsLoadedTrans, useAllTrans } from "utils/allTransactionProps";
import {
  columns,
  convertFiat,
  crypto,
  fundFiat,
  giftcards,
  transferBonus,
  transferFiat,
  utilities,
  withdrawFiat,
} from "utils/transactionProps";

import { useDrawerDocDetails } from "api";
import {
  useGetAllCompletedTransEse,
  useGetTransHistoryEse,
} from "api/actions/transactionEse";
import { useGetAllCompletedTransKap } from "api/actions/transactionKap";
import { startCase } from "lodash";
import { useSelector } from "react-redux";
import { formatCurrency, formatDate, formatMoney } from "utils";

export default function AllTransactions({
  type = "current_user",
  setGetTransCount = () => {},
  currentD,
  filter,
}) {
  const { current_user } = useSelector((state) => state.auth);

  const [setDrawerDocDetails] = useDrawerDocDetails();

  const [transHistory, getData, isSubmittingTransHis, isSubmittedTransHis] =
    useGetTransHistoryEse();

  useGetAllCompletedTransKap();
  useGetAllCompletedTransEse();

  const transFil = useAllTrans()?.filter((item) =>
    item?.id.includes("completed")
  );

  const isLoaded = getIsLoadedTrans(transFil);

  let trans = transFil
    ?.reduce((acc, item) => {
      if (item?.data) acc.push(...item?.data);
      return acc;
    }, [])
    ?.sort((a, b) => b.dateCreated - a.dateCreated);

  //  item?.paymentMethod === "automatic" &&
  //    item?.fiatTransferProvider === "paga" &&

  if (filter) {
    trans = trans?.filter((item) => {
      return (
        [filter?.status].includes(item?.status) &&
        (filter?.platform ? [filter?.platform].includes(item?.platform) : true)
      );
    });
  } else if (type === "approved") {
    trans = trans?.filter((item) => ["approved"].includes(item?.status));
  } else if (type === "current_user") {
    trans = trans?.filter((item) => item.agentId === current_user);
  }

  const obj = {
    nairaWallet: "NGN",
    nairaBonusWallet: "NGN",
    cedisWallet: "GHS",
    cedisBonusWallet: "GHS",
    cefaWallet: "XOF",
    cefaBonusWallet: "XOF",
    bitcoinWallet: "BTC",
    ethereumWallet: "ETH",
    tetherWallet: "USDT",
    tetherTronWallet: "USDT_TRON",
    binanceWallet: "BNB",
  };

  const totalTransCal = trans.reduce(
    (acc, item) => {
      if (item.product === "giftcard" && item.type === "sell") {
        acc[`giftcardsSell`][`${obj?.[item?.walletToCredit]}`]["count"] += 1;
        acc[`giftcardsSell`][`${obj?.[item?.walletToCredit]}`]["amount"] +=
          parseInt(item.amount) || 0;
      }

      if (item.product === "giftcard" && item.type === "buy") {
        acc[`giftcardsBuy`][`${obj?.[item?.walletToDebit]}`]["count"] += 1;
        acc[`giftcardsBuy`][`${obj?.[item?.walletToDebit]}`]["amount"] +=
          parseInt(item.amount) || 0;
      }

      if (item.product === "crypto") {
        if (item?.transaction?.tradeType === "receive") {
          acc[`crypto`]["receive"]["count"] += 1;
          // acc[`crypto`]["receive"]["amount"] +=
          //   parseFloat(item?.transaction?.crypto) || 0;
          acc[`crypto`]["receive"]["dollarAmount"] +=
            parseFloat(item?.transaction?.usd) || 0;
        }
        if (item?.transaction?.tradeType === "sell") {
          acc[`crypto`]["sell"]["count"] += 1;
          // acc[`crypto`]["sell"]["amount"] += item?.transaction?.crypto || 0;
          acc[`crypto`]["sell"]["dollarAmount"] += item?.transaction?.usd || 0;
          acc[`crypto`]["sell"]["nairaAmount"] +=
            item?.transaction?.["fiat" || "ngn"] || 0;
        }
        if (item?.transaction?.tradeType === "buy") {
          acc[`crypto`]["buy"]["count"] += 1;
          // acc[`crypto`]["buy"]["amount"] += item?.transaction?.crypto || 0;
          acc[`crypto`]["buy"]["dollarAmount"] += item?.transaction?.usd || 0;
          acc[`crypto`]["buy"]["nairaAmount"] +=
            item?.transaction?.["fiat" || "ngn"] || 0;
        }
        if (item?.transaction?.tradeType === "send") {
          acc[`crypto`]["send"]["count"] += 1;
          // acc[`crypto`]["send"]["amount"] += item?.transaction?.crypto || 0;
          acc[`crypto`]["sell"]["dollarAmount"] += item?.transaction?.usd || 0;
        }
        if (item?.transaction?.tradeType === "convert") {
          acc[`crypto`]["convert"]["count"] += 1;
          // acc[`crypto`]["convert"]["amount"] += item?.transaction?.crypto || 0;
          acc[`crypto`]["convert"]["dollarAmount"] +=
            item?.transaction?.usd || 0;
        }
      }

      if (item.product === "payout") {
        acc[`withdrawals`][`${obj?.[item?.walletToDebit]}`]["count"] += 1;
        acc[`withdrawals`][`${obj?.[item?.walletToDebit]}`]["amount"] +=
          parseInt(item.amount) || 0;
      }

      if (item.product === "utility") {
        if (item.productType !== "convert-airtime") {
          acc[`utilities`][`${obj?.[item?.walletToDebit]}`]["count"] += 1;
          acc[`utilities`][`${obj?.[item?.walletToDebit]}`]["amount"] +=
            parseInt(item.amount) || 0;
        } else {
          acc[`airtimeToCash`][`${obj?.[item?.walletToCredit]}`]["count"] += 1;
          acc[`airtimeToCash`][`${obj?.[item?.walletToCredit]}`]["amount"] +=
            parseInt(item.amount) || 0;
        }
      }

      if (
        item.product === "transfer-bonus" &&
        item.productType.includes("receive")
      ) {
        acc[`bonusesReceived`][`${obj?.[item?.walletToCredit]}`]["count"] += 1;
        acc[`bonusesReceived`][`${obj?.[item?.walletToCredit]}`]["amount"] +=
          parseInt(item.amount) || 0;
      }

      if (
        item.product === "transfer-bonus" &&
        item.productType.includes("withdraw")
      ) {
        acc[`bonusesWithdrawn`][`${obj?.[item?.walletToDebit]}`]["count"] += 1;
        acc[`bonusesWithdrawn`][`${obj?.[item?.walletToDebit]}`]["amount"] +=
          parseInt(item.amount) || 0;
      }

      return acc;
    },
    {
      crypto: {
        receive: { count: 0, amount: 0, dollarAmount: 0, nairaAmount: 0 },
        buy: { count: 0, amount: 0, dollarAmount: 0, nairaAmount: 0 },
        sell: { count: 0, amount: 0, dollarAmount: 0, nairaAmount: 0 },
        send: { count: 0, amount: 0, dollarAmount: 0, nairaAmount: 0 },
        convert: { count: 0, amount: 0, dollarAmount: 0, nairaAmount: 0 },
      },
      giftcardsSell: {
        NGN: { count: 0, amount: 0 },
        GHS: { count: 0, amount: 0 },
        XOF: { count: 0, amount: 0 },
      },
      giftcardsBuy: {
        NGN: { count: 0, amount: 0 },
        GHS: { count: 0, amount: 0 },
        XOF: { count: 0, amount: 0 },
      },
      withdrawals: {
        NGN: { count: 0, amount: 0 },
        GHS: { count: 0, amount: 0 },
        XOF: { count: 0, amount: 0 },
      },
      utilities: {
        NGN: { count: 0, amount: 0 },
        // GHS: { count: 0, amount: 0 },
        // XOF: { count: 0, amount: 0 },
      },
      airtimeToCash: {
        NGN: { count: 0, amount: 0 },
        // GHS: { count: 0, amount: 0 },
        // XOF: { count: 0, amount: 0 },
      },
      bonusesReceived: {
        NGN: { count: 0, amount: 0 },
        GHS: { count: 0, amount: 0 },
        XOF: { count: 0, amount: 0 },
      },
      bonusesWithdrawn: {
        NGN: { count: 0, amount: 0 },
        GHS: { count: 0, amount: 0 },
        XOF: { count: 0, amount: 0 },
      },
    }
  );

  const rowsObj = (product) => {
    switch (product) {
      case "giftcard":
        return giftcards()?.rows;
      case "convert-fiat":
        return convertFiat()?.rows;
      case "payout":
        return withdrawFiat()?.rows;

      case "crypto":
        return crypto()?.rows;
      case "utility":
        return utilities()?.rows;
      case "deposit":
        return fundFiat()?.rows;
      case "transfer-fiat":
        return transferFiat()?.rows;
      case "transfer-bonus":
        return transferBonus()?.rows;
      default:
        break;
    }
  };

  const rows = trans
    ?.sort((a, b) =>
      formatDate(b?.dateCreated, null) > formatDate(a?.dateCreated, null)
        ? 1
        : -1
    )
    ?.map((item, index) => rowsObj(item?.product)(item, index));

  const handleRowlick = (values) => {
    setDrawerDocDetails(values);
  };

  useEffect(() => {
    if (isLoaded) setGetTransCount(trans?.length);
    return () => {
      setGetTransCount(0);
    };
  }, [type, trans, current_user, isLoaded, currentD, setGetTransCount]);

  return (
    <Fragment>
      {isLoaded ? (
        <>
          <SectionContent>
            <div className="w-full p-0 border rounded shadow-sm border-slate-100 bg-box dark:border-slate-700 dark:bg-gray-700">
              <div className="relative p-2 overflow-x-auto rounded-lg shadow-md">
                <TableData
                  rows={rows}
                  columns={columns}
                  onRowClick={handleRowlick}
                  displayExport
                />
              </div>
            </div>
          </SectionContent>
          <div className="mt-4">
            <SectionContent>
              <div className="space-y-2">
                {Object.entries(totalTransCal)?.map((itemOne) => {
                  const headerL =
                    itemOne[0] === "crypto"
                      ? ["", "Count", "DollarAmount"]
                      : ["", "Count", "Amount"];
                  return (
                    <Accordion
                      key={itemOne[0]}
                      title={startCase(itemOne[0])}
                      content={
                        <table className="w-full text-left text-gray-500 dark:text-gray-400">
                          <thead className="text-sm uppercase dark:text-gray-50">
                            <tr>
                              {headerL.map((item) => (
                                <th
                                  key={item}
                                  scope="col"
                                  className="px-6 py-3 text-right"
                                >
                                  {startCase(item)}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              {Object.entries(itemOne[1]).map((itemTwo) => (
                                <tr
                                  key={itemTwo?.[0]}
                                  className="bg-white border-b hover:bg-gray-50 dark:border-gray-500 dark:bg-gray-800 dark:hover:bg-gray-600"
                                >
                                  <th
                                    scope="row"
                                    className="flex items-center px-6 py-4 whitespace-nowrap dark:text-white"
                                  >
                                    {startCase(itemTwo?.[0])}
                                  </th>
                                  {itemOne[0] === "crypto" && (
                                    <>
                                      <td className="px-6 py-4 text-right">
                                        {itemTwo?.[1]?.count}
                                      </td>
                                      <td className="px-6 py-4 text-right">
                                        {formatCurrency(
                                          itemTwo?.[1]?.dollarAmount,
                                          "$"
                                        )}
                                      </td>
                                    </>
                                  )}
                                  {itemOne[0] !== "crypto" && (
                                    <>
                                      <td className="px-6 py-4 text-right">
                                        {itemTwo?.[1]?.count}
                                      </td>
                                      <td className="px-6 py-4 text-right">
                                        {formatMoney(
                                          itemTwo?.[1]?.amount,
                                          itemTwo?.[0]
                                        )}
                                      </td>
                                    </>
                                  )}
                                </tr>
                              ))}
                            </>
                          </tbody>
                        </table>
                      }
                    ></Accordion>
                  );
                })}
              </div>
            </SectionContent>
          </div>
          <div className="mt-4">
            <SectionContent>
              <Accordion
                title="Download Payout Transactions"
                content={
                  <div>
                    {/* <CSVLink
                      data={getData}
                      asyncOnClick={true}
                      onClick={(event, done) => {
                        transHistory({}, done);
                      }}
                    >
                      <Button
                        isSubmitting={isSubmittingTransHis}
                        label="Download Paga Transaction for 03/12/23"
                      />
                    </CSVLink> */}
                  </div>
                }
              />
            </SectionContent>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
}

import { useSelector } from "react-redux";
import { PageContainer } from "shared";
import Analysis from "./Analysis";
import ProductPrice from "./ProductPrice";
import Team from "./Team";
import Todolist from "./Todolist";
import WorkSchedule from "./WorkSchedule";

const listP = [
  "Chief Executive Officer",
  "HR Manager",
  "Customer Care Agent",
  "Supervisor",
];

const listPOther = ["Accounting Officer", "Digital Marketer"];

export default function Dashboard() {
  const { user_details } = useSelector((state) => state?.profile || {});
  const { position } = user_details || {};

  return (
    <PageContainer>
      <Analysis />
      {listP.includes(position) && (
        <div className="mt-6 grid grid-cols-2 gap-6">
          <div className="col-span-2">
            <ProductPrice />
          </div>
          <div className="col-span-1">
            <Todolist />
          </div>
          <div className="col-span-1">
            <Team />
          </div>
          <div className="col-span-2">
            <WorkSchedule />
          </div>
        </div>
      )}

      {listPOther.includes(position) && (
        <div className="mt-6 grid grid-cols-3 gap-6">
          <div className="col-span-1">
            <Todolist />
          </div>
          <div className="col-span-2">
            <ProductPrice />
          </div>
        </div>
      )}
    </PageContainer>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import {
  Backdrop,
  BackdropSection,
  Button,
  DrawerSide,
  PopOver,
  SectionContent,
  Select,
  SimpleIcon,
  TextField,
} from "components";
import { useSelector } from "react-redux";
import { capitalize, toLower } from "lodash";
import { formatDate } from "utils";
import { useDisableStaff, useUpdateStaffProfile } from "api";
import { positionObj, departmentObj, genderObj } from "utils/constants";
import { useReInviteStaff } from "api/actions/auth";
import { PageContainer } from "shared";
import PasswordDialog from "components/PasswordDialog";

const Icon = ({ icon }) => (
  <SimpleIcon icon={icon} frontColor="text-gray-300" fontSize={18} />
);

export default function ManageStaff() {
  const [open, setOpen] = useState(false);
  const [openPass, setOpenPass] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [getUserData, setUserData] = useState({});
  const [getUserReInviteData, setUserReInviteData] = useState({});
  const [filterBy, setFilterBy] = useState("");
  const [delay, setDelay] = useState(false);

  const [getPassField, setPassField] = useState({
    password: "",
  });

  const { staffDetails } = useSelector((state) => state.staff || {});
  const { user_details } = useSelector((state) => state.profile || {});

  const [isUpdating, isUpdated, updateProfile] = useUpdateStaffProfile();
  const [isUpdatingReInvite, isUpdatedReInvite, reInviteStaff] =
    useReInviteStaff();

  const [isUpdatingDisable, isUpdatedDisable, disableStaff] = useDisableStaff();

  const staffDetailsFiltered = useCallback(() => {
    return Boolean(filterBy)
      ? staffDetails
          ?.slice()
          ?.sort((a, b) => (b?.accountCreated > a?.accountCreated ? 1 : -1))
          ?.sort((a, b) => a?.position?.localeCompare(b?.position))
          ?.filter((item) => item?.department === filterBy)
      : staffDetails
          ?.slice()
          ?.sort((a, b) => (b?.accountCreated > a?.accountCreated ? 1 : -1));
  }, [filterBy, staffDetails])();

  const handleDetails = (e) => {
    setUserData((prevS) => ({ ...prevS, [e.target.name]: e.target.value }));
  };

  const handleReInviteDetails = (e) => {
    setUserReInviteData((prevS) => ({
      ...prevS,
      [e.target.name]: e.target.value,
    }));
  };

  const handleReInviteStaff = () => {
    const { email, id: staffId, displayName } = currentUser || {};
    const values = {
      newEmail: getUserReInviteData?.newEmail,
      email,
      staffId,
      displayName,
    };
    // console.log(values);
    reInviteStaff(values);
  };

  const handleDisableStaff = () => {
    disableStaff({
      emailToDisable: currentUser?.email,
      UIDToDisable: currentUser?.id,
      currentPass: getPassField?.password,
    });
  };

  useEffect(() => {
    if (open && currentUser) {
      setUserData((prevS) => ({
        ...prevS,
        displayName: currentUser?.displayName,
        phoneNumber: currentUser?.phoneNumber,
      }));
    }
  }, [currentUser, open]);

  useEffect(() => {
    if (isUpdated) {
      setUserData({});
      setOpen(false);
    }
    if (!open) {
      setUserData({});
      setUserReInviteData({});
    }
  }, [isUpdated, open]);

  useEffect(() => {
    setTimeout(() => {
      setDelay(true);
    }, 500);
    setDelay(false);
  }, [filterBy]);

  useEffect(() => {
    setOpen(false);
    setOpenPass(false);
  }, [isUpdatedDisable, isUpdatedReInvite]);

  const editStaffAuth = [
    "Chief Executive Officer",
    "Software Engineer",
  ].includes(user_details?.position);

  return (
    <PageContainer>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-1">
        <div className="col-span-1 md:col-span-1">
          <SectionContent>
            <div className="w-full rounded border border-slate-100 bg-box p-0 shadow-sm dark:border-slate-700 dark:bg-gray-800">
              <div className="relative overflow-x-auto p-2 shadow-md  sm:rounded-lg">
                <div className="mb-4 flex items-start justify-between bg-white p-4 dark:bg-gray-900">
                  <div>
                    <PopOver
                      placement="bottom-start"
                      PopoverHandlerComponent={() => (
                        <button className="flex w-40 flex-row items-center items-center justify-between rounded-lg border border-gray-300 bg-white px-3 py-2 text-base font-medium text-gray-500 dark:border-gray-500 dark:bg-gray-700 dark:text-white">
                          Filter By
                          <Icon
                            icon="ExpandMore"
                            frontColor="dark:text-gray-200"
                          />
                        </button>
                      )}
                      PopoverContentComponent={() => (
                        <div>
                          <ul className="-my-3 -mx-1 h-40 divide-y-0 divide-gray-200 overflow-auto dark:divide-gray-500">
                            {departmentObj.map((item) => (
                              <li className="my-2 w-full" key={item.id}>
                                <button
                                  className="w-full rounded bg-box p-2 text-left dark:bg-gray-900"
                                  onClick={() => {
                                    setFilterBy(item?.value);
                                  }}
                                >
                                  <div className=" dark:text-white">
                                    {item?.value}
                                  </div>
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    />
                  </div>
                  <div className="">
                    <TextField
                      label="Search"
                      size="small"
                      shrink={false}
                      // startAdornment={<Icon icon="PersonSearch" />}
                    />
                  </div>
                </div>
                <table className="w-full text-left text-gray-500 dark:text-gray-400">
                  <thead className="bg-gray-50 text-sm uppercase  dark:bg-gray-700 dark:text-gray-200">
                    <tr>
                      {[
                        "Name",
                        "Position",
                        "Status",
                        "Date Joined",
                        "Last Login",
                        "Action",
                      ].map((item) => (
                        <th scope="col" className="py-2 px-6" key={item}>
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {staffDetails && delay ? (
                      <>
                        {staffDetailsFiltered?.length ? (
                          staffDetailsFiltered.map((item) => (
                            <tr
                              className="border-b bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:hover:bg-gray-600"
                              key={item.id}
                            >
                              <th
                                scope="row"
                                className="flex items-center whitespace-nowrap py-4 px-6  dark:text-white"
                              >
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={require(`assets/avatar/avatar-${
                                    toLower(item?.gender) || "female"
                                  }.png`)}
                                  alt={item.displayName}
                                />
                                <div className="pl-3">
                                  <div className="text-base font-semibold">
                                    {item.displayName}
                                  </div>
                                  <div className="text-gray-500 dark:text-gray-400">
                                    {toLower(item.email)}
                                  </div>
                                </div>
                              </th>
                              <td className="py-4 px-6">{item.position}</td>
                              <td className="py-4 px-6">
                                <div className="flex items-center text-green-500">
                                  <div className="mr-2 h-2.5 w-2.5 rounded-full bg-green-400"></div>{" "}
                                  {item.accountStatus}
                                </div>
                              </td>
                              <td className="whitespace-nowrap py-4 px-6">
                                {formatDate(item.accountCreated)}
                              </td>
                              <td className="whitespace-nowrap py-4 px-6">
                                {formatDate(item.lastLogin)}
                              </td>
                              <td className="py-4 px-6">
                                <div className="flex flex-row items-center gap-4">
                                  {editStaffAuth && (
                                    <Button
                                      label="Edit"
                                      color="info"
                                      size="small"
                                      handleClick={() => {
                                        setOpen(true);
                                        setCurrentUser(item);
                                      }}
                                      otherClass="!min-w-0"
                                    />
                                  )}
                                  <Button
                                    label="Chat"
                                    color="success"
                                    size="small"
                                    otherClass="!min-w-0"
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="h-32 w-full rounded bg-white p-4 dark:bg-gray-800">
                            <td colSpan={6} className="w-full p-4 text-center">
                              No data
                            </td>
                          </tr>
                        )}
                      </>
                    ) : (
                      <tr className="h-48 w-full rounded bg-white p-4 dark:bg-gray-800">
                        <td colSpan={6} className="w-full p-4 text-center">
                          <BackdropSection open={true} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </SectionContent>
        </div>
      </div>
      {editStaffAuth && (
        <>
          <DrawerSide {...{ open, setOpen }}>
            <div>
              {currentUser && (
                <div>
                  <div className="">
                    <div className="flex flex-col items-center justify-center border-b p-4 dark:border-gray-600">
                      <img
                        className="h-32 w-32 rounded-lg"
                        src={require(`assets/avatar/avatar-${
                          toLower(currentUser?.gender) || "female"
                        }.png`)}
                        alt={currentUser?.displayName}
                      />
                      <div className="my-4 text-center dark:text-white">
                        <div className="font-bold">
                          {currentUser?.displayName}
                        </div>
                        <div className="mt-1 text-sm">
                          {toLower(currentUser?.email)}
                        </div>
                      </div>
                      <div className="my-2">
                        <Button
                          label="Disable"
                          color="error"
                          handleClick={() => setOpenPass(true)}
                        />
                      </div>
                    </div>
                    <div className="my-4 border-b p-4 dark:border-gray-600">
                      <div className="grid w-full grid-cols-1 gap-y-6">
                        <div className="col-span-1">
                          <TextField
                            name="displayName"
                            label="Display Name"
                            value={getUserData?.displayName}
                            placeholder="Display Name"
                            handleChange={handleDetails}
                          />
                        </div>
                        <div className="col-span-1">
                          <TextField
                            name="phoneNumber"
                            label="Phone Number"
                            value={getUserData?.phoneNumber}
                            placeholder="Phone Number"
                            handleChange={handleDetails}
                          />
                        </div>
                        <div className="col-span-1">
                          <Select
                            inputLabel="Position"
                            name="position"
                            dropDown={positionObj}
                            handleChange={(e) =>
                              setUserData((prevS) => ({
                                ...prevS,
                                position: e.value,
                              }))
                            }
                            defaultValue={currentUser?.position || ""}
                          />
                        </div>
                        <div className="col-span-1">
                          <Select
                            inputLabel="Department"
                            name="department"
                            dropDown={departmentObj}
                            handleChange={(e) =>
                              setUserData((prevS) => ({
                                ...prevS,
                                department: e.value,
                              }))
                            }
                            defaultValue={currentUser?.department || ""}
                          />
                        </div>
                        <div className="col-span-1">
                          <Select
                            inputLabel="Gender"
                            name="gender"
                            dropDown={genderObj}
                            handleChange={(e) =>
                              setUserData((prevS) => ({
                                ...prevS,
                                gender: e.id,
                              }))
                            }
                            defaultValue={capitalize(currentUser?.gender) || ""}
                          />
                        </div>
                        <div className="col-span-1">
                          <Button
                            label="Edit"
                            // fullWidth
                            gradient
                            isSubmitting={isUpdating}
                            handleClick={() => {
                              updateProfile(getUserData, currentUser?.id);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="my-4 p-4">
                    <h3 className="mb-8 dark:text-white">
                      Change Email & ReInvite Staff
                    </h3>
                    <div className="grid w-full grid-cols-1 gap-y-6">
                      <div className="col-span-1">
                        <TextField
                          name="newEmail"
                          label="New Email"
                          value={
                            getUserReInviteData?.newEmail || currentUser?.email
                          }
                          placeholder="New Email"
                          handleChange={handleReInviteDetails}
                        />
                      </div>
                      <div className="col-span-1">
                        <Button
                          label="Re-Invite"
                          // fullWidth
                          gradient
                          isSubmitting={isUpdatingReInvite}
                          handleClick={handleReInviteStaff}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </DrawerSide>

          <PasswordDialog
            {...{
              title: "Disable User",
              open: openPass,
              setOpen: setOpenPass,
              getField: getPassField,
              setField: setPassField,
              isSubmitting: isUpdatingDisable,
              isSubmitted: isUpdatedDisable,
              handleClick: handleDisableStaff,
              buttonObj: { label: "Disable", color: "error" },
            }}
          />
        </>
      )}
    </PageContainer>
  );
}

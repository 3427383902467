import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useClaimTrade, useDrawerDocDetails, useFullPageDocDetails } from "api";

import { crypto, giftcards, utilities, withdrawFiat } from "./transactionProps";
import { useNavigate } from "react-router-dom";
import { capitalize } from "lodash";

export const useAllTrans = () => {
  const { fullPageDocDetails } = useSelector((state) => state.util || {});
  const { user_details } = useSelector((state) => state.profile);
  const { position } = user_details || {};
  // const position = "Customer Care Agent";

  const {
    pendingGiftcardTransKap,
    activeGiftcardTransKap,
    deferredGiftcardTransKap,
    pendingWithdrawFiatTransKap,
    activeWithdrawFiatTransKap,
    deferredWithdrawFiatTransKap,
    pendingUtilityTransKap,
    activeUtilityTransKap,
    deferredUtilityTransKap,
    completedGiftcardTransKap,
    completedWithdrawFiatTransKap,
    completedUtilityTransKap,
    completedFundWalletTransKap,
    completedTransferFiatTransKap,
    completedTransferBonusTransKap,
    completedConvertFiatTransKap,
  } = useSelector((state) => state.transactionKap);

  const {
    pendingCryptoTransEse,
    pendingWithdrawFiatTransEse,
    activeWithdrawFiatTransEse,
    deferredWithdrawFiatTransEse,
    completedCryptoTransEse,

    completedOldCryptoTransEse,
    completedOldGiftcardTransEse,
    completedOldWithdrawFiatTransEse,

    completedWithdrawFiatTransEse,
    completedUtilityTransEse,
    completedFundWalletTransEse,
    completedTransferFiatTransEse,
    completedTransferBonusTransEse,
    completedConvertFiatTransEse,
  } = useSelector((state) => state.transactionEse);

  const navigate = useNavigate();

  const [isSubmitting, isSubmittedClaimCode, claimTrade] = useClaimTrade();

  const [setDrawerDocDetails] = useDrawerDocDetails();
  const [setFullPageDocDetails] = useFullPageDocDetails();

  useEffect(() => {
    if (isSubmittedClaimCode && fullPageDocDetails) {
      navigate("/process-transaction", {
        state: fullPageDocDetails,
      });
    }
  }, [isSubmittedClaimCode]);

  const handleClaimTrade = (values) => {
    claimTrade(values);
  };

  const handleClickBtn = ({
    item,
    collection,
    collectionTo,
    type,
    onlyView,
    id,
  }) => {
    setDrawerDocDetails(false);
    setFullPageDocDetails(false);

    if (!onlyView && position === "Customer Care Agent") {
      handleClaimTrade({
        transactionId: item.id?.value,
        customerId: item.customerId?.value,
        collectionFrom: collection,
        platform: type,
      });
      setFullPageDocDetails({
        docId: item.id?.value,
        collection,
        collectionTo,
        type,
        id,
      });
    } else {
      setDrawerDocDetails({
        docId: item.id?.value,
        collection,
        collectionTo,
        type,
        id,
      });
    }
  };

  const handleIsSubmitting = (item) =>
    isSubmitting &&
    fullPageDocDetails &&
    fullPageDocDetails?.docId === item.id?.value;

  const payoutObj = ({ id, data, platform, title }) => ({
    id,
    idType: `payout${platform}`,
    data,
    buttonText:
      title === "active"
        ? "View"
        : position === "Customer Care Agent"
        ? title === "deferred"
          ? "Re-claim"
          : "Claim"
        : "View",
    objectSummary: withdrawFiat()?.drawerSummary,
    platform,
    title: `${capitalize(title)} Fiat Withdrawal - ${capitalize(platform)}`,
    handleOnClick: (item) => {
      handleClickBtn({
        item,
        collection: "pendingTransWithdrawals",
        collectionTo: "transWithdrawals",
        type: platform,
        onlyView: title === "active",
        id: "payout",
      });
    },
    ...(title !== "active" && {
      isSubmitting: (item) => handleIsSubmitting(item),
    }),
  });

  const giftcardObj = ({ id, data, platform, title }) => ({
    id,
    idType: `giftcard${platform}`,
    data,
    buttonText:
      title === "active"
        ? "View"
        : position === "Customer Care Agent"
        ? title === "deferred"
          ? "Re-claim"
          : "Claim"
        : "View",
    objectSummary: giftcards()?.drawerSummary,
    platform,
    title: `${capitalize(title)} Giftcard Trade - ${capitalize(platform)}`,
    handleOnClick: (item) => {
      handleClickBtn({
        item,
        collection: "pendingTransGiftcards",
        collectionTo: "transGiftcards",
        type: platform,
        onlyView: title === "active",
        id: "giftcard",
      });
    },
    ...(title !== "active" && {
      isSubmitting: (item) => handleIsSubmitting(item),
    }),
  });

  const utilityObj = ({ id, data, platform, title }) => ({
    id,
    idType: `utility${platform}`,
    data,
    buttonText:
      title === "active"
        ? "View"
        : position === "Customer Care Agent"
        ? title === "deferred"
          ? "Re-claim"
          : "Claim"
        : "View",
    objectSummary: utilities()?.drawerSummary,
    platform,
    title: `${capitalize(title)} Utility - ${capitalize(platform)}`,
    handleOnClick: (item) => {
      handleClickBtn({
        item,
        collection: "pendingTransUtilities",
        collectionTo: "transUtilities",
        type: platform,
        onlyView: title === "active",
        id: "utility",
      });
    },
    ...(title !== "active" && {
      isSubmitting: (item) => handleIsSubmitting(item),
    }),
  });

  const otherObj = ({ id, data, platform, title, collection, btnId }) => ({
    id,
    data,
    buttonText: "View",
    platform,
    title: `Completed ${capitalize(title)} - ${capitalize(platform)}`,
    handleOnClick: (item) => {
      handleClickBtn({
        item,
        collection,
        collectionTo: collection,
        type: platform,
        onlyView: true,
        id: btnId,
      });
    },
  });

  return [
    {
      id: "pendingCryptoEse",
      data: pendingCryptoTransEse,
      buttonText: "View",
      objectSummary: crypto()?.drawerSummary,
      platform: "esetech",
      title: "Pending Crypto Trade - Esetech",
      handleOnClick: (item) => {
        handleClickBtn({
          item,
          collection: "bitPowrCryptoNotifications",
          collectionTo: "bitPowrCryptoNotifications",
          type: "esetech",
          onlyView: "view",
          id: "crypto",
        });
      },
    },

    payoutObj({
      id: "pendingWithdrawFiatEse",
      data: pendingWithdrawFiatTransEse,
      platform: "esetech",
      title: "pending",
    }),

    payoutObj({
      id: "pendingWithdrawFiatKap",
      data: pendingWithdrawFiatTransKap,
      platform: "kaapo",
      title: "pending",
    }),

    payoutObj({
      id: "activeWithdrawFiatEse",
      data: activeWithdrawFiatTransEse,
      platform: "esetech",
      title: "active",
    }),

    payoutObj({
      id: "activeWithdrawFiatKap",
      data: activeWithdrawFiatTransKap,
      platform: "kaapo",
      title: "active",
    }),

    payoutObj({
      id: "deferredWithdrawFiatEse",
      data: deferredWithdrawFiatTransEse,
      platform: "esetech",
      title: "deferred",
    }),

    payoutObj({
      id: "deferredWithdrawFiatKap",
      data: deferredWithdrawFiatTransKap,
      platform: "kaapo",
      title: "deferred",
    }),

    giftcardObj({
      id: "pendingGiftcardKap",
      data: pendingGiftcardTransKap,
      platform: "kaapo",
      title: "pending",
    }),

    giftcardObj({
      id: "activeGiftcardKap",
      data: activeGiftcardTransKap,
      platform: "kaapo",
      title: "active",
    }),

    giftcardObj({
      id: "deferredGiftcardKap",
      data: deferredGiftcardTransKap,
      platform: "kaapo",
      title: "deferred",
    }),

    utilityObj({
      id: "pendingUtilityKap",
      data: pendingUtilityTransKap,
      platform: "kaapo",
      title: "pending",
    }),

    utilityObj({
      id: "activeUtilityKap",
      data: activeUtilityTransKap,
      platform: "kaapo",
      title: "active",
    }),

    utilityObj({
      id: "deferredUtilityKap",
      data: deferredUtilityTransKap,
      platform: "kaapo",
      title: "deferred",
    }),

    otherObj({
      id: "completedGiftcardKap",
      data: completedGiftcardTransKap,
      platform: "kaapo",
      title: "giftcard",
      collection: "transGiftcards",
      btnId: "giftcard",
    }),

    otherObj({
      id: "completedCryptoEse",
      data: completedCryptoTransEse,
      platform: "esetech",
      title: "crypto",
      collection: "transCrypto",
      btnId: "crypto",
    }),

    otherObj({
      id: "completedOldCryptoEse",
      data: completedOldCryptoTransEse,
      platform: "esetech",
      title: "crypto",
      collection: "transactions",
      btnId: "crypto",
    }),

    otherObj({
      id: "completedOldGiftcardEse",
      data: completedOldGiftcardTransEse,
      platform: "esetech",
      title: "giftcard",
      collection: "transactions",
      btnId: "giftcard",
    }),

    otherObj({
      id: "completedOldWithdrawFiatEse",
      data: completedOldWithdrawFiatTransEse,
      platform: "esetech",
      title: "withdraw Fiat",
      collection: "withdrawalRequest",
      btnId: "payout",
    }),

    otherObj({
      id: "completedWithdrawFiatKap",
      data: completedWithdrawFiatTransKap,
      platform: "kaapo",
      title: "withdraw Fiat",
      collection: "transWithdrawals",
      btnId: "payout",
    }),

    otherObj({
      id: "completedWithdrawFiatEse",
      data: completedWithdrawFiatTransEse,
      platform: "esetech",
      title: "withdraw Fiat",
      collection: "transWithdrawals",
      btnId: "payout",
    }),

    otherObj({
      id: "completedUtilityKap",
      data: completedUtilityTransKap,
      platform: "kaapo",
      title: "utility",
      collection: "transUtilities",
      btnId: "utility",
    }),

    otherObj({
      id: "completedUtilityEse",
      data: completedUtilityTransEse,
      platform: "esetech",
      title: "utility",
      collection: "transUtilities",
      btnId: "utility",
    }),

    otherObj({
      id: "completedFundWalletKap",
      data: completedFundWalletTransKap,
      platform: "kaapo",
      title: "fund Deposit",
      collection: "transDeposits",
      btnId: "fiatDeposit",
    }),

    otherObj({
      id: "completedFundWalletEse",
      data: completedFundWalletTransEse,
      platform: "esetech",
      title: "fund Deposit",
      collection: "transDeposits",
      btnId: "fiatDeposit",
    }),

    otherObj({
      id: "completedTransferFiatKap",
      data: completedTransferFiatTransKap,
      platform: "kaapo",
      title: "transfer Fiat",
      collection: "transTransferFiat",
      btnId: "transferFiat",
    }),

    otherObj({
      id: "completedTransferFiatEse",
      data: completedTransferFiatTransEse,
      platform: "esetech",
      title: "transfer Fiat",
      collection: "transTransferFiat",
      btnId: "transferFiat",
    }),

    otherObj({
      id: "completedTransferBonusKap",
      data: completedTransferBonusTransKap,
      platform: "kaapo",
      title: "transfer Bonus",
      collection: "transTransferBonus",
      btnId: "transferBonus",
    }),

    otherObj({
      id: "completedTransferBonusEse",
      data: completedTransferBonusTransEse,
      platform: "esetech",
      title: "transfer Bonus",
      collection: "transTransferBonus",
      btnId: "transferBonus",
    }),

    otherObj({
      id: "completedConvertFiatKap",
      data: completedConvertFiatTransKap,
      platform: "kaapo",
      title: "convert Fiat",
      collection: "transConvertFiat",
      btnId: "convertFiat",
    }),

    otherObj({
      id: "completedConvertFiatEse",
      data: completedConvertFiatTransEse,
      platform: "esetech",
      title: "convert Fiat",
      collection: "transConvertFiat",
      btnId: "convertFiat",
    }),
  ];
};

export const getIsLoadedTrans = (trans) =>
  trans
    .reduce((acc, item) => {
      acc.push(Boolean(item?.data));
      return acc;
    }, [])
    .every((i) => Boolean(i));

import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import * as Icons from "@mui/icons-material";
import { classNames } from "utils";
import { Badge } from "@mui/material";

export const IconButton = ({
  icon = "Home",
  handleClick,
  iconBgColor = "bg-primary",
  iconColor = "text-white",
  otherClass = "",
  isLoading,
  fontSize = 25,
}) => {
  const AppIcon = Icons[icon];

  return (
    <button
      className={classNames(
        iconColor,
        // iconBgColor,
        isLoading ? `bg-primary/50` : iconBgColor,
        "flex h-12 w-12 items-center justify-center rounded p-2 text-center shadow-lg",
        otherClass
      )}
      onClick={handleClick}
      disabled={isLoading}
    >
      {isLoading ? (
        <div>@</div>
      ) : (
        <AppIcon
          {...(typeof fontSize === "string"
            ? {
                fontSize,
              }
            : {
                sx: { fontSize },
              })}
        />
      )}
    </button>
  );
};

// iconShadow-alt
export function SimpleIcon({
  icon = "Home",
  fontSize = "medium",
  frontColor = "text-primary dark:text-white",
  otherClass = "",
}) {
  const AppIcon = Icons[icon];

  return (
    <AppIcon
      {...(typeof fontSize === "string"
        ? {
            fontSize,
          }
        : {
            sx: { fontSize },
          })}
      className={classNames(frontColor, otherClass)}
    />
  );
}

export function BackPage({ handleClick }) {
  return (
    <button className="mb-2" onClick={handleClick}>
      <IconBox
        Icon={ArrowCircleLeftOutlinedIcon}
        textColor="text-color-main"
        iconColor="bg-slate-50"
        shadowAlt
        fontSize={30}
        hover="hover:bg-slate-200"
      />
    </button>
  );
}

export function IconBox({
  icon,
  // = 'bg-slate-200',
  iconColor,
  textColor = "text-white",
  shadowAlt,
  gradient,
  fontSize = 20,
  hover,
  otherClass,
}) {
  const AppIcon = Icons[icon];

  return (
    <div
      className={classNames(
        gradient
          ? `bg-gradient-to-l from-button_gradient_from to-button_gradient_to`
          : iconColor,
        hover ? hover : "",
        "inline-flex h-10 w-10 items-center justify-center rounded-lg p-3 text-center shadow-lg",
        otherClass
      )}
    >
      <AppIcon
        sx={{ fontSize }}
        className={classNames(
          // shadowAlt ? 'iconShadow-alt' : 'iconShadow',
          textColor,
          "text-base"
        )}
      />
    </div>
  );
}

export function BadgeCount({ label, count, position = "top", align }) {
  return (
    <Badge
      color="secondary"
      {...(count && {
        badgeContent: `${count}`,
      })}
      max={999}
      anchorOrigin={{
        vertical: position,
        horizontal: "right",
      }}
      // classes={{
      //   badge: clsx(classes.badgeCount, { [classes.badgeAlign]: align }),
      // }}
    >
      {label}
    </Badge>
  );
}

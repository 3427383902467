import { useUpdateGiftcardFac } from "api";
import { Button, SectionContent, SimpleIcon, TextField } from "components";
import { startCase } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toFixed } from "utils";
import { listContentUpdate } from "utils/constants";

export default function AddGiftcardFac() {
  const [fac, setFac] = useState({});

  const { giftcardFac } = useSelector((state) => state.giftcard);

  const [isUpdating, , updateFac] = useUpdateGiftcardFac();

  useEffect(() => {
    setFac(giftcardFac);
  }, [giftcardFac]);

  return (
    <div className="h-full p-4 bg-background-main">
      <div className="flex flex-row items-center justify-between p-3">
        <div className="w-1/3">
          {[
            {
              btnText: "Update Commission",
              label: "Commission ($)",
              name: "commission",
            },
            {
              btnText: "Update Exchange Rate",
              label: "Exchange Rate ($)",
              name: "exchangeRate",
            },
            {
              btnText: "Update VAT Rate",
              label: "Vat Rate (%)",
              name: "vatRate",
            },
          ].map((item) => (
            <div className="mb-8">
              <TextField
                value={fac[item.name] || ""}
                label={item.label}
                type="number"
                handleChange={(e) =>
                  setFac((prevS) => ({
                    ...prevS,
                    [item.name]: parseFloat(e.target.value),
                  }))
                }
                disableAutoComplete
              />
            </div>
          ))}
          <div className="flex-1 mt-2">
            <Button
              label="Edit Rate Factor"
              handleClick={() => updateFac(fac)}
              isSubmitting={isUpdating}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function UpdateGiftcardFac() {
  const [getData, setGetData] = useState(null);

  const { giftcardFac, buyGiftcardFac } = useSelector(
    (state) => state.giftcard
  );

  const {
    user_details: { position },
  } = useSelector((state) => state.profile);

  const [isUpdating, isUpdated, updateFac] = useUpdateGiftcardFac();

  useEffect(() => {
    if (isUpdated) {
      setGetData(null);
    }
  }, [isUpdated]);

  const handleUpdateValue = () => {
    updateFac(getData);
  };

  return (
    <>
      {listContentUpdate.includes(position) && (
        <>
          {giftcardFac && buyGiftcardFac ? (
            <div className="mb-4">
              <SectionContent>
                <div className="flex flex-row items-center justify-between p-2 bg-white rounded dark:bg-slate-800">
                  <div className="flex flex-row items-center justify-start">
                    <div className="flex flex-row items-center justify-center w-8 h-8 rounded bg-slate-50 dark:bg-slate-600">
                      <SimpleIcon icon="ViewAgenda" fontSize={18} />
                    </div>
                    <div>
                      <div className="ml-2">
                        <p className="font-bold dark:text-gray-50">
                          Giftcard Rate Factors
                        </p>
                        <p className="text-sm text-gray-500 truncate dark:text-gray-200">
                          Edit factor rate
                        </p>
                      </div>
                    </div>
                  </div>

                  <Button
                    label="Save"
                    gradient
                    disabled={!getData}
                    handleClick={handleUpdateValue}
                    isSubmitting={isUpdating}
                  />
                </div>
                <div className="mt-2">
                  <div className="flex flex-row gap-2">
                    {[
                      {
                        header: "sellGiftcard",
                        doc: "sellFactors",
                        col: giftcardFac,
                        fields: [
                          {
                            id: "exchangeRateRmbToNgn",
                            name: "Exchange Rate (NGN)",
                          },
                          { id: "commission", name: "Commission ($)" },
                          { id: "vatRate", name: "VAT Rate (%)" },
                        ],
                      },
                      {
                        header: "buyGiftcard",
                        doc: "buyFactors",
                        col: buyGiftcardFac,
                        fields: [
                          {
                            id: "commissionCaps",
                            name: "Commission Caps (NGN)",
                          },
                          { id: "percCommission", name: "Perc Commission (%)" },
                        ],
                      },
                    ].map((item) => (
                      <div className="w-1/2 rounded bg-box dark:bg-gray-800">
                        <div className="p-3 border-b border-gray-200 dark:border-gray-700 dark:text-white">
                          {startCase(item?.header)}
                        </div>
                        <ul className="w-full p-2 divide-y divide-gray-200 dark:divide-gray-700">
                          {item?.fields.map((fItem) => (
                            <li className="py-3 sm:py-4" key={fItem.id}>
                              <div className="flex items-center justify-between space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="font-medium truncate dark:text-white">
                                    {fItem.name}
                                  </p>
                                  <p className="text-sm text-gray-500 truncate dark:text-gray-300">
                                    click to edit
                                  </p>
                                </div>

                                <div className="flex flex-row items-end justify-end flex-1 min-w-0 py-3 pr-6 text-gray-500 truncate">
                                  <input
                                    className="w-full text-right bg-transparent border-none outline-none dark:text-white"
                                    defaultValue={toFixed(
                                      item?.col?.[fItem.id] || 0,
                                      2
                                    )}
                                    placeholder="0.00"
                                    onChange={(e) => {
                                      setGetData({
                                        data: {
                                          [`${fItem?.id}`]: parseFloat(
                                            e.target.value
                                          ),
                                        },
                                        doc: item.doc,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </SectionContent>
            </div>
          ) : null}
        </>
      )}
    </>
  );
}

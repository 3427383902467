import React, { useState } from "react";
import { WeekTab } from "components/Tab";
import { useGetFilterDate } from "api";
import AllTransactions from "../../components/AllTransactions";

export default function CompletedTransaction1() {
  const [currentD, setCurrentD] = useState(new Date());
  const [getTransCount, setGetTransCount] = useState(0);
  const [getFilterDate] = useGetFilterDate();

  return (
    <>
      <WeekTab
        {...{ currentD, setCurrentD, getFilterDate }}
        countNum={getTransCount}
      />
      <div className="mt-2">
        <AllTransactions
          type="current_user"
          setGetTransCount={setGetTransCount}
          currentD={currentD}
        />
      </div>
    </>
  );
}

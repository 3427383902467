import React, { useState } from "react";
import { FullWidthTabs } from "components";
import Profile from "./Profile";
import WalletBalances from "./WalletBalances";
import Transactions from "./Transactions";
import Referrals from "./Referrals";
import CryptoAddress from "./CryptoAddress";
import { useSelector } from "react-redux";

export default function UserDrawer() {
  const { userDetails } = useSelector((state) => state.users);

  const tabList = [
    { label: "Profile", Icon: "AssignmentInd", Comp: Profile },
    {
      label: "Wallet Balances",
      Icon: "AccountBalanceWallet",
      Comp: WalletBalances,
    },
    { label: "Transactions", Icon: "TableChart", Comp: Transactions },
    {
      label: "Wallet Address",
      Icon: "Wallet",
      Comp: CryptoAddress,
      hide: userDetails?.platform !== "esetech",
    },
    { label: "Referrals", Icon: "PersonAdd", Comp: Referrals },
  ];

  const [currentTab, setCurrentTab] = useState(tabList[0]);

  return (
    <div className="m-4 grid grid-cols-1 gap-6 md:grid-cols-1">
      <div className="col-span-1 md:col-span-1">
        <FullWidthTabs
          {...{ currentTab, setCurrentTab, tabList }}
          Comp={
            <currentTab.Comp {...currentTab} platform={userDetails?.platform} />
          }
        />
      </div>
    </div>
  );
}

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Slide, useTheme } from "@mui/material";
import { SimpleIcon, SimpleLoader } from "components";
import { useSelector } from "react-redux";
import { useGetCurrentChatUser } from "api";
import { toLower } from "lodash";
import SwipeableViews from "react-swipeable-views";
import { classNames, formatDateFromNow, truncate } from "utils";
import { ChatContext } from "../ChatContext";
import { decode } from "js-base64";

const pos = {
  "Customer Care Agent": 1,
  Supervisor: 2,
  "Accounting Officer": 3,
  "IT Personnel": 4,
  "Chief Executive Officer": 5,
};

const Contacts = ({ chatUsersObj: chatUsers }) => {
  const { currentChatUserDetails } = useSelector((state) => state.chat || {});
  const { current_user } = useSelector((state) => state.auth || {});

  const [getCurrentChat] = useGetCurrentChatUser();

  const chatId = (currentUser, toUserUid) =>
    currentUser > toUserUid
      ? `${currentUser}${toUserUid}`
      : `${toUserUid}${currentUser}`;

  return (
    <div className="w-full pb-0">
      {chatUsers && chatUsers.length ? (
        <div className="grid grid-cols-1 gap-2">
          {chatUsers.map((item, index) => (
            <div className="col-span-1" key={item.id}>
              <button
                className={classNames(
                  "w-full rounded-lg border-0 p-3 outline-0 duration-200 ease-in hover:-top-2 hover:bg-primary/20 hover:shadow-lg dark:hover:bg-gray-900",
                  currentChatUserDetails?.id === item?.id
                    ? "bg-primary/20 shadow-sm dark:bg-gray-900"
                    : ""
                )}
                onClick={() =>
                  getCurrentChat(
                    item,
                    chatId(current_user, currentChatUserDetails?.id)
                  )
                }
              >
                <div className="relative flex items-center">
                  <img
                    src={require(`assets/avatar/avatar-${
                      toLower(item?.gender) || "female"
                    }.png`)}
                    alt={item.displayName}
                    className="h-8 w-8 rounded-full"
                  />

                  <div className="flex w-full items-start justify-between">
                    <div className="ml-2 flex flex-col items-start text-left">
                      <span className="font-semibold dark:text-white">
                        {truncate(item?.displayName, 20)}
                      </span>
                      <span className="text-sm dark:text-gray-400">
                        {item?.position}
                      </span>
                    </div>
                  </div>
                </div>
              </button>
              {index !== chatUsers.length - 1 && (
                <div className="mt-3 flex w-[100%] justify-end border-b dark:border-gray-700" />
              )}
            </div>
          ))}
        </div>
      ) : (
        <SimpleLoader />
      )}
    </div>
  );
};

const SendEmail = ({ chatUsersObj: chatUsers }) => {
  const { currentChatUserDetails } = useSelector((state) => state.chat || {});

  const [getCurrentChat] = useGetCurrentChatUser();

  return (
    <div className="w-full pb-0">
      {chatUsers && chatUsers.length ? (
        <div className="grid grid-cols-1 gap-2">
          {chatUsers.map((item, index) => (
            <div className="col-span-1" key={item.id}>
              <button
                className={classNames(
                  "w-full rounded-lg border-0 p-3 outline-0 duration-200 ease-in hover:-top-2 hover:bg-primary/20 hover:shadow-lg dark:hover:bg-gray-900",

                  currentChatUserDetails?.id === item?.id
                    ? "bg-primary/20 shadow-sm dark:bg-gray-900"
                    : ""
                )}
                onClick={() => getCurrentChat(item, null)}
              >
                <div className="relative flex items-center">
                  <img
                    src={require(`assets/avatar/avatar-${
                      toLower(item?.gender) || "female"
                    }.png`)}
                    alt={item.displayName}
                    className="h-8 w-8 rounded-full"
                  />

                  <div className="flex w-full items-start justify-between">
                    <div className="ml-2 flex flex-col items-start text-left">
                      <span className="font-semibold dark:text-white">
                        {truncate(item?.displayName, 20)}
                      </span>
                      <span className="text-sm dark:text-gray-400">
                        {item?.position}
                      </span>
                    </div>
                  </div>
                </div>
              </button>
              {index !== chatUsers.length - 1 && (
                <div className="mt-3 flex w-[100%] justify-end border-b dark:border-gray-700" />
              )}
            </div>
          ))}
        </div>
      ) : (
        <SimpleLoader />
      )}
    </div>
  );
};

const Chats = ({ chatUsersObj: chatToUsersList }) => {
  const { currentChatUserDetails } = useSelector((state) => state.chat || {});

  const { current_user } = useSelector((state) => state.auth || {});

  const [getCurrentChat] = useGetCurrentChatUser();

  const { scrollToTheBottom } = useContext(ChatContext);

  useEffect(() => {
    if (currentChatUserDetails) scrollToTheBottom();
  }, [scrollToTheBottom, currentChatUserDetails]);

  return (
    <div className="relative h-full">
      {chatToUsersList ? (
        <>
          {chatToUsersList?.length ? (
            <div className="grid grid-cols-1 gap-0">
              {chatToUsersList?.map((item, index) => {
                const otherUserDetails = item?.otherUsersFullD[0];
                const currentUserDetails = item?.currentUserD;
                const isChatReads = item?.participants?.[current_user]?.unread;

                return (
                  <div className="col-span-1" key={item.id}>
                    <button
                      className={classNames(
                        "w-full rounded-xl border-0 p-3 outline-0 duration-200 ease-in hover:-top-2 hover:bg-primary/20 hover:shadow-lg dark:hover:bg-gray-900",
                        currentChatUserDetails?.id === otherUserDetails?.id
                          ? "bg-primary/20 shadow-none dark:bg-gray-900"
                          : ""
                      )}
                      onClick={() => {
                        getCurrentChat(
                          {
                            ...otherUserDetails,
                            participantsList: item?.participantsList,
                          },
                          item?.id
                        );
                      }}
                    >
                      <div className="relative flex items-center">
                        <img
                          src={require(`assets/avatar/avatar-${
                            toLower(otherUserDetails?.gender) || "female"
                          }.png`)}
                          alt={otherUserDetails?.displayName}
                          className="h-8 w-8 rounded-full"
                        />

                        <div className="flex w-full items-start justify-between">
                          <div className="ml-2 flex flex-col items-start text-left">
                            <span
                              className={classNames(
                                "font-semibold",
                                isChatReads
                                  ? "text-white"
                                  : "dark:text-gray-300"
                              )}
                            >
                              {truncate(otherUserDetails?.displayName, 20)}
                            </span>
                            <span className="mt-1 text-sm dark:text-gray-500">
                              {truncate(decode(item?.lastMessage), 30)}
                            </span>
                          </div>
                        </div>
                        <div
                          className={classNames(
                            "absolute right-0 top-0 text-right text-sm font-thin",
                            isChatReads ? "text-white" : "dark:text-gray-400"
                          )}
                        >
                          {toLower(formatDateFromNow(item?.dateUpdated))}
                        </div>
                        {currentUserDetails?.unread ? (
                          <div className="absolute right-0 bottom-0 h-5 w-5 rounded-full bg-red-500 shadow-sm">
                            <div className="flex h-full w-full items-center justify-center text-xs text-white">
                              {currentUserDetails?.unread}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </button>
                    {/* {index !== chatToUsersList.length - 1 && ( */}
                    <div className="my-2 flex w-[100%] justify-end border-b dark:border-gray-700" />
                    {/* )} */}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex items-center justify-center rounded-xl bg-gray-200 p-4 text-sm dark:bg-gray-700 dark:text-white">
              No previous chat
            </div>
          )}
        </>
      ) : (
        <SimpleLoader />
      )}
    </div>
  );
};

export default function ChatUserList() {
  const theme = useTheme();

  const { tabValue, setTabValue } = useContext(ChatContext);

  const [search, setSearch] = useState("");
  // const [openChatUsersList, setOpenChatUsersList] = useState(false);

  const { chatToUsersList, chatUsers, currentUserUnread } = useSelector(
    (state) => state.chat || {}
  );
  const { user_details } = useSelector((state) => state.profile || {});

  useEffect(() => {
    setSearch("");
  }, [tabValue]);

  const chatUsersObj = useCallback(() => {
    let userListToDisplay;
    if ([1, 2].includes(tabValue)) {
      userListToDisplay = chatUsers;
      //   .filter(
      //   (i) => i.accountStatus === "enabled"
      // );
    } else if (tabValue === 0) {
      userListToDisplay = chatToUsersList;
    }

    const users =
      userListToDisplay &&
      [...userListToDisplay]?.sort(
        (a, b) => pos?.[a.position] - pos?.[b.position]
      );

    if (Boolean(search.trim())) {
      return users?.filter((item) => {
        if ([1, 2].includes(tabValue)) {
          return toLower(item.displayName).includes(toLower(search));
        } else if (tabValue === 0) {
          return toLower(item?.otherUsersFullD?.[0]?.displayName).includes(
            toLower(search)
          );
        }
        return item;
      });
    } else {
      return users;
    }
  }, [chatToUsersList, chatUsers, search, tabValue])();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    // setOpenChatUsersList(false);
  };

  const handleChangeIndex = (index) => {
    setTabValue(index);
  };

  return (
    <div className="h-full border-r-0 dark:border-gray-700">
      <div className="h-full overflow-hidden rounded-tl-xl rounded-bl-xl border-r-0 bg-slate-100 dark:border-gray-700 dark:bg-gray-800">
        <div className="mb-0 flex flex-row items-center gap-2 p-4">
          <div className="relative h-8 w-8">
            <img
              src={require(`assets/avatar/avatar-${
                toLower(user_details?.gender) || "female"
              }.png`)}
              alt={user_details?.displayName}
              className="h-full w-full rounded-full"
            />
            <div className="absolute bottom-0 right-0 h-2 w-2 rounded-full bg-emerald-400" />
          </div>

          <div className="flex w-full flex-1 items-start justify-between">
            <div className="ml-2 flex flex-col items-start text-left">
              <span className="font-semibold dark:text-gray-300">
                {truncate(user_details?.displayName, 20)}
              </span>
              <span className="mt-0 text-sm dark:text-gray-500">
                {truncate(user_details?.position, 30)}
              </span>
            </div>
          </div>
        </div>

        <div className="px-2">
          <div className="mb-3 flex flex-row items-center rounded-xl border-0 bg-white p-2 dark:bg-gray-900">
            <SimpleIcon icon="Search" fontSize={16} otherClass="mr-2" />
            <input
              className="w-full bg-transparent outline-0 placeholder:text-sm dark:text-gray-300"
              placeholder="People, messages"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="relative h-full">
          <div className="bg-gray-700-0 flex flex-row items-center justify-between border-b p-2 dark:border-gray-700">
            {[
              {
                label: "Chats",
                id: "chats",
                icon: "Chat",
                iconSize: 18,
                applyBatch: currentUserUnread,
              },
              {
                label: "Contacts",
                id: "contacts",
                icon: "PermContactCalendar",
                iconSize: 20,
              },
              {
                label: "Send Email",
                id: "sendEmail",
                icon: "Email",
                iconSize: 20,
              },
            ].map((item, index) => (
              <div className="relative h-full w-full" key={index}>
                <button
                  onClick={(e) => handleChange(e, index)}
                  className={classNames(
                    "flex h-full w-full flex-col items-center justify-center rounded-xl border-0 p-2 py-3 outline-0",
                    tabValue === index ? "bg-white dark:bg-gray-700/50" : ""
                  )}
                  disabled={item.disabled}
                >
                  <SimpleIcon
                    icon={item.icon}
                    fontSize={item.iconSize}
                    frontColor={
                      tabValue === index
                        ? "dark:text-light-primary text-primary"
                        : "text-gray-400"
                    }
                  />
                  <div
                    className={classNames(
                      "mt-1 text-sm",
                      tabValue === index
                        ? "font-bold text-primary dark:text-light-primary"
                        : "text-gray-400"
                    )}
                  >
                    {item.label}
                  </div>
                </button>
                {item.applyBatch ? (
                  <div className="absolute top-2 right-7">
                    <div className="flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-xs text-white">
                      {item.applyBatch}
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>

          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={tabValue}
            onChangeIndex={handleChangeIndex}
            className="!mt-2 !h-[330px] px-2"
          >
            {[
              {
                Page: Chats,
                id: "chats",
                props: { chatUsersObj },
              },
              {
                Page: Contacts,
                id: "contacts",
                props: { chatUsersObj },
              },
              {
                Page: SendEmail,
                id: "sendEmail",
                props: { chatUsersObj },
              },
            ].map((item, index) => (
              <div
                role="tabpanel"
                hidden={tabValue !== index}
                aria-labelledby={`full-width-tab-${index}`}
                key={item.id}
                value={tabValue}
                index={index}
                dir={theme.direction}
                classes={{
                  root: "!h-full",
                }}
              >
                {tabValue === index && <item.Page {...item.props} />}
              </div>
            ))}
          </SwipeableViews>

          {/* <Slide
            direction="up"
            in={openChatUsersList}
            mountOnEnter
            unmountOnExit
          >
            <div className="absolute top-0 right-0 h-full w-full overflow-auto rounded-xl bg-box dark:bg-slate-800">
              <div className="flex items-center justify-center rounded-xl bg-gray-200 p-4 text-sm dark:bg-gray-700 dark:text-white ">
                Create Group
              </div>
            </div>
          </Slide>
          <button
            className="bottom-2 right-0 flex h-8 w-8 items-center justify-center rounded-full bg-emerald-800"
            onClick={() => setOpenChatUsersList(!openChatUsersList)}
          >
            <SimpleIcon icon="AddCircle" fontSize={20} />
          </button> */}
        </div>
      </div>
    </div>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import isUrl from "is-url";
import { isHotkey } from "is-hotkey";
import { Editable, withReact, useSlate } from "slate-react";
import * as SlateReact from "slate-react";
import {
  Transforms,
  Editor,
  Range,
  createEditor,
  Element as SlateElement,
  Text,
} from "slate";
import { withHistory } from "slate-history";
import { classNames } from "utils";
import { SimpleIcon } from "./Icon";
import escapeHtml from "escape-html";

const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={classNames(className, "absolute bottom-0 flex gap-2")}
  ></div>
));

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};
const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const serialize = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    return string;
  }

  const children = node.children.map((n) => serialize(n)).join("");

  switch (node.type) {
    case "quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "paragraph":
      return `<p>${children}</p>`;
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    default:
      return children;
  }
};

const RichTextEditor = ({ setEditorContent, isEmailSent }) => {
  const initialEditorValue = JSON.parse(
    localStorage.getItem("esetech_email_content")
  ) || [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ];

  const [editorValue, setEditorValue] = useState(initialEditorValue);

  const editor = useMemo(
    () => withInlines(withHistory(withReact(createEditor()))),
    []
  );

  const renderElement = useCallback((props) => <Element {...props} />, []);

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  useEffect(() => {
    if (isEmailSent) {
      localStorage.removeItem("esetech_email_content");
      editor.children = [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ];
    }
  }, [isEmailSent]);

  useEffect(() => {
    const parseHtml = serialize({ children: editorValue });
    setEditorContent(parseHtml);
  }, [editorValue]);

  const onKeyDown = (event) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event;
      if (isHotkey("left", nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: "offset", reverse: true });
        return;
      }
      if (isHotkey("right", nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: "offset" });
        return;
      }
    } else {
      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event)) {
          event.preventDefault();
          const mark = HOTKEYS[hotkey];
          toggleMark(editor, mark);
        }
      }
    }
  };

  const onHandleChange = (value) => {
    const isAstChange = editor.operations.some(
      (op) => "set_selection" !== op.type
    );
    if (isAstChange) {
      const content = JSON.stringify(value);
      localStorage.setItem("esetech_email_content", content);
      setEditorValue(value);
    }
  };

  return (
    <div className="relative h-full">
      <SlateReact.Slate
        editor={editor}
        value={editorValue}
        onChange={onHandleChange}
      >
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Your email ..."
          spellCheck
          // autoFocus
          onKeyDown={onKeyDown}
          className="rich-text-editor !h-[280px] !overflow-auto dark:!text-white"
        />

        <Toolbar>
          <MarkButton
            format="bold"
            icon="format_bold"
            simpleIcon="FormatBold"
          />
          <MarkButton
            format="italic"
            icon="format_italic"
            simpleIcon="FormatItalic"
          />
          <MarkButton
            format="underline"
            icon="format_underlined"
            simpleIcon="FormatUnderlined"
          />
          <MarkButton format="code" icon="code" simpleIcon="Code" />

          <BlockButton
            format="heading-one"
            icon="looks_one"
            simpleIcon="LooksOne"
          />
          <BlockButton
            format="heading-two"
            icon="looks_two"
            simpleIcon="LooksTwo"
          />
          <BlockButton
            format="block-quote"
            icon="format_quote"
            simpleIcon="FormatQuote"
          />
          <BlockButton
            format="numbered-list"
            icon="format_list_numbered"
            simpleIcon="FormatListNumbered"
          />
          <BlockButton
            format="bulleted-list"
            icon="format_list_bulleted"
            simpleIcon="FormatListBulleted"
          />
          <BlockButton
            format="left"
            icon="format_align_left"
            simpleIcon="FormatAlignLeft"
          />
          <BlockButton
            format="center"
            icon="format_align_center"
            simpleIcon="FormatAlignCenter"
          />
          <BlockButton
            format="right"
            icon="format_align_right"
            simpleIcon="FormatAlignRight"
          />
          <BlockButton
            format="justify"
            icon="format_align_justify"
            simpleIcon="FormatAlignJustify"
          />
          <AddLinkButton />
          <RemoveLinkButton />
        </Toolbar>
      </SlateReact.Slate>
    </div>
  );
};

/////

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
  return !!link;
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

/////

const withInlines = (editor) => {
  const { insertData, insertText, isInline, isElementReadOnly, isSelectable } =
    editor;

  editor.isInline = (element) =>
    ["link", "button", "badge"].includes(element.type) || isInline(element);

  editor.isElementReadOnly = (element) =>
    element.type === "badge" || isElementReadOnly(element);

  editor.isSelectable = (element) =>
    element.type !== "badge" && isSelectable(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

//////////

const InlineChromiumBugfix = () => (
  <span contentEditable={false} className="text-[0px]">
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

const Element = (props) => {
  const { attributes, children, element } = props;
  const style = { textAlign: element.align };

  switch (element.type) {
    case "block-quote":
      return (
        <blockquote
          style={style}
          {...attributes}
          className="my-4 rounded border-l-2 bg-gray-50/5 py-2 pl-4 italic dark:border-gray-200"
        >
          {children}
        </blockquote>
      );

    case "heading-one":
      return (
        <h1 style={style} {...attributes} className="!text-2xl">
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes} className="!text-xl">
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes} className="!list-disc !pl-4">
          {children}
        </ul>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes} className="!list-decimal !pl-4">
          {children}
        </ol>
      );

    case "link":
      return (
        <a
          {...attributes}
          href={element.url}
          className="!text-primary !underline"
        >
          <InlineChromiumBugfix />
          {children}
          <InlineChromiumBugfix />
        </a>
      );

    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = (
      <code className="!rounded !bg-transparent-main !p-1 !text-[#ff1493]">
        {children}
      </code>
    );
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.link) {
    children = (
      <span
        className={classNames(leaf.text === "" ? "pl-[0.1px]" : "")}
        {...attributes}
      >
        {children}
      </span>
    );
  }

  return <span {...attributes}>{children}</span>;
};

//////
const Button = ({ simpleIcon, isActive, onMouseDown }) => {
  return (
    <button
      // active={Boolean(isActive)}
      onMouseDown={onMouseDown}
      className={classNames(
        "flex items-center justify-center rounded-md border p-1 shadow-dialog active:bg-gray-600 dark:border-gray-600"
      )}
    >
      <SimpleIcon
        icon={simpleIcon}
        fontSize={16}
        frontColor={
          isActive ? "text-light-primary" : "dark:text-gray-100 text-gray-600"
        }
      />
    </button>
  );
};

const BlockButton = ({ format, icon, simpleIcon }) => {
  const editor = useSlate();
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  return (
    <Button
      isActive={isActive}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      simpleIcon={simpleIcon}
    />
  );
};

const MarkButton = ({ format, icon, simpleIcon }) => {
  const editor = useSlate();

  const isActive = isMarkActive(editor, format);
  return (
    <Button
      isActive={isActive}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      simpleIcon={simpleIcon}
    />
  );
};

const AddLinkButton = () => {
  const editor = useSlate();
  const isActive = isLinkActive(editor);

  return (
    <Button
      isActive={isActive}
      onMouseDown={(event) => {
        event.preventDefault();
        const url = window.prompt("Enter the URL of the link:");
        if (!url) return;
        insertLink(editor, url);
      }}
      simpleIcon={"InsertLink"}
    />
  );
};

const RemoveLinkButton = () => {
  const editor = useSlate();
  // const isActive = isLinkActive(editor);
  const isActive = null;

  return (
    <Button
      isActive={isActive}
      onMouseDown={(event) => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}
      simpleIcon={"LinkOff"}
    />
    // <SimpleIcon icon={"LinkOff"} fontSize={16} frontColor={"text-gray-500"} />
  );
};

export default RichTextEditor;

import React, { useEffect, useState } from "react";
import { DrawerSide, SectionContent, SimpleIcon } from "components";
import { capitalize } from "lodash";
import DatePicker from "../../components/DatePicker";
import { useGetTransReportDateRange } from "api";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import WithdrawalSummary from "./WithdrawalSummary";
import CryptoSummary from "./CryptoSummary";
import UtilitySummary from "./UtilitySummary";
import GiftcardSummary from "./GiftcardSummary";
import BonusSummary from "./BonusSummary";

const pageS = {
  withdrawal: WithdrawalSummary,
  crypto: CryptoSummary,
  utilities: UtilitySummary,
  giftcard: GiftcardSummary,
  bonus: BonusSummary,
  // fund: GiftcardSummary,
};

export default function Index({ summaryPage, setOpenSummary, platform }) {
  const { transReportDateRange } = useSelector((state) => state.util);

  const { startDate, endDate, calendarType } = transReportDateRange || {};

  const [openDateRange, setOpenDateRange] = useState(false);
  const [getDate, setDate] = useState({
    startDate: startDate || dayjs().startOf("day"),
    endDate: endDate || dayjs().endOf("day"),
  });

  const [calendarTypeF, setCalendarTypeF] = useState(
    transReportDateRange?.calendarType || "day"
  );

  const [getReportDateRange] = useGetTransReportDateRange();

  const handleSelectDate = () => {
    const getTimeVal = (no, type) => {
      if (type === "start") {
        return startDate.subtract(no, calendarTypeF).startOf(calendarTypeF);
      } else if (type === "end") {
        return endDate.subtract(no, calendarTypeF).endOf(calendarTypeF);
      }
      return null;
    };
    const { startDate, endDate } = getDate || {};
    getReportDateRange({
      ...getDate,
      startDateValueOfPrev1: getTimeVal(1, "start").valueOf(),
      endDateValueOfPrev1: getTimeVal(1, "end").valueOf(),
      startDateValueOfPrev2: getTimeVal(2, "start").valueOf(),
      endDateValueOfPrev2: getTimeVal(2, "end").valueOf(),
      startDatePrev1: getTimeVal(1, "start"),
      endDatePrev1: getTimeVal(1, "end"),
      startDatePrev2: getTimeVal(2, "start"),
      endDatePrev2: getTimeVal(2, "end"),
      startDateValueOf: startDate?.valueOf(),
      endDateValueOf: endDate?.valueOf(),
      startDateString: startDate?.format("YYYY-MM-DD"),
      endDateString: endDate?.format("YYYY-MM-DD"),
      calendarType: calendarTypeF,
    });
  };

  useEffect(() => {
    handleSelectDate();

    return () => {
      getReportDateRange(null);
    };
  }, [getDate]);

  const dateRange = startDate
    ? `${startDate.format("Do MMM, YY")} - ${endDate.format("Do MMM, YY")}`
    : "";

  const calendarTypeFormat = {
    day: "daily",
    week: "weekly",
    month: "monthly",
  };

  const Page = pageS[summaryPage];

  return (
    <div className="p-2 dark:bg-gray-800">
      <div className="mb-2 flex items-center justify-between gap-2">
        <div className="">
          <button
            onClick={() => {
              setOpenSummary(false);
            }}
            className="flex h-10 w-10 items-center justify-center rounded-full bg-primary"
          >
            <SimpleIcon
              icon="ArrowBack"
              fontSize={20}
              frontColor="text-white"
            />
          </button>
        </div>

        <div className="flex gap-2 text-sm dark:text-white">
          <SectionContent>
            <div className="rounded-lg p-0 shadow-card-box">
              <div className="relative rounded bg-white p-2 dark:bg-gray-800">
                <div className="my-2 mr-2 flex flex-row items-center justify-center gap-3 px-4 text-sm dark:text-white">
                  {capitalize(calendarTypeFormat?.[calendarType])} Report
                  <span>|</span>
                  <div className="font-bold">{dateRange}</div>
                </div>
              </div>
            </div>
          </SectionContent>

          <SectionContent>
            <button
              className="rounded-lg p-0 shadow-card-box"
              onClick={() => setOpenDateRange(true)}
            >
              <div className="relative rounded bg-white p-2 dark:bg-gray-800">
                <div className="my-2 flex flex-row items-center justify-center px-4">
                  <SimpleIcon
                    icon="CalendarMonth"
                    fontSize={16}
                    frontColor="dark:text-gray-200"
                  />

                  <div className="ml-2 mr-4 text-sm dark:text-white">
                    Select Date Range
                  </div>
                  <SimpleIcon
                    icon="KeyboardArrowRight"
                    fontSize={20}
                    frontColor="dark:text-gray-200"
                  />
                </div>
              </div>
            </button>
          </SectionContent>
        </div>
      </div>

      <div className="flex flex-col gap-y-6">
        <div className="shadow-xs relative w-full overflow-x-auto rounded border-0 border-slate-100 bg-box p-2 shadow-sm dark:border-gray-500 dark:bg-gray-900">
          <div className="mb-2 rounded border-b-0 border-slate-200 p-3 font-bold text-primary dark:border-slate-500 dark:bg-gray-800 dark:text-light-primary">
            <div className="flex items-center justify-between">
              <div>{capitalize(summaryPage)} Transactions</div>
            </div>
          </div>
          {Page ? <Page platform={platform} /> : null}
        </div>
      </div>
      <DrawerSide {...{ open: openDateRange, setOpen: setOpenDateRange }}>
        <div className="relative h-full">
          <DatePicker
            setOpenDateRange={setOpenDateRange}
            {...{
              getDate,
              setDate,
              handleSelectDate,
              calendarType: calendarTypeF,
              setCalendarType: setCalendarTypeF,
            }}
          />
        </div>
      </DrawerSide>
    </div>
  );
}

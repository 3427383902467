import React, { useEffect, useState } from "react";
import {
  Button,
  DrawerSide,
  SectionContent,
  SimpleIcon,
  TextField,
  PopOver,
} from "components";
import { useAddStaffToTeam, useCreateStaffTeam } from "api";
import { useSelector } from "react-redux";
import { capitalize, pick, toLower } from "lodash";
import { CircularProgress, Tooltip } from "@mui/material";
import { PageContainer } from "shared";

const Icon = ({ icon }) => (
  <SimpleIcon icon={icon} frontColor="text-gray-300" fontSize={18} />
);

const Card = ({
  staffHaveTeam,
  staffHaveNoTeam,
  item,
  addStaffToTeam,
  isUpdatingAddStaff,
  actionType,
  setOpenDrawer,
  setTeamId,
}) => {
  const [currentHoverId, setCurrentHoverId] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [openPopover, setOpenPopover] = useState(null);

  const { teamName, id } = item;

  return (
    <SectionContent>
      <div className="w-full rounded border border-slate-100 bg-box p-2 shadow-sm dark:border-gray-500 dark:bg-gray-900">
        <div className="flex items-center justify-between">
          <div className="font-semibold text-primary dark:text-light-primary">
            {capitalize(teamName)} Team
          </div>
          <Tooltip title="Edit">
            <button
              onClick={() => {
                setTeamId(id);
                setOpenDrawer("edit");
              }}
            >
              <SimpleIcon
                icon="AutoAwesomeMosaic"
                fontSize={20}
                frontColor="text-cyan-500"
              />
            </button>
          </Tooltip>
        </div>
        <div className="flow-root">
          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            {staffHaveTeam?.length ? (
              staffHaveTeam.map((item) => {
                return (
                  <>
                    {item.teamId === id && (
                      <li
                        className="relative py-3"
                        onMouseEnter={() => setCurrentHoverId(item.id)}
                        onMouseLeave={() => setCurrentHoverId(null)}
                      >
                        {isUpdatingAddStaff &&
                          actionType === "deleted" &&
                          item.id === currentId && (
                            <div className="absolute top-0 left-0 flex h-full w-full flex-row items-center justify-center bg-zinc-900/40">
                              <CircularProgress color="success" size={20} />
                            </div>
                          )}

                        {currentHoverId === item.id && (
                          <button
                            className="absolute top-4 right-0 duration-500 ease-in"
                            onClick={() => {
                              setCurrentId(item.id);
                              addStaffToTeam({
                                staffId: item.id,
                                teamId: id,
                                action: "deleted",
                              });
                            }}
                          >
                            <SimpleIcon
                              icon="Cancel"
                              fontSize={20}
                              frontColor="text-red-500"
                            />
                          </button>
                        )}
                        <div className="flex items-center space-x-4">
                          <div className="flex-shrink-0">
                            <img
                              className="h-8 w-8 rounded-full"
                              src={require(`assets/avatar/avatar-${
                                toLower(item?.gender) || "female"
                              }.png`)}
                              alt={item?.displayName}
                            />
                          </div>
                          <div className="min-w-0 flex-1">
                            <p className="truncate font-medium  dark:text-white">
                              {item.displayName}
                            </p>
                            <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                              {item.email}
                            </p>
                          </div>
                        </div>
                      </li>
                    )}
                  </>
                );
              })
            ) : (
              <li className="py-3">
                <div className="flex items-center space-x-4">
                  No team member added
                </div>
              </li>
            )}

            <li className="pt-3">
              <PopOver
                open={openPopover}
                setOpen={setOpenPopover}
                PopoverHandlerComponent={() => (
                  <div
                    onClick={() => {
                      setOpenPopover(!openPopover);
                    }}
                  >
                    <Button
                      fullWidth
                      label="Add Staff"
                      isSubmitting={
                        isUpdatingAddStaff && actionType === "added"
                      }
                    />
                  </div>
                )}
                PopoverContentComponent={() => (
                  <div>
                    <div className="mb-4">
                      <TextField
                        label="Search"
                        size="small"
                        shrink="false"
                        startAdornment={<Icon icon="PersonSearch" />}
                      />
                    </div>
                    <ul className="-my-3 -mx-1 h-40 divide-y divide-gray-200 overflow-auto dark:divide-gray-500">
                      {staffHaveNoTeam?.length ? (
                        staffHaveNoTeam.map((item) => (
                          <li className="my-2 w-full" key={item.id}>
                            <button
                              className="w-full rounded bg-box p-2 text-left dark:bg-gray-900"
                              onClick={() => {
                                addStaffToTeam({
                                  staffId: item.id,
                                  teamId: id,
                                  action: "added",
                                });
                              }}
                            >
                              <div className=" dark:text-white">
                                {item?.displayName}
                              </div>
                              <div className="text-sm dark:text-gray-200">
                                {toLower(item?.email)}
                              </div>
                            </button>
                          </li>
                        ))
                      ) : (
                        <li className="my-2 w-full p-2 dark:text-gray-200">
                          No data
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              />
            </li>
          </ul>
        </div>
      </div>
    </SectionContent>
  );
};

export default function AddTeam() {
  const [open, setOpen] = useState(false);
  const [getTeamName, setTeamName] = useState("");
  const [getTeamId, setTeamId] = useState(null);
  const { staffTeam, staffDetails } = useSelector((state) => state.staff);

  const isStaffInTeam = (haveTeam) =>
    staffDetails
      .filter(
        (item) =>
          (haveTeam ? Boolean(item?.teamId) : !Boolean(item?.teamId)) &&
          item.position === "Customer Care Agent"
      )
      .map((item) => {
        return pick(item, ["id", "displayName", "email", "gender", "teamId"]);
      });

  const type = open;
  const currentTeamObj = staffTeam.find((i) => i.id === getTeamId);

  useEffect(() => {
    if (type === "create") {
      setTeamName("");
    } else if (type === "edit") {
      setTeamName(currentTeamObj?.teamName);
    }
  }, [type]);

  const [isUpdating, isUpdated, createTeam] = useCreateStaffTeam();
  const [isUpdatingAddStaff, isUpdatedAddStaff, addStaffToTeam, actionType] =
    useAddStaffToTeam();

  useEffect(() => {
    if (isUpdated) setOpen(false);
  }, [isUpdated]);

  return (
    <PageContainer>
      <SectionContent>
        <div className="w-full rounded border border-slate-100 bg-box p-2 shadow-sm dark:border-gray-500 dark:bg-gray-700">
          <div className="grid grid-cols-3 gap-3">
            {staffTeam?.length ? (
              staffTeam
                .slice()
                ?.sort((a, b) => (a.dateCreated > b.dateCreated ? 1 : -1))
                ?.map((item) => (
                  <div className="col-span-1">
                    <Card
                      key={item.id}
                      staffHaveNoTeam={isStaffInTeam(false)}
                      staffHaveTeam={isStaffInTeam(true)}
                      item={item}
                      addStaffToTeam={addStaffToTeam}
                      isUpdatingAddStaff={isUpdatingAddStaff}
                      actionType={actionType}
                      setOpenDrawer={setOpen}
                      setTeamId={setTeamId}
                    />
                  </div>
                ))
            ) : (
              <div className="col-span-3">
                <div className="flex h-32 flex-row items-center justify-center bg-box dark:bg-gray-800 dark:text-gray-200">
                  No Team Created
                </div>
              </div>
            )}

            <div className="col-span-3">
              <button
                className="relative flex h-20 w-full flex-row items-center justify-center rounded border-2 border-dashed bg-white shadow-sm dark:border-gray-500 dark:bg-gray-900"
                onClick={() => setOpen("create")}
              >
                <SimpleIcon
                  icon="AddCircleOutline"
                  otherClass="iconShadow-alt"
                  frontColor="text-gray-300"
                  fontSize={40}
                />
              </button>
            </div>
          </div>
        </div>
      </SectionContent>

      <DrawerSide {...{ open: Boolean(open), setOpen }}>
        <div className="">
          <div className="border-b border-slate-100 p-4">
            {capitalize(type)} Team
          </div>
          <div className="mt-2 grid grid-cols-1 gap-4 p-4">
            <div className="col-span-1">
              <TextField
                label={`${capitalize(type)} Team Name`}
                placeholder={`Team Name`}
                value={getTeamName}
                handleChange={(e) => {
                  setTeamName(e.target.value);
                }}
                startAdornment={<Icon icon="Person" />}
              />
            </div>
            <div className="col-span-1">
              <Button
                gradient
                fullWidth
                label={capitalize(type)}
                isSubmitting={isUpdating}
                handleClick={() =>
                  createTeam({
                    teamName: getTeamName,
                    teamId: getTeamId,
                    type: type,
                  })
                }
              />
            </div>
          </div>
        </div>
      </DrawerSide>
    </PageContainer>
  );
}

import React, { Fragment, useEffect, useState } from "react";
import { startCase } from "lodash";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
// import Calendar from 'react-calendar';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { classNames } from "utils";
import { SimpleIcon, IconButton, SectionContent } from ".";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
var isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

export function HorizontalTab({ list, contents }) {
  const [active, setActive] = useState(0);

  const content = contents[active];

  return (
    <div>
      <ul className="flex items-center justify-start">
        {list.map((item, index) => (
          <li
            key={item}
            className={classNames(
              active === index
                ? " rounded bg-[#6967ce] text-white shadow-[0_5px_35px_0_rgba(0,0,0,0.3)]"
                : "",
              "text-center"
            )}
          >
            <button
              className="h-full w-full p-4 text-sm"
              onClick={() => setActive(index)}
            >
              {startCase(item)}
            </button>
          </li>
        ))}
      </ul>
      <div className="mt-6 p-2">{content}</div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className="w-full rounded bg-slate-50 shadow-header"
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
    classes: {
      root: "!capitalize !px-8 !items-start",
    },
  };
}

export function VerticalTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        // bgcolor: 'background.paper',
        display: "flex",
        // height: 224,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
        classes={{
          vertical: "mr-5 bg-slate-50 shadow-header rounded !py-4",
          indicator: "!w-[4px] rounded",
        }}
      >
        <Tab label="Profile" {...a11yProps(0)} />
        <Tab label="Referral" {...a11yProps(1)} />
        <Tab label="Item Three" {...a11yProps(2)} />
        <Tab label="Item Four" {...a11yProps(3)} />
        <Tab label="Item Five" {...a11yProps(4)} />
        <Tab label="Item Six" {...a11yProps(5)} />
        <Tab label="Item Seven" {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        Item One
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel>
    </Box>
  );
}

export function FullWidthTabs({ tabList, currentTab, setCurrentTab, Comp }) {
  return (
    <div className="overflow-auto">
      <SectionContent>
        <div className="shadow-card- rounded-lg bg-box p-0 dark:bg-dark-primary">
          <AppBar
            // color="light"
            position="static"
            classes={{
              root: classNames("text-white bg-white dark:!bg-dark-primary"),
            }}
          >
            <div className={classNames("flex", "flex-row items-center")}>
              {tabList?.length &&
                tabList?.map((item) => (
                  <Fragment key={item?.label}>
                    {!Boolean(item?.hide) && (
                      <button
                        key={item?.label}
                        className={classNames(
                          "relative flex flex-row items-center pr-4 text-white",
                          "p-3",
                          currentTab?.label === item?.label
                            ? "bg-current-nav dark:bg-dark-current-nav"
                            : ""
                        )}
                        onClick={() => {
                          setCurrentTab(item);
                        }}
                      >
                        {item?.count ? (
                          <div className="absolute top-1 right-0 flex h-6 w-6 flex-row items-center justify-center rounded-full bg-red-400 text-xs font-bold shadow-md">
                            {item?.count}
                          </div>
                        ) : null}
                        <SimpleIcon
                          icon={item?.Icon}
                          fontSize={18}
                          frontColor="text-white"
                          otherClass="iconShadow-alt"
                        />
                        <span className="ml-2 font-medium">{item?.label}</span>
                      </button>
                    )}
                  </Fragment>
                ))}
            </div>
          </AppBar>
        </div>
      </SectionContent>
      <div className="mt-2">{Comp}</div>
    </div>
  );
}

export function PathTab({ list, handleClick, countNum, path, labelFormat }) {
  return (
    <AppBar
      // color="light"
      position="static"
      classes={{
        root: classNames(
          "text-white bg-white dark:!bg-dark-primary !w-[90%] !h-12 !rounded !overflow-hidden"
        ),
      }}
    >
      {/* mx-1 h-12 w-full overflow-hidden rounded bg-box shadow-lg */}
      <div className={"h-full w-full"}>
        <ul className={"flex h-full flex-row items-center justify-between"}>
          {list &&
            list.map((l, index) => (
              <li
                key={index}
                className={classNames(
                  "relative flex h-full w-full flex-row items-center justify-center",
                  path === l
                    ? "bg-current-nav text-white dark:bg-dark-current-nav"
                    : ""
                )}
              >
                {countNum && path === l ? (
                  <div className="absolute top-1 right-0 flex h-6 w-6 flex-row items-center justify-center rounded-full bg-red-400 text-xs font-bold text-white shadow-md">
                    {countNum}
                  </div>
                ) : null}
                <button
                  onClick={() => handleClick(l)}
                  className="flex h-full w-full flex-row items-center justify-center"
                >
                  {/* <SimpleIcon
                    icon="WatchLater"
                    frontColor="text-white"
                    fontSize={18}
                    otherClass="iconShadow-alt"
                  /> */}
                  <div className="text-sm">{labelFormat(l)}</div>
                </button>
              </li>
            ))}
        </ul>
      </div>
    </AppBar>
  );
}

export function WeekTab({ currentD, setCurrentD, getFilterDate, countNum }) {
  const { path } = useLocation();

  const [getWeekD, setGetWeekD] = useState(null);
  const format = "Do MMM, YYYY";
  const momentDate = (d, formatD = format) => dayjs(d).format(formatD);

  const startOfWeek = dayjs(currentD).startOf("isoWeek");
  const endOfWeek = dayjs(currentD).endOf("isoWeek");

  useEffect(() => {
    const weekD = [...Array(7)].map((_, ind) => {
      const dd = startOfWeek.clone().add(ind, "d");
      return dd.format("YYYY/MM/DD");
    });
    setGetWeekD(weekD);

    getFilterDate({
      startDate: dayjs(currentD).startOf("day").valueOf(),
      endDate: dayjs(currentD).endOf("day").valueOf(),
    });
    return () => {
      getFilterDate();
    };
  }, [currentD]);

  useEffect(() => {
    setCurrentD(new Date());
  }, [path]);

  const handleWeekNav = (dayNav) => {
    const mm = dayjs(currentD);
    const tt = dayNav === "add" ? mm.add(7, "d") : mm.subtract(7, "d");
    setCurrentD(tt.format("YYYY/MM/DD"));
  };

  return (
    <div className="flex flex-col items-end">
      <div className="mb-2">
        <SectionContent>
          <div className="rounded-lg p-0 shadow-card-box">
            <div className="relative rounded bg-white p-2 dark:bg-gray-800">
              <div className="my-2 flex flex-row items-center justify-end">
                <div className="dark:text-white">
                  {`${momentDate(startOfWeek)} - ${momentDate(endOfWeek)}`}
                </div>
                <div className="relative mr-10 bg-white">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Date desktop"
                      inputFormat="MM/DD/YYYY"
                      // value={value}
                      onChange={(e) => {
                        setCurrentD(e);
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant="standard"
                            label=""
                            InputProps={{
                              ...params.InputProps,
                              classes: {
                                root: "!w-0 !h-0",
                                input: "!invisible",
                              },
                              sx: [
                                {
                                  "& .MuiButtonBase-root": {
                                    position: "absolute",
                                    top: -8,
                                    color: "green !important",
                                  },
                                },
                              ],
                            }}
                          />
                        );
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>
        </SectionContent>
      </div>
      <div className="w-full">
        <SectionContent>
          <div className={"flex flex-row items-center justify-between"}>
            <div className="flex w-[5%] flex-1 justify-start">
              <IconButton
                icon="KeyboardArrowLeft"
                color="primary"
                handleClick={() => handleWeekNav("subtract")}
              />
            </div>
            <PathTab
              list={getWeekD}
              handleClick={(d) => setCurrentD(d)}
              path={momentDate(currentD, "YYYY/MM/DD")}
              labelFormat={(l) => momentDate(l, "ddd, DD MMM")}
              countNum={countNum}
              evenSpace
            />
            <div className="flex w-[5%] flex-1 justify-end">
              <IconButton
                icon="KeyboardArrowRight"
                color="primary"
                handleClick={() => handleWeekNav("add")}
              />
            </div>
          </div>
        </SectionContent>
      </div>
    </div>
  );
}

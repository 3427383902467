import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current_user: null,
  current_user_details: null,
  isLoading: true,
  isLoaded: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getCurrentUser: (state, { payload }) => ({
      ...payload,
    }),
  },
});

export const { getCurrentUser } = authSlice.actions;

export default authSlice.reducer;

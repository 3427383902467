import { Route } from "react-router-dom";
import {
  Home,
  Dashboard,
  Transaction,
  Users,
  StaffRoles,
  ManageStaff,
  AddTeam,
  InviteStaff,
  ProcessTransaction,
  CheckTransaction,
  DailyTransaction,
  WalletBalances,
  AutomatedWithdrawal,
  TransactionReport,
  GiftcardRate,
  CryptoRate,
  FiatRate,
  UtilsUpdate,
  SiteAdvertPopup,
  PushNotification,
  Settings,
  ChatBox,
  Marketing,
} from "../pages";
import { UserIsAuthenticated } from "./AuthProvider";

const PrivateRoutes = (
  <Route>
    {[
      { path: "/", element: Home },
      { path: "/dashboard", element: Dashboard },
      { path: "/transaction", element: Transaction },
      { path: "/users", element: Users },
      { path: "/chatbox", element: ChatBox },

      { path: "/staff/staff-roles", element: StaffRoles },
      { path: "/staff/manage-staff", element: ManageStaff },
      { path: "/staff/add-team", element: AddTeam },
      { path: "/staff/invite-staff", element: InviteStaff },

      { path: "/process-transaction", element: ProcessTransaction },
      { path: "/check-transaction", element: CheckTransaction },
      { path: "/daily-transaction", element: DailyTransaction },
      { path: "/wallets-balances", element: WalletBalances },
      { path: "/automated-transactions", element: AutomatedWithdrawal },

      { path: "/transaction-reports", element: TransactionReport },
      { path: "/marketing-reports", element: Marketing },

      { path: "/settings", element: Settings },

      { path: "/rate-update/giftcard", element: GiftcardRate },
      { path: "/rate-update/crypto", element: CryptoRate },
      { path: "/rate-update/fiat", element: FiatRate },
      { path: "/rate-update/utils", element: UtilsUpdate },

      { path: "/content-update/advert-popup", element: SiteAdvertPopup },
      { path: "/content-update/push-notification", element: PushNotification },
    ].map((r, ind) => (
      <Route
        key={ind}
        exact
        path={r.path}
        element={
          <UserIsAuthenticated>
            <r.element />
          </UserIsAuthenticated>
        }
      />
    ))}
  </Route>
);

export default PrivateRoutes;

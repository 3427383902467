import React, { useState } from "react";
import { PageContainer } from "shared";
import { FullWidthTabs } from "components";
import PendingTransaction from "./PendingTransaction";
import ActiveTransaction from "./ActiveTransaction";
import DeferredTransaction from "./DeferredTransaction";
import CompletedTransaction from "./CompletedTransaction";

import { useAllTrans } from "utils/allTransactionProps";
import { useSelector } from "react-redux";

export default function Index() {
  const { user_details } = useSelector((state) => state.profile);

  const useTrans = (id) =>
    useAllTrans().filter((item) => item?.id.includes(id) && item.data?.length);

  const useTransCount = (id) =>
    useTrans(id)?.reduce((acc, item) => {
      acc += item?.data?.length || 0;
      return acc;
    }, 0);

  const tabList = [
    {
      label: "Pending",
      Icon: "TableChart",
      Comp: PendingTransaction,
      count: useTransCount("pending"),
    },
    {
      label: "Active",
      Icon: "TableChart",
      Comp: ActiveTransaction,
      count: useTransCount("active"),
    },
    {
      label: "Deferred",
      Icon: "TableChart",
      Comp: DeferredTransaction,
      count: useTransCount("deferred"),
    },
    {
      label: "Completed",
      Icon: "TableChart",
      Comp: CompletedTransaction,
      hide: user_details?.position !== "Customer Care Agent",
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabList[0]);

  return (
    <PageContainer>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-1">
        <div className="col-span-1 md:col-span-1">
          <FullWidthTabs
            {...{ currentTab, setCurrentTab, tabList }}
            Comp={<currentTab.Comp {...currentTab} />}
          />
        </div>
      </div>
    </PageContainer>
  );
}

import { Route } from 'react-router-dom';
import { Login, Register } from '../pages';
import { UserIsNotAuthenticated } from './AuthProvider';

const PublicRoutes = (
  <Route>
    {[
      { path: '/login', element: Login },
      {
        path: '/register',
        element: Register,
      },
      
    ].map((r, ind) => (
      <Route
        key={ind}
        exact
        path={r.path}
        element={
          <UserIsNotAuthenticated>
            <r.element />
          </UserIsNotAuthenticated>
        }
      />
    ))}
  </Route>
);

export default PublicRoutes;

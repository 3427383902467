import React, { useCallback, useEffect, useState } from "react";
import {
  BackdropSection,
  Button,
  DrawerSide,
  SectionContent,
  Select,
  SimpleIcon,
} from "components";
import { PageContainer } from "shared";
import { capitalize, filter } from "lodash";
import { useCreateCrypto, useCryptoRate, useUpdateCryptoRate } from "api";
import { useSelector } from "react-redux";
import { toFixed } from "utils";

const Card = (props) => {
  const { id, img, name, rate, isUpdating, isUpdated, updateRate } = props;
  const [getData, setGetData] = useState(null);
  const [getCryptoId, setGetCryptoId] = useState(null);

  useEffect(() => {
    if (isUpdated) {
      setGetData(null);
      setGetCryptoId(null);
    }
  }, [isUpdated]);

  return (
    <div className="rounded bg-box p-2 dark:bg-gray-800">
      <div className="w-full rounded border border-slate-200 bg-box p-2 shadow-sm dark:border-slate-500 dark:bg-gray-800">
        <div className="mb-2 flex flex-row items-center justify-between rounded bg-white p-2 dark:bg-gray-900">
          <div className="flex flex-row items-center justify-start">
            <div className="flex h-8 w-8 flex-row items-center justify-center rounded bg-slate-50 dark:bg-gray-900">
              <img
                src={require(`assets/crypto/${img}.svg`)}
                className="h-6 w-6"
                alt={img}
              />
            </div>
            <div>
              <div className="ml-2">
                <p className="font-bold text-primary dark:text-light-primary">
                  {capitalize(name)}
                </p>
                <p className="truncate text-sm text-gray-500 dark:text-gray-300">
                  Edit {name} rate
                </p>
              </div>
            </div>
          </div>
          <div>
            <Button
              size="small"
              color="success"
              label="Save"
              otherClass="!min-w-0"
              disabled={!getData}
              handleClick={() => {
                updateRate(getData, id);
                setGetCryptoId(id);
              }}
              isSubmitting={isUpdating && getCryptoId === id}
            />
          </div>
        </div>
        <div className="flow-root">
          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            <li className="py-3 sm:py-4">
              <div className="flex items-start justify-between">
                <div className="min-w-0 flex-1">
                  <p className="truncate font-medium  dark:text-white">
                    Trade Rates
                  </p>
                  <div className="mt-2">
                    {[
                      { id: "sell", name: "Sell" },
                      { id: "buy", name: "Buy" },
                    ].map((item) => (
                      <p className="truncate py-3 text-gray-500 dark:text-gray-400">
                        {item.name}
                      </p>
                    ))}
                  </div>
                </div>
                {[
                  { id: "naira", name: "Naira" },
                  { id: "cedis", name: "Cedis" },
                  { id: "cefa", name: "Cefa" },
                ].map((item) => (
                  <div className="min-w-0 flex-1 pr-6 text-right" key={item.id}>
                    <p className="truncate font-medium dark:text-gray-400">
                      {item.name}
                    </p>
                    <div className="mt-2">
                      {[
                        { id: "sell", name: "Sell" },
                        { id: "buy", name: "Buy" },
                      ].map((tradeItem) => (
                        <p className="flex flex-row items-end truncate py-3 text-gray-500">
                          <input
                            className="w-full border-none bg-transparent text-right outline-none dark:text-gray-200"
                            defaultValue={toFixed(
                              rate?.[item?.id]?.[tradeItem?.id] || 0,
                              2
                            )}
                            placeholder="0.00"
                            onChange={(e) => {
                              setGetData({
                                [`rate.${item?.id}.${tradeItem?.id}`]:
                                  parseFloat(e.target.value),
                              });
                            }}
                          />
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </li>

            {[
              { id: "convertRate", name: "Convert Rate" },
              { id: "sendFee", name: "Send Rate" },
            ].map((item) => (
              <li className="py-3 sm:py-4" key={item.id}>
                <div className="flex items-center justify-between space-x-4">
                  <div className="min-w-0 flex-1">
                    <p className="truncate font-medium dark:text-white">
                      {item.name}
                    </p>
                    <p className="truncate text-sm text-gray-500 dark:text-gray-400">
                      click to edit
                    </p>
                  </div>

                  <div className="flex min-w-0 flex-1 flex-row items-end justify-end truncate py-3 pr-6 text-gray-500">
                    <input
                      className="w-full border-none bg-transparent text-right outline-none dark:text-gray-200"
                      defaultValue={toFixed(props?.[item.id] || 0, 2)}
                      placeholder="0.00"
                      onChange={(e) => {
                        setGetData({ [item?.id]: parseFloat(e.target.value) });
                      }}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const cryptList = [
  { id: "bitcoin", value: "Bitcoin", img: "btc" },
  { id: "ethereum", value: "Ethereum", img: "eth" },
  { id: "tether", value: "Tether", img: "usdt" },
  // { id: "tether-tron", value: "Tether-Tron", img: "usdt" },
  { id: "binance", value: "Binance", img: "bnb" },
  { id: "litecoin", value: "Litecoin", img: "ltc" },
];

export default function Crypto() {
  const [open, setOpen] = useState(false);
  const [getCrypto, setCrypto] = useState(null);

  const { crypto } = useSelector((state) => state.rate || {});

  const [isUpdating, isUpdated, updateRate] = useUpdateCryptoRate();
  const [isUpdatingCreate, isUpdatedCreate, createCrypto] = useCreateCrypto();

  useEffect(() => {
    if (isUpdatedCreate) setOpen(false);
  }, [isUpdatedCreate]);

  const cryptoObj = useCallback(() => {
    const cryptoFil = crypto ? crypto?.map((item) => item.name) : [];
    return filter(cryptList, (item) => !cryptoFil.includes(item.value));
  }, [crypto])();

  const cryptoFiltered = useCallback(() => {
    return crypto
      ? crypto?.filter((item) => {
          return cryptList?.map((item) => item.id)?.includes(item.id);
        })
      : [];
  }, [crypto])();

  return (
    <PageContainer>
      <SectionContent>
        <div className="rounded-lg bg-box p-0 shadow-card-box dark:bg-slate-900">
          <div className="m-0">
            {crypto ? (
              <div>
                <div className="grid grid-cols-2 gap-2">
                  {crypto?.length &&
                    cryptoFiltered
                      ?.slice()
                      ?.sort((a, b) => a.dateCreated - b.dateCreated)
                      ?.map((item) => (
                        <div className="col-span-1" key={item.id}>
                          <Card
                            {...item}
                            {...{ isUpdating, isUpdated, updateRate }}
                          />
                        </div>
                      ))}
                </div>
                <div className="mt-4">
                  <button
                    className="relative flex h-20 w-full flex-row items-center justify-center rounded border-2 border-dashed bg-white shadow-sm dark:border-gray-500 dark:bg-gray-800"
                    onClick={() => setOpen(true)}
                  >
                    <SimpleIcon
                      icon="AddCircleOutline"
                      otherClass="iconShadow-alt"
                      frontColor="text-gray-300"
                      fontSize={40}
                    />
                  </button>
                </div>
              </div>
            ) : (
              <div className="ltems-center flex h-48 w-full flex-row justify-center rounded bg-box p-4 dark:bg-gray-900">
                <div className="w-full p-4 text-center">
                  <BackdropSection open={true} />
                </div>
              </div>
            )}
          </div>
        </div>
      </SectionContent>

      <DrawerSide {...{ open: Boolean(open), setOpen }}>
        <div className="">
          <div className="border-b border-slate-100 p-4 dark:border-gray-500 dark:text-white">
            Add Crypto
          </div>
          <div className="mt-2 grid grid-cols-1 gap-4 p-4">
            <div className="col-span-1">
              <Select
                inputLabel="Crypto"
                name="crypto"
                dropDown={cryptoObj}
                handleChange={(e) => setCrypto(e)}
                value={getCrypto?.value || ""}
                // startAdornment={<Icon icon="Work" />
                // }
              />
            </div>
            <div className="col-span-1">
              <Button
                gradient
                fullWidth
                label={"Add"}
                isSubmitting={isUpdatingCreate}
                handleClick={() => {
                  // console.log(getCrypto);
                  createCrypto(getCrypto);
                }}
              />
            </div>
          </div>
        </div>
      </DrawerSide>
    </PageContainer>
  );
}

import React, { useEffect, useState } from "react";
import { IconButton } from "components";
import { Slide } from "react-reveal";

export default function AddRate({
  isUpdating,
  setCurrentContent,
  handleAddRate,
}) {
  const [values, setValues] = useState({
    qty: "",
    rate: "",
  });

  const [openForm, setOpenForm] = useState(false);

  const { qty, rate } = values || {};

  return (
    <div className="mt-4 pb-6">
      <div className="flex flex-row items-start justify-between">
        {openForm ? (
          <div className="mr-3 w-full overflow-hidden">
            <Slide right duration={200}>
              <div className="grid grid-cols-2 items-center justify-between gap-2 bg-background-main p-2 shadow-card dark:bg-gray-800">
                {[
                  { name: "qty", placeholder: "Input Qty" },
                  { name: "rate", placeholder: "Input Rate" },
                ].map((item) => (
                  <div key={item.name} className="cols-1">
                    <input
                      className="h-12 w-full border-0 px-3 text-sm outline-none dark:bg-gray-900 dark:text-gray-200"
                      placeholder={item.placeholder}
                      type="number"
                      value={values?.[item.name] || ""}
                      onChange={(e) =>
                        setValues((prevS) => ({
                          ...prevS,
                          [item.name]: e.target.value,
                        }))
                      }
                    />
                  </div>
                ))}
              </div>
            </Slide>
          </div>
        ) : (
          <div />
        )}
        <div className="flex items-end">
          <IconButton
            icon="Add"
            handleClick={() => {
              setCurrentContent({});
              if (qty === "" && rate === "") {
                setOpenForm(!openForm);
              } else {
                const callback = () => setValues({ qty: "", rate: "" });
                handleAddRate(qty, rate, callback);
              }
            }}
            isLoading={isUpdating}
          />
        </div>
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const transObj = {
  esetechWithdraw: null,
  kaapoWithdraw: null,
  esetechUtility: null,
  kaapoUtility: null,
};
const initialState = {
  completedTrans: null,
  loadingAllTrans: null,
  pendingTrans: null,
  activeTrans: null,
  deferredTrans: null,

  singleTrans: null,
  drawerTrans: null,

  isSingleTransLoading: false,
  isDrawerTransLoading: false,
  allTransFilterDate: null,
  transactionStatus: false,
  withdrawalFiatBalance: null,
  automatedList: transObj,
  automatedListCount: transObj,
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    getAllTrans: (state, { payload }) => {
      state.completedTrans = payload;
    },
    isLoadingAllTrans: (state, { payload }) => {
      state.loadingAllTrans = payload;
    },
    getPendingTrans: (state, { payload }) => {
      state.pendingTrans = payload;
    },
    getActiveTrans: (state, { payload }) => {
      state.activeTrans = payload;
    },
    getDeferredTrans: (state, { payload }) => {
      state.deferredTrans = payload;
    },
    /////

    getSingleTrans: (state, { payload }) => {
      state.singleTrans = payload;
    },
    getDrawerTrans: (state, { payload }) => {
      state.drawerTrans = payload;
    },
    getIsDrawerTransLoading: (state, { payload }) => {
      state.isDrawerTransLoading = payload;
    },
    getIsSingleTransLoading: (state, { payload }) => {
      state.isSingleTransLoading = payload;
    },
    getTransactionStatus: (state, { payload }) => {
      state.transactionStatus = payload;
    },
    getWithdrawalFiatBalance: (state, { payload }) => {
      state.withdrawalFiatBalance = payload;
    },
    getAutomatedList: (state, { payload }) => {
      state.automatedList = { ...state.automatedList, ...payload };
    },
    getAutomatedListCount: (state, { payload }) => {
      state.automatedListCount = { ...state.automatedListCount, ...payload };
    },
  },
});

export const {
  getAllTrans,
  isLoadingAllTrans,
  getPendingTrans,
  getActiveTrans,
  getDeferredTrans,

  getSingleTrans,
  getDrawerTrans,
  getIsSingleTransLoading,
  getIsDrawerTransLoading,

  getTransactionStatus,

  getWithdrawalFiatBalance,
  getAutomatedList,
  getAutomatedListCount,
} = transactionSlice.actions;

export default transactionSlice.reducer;

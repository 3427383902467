import React, { Fragment, useState } from "react";
import { PageContainer } from "shared";
import { Button, Loader, SectionContent, SimpleIcon } from "components";
import {
  useRevertTransManualEse,
  useRevertTransManualKap,
  useApproveUtilityManualEse,
  useApproveUtilityManualKap,
  useDrawerDocDetails,
} from "api";
import { checkTransactions } from "utils/transactionProps";
import { useSelector } from "react-redux";
import { startCase, sum, toLower } from "lodash";

const listP = ["Chief Executive Officer", "Supervisor"];

export default function Index() {
  const [currentId, setCurrentId] = useState(null);
  const { user_details } = useSelector((state) => state.profile);
  const { automatedList, automatedListCount } = useSelector(
    (state) => state.transaction
  );

  const [setDrawerDocDetails] = useDrawerDocDetails();

  const { esetechWithdraw, kaapoWithdraw, esetechUtility, kaapoUtility } =
    automatedList;

  const {
    esetechWithdraw: esetechWithdrawCount,
    kaapoWithdraw: kaapoWithdrawCount,
    esetechUtility: esetechUtilityCount,
    kaapoUtility: kaapoUtilityCount,
  } = automatedListCount;

  const { listAutomatedFiatWithdrawalData: data } = checkTransactions();

  const [revertTransEse, isSubmittingEse, isSubmittedEse] =
    useRevertTransManualEse();

  const [revertTransKap, isSubmittingKap, isSubmittedKap] =
    useRevertTransManualKap();

  const [approveUtilTransEse, isSubmittingUtilEse, isSubmittedUtilEse] =
    useApproveUtilityManualEse();

  const [approveUtilTransKap, isSubmittingUtilKap, isSubmittedUtilKap] =
    useApproveUtilityManualKap();

  const { columns, rows } = data() || {};

  const handleRevertToManual = (values, id) => {
    const { transactionId, status } = values;
    let revertTransFunc;

    switch (id) {
      case "esetech":
        revertTransFunc = revertTransEse;
        break;
      case "kaapo":
        revertTransFunc = revertTransKap;
        break;
      default:
        return;
    }
    revertTransFunc({ transactionId, status });
  };

  const handleApproveUtilManual = (values, id) => {
    const { transactionId, status } = values;
    let approveTransFunc;

    switch (id) {
      case "esetech":
        approveTransFunc = approveUtilTransEse;
        break;
      case "kaapo":
        approveTransFunc = approveUtilTransKap;
        break;
      default:
        return;
    }
    approveTransFunc({ transactionId, status });
  };

  const handleOpenFullTrans = (values) => {
    setDrawerDocDetails(values);
  };

  const dataD = [
    {
      id: "automatedWithdrawal",
      transId: "payout",
      collection: "pendingTransWithdrawals",
      isLoaded: esetechWithdraw && kaapoWithdraw,
      isLength: sum([esetechWithdrawCount, kaapoWithdrawCount]),
      label: "Process",
      showApproveButtons: (status) =>
        status === "failed" && listP.includes(user_details?.position),
      isDisabled: (status) =>
        status !== "failed" || !listP.includes(user_details?.position),
      handleClick: (listItem, itemId) =>
        handleRevertToManual(
          {
            transactionId: listItem?.id,
            status: listItem?.status,
          },
          itemId
        ),
      list: [
        {
          title: "Automated Payment - Esetech",
          list: esetechWithdraw,
          isSubmitting: isSubmittingEse,
          id: "esetech",
        },
        {
          title: "Automated Payment - Kaapo",
          list: kaapoWithdraw,
          isSubmitting: isSubmittingKap,
          id: "kaapo",
        },
      ],
    },

    {
      id: "automatedUtility",
      transId: "utility",
      collection: "pendingTransUtilities",
      isLoaded: esetechUtility && kaapoUtility,
      isLength: sum([esetechUtilityCount, kaapoUtilityCount]),
      label: "Approve",
      showApproveButtons: (status) =>
        status === "failed" && listP.includes(user_details?.position),
      isDisabled: (status) =>
        status !== "failed" || !listP.includes(user_details?.position),
      handleClick: (listItem, itemId) =>
        handleApproveUtilManual(
          {
            transactionId: listItem?.id,
            status: listItem?.status,
          },
          itemId
        ),
      list: [
        {
          title: "Automated Utility - Esetech",
          list: esetechUtility,
          isSubmitting: isSubmittingUtilEse,
          id: "esetech",
        },
        {
          title: "Automated Utility - Kaapo",
          list: kaapoUtility,
          isSubmitting: isSubmittingUtilKap,
          id: "kaapo",
        },
      ],
    },
  ];

  return (
    <PageContainer>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-1">
        {dataD.map((itemD) => (
          <div key={itemD.id} className="col-span-1 md:col-span-1">
            <SectionContent>
              <div className="flex flex-col gap-y-6">
                {itemD.isLoaded ? (
                  <>
                    {itemD.isLength ? (
                      itemD.list?.map((itemL, index) => (
                        <Fragment key={index}>
                          {itemL?.list?.length ? (
                            <div
                              className="shadow-xs relative w-full overflow-x-auto rounded border border-slate-100 bg-slate-200 p-2 shadow-sm dark:border-gray-500 dark:bg-gray-800"
                              key={itemL?.id}
                            >
                              <div className="mb-2 rounded border-b-0 border-slate-200 p-3 font-bold text-primary dark:border-slate-500 dark:bg-gray-900 dark:text-light-primary">
                                {itemL?.title}
                              </div>

                              <div className="mt-1 mb-3 border-b dark:border-slate-700" />

                              <div className="grid grid-cols-2 gap-4">
                                {itemL?.list
                                  ? itemL?.list?.map((itemSub, index) => {
                                      const itemMapped = rows(itemSub);
                                      const { status } = itemSub?.otherDetails;

                                      return (
                                        <div className="col-span-1" key={index}>
                                          <div className="flex flex-col gap-0 rounded-lg bg-box p-0 dark:bg-gray-900">
                                            {columns?.map(
                                              (itemCol, indexCol) => {
                                                return (
                                                  <div
                                                    className="flex flex-row items-center border-b p-3 dark:border-gray-700"
                                                    key={indexCol}
                                                  >
                                                    <div className="flex h-6 w-6  items-center justify-center rounded-full bg-primary/50">
                                                      <SimpleIcon
                                                        fontSize={15}
                                                        icon="ViewAgenda"
                                                      />
                                                    </div>
                                                    <div
                                                      className="ml-2 flex w-full flex-1 flex-row items-center justify-between text-sm dark:text-white"
                                                      key={indexCol}
                                                    >
                                                      <div>
                                                        {itemCol?.title}
                                                      </div>
                                                      <div className="text-right dark:text-gray-200">
                                                        {
                                                          itemMapped?.[
                                                            itemCol?.id
                                                          ]
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                            <div className="flex items-center justify-between p-2">
                                              <Button
                                                label="view"
                                                size="small"
                                                color="info"
                                                otherClass="!min-w-0"
                                                handleClick={() =>
                                                  handleOpenFullTrans({
                                                    docId: itemSub?.id,
                                                    collection:
                                                      itemD?.collection,
                                                    collectionTo:
                                                      itemD?.collection,
                                                    type: itemL?.id,
                                                    id: itemD?.transId,
                                                  })
                                                }
                                              />
                                              {itemD.showApproveButtons(
                                                status
                                              ) && (
                                                <div className="flex gap-2">
                                                  {itemD.id ===
                                                    "automatedUtility" && (
                                                    <Button
                                                      label="retry"
                                                      size="small"
                                                      color="warning"
                                                      otherClass="!min-w-0"
                                                    />
                                                  )}

                                                  <Button
                                                    label={itemD.label}
                                                    disabled={itemD.isDisabled(
                                                      status
                                                    )}
                                                    size="small"
                                                    color="success"
                                                    otherClass="!min-w-0"
                                                    isSubmitting={
                                                      itemL?.isSubmitting &&
                                                      currentId === itemSub?.id
                                                    }
                                                    handleClick={() => {
                                                      setCurrentId(itemSub?.id);
                                                      itemD.handleClick(
                                                        itemSub,
                                                        itemL?.id
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : null}
                              </div>
                            </div>
                          ) : null}
                        </Fragment>
                      ))
                    ) : (
                      <div className="flex h-16 w-full flex-row items-center justify-start rounded bg-box px-4 dark:bg-gray-900 dark:text-white">
                        No active {toLower(startCase(itemD.id))}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="">
                    <Loader />
                  </div>
                )}
              </div>
            </SectionContent>
          </div>
        ))}
      </div>
    </PageContainer>
  );
}

import * as yup from "yup";
import mapValues from "lodash/mapValues";

const field = {
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string().required("Password is required."),
};

export const loginValidationSchema = yup.object({
  email: field.email,
  password: field.password,
});

export const registerValidationSchema = yup.object({
  displayName: yup
    .string("Enter your full name")
    .min(3, "Full Name should be of minimum 3 characters length")
    .required("Full Name is required"),
  phoneNumber: yup
    .string("Enter your phone number")
    .min(9, "Phone Number should be of minimum 3 characters length")
    .required("phone Number is required"),
  email: field.email,
  password: field.password,
});

export const displayNameSchema = yup.object({
  displayName: yup
    .string("Enter your full name")
    .min(3, "Name should be of minimum 3 characters length")
    .max(50, "Name should be of maximum 50 characters length")
    .required("Full Name is required"),
});

export const pinResetSchema = yup.object({
  password: yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  pin: yup
    .string()
    .length(6, "Pin must 6 characters")
    .matches(/^[0-9]+$/, "Pin must contain only numeric")
    .required("Pin is required"),
  confirmPin: yup
    .string()
    .oneOf([yup.ref("pin"), null], "Pins must match")
    .required("Confirm Pin is required"),
});

export const changePhoneSchema = yup.object({
  code: yup.string().required("OTP is required"),
});

export const verifyPasswordSchema = yup.object({
  currentPass: yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("Current Password is required"),
});

export const changePasswordSchema = yup.object({
  newPass: yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("New Password is required"),
  confirmNewPass: yup
    .string()
    .oneOf([yup.ref("newPass"), null], "Passwords must match")
    .required("Confirm Password is required"),
  code: yup.string().required("OTP is required"),
});

export const changePasswordSchemaAlt = yup.object({
  currentPass: yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("Current Password is required"),
  newPass: yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("New Password is required"),
  confirmNewPass: yup
    .string()
    .oneOf([yup.ref("newPass"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const verifyEmailSchema = yup.object({
  newEmail: yup
    .string()
    .email("New Email must be a valid email")
    .required("New Email is required"),
  currentPass: yup
    .string()
    // .min(6, 'Password should be of minimum 6 characters length')
    .required("Password is required"),
});

export const changeEmailSchema = yup.object({
  code: yup.string().required("OTP is required"),
});

export const registerStaffSchema = yup.object({
  firstName: yup.string().min(3).required(),
  lastName: yup.string().min(3).required("Full Name is required"),
  phoneNumber: yup.string().required("phone Number is required"),
  email: field.email.required(),
  department: yup.string().required(),
  position: yup.string().required(),
});

export const reinviteStaffSchema = yup.object({
  email: field.email.required(),
  newEmail: field.email.required(),
  displayName: yup.string().min(3).required(),
  staffId: yup.string().required(),
});

import React from "react";

export default function AuthContainer({ title, showForgetPass, children }) {
  return (
    <div className="flex h-screen">
      <div className="m-auto items-center justify-center overflow-hidden rounded-lg bg-white dark:bg-slate-900 sm:w-5/6 md:w-1/3">
        <div className="m-auto flex justify-center bg-slate-50 p-8 dark:bg-gray-900">
          <div className="flex flex-row items-center justify-center">
            <div className="flex h-full flex-row items-center justify-center">
              <img
                src={require("assets/e-logo.svg").default}
                alt="esetech-logo"
                width="20"
              />
            </div>
            <div className="flex h-full flex-row items-center justify-center ">
              <h1 className="ml-1 text-2xl font-bold leading-none text-primary dark:text-primary">
                esetech
              </h1>
            </div>
          </div>
        </div>
        <hr className="mb-6 border-t dark:border-gray-500" />
        <div className="m-auto flex h-full flex-col justify-center px-12 pb-12">
          <div className="mb-6 text-center">
            <h6 className="text-center text-lg font-semibold text-gray-800 dark:text-white">
              {title}
            </h6>
            <p className="text-sm text-gray-400 dark:text-gray-200">
              Sign in to continue
            </p>
          </div>

          <div className="mb-4">{children}</div>
          {/* {showForgetPass && (
            <div className="text-center">
              <Link
                className="inline-block align-baseline text-sm text-primary"
                to="/"
              >
                Forgot Password?
              </Link>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

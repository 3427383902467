import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Chip, Divider } from "@mui/material";
import {
  useCheckReadChat,
  useEditChatMessage,
  useFetchCurrentChatMessages,
} from "api";
import { SimpleIcon, SimpleLoader } from "components";
import { useSelector } from "react-redux";
import { ChatContext } from "../ChatContext";
import SingleMessageChat from "./SingleMessageChat";
import { formatDate } from "utils";
import { groupBy } from "lodash";
import { useFetchMoreCurrentChatMessages } from "api/actions/chat";

export default function MessageChatList() {
  const [pageTimer, setPageTimer] = useState(false);
  const [onEditMsg, setOnEditMsg] = useState({ edit: false });
  const { currentChatUserDetails, currentChatMessages, currentChatId } =
    useSelector((state) => state.chat || {});

  const { scrollToviewRef, scrollToTheBottom } = useContext(ChatContext);

  const [fetchMoreChats, , isSubmittedFetchMore, ,] =
    useFetchMoreCurrentChatMessages();

  const [editChatMessage, , isSubmitted] = useEditChatMessage();

  useCheckReadChat();
  useFetchCurrentChatMessages();

  useEffect(() => {
    const containerOffset = scrollToviewRef?.current?.offsetHeight;

    if (scrollToviewRef?.current?.scrollTop === 0 && isSubmittedFetchMore) {
      scrollToviewRef?.current?.scroll({
        top: containerOffset,
      });
    }
  }, [isSubmittedFetchMore, scrollToviewRef]);

  useEffect(() => {
    if (isSubmitted) setOnEditMsg({ edit: false });
  }, [isSubmitted]);

  useEffect(() => {
    setOnEditMsg({ edit: false });
  }, [currentChatMessages, currentChatUserDetails]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const scrollEl = scrollToviewRef.current;
      scrollEl?.scroll({
        top: scrollEl?.scrollHeight,
        // behavior: "smooth",
      });
    }, 2110);

    const timer2 = setTimeout(() => {
      setPageTimer(true);
    }, 2000);

    setPageTimer(false);

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, [scrollToviewRef, currentChatId]);

  const handleEditMessage = (message) => {
    editChatMessage({ ...onEditMsg, message, editType: "edit" });
  };

  const handleDeleteMessage = (messageDetails) => {
    editChatMessage({ ...onEditMsg, ...messageDetails, editType: "delete" });
  };

  const handleLoadChatOnScroll = () => {
    if (scrollToviewRef?.current?.scrollTop === 0) {
      fetchMoreChats(currentChatId);
    }
  };

  const groupMessageByDate = () => {
    const formatMessage =
      currentChatMessages &&
      currentChatMessages?.map((item) => ({
        ...item,
        date: item?.timeSent ? formatDate(item?.timeSent, "DD/MM/YY") : null,
      }));
    const groupMsgs = groupBy(formatMessage, (i) => i.date);
    return groupMsgs;
  };

  return (
    <div className="relative overflow-hidden">
      <div className="absolute h-full w-full bg-hero-pattern dark:opacity-[0.05]" />
      <div
        className="message-container relative h-[calc(100vh-320px)] overflow-auto"
        ref={scrollToviewRef}
        onScroll={handleLoadChatOnScroll}
      >
        {currentChatMessages && pageTimer ? (
          <Fragment>
            {/* <div className="m-auto flex justify-center">
              <div
                className={classNames(
                  isSubmittingFetchMore ? "block" : "hidden",
                  !isDataEmpty
                    ? ""
                    : "w-fit rounded-2xl bg-box p-2 font-bold dark:bg-gray-900/50 dark:text-white",
                  "mt-0 mb-0 flex items-center justify-center text-center text-sm dark:text-white"
                )}
              >
                {!isDataEmpty ? <SimpleLoader /> : <> Chat starts</>}
              </div>
            </div> */}

            {Object.keys(groupMessageByDate())?.length ? (
              Object.entries(groupMessageByDate())?.map((item) => {
                const groupMessageByDateKey = item?.[0];
                const groupMessageByDateValue = item?.[1];
                return (
                  <div key={groupMessageByDateKey}>
                    {groupMessageByDateKey !== "null" && (
                      <div className="my-2">
                        <Divider light>
                          <Chip
                            label={groupMessageByDateKey}
                            classes={{
                              label: "dark:text-gray-300",
                            }}
                          />
                        </Divider>
                      </div>
                    )}

                    <div className="flex flex-col gap-2 p-4">
                      {groupMessageByDateValue &&
                        groupMessageByDateValue?.map((msgItem, index, arr) => (
                          <SingleMessageChat
                            key={index}
                            {...{
                              msgItem,
                              onEditMsg,
                              setOnEditMsg,
                              handleDeleteMessage,
                              handleEditMessage,
                              arrIndexCurrent: arr[index],
                              arrIndexPrev: arr[index - 1],
                            }}
                          />
                        ))}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="m-auto flex justify-center">
                <div className="mt-3 flex flex-col items-center justify-center rounded-xl bg-gray-200 p-4 text-sm dark:bg-gray-700 dark:text-white">
                  This is an end-to-end encrypted chat
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <div className="flex h-full w-full items-center justify-center">
            <SimpleLoader />
          </div>
        )}
      </div>
      {currentChatMessages?.length ? (
        <div className="scroll-down-btn absolute bottom-0 right-0">
          <button
            className="flex h-10 w-10 items-center justify-center rounded-full bg-primary shadow-button"
            onClick={() => {
              scrollToTheBottom();
            }}
          >
            <SimpleIcon
              icon="KeyboardDoubleArrowDown"
              frontColor="text-white"
            />
          </button>
        </div>
      ) : null}
    </div>
  );
}

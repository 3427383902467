import { Button, Loader, NoData, SectionContent, SimpleIcon } from "components";
import { Fragment } from "react";
import { truncate } from "utils";
import { getIsLoadedTrans, useAllTrans } from "utils/allTransactionProps";

export default function ActiveTransaction({ count: transLength }) {
  const trans = useAllTrans().filter((item) => item?.id.includes("active"));

  const isLoaded = getIsLoadedTrans(trans);

  console.log(trans);
  return (
    <Fragment>
      {isLoaded ? (
        <div className="grid grid-cols-1 gap-4">
          <>
            {trans && transLength ? (
              trans?.map((transItem, index) => {
                return (
                  <Fragment key={index}>
                    {transItem?.data && transItem?.data?.length ? (
                      <div className="col-span-1">
                        <SectionContent>
                          <div className="w-full p-0 border rounded shadow-sm border-slate-100 bg-box dark:border-slate-600 dark:bg-gray-700">
                            <div className="relative p-2 overflow-x-auto shadow-md">
                              <div className="p-2 bg-white rounded dark:bg-slate-800">
                                <h6 className="p-1 mb-1 font-semibold dark:text-white">
                                  {transItem.title}
                                </h6>
                                <ul>
                                  {transItem?.data?.map((itemD) => {
                                    const item =
                                      transItem?.objectSummary(itemD);
                                    const itemValues = Object.values(item);
                                    return (
                                      <li
                                        key={item.id}
                                        className="flex flex-row items-center justify-between p-2 my-1 rounded bg-link-primary/50 dark:bg-gray-900"
                                      >
                                        <div className="flex flex-row items-center justify-start">
                                          <SimpleIcon icon="MoreHoriz" />
                                          <div className="ml-2 text-text-main dark:text-light-primary">
                                            <span>
                                              Active - {itemValues[2]?.value} /{" "}
                                              {itemValues[3]?.value} /{" "}
                                              {truncate(
                                                itemValues[4]?.value,
                                                25
                                              )}{" "}
                                              - {itemValues[6]?.value}
                                            </span>
                                          </div>
                                        </div>
                                        <Button
                                          color={
                                            ["Claim", "Re-claim"].includes(
                                              transItem?.buttonText
                                            )
                                              ? "warning"
                                              : "success"
                                          }
                                          label={transItem?.buttonText}
                                          size="small"
                                          // otherClass="!min-w-0"
                                          {...(transItem?.handleOnClick && {
                                            handleClick: () =>
                                              transItem?.handleOnClick(item),
                                          })}
                                          {...(transItem?.isSubmitting && {
                                            isSubmitting:
                                              transItem?.isSubmitting(item),
                                          })}
                                        />
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </SectionContent>
                      </div>
                    ) : null}
                  </Fragment>
                );
              })
            ) : (
              <NoData text="No active transaction at the moment. Enjoy! 😊" />
            )}
          </>
        </div>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
}

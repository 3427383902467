import { sum } from "lodash";
import { useEffect, useState } from "react";
import { formatDate } from "utils";
import * as i from "../lib";

import { axiosCall } from "api/helpers";
import { useDispatch, useStore } from "react-redux";
import { getUserPushNotification } from "redux/reducers/usersReducers";
import { notification } from "utils";
import { checkVerifyOtp, sendVerifyOtp } from "./auth";

const auth = i.getAuth();

const url = (endpoint, platform) => {
  const baseUrl = () => {
    switch (platform) {
      case "kaapo":
        return i.baseURL_KP;
      case "esetech":
        return i.baseURL_ET;

      default:
        throw new Error("platform is missing");
    }
  };
  return `${baseUrl()}/transaction/${endpoint}`;
};

const getPlatformDB = (platform) => {
  try {
    let DB;
    switch (platform) {
      case "esetech":
        DB = i.db;
        break;
      case "kaapo":
        DB = i.db2;
        break;

      default:
        throw new Error("Invalid Platform");
    }
    return DB;
  } catch (error) {
    console.log(error);
  }
};

export const useFetchUsers = (platform, singleUserId) => {
  const [getUsers, setGetUsers] = useState();
  const currentUser = auth?.currentUser;

  useEffect(() => {
    setGetUsers();
    const fetchUsers = async () => {
      try {
        if (currentUser) {
          let DB;
          switch (platform) {
            case "esetech":
              DB = i.db;
              break;
            case "kaapo":
              DB = i.db2;
              break;
            default:
              throw new Error("invalid platform");
          }

          if (singleUserId) {
            if (singleUserId.includes("@")) {
              const q = i.query(
                i.collection(DB, "users"),
                i.where("email", "==", singleUserId),
                i.limit(1)
              );

              const querySnapshot = await i.getDocs(q);

              if (!querySnapshot.empty) {
                const doc = querySnapshot?.docs?.[0];
                const users = [{ id: doc.id, ...doc.data() }];
                setGetUsers(users);
              } else {
                setGetUsers([]);
              }
            } else {
              const doc = await i.getDoc(i.doc(DB, "users", singleUserId));
              if (doc.exists()) {
                const users = [{ id: doc.id, ...doc.data() }];
                setGetUsers(users);
              } else {
                setGetUsers([]);
              }
            }
          } else {
            const q = i.query(
              i.collection(DB, "users"),
              i.orderBy("dateCreated", "desc"),
              i.limit(50)
            );

            const querySnapshot = await i.getDocs(q);
            const users = await Promise.all(
              querySnapshot.docs.map((doc) => {
                return { id: doc.id, ...doc.data() };
              })
            );
            setGetUsers(users);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUsers();
  }, [currentUser, platform, singleUserId]);
  return [getUsers];
};

export const useGetUserProfile = (userId, platform) => {
  const [profile, setProfile] = useState();
  const currentUser = auth?.currentUser;

  useEffect(() => {
    const getProfile = async () => {
      if (userId && currentUser) {
        const DB = getPlatformDB(platform);

        const docSnap = await i.getDoc(i.doc(DB, "users", userId));

        if (docSnap.exists()) {
          setProfile(docSnap.data());
        }
      }
    };
    getProfile();
  }, [userId, currentUser]);

  return [profile];
};

export const useGetUserWalletBalances = (userId, platform) => {
  const [balances, setBalances] = useState();
  const currentUser = auth?.currentUser;

  useEffect(() => {
    const getBalances = async () => {
      try {
        if (userId && currentUser) {
          const DB = getPlatformDB(platform);

          let cryptoSnap;
          if (platform === "esetech") {
            cryptoSnap = await i.getDoc(i.doc(DB, "cryptoWallet", userId));
          }
          const nairaSnap = await i.getDoc(i.doc(DB, "nairaWallet", userId));
          const cedisSnap = await i.getDoc(i.doc(DB, "cedisWallet", userId));
          const cefaSnap = await i.getDoc(i.doc(DB, "cefaWallet", userId));

          const {
            bitcoinWallet = 0,
            ethereumWallet = 0,
            tetherWallet = 0,
            tetherTronWallet = 0,
            binanceWallet = 0,
          } = cryptoSnap?.data() || {};

          const cryptoWallets = {
            bitcoinWallet,
            ethereumWallet,
            tetherWallet,
            tetherTronWallet,
            binanceWallet,
          };
          const fiatWallets = {
            nairaWallet: nairaSnap?.data()?.amount || 0,
            cedisWallet: cedisSnap?.data()?.amount || 0,
            cefaWallet: cefaSnap?.data()?.amount || 0,
          };
          const fiatBonusWallets = {
            nairaBonusWallet: nairaSnap?.data()?.nairaBonus || 0,
            cedisBonusWallet: cedisSnap?.data()?.cedisBonus || 0,
            cefaBonusWallet: cefaSnap?.data()?.cefaBonus || 0,
          };

          setBalances({
            fiatWallets,
            fiatBonusWallets,
            ...(platform === "esetech" && { cryptoWallets }),
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    getBalances();
  }, [userId, currentUser, platform]);

  return [balances];
};

export const useGetUserCryptoAddress = (userId) => {
  const [addresses, setAddresses] = useState();
  const currentUser = auth?.currentUser;
  useEffect(() => {
    const getAddresses = async () => {
      try {
        if (userId && currentUser) {
          const addressSnap = await i.getDoc(
            i.doc(i.db, "userCryptoAddressesNew", userId)
          );
          if (addressSnap.exists()) {
            const {
              bitcoinWalletAddress = null,
              ethereumWalletAddress = null,
              tetherWalletAddress = null,
              tetherTronWalletAddress = null,
              tetherBscWalletAddress = null,
              binanceWalletAddress = null,
              litecoinWalletAddress = null,
              dateCreated,
              dateUpdated,
              derivationIndex,
            } = addressSnap.data() || {};

            const cryptoAddresses = {
              bitcoinWalletAddress,
              ethereumWalletAddress,
              tetherWalletAddress,
              tetherTronWalletAddress,
              tetherBscWalletAddress,
              binanceWalletAddress,
              litecoinWalletAddress,
            };
            const otherInfo = {
              dateCreated: formatDate(dateCreated),
              dateUpdated: formatDate(dateUpdated),
              derivationIndex,
            };

            setAddresses({
              cryptoAddresses,
              otherInfo,
            });
          } else {
            setAddresses("null");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAddresses();
  }, [userId, currentUser]);

  return [addresses];
};

export const useGetUserFiatBalances = (currentWallet, platform) => {
  const [balance, setBalances] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [totalBonusAmount, setTotalBonusAmount] = useState();
  const currentUser = auth?.currentUser;

  const { wallet, type, bonusCol } = currentWallet || {};

  useEffect(() => {
    setBalances();
    setTotalAmount();
    const getBalances = async () => {
      let DB;
      switch (platform) {
        case "esetech":
          DB = i.db;
          break;
        case "kaapo":
          DB = i.db2;
          break;

        default:
          throw new Error("Invalid Platform");
      }
      try {
        if (currentUser && currentWallet) {
          let q;
          if (type === "fiat") {
            q = i.query(
              i.collection(DB, wallet),
              i.where("amount", ">", 0),
              i.orderBy("amount", "desc")
              // i.limit(50)
            );
          }
          if (type === "crypto") {
            q = i.query(
              i.collection(DB, "cryptoWallet"),
              i.where(wallet, ">", 0),
              i.orderBy(wallet, "desc")
              // i.limit(50)
            );
          }

          const querySnapshot = await i.getDocs(q);

          const snap = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
              const customerIdRef = doc.id;
              const { amount } = doc?.data() || {};
              const cryptoWallet = doc?.data()?.[wallet] || {};

              const customerRef = await i.getDoc(
                i.doc(DB, "users", customerIdRef)
              );

              const { displayName, email } = customerRef?.data() || {};

              return {
                id: doc.id,
                amount: amount || cryptoWallet,
                ...(type === "fiat" && {
                  bonusAmount: doc?.data()?.[bonusCol] || 0,
                }),
                customerId: doc.id,
                customerDetails: { displayName, email },
              };
            })
          );

          const tAmount = sum(snap.map((item) => item.amount));
          const tBonusAmount = sum(snap.map((item) => item.bonusAmount));
          setBalances(snap);
          setTotalAmount(tAmount);
          setTotalBonusAmount(tBonusAmount);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getBalances();
  }, [bonusCol, currentUser, currentWallet, platform, type, wallet]);

  return [balance, totalAmount, totalBonusAmount];
};

export const useGetUserTransactionEse = (userId, startDate, endDate) => {
  const [getTransactions, setGetTransactions] = useState();
  const currentUser = auth?.currentUser;

  useEffect(() => {
    const getTrans = async () => {
      try {
        if (currentUser) {
          const q = (col, product, isNotOld) =>
            i.query(
              i.collection(i.db, col),
              i.where("customerId", "==", userId),
              i.where("product", "==", product),
              ...(startDate
                ? [
                    i.where(
                      "dateCreated",
                      ">=",
                      product === "giftcard" && isNotOld
                        ? new Date(startDate)
                        : startDate
                    ),
                  ]
                : []),
              ...(endDate
                ? [
                    i.where(
                      "dateCreated",
                      "<=",
                      product === "giftcard" && isNotOld
                        ? new Date(endDate)
                        : endDate
                    ),
                  ]
                : []),
              i.orderBy("dateCreated", "desc"),
              ...(!(startDate && endDate) ? [i.limit(100)] : [])
            );
          const getData = (arr) =>
            arr.map((doc) => {
              return { id: doc.id, ...doc.data(), platform: "esetech" };
            });

          const pendingWithdrawalSnapshot = await i.getDocs(
            q("pendingTransWithdrawals", "payout")
          );
          const completedOldGiftcardSnapshot = await i.getDocs(
            q("transactions", "giftcard")
          );
          const completedOldCryptoSnapshot = await i.getDocs(
            q("transactions", "crypto")
          );
          const completedOldWithdrawalSnapshot = await i.getDocs(
            q("withdrawalRequest", "payout")
          );
          const completedWithdrawalSnapshot = await i.getDocs(
            q("transWithdrawals", "payout")
          );
          const completedCryptoSnapshot = await i.getDocs(
            q("transCrypto", "crypto")
          );
          const completedUtilitySnapshot = await i.getDocs(
            q("transUtilities", "utility")
          );
          const completedBonusSnapshot = await i.getDocs(
            q("transTransferBonus", "transfer-bonus")
          );
          const completedDepositFundSnapshot = await i.getDocs(
            q("transDeposits", "deposit")
          );
          const completedTransferFundSnapshot = await i.getDocs(
            q("transTransferFiat", "transfer-fiat")
          );

          const pendingWithdrawalSnap = await Promise.all(
            getData(pendingWithdrawalSnapshot.docs)
          );
          const completedOldGiftcardSnap = await Promise.all(
            getData(completedOldGiftcardSnapshot.docs)
          );
          const completedOldCryptoSnap = await Promise.all(
            getData(completedOldCryptoSnapshot.docs)
          );
          const completedOldWithdrawalSnap = await Promise.all(
            getData(completedOldWithdrawalSnapshot.docs)
          );
          const completedWithdrawalSnap = await Promise.all(
            getData(completedWithdrawalSnapshot.docs)
          );
          const completedCryptoSnap = await Promise.all(
            getData(completedCryptoSnapshot.docs)
          );
          const completedUtilitySnap = await Promise.all(
            getData(completedUtilitySnapshot.docs)
          );
          const completedBonusSnap = await Promise.all(
            getData(completedBonusSnapshot.docs)
          );
          const completedDepositFundSnap = await Promise.all(
            getData(completedDepositFundSnapshot.docs)
          );
          const completedTransferFiatSnap = await Promise.all(
            getData(completedTransferFundSnapshot.docs)
          );

          setGetTransactions([
            ...pendingWithdrawalSnap,
            ...completedOldGiftcardSnap,
            ...completedOldCryptoSnap,
            ...completedOldWithdrawalSnap,
            ...completedWithdrawalSnap,
            ...completedCryptoSnap,
            ...completedUtilitySnap,
            ...completedBonusSnap,
            ...completedDepositFundSnap,
            ...completedTransferFiatSnap,
          ]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getTrans();
  }, [userId, currentUser, startDate, endDate]);

  return [getTransactions];
};

export const useGetUserTransactionKap = (userId, startDate, endDate) => {
  const [getTransactions, setGetTransactions] = useState();
  const currentUser = auth?.currentUser;

  useEffect(() => {
    const getTrans = async () => {
      try {
        if (currentUser) {
          const q = (col, product) =>
            i.query(
              i.collection(i.db2, col),
              i.where("customerId", "==", userId),
              i.where("product", "==", product),
              ...(startDate
                ? [
                    i.where(
                      "dateCreated",
                      ">=",
                      product === "giftcard" ? new Date(startDate) : startDate
                    ),
                  ]
                : []),
              ...(endDate
                ? [
                    i.where(
                      "dateCreated",
                      "<=",
                      product === "giftcard" ? new Date(endDate) : endDate
                    ),
                  ]
                : []),
              i.orderBy("dateCreated", "desc"),
              ...(!(startDate && endDate) ? [i.limit(100)] : [])
            );
          const getData = (arr) =>
            arr.map((doc) => {
              return { id: doc.id, ...doc.data(), platform: "kaapo" };
            });

          const pendingWithdrawalSnapshot = await i.getDocs(
            q("pendingTransWithdrawals", "payout")
          );
          const pendingGiftcardSnapshot = await i.getDocs(
            q("pendingTransGiftcards", "giftcard")
          );
          const completedGiftcardSnapshot = await i.getDocs(
            q("transGiftcards", "giftcard")
          );
          const completedWithdrawalSnapshot = await i.getDocs(
            q("transWithdrawals", "payout")
          );
          const completedUtilitySnapshot = await i.getDocs(
            q("transUtilities", "utility")
          );
          const completedBonusSnapshot = await i.getDocs(
            q("transTransferBonus", "transfer-bonus")
          );
          const completedDepositFundSnapshot = await i.getDocs(
            q("transDeposits", "deposit")
          );
          const completedTransferFundSnapshot = await i.getDocs(
            q("transTransferFiat", "transfer-fiat")
          );

          const pendingWithdrawalSnap = await Promise.all(
            getData(pendingWithdrawalSnapshot.docs)
          );
          const pendingGiftcardSnap = await Promise.all(
            getData(pendingGiftcardSnapshot.docs)
          );
          const completedGiftcardSnap = await Promise.all(
            getData(completedGiftcardSnapshot.docs)
          );
          const completedWithdrawalSnap = await Promise.all(
            getData(completedWithdrawalSnapshot.docs)
          );
          const completedUtilitySnap = await Promise.all(
            getData(completedUtilitySnapshot.docs)
          );
          const completedBonusSnap = await Promise.all(
            getData(completedBonusSnapshot.docs)
          );
          const completedDepositFundSnap = await Promise.all(
            getData(completedDepositFundSnapshot.docs)
          );
          const completedTransferFiatSnap = await Promise.all(
            getData(completedTransferFundSnapshot.docs)
          );

          setGetTransactions([
            ...pendingWithdrawalSnap,
            ...pendingGiftcardSnap,
            ...completedGiftcardSnap,
            ...completedWithdrawalSnap,
            ...completedUtilitySnap,
            ...completedBonusSnap,
            ...completedDepositFundSnap,
            ...completedTransferFiatSnap,
          ]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getTrans();
  }, [userId, currentUser, startDate, endDate]);

  return [getTransactions];
};

export const useSendPushNotifVerfiyEmailOtp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(false);
    }, 500);
  }, [isLoaded]);

  const verifyOtp = async (callback) => {
    try {
      // console.log("verifyOtp", "verifyOtp");
      await sendVerifyOtp();
      // console.log("verifyOtpSend", "verifyOtpSend");

      if (callback) callback();
      setIsLoading(false);
      setIsLoaded(true);
      notification("success", "OTP sent!");
    } catch (error) {
      let message = JSON.stringify(error?.message || error);

      // console.log(error?.message, error);
      setIsLoading(false);
      setError(true);
      notification("danger", message, "error");
    }
  };

  return [isLoading, isLoaded, verifyOtp];
};

export const useUserSendPushNotification = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isError, setError] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      setTimeout(() => {
        setSubmitted(false);
      }, 500);
    }
  }, [isSubmitted]);

  const sendNotif = async (values, passProp, callback) => {
    const { platform, content, saveInCol, userType } = values;

    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
      platform,
    };

    try {
      if (
        !(
          Boolean(content) &&
          content?.title?.length >= 5 &&
          content?.message?.length >= 50 &&
          Boolean(userType) &&
          Boolean(platform) &&
          passProp.tags === "ready" &&
          passProp.password &&
          passProp.code
        )
      ) {
        notification("danger", "Invalid inputs", "error");
        return;
      }
      setSubmitting(true);
      setError(false);

      const credential = i.EmailAuthProvider.credential(
        auth.currentUser.email,
        passProp.password
      );

      await i.reauthenticateWithCredential(auth.currentUser, credential);

      await checkVerifyOtp(passProp.code);
      const trans = await axiosCall(
        url("send_push_notification", platform),
        valueFormat,
        true,
        platform
      );

      setSubmitting(false);
      setSubmitted(true);
      notification("success", "Push notification sent successfully");
      if (callback) callback();
    } catch (error) {
      // console.log(error);
      let msg;
      const errorM = error.code || error.message || error;

      switch (errorM) {
        case "Invalid inputs":
          msg = "Invalid inputs";
          break;
        case "auth/wrong-password":
          msg = "Incorrect password";
          break;
        case "auth/too-many-requests":
          msg = "Too many failed password verify attempts. Try again later";
          break;
        case "Otp do not match":
          msg = "Otp do not match";
          break;

        default:
          msg = "An error occured! Try again later.";
          break;
      }

      setSubmitting(false);
      notification("danger", msg, "error");
    }
  };

  return [isSubmitting, isSubmitted, sendNotif];
};

export const useUserSavePushNotification = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      setTimeout(() => {
        setSubmitted(false);
      }, 500);
    }
  }, [isSubmitted]);

  const saveNotif = async (values) => {
    const { platform, title, message, id, tags } = values;

    const DB = getPlatformDB(platform);
    try {
      if (
        !(
          Boolean(message) &&
          title?.length >= 5 &&
          message?.length >= 50 &&
          Boolean(platform) &&
          tags
        )
      ) {
        notification("danger", "Invalid inputs", "error");
        return;
      }

      setSubmitting(true);

      await i.setDoc(
        i.doc(DB, "notificationContent", id),
        {
          ...values,
        },
        { merge: true }
      );

      setSubmitting(false);
      setSubmitted(true);
      notification("success", "Push notification successfully saved.");
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      notification("danger", "An error occured.", "error");
    }
  };
  return [isSubmitting, isSubmitted, saveNotif];
};

export const useUserEditPushNotification = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const { users } = useStore().getState();
  const { userPushNotification } = users || {};

  useEffect(() => {
    if (isSubmitted) {
      setTimeout(() => {
        setSubmitted(false);
      }, 500);
    }
  }, [isSubmitted]);

  const editNotif = async (values) => {
    const { platform, id, tags, dateUpdated } = values;
    const getReadyNotifs = userPushNotification?.filter(
      (item) => item.tags === "ready"
    );

    if (getReadyNotifs?.length >= 1 && tags === "ready") {
      notification("warning", "You can only have 1 completed notification");
      return;
    }

    const DB = getPlatformDB(platform);
    try {
      if (!(Boolean(id) && Boolean(platform) && tags)) {
        notification("danger", "Invalid inputs", "error");
        return;
      }

      setSubmitting(true);

      await i.updateDoc(i.doc(DB, "notificationContent", id), {
        tags,
        dateUpdated,
      });

      setSubmitting(false);
      setSubmitted(true);
      notification("success", "Push notification successfully edited.");
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      notification("danger", "An error occured.", "error");
    }
  };
  return [isSubmitting, isSubmitted, editNotif];
};

export const useGetPushNotification = (platform) => {
  const dispatch = useDispatch();
  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    const DB = getPlatformDB(platform);
    let unsub;

    try {
      if (userUid) {
        dispatch(getUserPushNotification(null));
        let q = i.query(
          i.collection(DB, "notificationContent"),
          i.orderBy("dateCreated", "desc")
        );
        unsub = i.onSnapshot(q, (querySnapshot) => {
          const t = [];
          querySnapshot.forEach((doc) => {
            t.push({ id: doc.id, ...doc.data() });
          });
          dispatch(getUserPushNotification(t));
        });
      }
    } catch (error) {
      dispatch(getUserPushNotification(null));
      console.log(error);
    }
    return unsub;
  }, [dispatch, userUid, platform]);
};

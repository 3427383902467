import React, { Fragment } from "react";
import {
  AppBar,
  DialogTitle,
  Drawer as MuiDrawer,
  IconButton,
  Toolbar,
  Box,
  Accordion as MuiAccordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Transition, Dialog } from "@headlessui/react";
import { classNames } from "utils";
import { SimpleIcon } from "./Icon";

export function Drawer({
  anchor,
  state,
  setState,
  children,
  title,
  handleOnClose,
}) {
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const content = (
    <Box
      role="presentation"
      // onClick={toggleDrawer(false)}
      // onKeyDown={toggleDrawer(false)}
      className={classNames(
        anchor === "top" || anchor === "bottom"
          ? "w-auto"
          : "w-auto md:w-[500px]",
        anchor === "bottom"
          ? "bg-background_main relative h-3/5 rounded-t-lg p-6"
          : "",
        ""
      )}
    >
      {anchor === "bottom" && (
        <>
          <div className="absolute -top-6 right-0">
            <button
              className="flex h-12 w-12 items-center justify-center rounded-full bg-slate-50 shadow-lg"
              onClick={toggleDrawer(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="absolute top-3 left-1/2 h-2 w-12 -translate-x-1/2 rounded bg-slate-300" />
        </>
      )}
      {["left", "right"].includes(anchor) && (
        <AppBar
          sx={{ position: "relative" }}
          classes={{
            root: "h-16 bg-gradient-to-r from-bg_gradient_from to-bg_gradient_to shadow !sticky",
          }}
        >
          <Toolbar
            classes={{
              root: "justify-between items-center h-full",
            }}
          >
            <DialogTitle
              classes={{
                root: "!font-normal text-center !text-base",
              }}
            >
              {title}
            </DialogTitle>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setState(false);
                handleOnClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      {children}
    </Box>
  );

  return (
    <div>
      <MuiDrawer anchor={anchor} open={state}>
        {content}
      </MuiDrawer>
    </div>
  );
}

export function DrawerSide({ open, setOpen, children, sideInfo, customWidth }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={classNames(
          "fixed",
          !sideInfo ? "inset-0 z-[1600] overflow-hidden" : "z-[1300]"
        )}
        onClose={setOpen}
      >
        <div
          className={classNames(
            "absolute",
            !sideInfo ? "inset-0 overflow-hidden" : ""
          )}
        >
          {!sideInfo && (
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                className={classNames(
                  "absolute bg-gray-900 bg-opacity-75 transition-opacity",
                  !sideInfo ? "inset-0" : ""
                )}
              />
            </Transition.Child>
          )}

          <div
            className={classNames(
              "pointer-events-none fixed right-0 flex max-w-full pl-10",
              sideInfo ? "top-[80px] bottom-0" : "inset-y-0"
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={classNames(
                  "pointer-events-auto max-w-md",
                  sideInfo
                    ? "w-[300px]"
                    : customWidth
                    ? customWidth
                    : "w-screen"
                )}
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-box shadow-xl dark:bg-gray-800">
                  {/* <div className="h-auto bg-box  p-2">
                    <div className="h-auto rounded bg-white p-2 shadow-md"> */}
                  {children}
                  {/* </div>
                  </div> */}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function Accordion() {
  return (
    <MuiAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Accordion 1</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </Typography>
      </AccordionDetails>
    </MuiAccordion>
  );
}

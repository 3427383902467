import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import bcrypt from "bcryptjs";
import * as i from "../lib";
import { getUserProfile } from "redux/reducers/profileReducers";
import { notification } from "utils";

import { verifyOTP, checkOTP, uploadImage, axiosCall } from "../helpers";

const auth = i.getAuth();

const url = (endpoint, platform) => {
  const baseUrl = () => {
    switch (platform) {
      case "kaapo":
        return i.baseURL_KP;
      case "esetech":
        return i.baseURL_ET;

      default:
        throw new Error("platform is missing");
    }
  };
  return `${baseUrl()}/profile/${endpoint}`;
};

export const useUserProfile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsub;
    try {
      unsub = i.onSnapshot(
        i.doc(i.db, "staff", auth?.currentUser?.uid),
        (doc) => {
          dispatch(
            getUserProfile({
              user_details: doc.data(),
              isLoading: false,
              isLoaded: true,
            })
          );
        }
      );
    } catch (error) {
      dispatch(
        getUserProfile({
          user_details: null,
          isLoading: false,
          isLoaded: true,
        })
      );
    }

    return unsub;
  }, [dispatch, auth?.currentUser]);
};

export const useUpdateProfile = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const updateProfile = async (values) => {
    setUpdating(true);
    const { displayName } = values;

    try {
      await i.updateProfile(auth.currentUser, {
        displayName,
      });
      await i.updateDoc(i.doc(i.db, "staff", auth?.currentUser?.uid), {
        displayName,
      });
      setUpdating(false);
      setUpdated(true);
      notification("success", "Profile successfully updated.");
    } catch (error) {
      // console.log(error);
      setUpdating(false);
      notification("danger", "An error occured. Try again later!", "error");
    }
  };

  return [isUpdating, isUpdated, updateProfile];
};

export const useVerifyPin = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const verifyPin = async (values) => {
    setUpdating(true);
    try {
      const credential = i.EmailAuthProvider.credential(
        auth.currentUser.email,
        values.password
      );

      await i.reauthenticateWithCredential(auth.currentUser, credential);
      await verifyOTP(values);
      setUpdated(true);
      setUpdating(false);
      notification(
        "success",
        `OTP successfully sent to ${auth.currentUser.email}`
      );
    } catch (error) {
      console.log(error, error.code);
      setUpdating(false);
      const msg = error.code;
      notification(
        "danger",
        msg === "auth/wrong-password"
          ? "Incorrect Password"
          : msg === "auth/too-many-requests"
          ? "Too many failed password verify attempts. Try again later"
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, verifyPin];
};

export const useUpdatePin = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const dispatch = useDispatch();

  const updatePIN = async (values) => {
    setUpdating(true);

    try {
      await checkOTP(values);
      const salt = bcrypt.genSaltSync(10);
      const hashed = bcrypt.hashSync(values.pin, salt);

      await i.updateDoc(i.doc(i.db, "staff", auth?.currentUser?.uid), {
        transactionPIN: hashed,
      });

      setUpdating(false);
      setUpdated(true);

      notification("success", "PIN successfully updated.");
    } catch (error) {
      setUpdating(false);

      notification(
        "danger",
        [
          "Otp do not match",
          "Otp has expired",
          "Exceeded maximum trials",
        ].includes(error)
          ? error
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, updatePIN];
};

export const useUpdatePinAlt = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const verifyPin = async (values) => {
    setUpdating(true);
    try {
      const credential = i.EmailAuthProvider.credential(
        auth.currentUser.email,
        values.password
      );

      await i.reauthenticateWithCredential(auth.currentUser, credential);
      const salt = bcrypt.genSaltSync(10);
      const hashed = bcrypt.hashSync(values.pin, salt);

      await i.updateDoc(i.doc(i.db, "staff", auth?.currentUser?.uid), {
        transactionPIN: hashed,
      });

      setUpdated(true);
      setUpdating(false);
      notification("success", "PIN successfully updated.");
    } catch (error) {
      console.log(error, error.code);
      setUpdating(false);
      const msg = error.code;
      notification(
        "danger",
        msg === "auth/wrong-password"
          ? "Incorrect Password"
          : msg === "auth/too-many-requests"
          ? "Too many failed password verify attempts. Try again later"
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, verifyPin];
};

export const useVerifyPass = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const dispatch = useDispatch();

  const verifyPass = async (values) => {
    setUpdating(true);
    try {
      const credential = i.EmailAuthProvider.credential(
        auth.currentUser.email,
        values.currentPass
      );

      await i.reauthenticateWithCredential(auth.currentUser, credential);
      await verifyOTP(values);
      setUpdated(true);
      setUpdating(false);
      notification(
        "success",
        `Otp succesfully sent to ${auth.currentUser.email}.`
      );
    } catch (error) {
      console.log(error);
      setUpdating(false);
      const msg = error?.code;
      notification(
        "danger",
        msg === "auth/wrong-password"
          ? "Incorrect Password"
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, verifyPass];
};

export const useChangePass = () => {
  const [isUpdatingPass, setUpdating] = useState(false);
  const [isUpdatedPass, setUpdated] = useState(false);

  const changePass = async (values) => {
    setUpdating(true);
    try {
      // dispatch(setLogoutTimer(false));

      await checkOTP(values);
      await i.updatePassword(auth.currentUser, values?.newPass);
      setUpdated(true);
      setUpdating(false);

      // dispatch(setLogoutTimer(true));
      notification("success", "Password successfully updated.");
    } catch (error) {
      // console.log(error);
      setUpdating(false);

      notification(
        "danger",
        ["Otp do not match", "Otp has expired"].includes(error)
          ? error
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdatingPass, isUpdatedPass, changePass];
};

export const useVerifyPassAlt = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const verifyPass = async (values) => {
    setUpdating(true);
    try {
      const credential = i.EmailAuthProvider.credential(
        auth.currentUser.email,
        values.currentPass
      );

      await i.reauthenticateWithCredential(auth.currentUser, credential);
      await Promise.all([
        i.updatePassword(auth.currentUser, values?.newPass),
        i.updatePassword(i.auth2.currentUser, values?.newPass),
      ]);

      setUpdated(true);
      setUpdating(false);
      notification("success", "Password successfully updated.");
    } catch (error) {
      console.log(error);
      setUpdating(false);
      const msg = error?.code;
      notification(
        "danger",
        msg === "auth/wrong-password"
          ? "Incorrect Password"
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, verifyPass];
};

export const useUpdateProfileImage = () => {
  const [isUpdatingUpload, setUpdatingUpload] = useState(false);
  const [isUpdatingReset, setUpdatingReset] = useState(false);
  // const [isUpdated, setUpdated] = useState(false);

  const updateImage = async (values) => {
    const { profileImage, type } = values;
    try {
      let imageToUpload;
      if (type === "upload") {
        setUpdatingUpload(true);
        const uploadedImages = await uploadImage(
          profileImage,
          `user/${auth.currentUser.uid}`,
          auth.currentUser.uid
        );
        imageToUpload = uploadedImages[0];
      } else if (type === "reset") {
        setUpdatingReset(true);
        imageToUpload =
          "https://demos.themeselection.com/materio-vuetify-vuejs-admin-template/demo-1/img/1.e2938115.png";
      }

      await i.updateProfile(auth.currentUser, {
        photoURL: imageToUpload,
      });
      await i.updateDoc(i.doc(i.db, "users", auth?.currentUser?.uid), {
        avatarUrl: imageToUpload,
      });
      setUpdatingUpload(false);
      setUpdatingReset(false);
      notification("success", "Profile image successfully updated");
    } catch (error) {
      console.log(error);
      notification("danger", "An error occured. Try again later!", "error");
      setUpdatingUpload(false);
      setUpdatingReset(false);
    }
  };

  return [isUpdatingUpload, isUpdatingReset, updateImage];
};

export const useDisableStaff = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => {
      setUpdated(false);
    }, 500);
    return () => {
      clearTimeout(t);
    };
  }, [isUpdated]);

  const disableStaff = async (values) => {
    setUpdating(true);

    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
      platform: "esetech",
    };

    try {
      const credential = i.EmailAuthProvider.credential(
        auth.currentUser.email,
        values.currentPass
      );

      await i.reauthenticateWithCredential(auth.currentUser, credential);

      const trans = await axiosCall(
        url("disable_staff_account", "esetech"),
        valueFormat,
        true,
        "esetech"
      );

      setUpdated(true);
      setUpdating(false);
      notification("success", "Staff Account disabled");
    } catch (error) {
      console.log(error);
      setUpdating(false);
      const msg = error?.code;
      notification(
        "danger",
        msg === "auth/wrong-password"
          ? "Incorrect Password"
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, disableStaff];
};

export const useDisableUser = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const t = setTimeout(() => {
      setUpdated(false);
    }, 500);
    return () => {
      clearTimeout(t);
    };
  }, [isUpdated]);

  const disableUser = async (values) => {
    setUpdating(true);

    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
      platform: "esetech",
    };

    try {
      const credential = i.EmailAuthProvider.credential(
        auth.currentUser.email,
        values.currentPass
      );

      await i.reauthenticateWithCredential(auth.currentUser, credential);

      const trans = await axiosCall(
        url("disable_user_account", "esetech"),
        valueFormat,
        true,
        "esetech"
      );

      setUpdated(true);
      setUpdating(false);
      notification("success", "user account updated successfully");
    } catch (error) {
      console.log(error);
      setUpdating(false);
      const msg = error?.code;
      notification(
        "danger",
        msg === "auth/wrong-password"
          ? "Incorrect Password"
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [isUpdating, isUpdated, disableUser];
};

export const useDisableUserWithdrawal = () => {
  const [isUpdatingDisableWithdrawal, setUpdatingDisableWithdrawal] =
    useState(false);
  const [isUpdatedDisableWithdrawal, setUpdatedDisableWithdrawal] =
    useState(false);

  useEffect(() => {
    const t = setTimeout(() => {
      setUpdatedDisableWithdrawal(false);
    }, 500);
    return () => {
      clearTimeout(t);
    };
  }, [isUpdatedDisableWithdrawal]);

  const disableUserWithdrawal = async (values) => {
    setUpdatingDisableWithdrawal(true);

    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
      platform: "esetech",
    };

    try {
      const credential = i.EmailAuthProvider.credential(
        auth.currentUser.email,
        values.currentPass
      );

      await i.reauthenticateWithCredential(auth.currentUser, credential);

      const trans = await axiosCall(
        url("disable_user_withdrawal", "esetech"),
        valueFormat,
        true,
        "esetech"
      );

      setUpdatedDisableWithdrawal(true);
      setUpdatingDisableWithdrawal(false);
      notification("success", "User withdrawal disabled");
    } catch (error) {
      console.log(error);
      setUpdatingDisableWithdrawal(false);
      const msg = error?.code;
      notification(
        "danger",
        msg === "auth/wrong-password"
          ? "Incorrect Password"
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [
    isUpdatingDisableWithdrawal,
    isUpdatedDisableWithdrawal,
    disableUserWithdrawal,
  ];
};

export const useReduceUserBalance = () => {
  const [isUpdatingReduceUserBalance, setUpdatingReduceUserBalance] =
    useState(false);
  const [isUpdatedReduceUserBalance, setUpdatedReduceUserBalance] =
    useState(false);

  useEffect(() => {
    const t = setTimeout(() => {
      setUpdatedReduceUserBalance(false);
    }, 500);
    return () => {
      clearTimeout(t);
    };
  }, [isUpdatedReduceUserBalance]);

  const reduceUserBalance = async (values) => {
    setUpdatingReduceUserBalance(true);

    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
      platform: "esetech",
    };

    try {
      const credential = i.EmailAuthProvider.credential(
        auth.currentUser.email,
        values.currentPass
      );

      await i.reauthenticateWithCredential(auth.currentUser, credential);

      const trans = await axiosCall(
        url("reduce_user_balance", "esetech"),
        valueFormat,
        true,
        "esetech"
      );

      setUpdatedReduceUserBalance(true);
      setUpdatingReduceUserBalance(false);
      notification("success", "User balance reduced");
    } catch (error) {
      console.log(error);
      setUpdatingReduceUserBalance(false);
      const msg = error?.code;
      notification(
        "danger",
        msg === "auth/wrong-password"
          ? "Incorrect Password"
          : "An error occured. Try again later!",
        "error"
      );
    }
  };

  return [
    isUpdatingReduceUserBalance,
    isUpdatedReduceUserBalance,
    reduceUserBalance,
  ];
};

import React, { useState } from "react";
import { FullWidthTabs } from "components";
import { PageContainer } from "shared";
import NotificationContent from "./NotificationContent";

const tabList = [
  {
    label: "Esetech",
    Icon: "EditNotifications",
    platform: "esetech",
    Comp: NotificationContent,
  },
  {
    label: "Kaapo",
    Icon: "EditNotifications",
    platform: "kaapo",
    Comp: NotificationContent,
  },
];

export default function Index() {
  const [currentTab, setCurrentTab] = useState(tabList[0]);

  return (
    <PageContainer>
      <FullWidthTabs
        {...{ currentTab, setCurrentTab, tabList }}
        Comp={<currentTab.Comp {...currentTab} />}
      />
    </PageContainer>
  );
}

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { ScrollTop } from "../shared";
import { Error } from "../pages";
import { NavbarF } from "shared/Navbar";

const AppRoutes = () => (
  <BrowserRouter>
    <ScrollTop>
      <NavbarF />
      <>
        <Routes>
          {PrivateRoutes}
          {PublicRoutes}
          <Route path="*" element={<Error />} />
        </Routes>
      </>
    </ScrollTop>
  </BrowserRouter>
);

export default AppRoutes;

import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import { Button, TextField } from "components";

import { Fade } from "react-reveal";

export const UpdateRate = ({
  openModal,
  setOpenModal,
  content,
  isSubmitting,
  handleSubmit,
  rateValue,
  setRateValue,
}) => {
  const { qty, rate } = content || {};

  useEffect(() => {
    setRateValue(rate || "");
  }, [rate]);

  return (
    <>
      {openModal && rate && qty ? (
        <Draggable
          axis="both"
          handle=".updateRateModal"
          positionOffset={{ x: "50%", y: "-50%" }}
          position={null}
          grid={[25, 25]}
          scale={1}
        >
          <div className="updateRateModal fixed top-[50%] right-[50%] z-[2000]">
            <Fade duration={300} spy={qty}>
              <div className="min-w-[350px] rounded bg-background-main shadow-dialog dark:bg-gray-900">
                <div className="p-4">
                  <div className="mb-4">
                    <TextField label="Qty" value={qty} readOnly />
                  </div>
                  <div>
                    <TextField
                      label="Rate"
                      type="number"
                      value={rateValue}
                      handleChange={(e) => setRateValue(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between pt-4">
                    <Button
                      label="Cancel"
                      color="default"
                      handleClick={() => {
                        setOpenModal(false);
                        // setRateValue("");
                      }}
                    />
                    <Button
                      label="Update"
                      handleClick={() => {
                        handleSubmit(rateValue);
                      }}
                      isSubmitting={isSubmitting}
                    />
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </Draggable>
      ) : null}
    </>
  );
};

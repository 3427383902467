import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pendingGiftcardTransKap: null,
  activeGiftcardTransKap: null,
  deferredGiftcardTransKap: null,

  pendingWithdrawFiatTransKap: null,
  activeWithdrawFiatTransKap: null,
  deferredWithdrawFiatTransKap: null,

  pendingUtilityTransKap: null,
  activeUtilityTransKap: null,
  deferredUtilityTransKap: null,

  completedGiftcardTransKap: null,
  completedWithdrawFiatTransKap: null,
  completedUtilityTransKap: null,
  completedFundWalletTransKap: null,
  completedTransferFiatTransKap: null,
  completedTransferBonusTransKap: null,
  completedConvertFiatTransKap: null,
};

export const transactionKapSlice = createSlice({
  name: "transactionKap",
  initialState,
  reducers: {
    getPendingGiftcardTransKap: (state, { payload }) => {
      state.pendingGiftcardTransKap = payload;
    },
    getActiveGiftcardTransKap: (state, { payload }) => {
      state.activeGiftcardTransKap = payload;
    },
    getDeferredGiftcardTransKap: (state, { payload }) => {
      state.deferredGiftcardTransKap = payload;
    },

    getPendingWithdrawalFiatTransKap: (state, { payload }) => {
      state.pendingWithdrawFiatTransKap = payload;
    },
    getActiveWithdrawalFiatTransKap: (state, { payload }) => {
      state.activeWithdrawFiatTransKap = payload;
    },
    getDeferredWithdrawalFiatTransKap: (state, { payload }) => {
      state.deferredWithdrawFiatTransKap = payload;
    },

    getPendingUtilityTransKap: (state, { payload }) => {
      state.pendingUtilityTransKap = payload;
    },
    getActiveUtilityTransKap: (state, { payload }) => {
      state.activeUtilityTransKap = payload;
    },
    getDeferredUtilityTransKap: (state, { payload }) => {
      state.deferredUtilityTransKap = payload;
    },

    getCompletedGiftcardTransKap: (state, { payload }) => {
      state.completedGiftcardTransKap = payload;
    },
    getCompletedWithdrawalFiatTransKap: (state, { payload }) => {
      state.completedWithdrawFiatTransKap = payload;
    },
    getCompletedUtilityTransKap: (state, { payload }) => {
      state.completedUtilityTransKap = payload;
    },
    getCompletedFundWalletTransKap: (state, { payload }) => {
      state.completedFundWalletTransKap = payload;
    },
    getCompletedTransferFiatTransKap: (state, { payload }) => {
      state.completedTransferFiatTransKap = payload;
    },
    getCompletedTransferBonusTransKap: (state, { payload }) => {
      state.completedTransferBonusTransKap = payload;
    },
    getCompletedConvertFiatTransKap: (state, { payload }) => {
      state.completedConvertFiatTransKap = payload;
    },
  },
});

export const {
  getPendingGiftcardTransKap,
  getActiveGiftcardTransKap,
  getDeferredGiftcardTransKap,

  getPendingWithdrawalFiatTransKap,
  getActiveWithdrawalFiatTransKap,
  getDeferredWithdrawalFiatTransKap,

  getPendingUtilityTransKap,
  getActiveUtilityTransKap,
  getDeferredUtilityTransKap,

  getCompletedGiftcardTransKap,
  getCompletedWithdrawalFiatTransKap,
  getCompletedUtilityTransKap,
  getCompletedFundWalletTransKap,
  getCompletedTransferFiatTransKap,
  getCompletedTransferBonusTransKap,
  getCompletedConvertFiatTransKap,
} = transactionKapSlice.actions;

export default transactionKapSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pendingCryptoTransEse: null,
  pendingWithdrawFiatTransEse: null,
  activeWithdrawFiatTransEse: null,
  deferredWithdrawFiatTransEse: null,

  completedCryptoTransEse: null,

  completedOldCryptoTransEse: null,
  completedOldGiftcardTransEse: null,
  completedOldWithdrawFiatTransEse: null,

  completedWithdrawFiatTransEse: null,
  completedUtilityTransEse: null,
  completedFundWalletTransEse: null,
  completedTransferFiatTransEse: null,
  completedTransferBonusTransEse: null,
  completedConvertFiatTransEse: null,
};

export const transactionEseSlice = createSlice({
  name: "transactionEse",
  initialState,
  reducers: {
    getPendingCryptoTransEse: (state, { payload }) => {
      state.pendingCryptoTransEse = payload;
    },

    getPendingWithdrawalFiatTransEse: (state, { payload }) => {
      state.pendingWithdrawFiatTransEse = payload;
    },
    getActiveWithdrawalFiatTransEse: (state, { payload }) => {
      state.activeWithdrawFiatTransEse = payload;
    },
    getDeferredWithdrawalFiatTransEse: (state, { payload }) => {
      state.deferredWithdrawFiatTransEse = payload;
    },

    getCompletedCryptoTransEse: (state, { payload }) => {
      state.completedCryptoTransEse = payload;
    },

    getCompletedOldCryptoTransEse: (state, { payload }) => {
      state.completedOldCryptoTransEse = payload;
    },
    getCompletedOldGiftcardTransEse: (state, { payload }) => {
      state.completedOldGiftcardTransEse = payload;
    },
    getCompletedOldWithdrawalFiatTransEse: (state, { payload }) => {
      state.completedOldWithdrawFiatTransEse = payload;
    },

    getCompletedWithdrawalFiatTransEse: (state, { payload }) => {
      state.completedWithdrawFiatTransEse = payload;
    },
    getCompletedUtilityTransEse: (state, { payload }) => {
      state.completedUtilityTransEse = payload;
    },
    getCompletedFundWalletTransEse: (state, { payload }) => {
      state.completedFundWalletTransEse = payload;
    },
    getCompletedTransferFiatTransEse: (state, { payload }) => {
      state.completedTransferFiatTransEse = payload;
    },
    getCompletedTransferBonusTransEse: (state, { payload }) => {
      state.completedTransferBonusTransEse = payload;
    },
    getCompletedConvertFiatTransEse: (state, { payload }) => {
      state.completedConvertFiatTransEse = payload;
    },
  },
});

export const {
  getPendingCryptoTransEse,
  getPendingWithdrawalFiatTransEse,
  getActiveWithdrawalFiatTransEse,
  getDeferredWithdrawalFiatTransEse,
  getCompletedCryptoTransEse,

  getCompletedOldCryptoTransEse,
  getCompletedOldGiftcardTransEse,
  getCompletedOldWithdrawalFiatTransEse,

  getCompletedWithdrawalFiatTransEse,
  getCompletedUtilityTransEse,
  getCompletedFundWalletTransEse,
  getCompletedTransferFiatTransEse,
  getCompletedTransferBonusTransEse,
  getCompletedConvertFiatTransEse,
} = transactionEseSlice.actions;

export default transactionEseSlice.reducer;

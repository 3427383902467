import React, { useCallback, useState } from "react";
import { useMarketCryptoRate } from "api";
import { SectionContent, SimpleLoader, SimpleTable } from "components";
import { capitalize } from "lodash";
import { formatInputNum, toFixed } from "utils";
import { useSelector } from "react-redux";

const product = [
  { id: "bitcoin", img: "btc", sym: "BTC", changeSym: "BTC" },
  { id: "ethereum", img: "eth", sym: "ETH", changeSym: "ETH" },
  { id: "tether", img: "usdt", sym: "USDT", changeSym: "USDT" },
  // { id: "tether-tron", img: "usdt", sym: "USDT_TRON", changeSym: "USDT" },
  { id: "binance", img: "bnb", sym: "BNB", changeSym: "BNB" },
  { id: "litecoin", img: "ltc", sym: "LTC", changeSym: "LTC" },
];

export default function ProductPrice() {
  const [rowId, setRowId] = useState(product[0]);
  const { crypto: cryptoL } = useSelector((state) => state.rate || {});

  const [rate] = useMarketCryptoRate();

  const { priceChange, crypto } = rate || {};

  const setSiteRate = useCallback(() => {
    if (cryptoL) {
      return cryptoL.reduce((acc, item) => {
        acc[item.id] = item.rate;
        return acc;
      }, {});
    }
    return null;
  }, [cryptoL])();

  const siteRate = setSiteRate?.[rowId.id];

  const handleClick = (row) => {
    setRowId(row);
  };

  return (
    <SectionContent>
      <div className="rounded bg-box p-4 shadow-card-box dark:bg-gray-800">
        <h4 className="mb-2 text-lg text-primary dark:text-light-primary">
          Product Prices & Rates
        </h4>
        <hr className="mb-6 border-t dark:border-gray-500" />
        {rate && crypto ? (
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1">
              <div className="rounded-lg bg-white dark:bg-gray-900">
                <SimpleTable
                  columns={[
                    { label: "Coin", id: "coin" },
                    { label: "Market Price", id: "price", align: "right" },
                    {
                      label: "Price Change (24hrs)",
                      id: "priceChange",
                      align: "right",
                    },
                  ]}
                  rows={product.map((i) => {
                    const pricechange =
                      typeof priceChange?.[i.changeSym] === "number"
                        ? `${(priceChange?.[i.changeSym]).toFixed(4)}%`
                        : `${priceChange?.[i.changeSym]}%`;

                    return {
                      coin: (
                        <div key={i.id} className="flex flex-row gap-1">
                          <img
                            src={require(`assets/crypto/${i.img}.svg`)}
                            className="h-6 w-6"
                            alt={i.img}
                          />
                          <div>{capitalize(i.id)}</div>
                        </div>
                      ),
                      price: `$${formatInputNum(crypto?.[i.sym], 2)}`,
                      priceChange: pricechange,
                      ...i,
                    };
                  })}
                  handleClick={handleClick}
                />
              </div>
            </div>
            <div className="col-span-1">
              <div className="rounded-lg bg-white dark:bg-gray-900">
                <div className="flex flex-row items-center justify-between rounded bg-white p-2 dark:bg-gray-900">
                  <div className="flex flex-row items-center justify-start">
                    <div className="flex h-8 w-8 flex-row items-center justify-center rounded bg-slate-50 dark:bg-gray-900">
                      <img
                        src={require(`assets/crypto/${rowId.img}.svg`)}
                        className="h-6 w-6"
                        alt={rowId.img}
                      />
                    </div>
                    <div>
                      <div className="ml-2">
                        <p className="font-bold text-primary dark:text-white">
                          {capitalize(rowId?.id)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="border-t dark:border-gray-600" />
                <ul className="divide-y divide-gray-200 p-4 dark:divide-gray-700">
                  <li className="py-3 sm:py-4">
                    <div className="flex items-start justify-between text-sm dark:text-white">
                      <div className="min-w-0 flex-1">
                        <p className="truncate dark:text-white">Trade Rates</p>
                        <div className="mt-2">
                          {[
                            { id: "sell", name: "Sell" },
                            { id: "buy", name: "Buy" },
                          ].map((item) => (
                            <p
                              key={item.id}
                              className="truncate py-3 text-gray-500 dark:text-gray-400"
                            >
                              {item.name}
                            </p>
                          ))}
                        </div>
                      </div>
                      {[
                        { id: "naira", name: "Naira" },
                        { id: "cedis", name: "Cedis" },
                        { id: "cefa", name: "Cefa" },
                      ].map((item) => (
                        <div
                          className="min-w-0 flex-1 pr-6 text-right"
                          key={item.id}
                        >
                          <div className="dark:text-white">{item.name}</div>
                          <div className="mt-2">
                            {[
                              { id: "sell", name: "Sell" },
                              { id: "buy", name: "Buy" },
                            ].map((tradeItem) => (
                              <div
                                key={tradeItem.id}
                                className="py-3 text-right"
                              >
                                {siteRate?.[item?.id]?.[tradeItem?.id]
                                  ? toFixed(
                                      siteRate?.[item?.id]?.[tradeItem?.id] ||
                                        0,
                                      2
                                    )
                                  : "-"}
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <SimpleLoader />
        )}
      </div>
    </SectionContent>
  );
}

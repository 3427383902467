import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducers from "./reducers/authReducers";
import profileReducers from "./reducers/profileReducers";
import transactionReducers from "./reducers/transactionReducers";
import todoReducers from "./reducers/todoReducers";
import giftcardReducers from "./reducers/giftcardReducers";
import utilReducers from "./reducers/utilReducers";
import staffReducers from "./reducers/staffReducers";
import rateReducers from "./reducers/rateReducers";
import transactionKapReducers from "./reducers/transactionKapReducers";
import transactionEseReducers from "./reducers/transactionEseReducers";
import usersReducers from "./reducers/usersReducers";
import chatReducers from "./reducers/chatReducers";
import marketingReducer from "./reducers/marketingReducer";

export const store = configureStore({
  reducer: {
    auth: authReducers,
    profile: profileReducers,
    transaction: transactionReducers,
    transactionKap: transactionKapReducers,
    transactionEse: transactionEseReducers,
    todo: todoReducers,
    giftcard: giftcardReducers,
    util: utilReducers,
    staff: staffReducers,
    users: usersReducers,
    rate: rateReducers,
    chat: chatReducers,
    marketing: marketingReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

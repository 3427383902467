import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Loader, SectionContent, Switch } from "components";
import { useSelector } from "react-redux";
import { capitalize, pick, toLower } from "lodash";
import { useGetCurrentTeam, useUpdateStaffProfile } from "api";
import { PageContainer } from "shared";

const rolesObj = [
  { id: "giftcard", title: "Giftcard Trade" },
  { id: "payout", title: "Payout" },
  { id: "rate-update", title: "Rate Update" },
  { id: "check-transaction", title: "Check Transaction" },
];

export default function StaffRoles() {
  const { staffDetails } = useSelector((state) => state.staff);
  const [isUpdating, isUpdated, updateProfile] = useUpdateStaffProfile();

  const [, , , , , , currentWorkingTeam, staffT] = useGetCurrentTeam();

  const staffTeam = useCallback(() => {
    if (currentWorkingTeam) {
      const stafflist = [...staffT];
      const findI = stafflist?.findIndex((i) => i.id === currentWorkingTeam);
      const otherTeamList = stafflist.splice(findI, 1);
      stafflist.unshift(otherTeamList[0]);

      return stafflist;
    }
    return null;
  }, [currentWorkingTeam, staffT])();

  const staffHaveTeam = staffDetails
    .filter(
      (item) => Boolean(item?.teamId) && item.position === "Customer Care Agent"
    )
    .map((item) => {
      return pick(item, [
        "id",
        "displayName",
        "email",
        "roles",
        "gender",
        "teamId",
      ]);
    });

  return (
    <PageContainer>
      <SectionContent>
        {currentWorkingTeam && staffTeam ? (
          <div className="flex flex-col gap-y-6">
            {staffTeam?.length ? (
              staffTeam?.map((teamItem) => (
                <div
                  className="shadow-xs relative w-full overflow-x-auto rounded border border-slate-100 bg-box p-2 shadow-sm dark:border-gray-500 dark:bg-gray-800"
                  key={teamItem?.id}
                >
                  <div className="border-b border-slate-200 p-4 font-bold text-primary dark:border-slate-500 dark:text-light-primary">
                    {capitalize(teamItem?.teamName)} Team
                  </div>
                  <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
                    <thead className="bg-gray-50 text-sm uppercase  dark:bg-gray-700">
                      <tr>
                        {["Name", ...rolesObj.map((i) => i.title)].map(
                          (item, index) => (
                            <th
                              scope="col"
                              className="py-3 px-6 dark:text-white"
                              key={index}
                            >
                              {item}
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {staffHaveTeam?.length ? (
                        staffHaveTeam.map((staffItem, index) => (
                          <Fragment key={index}>
                            {staffItem?.teamId === teamItem?.id && (
                              <tr className="border-b bg-white hover:bg-gray-50 dark:border-gray-500 dark:bg-gray-900 dark:hover:bg-gray-600">
                                <th
                                  scope="row"
                                  className="flex items-center whitespace-nowrap py-4 px-6  dark:text-white"
                                >
                                  <img
                                    className="h-10 w-10 rounded-full"
                                    src={require(`assets/avatar/avatar-${
                                      toLower(staffItem?.gender) || "female"
                                    }.png`)}
                                    alt={staffItem.displayName}
                                  />
                                  <div className="pl-3">
                                    <div className="text-base font-semibold">
                                      {staffItem?.displayName}
                                    </div>
                                    <div className="font-normal text-gray-500">
                                      {toLower(staffItem?.email)}
                                    </div>
                                  </div>
                                </th>

                                {rolesObj?.map((item, index) => (
                                  <td className="py-4 px-6" key={index}>
                                    <label className="relative mr-5 inline-flex cursor-pointer items-center">
                                      <Switch
                                        checked={staffItem?.roles?.[item.id]}
                                        onChange={() => {
                                          updateProfile(
                                            {
                                              [`roles.${[item.id]}`]:
                                                !staffItem?.roles?.[item.id],
                                            },
                                            staffItem?.id,
                                            "role"
                                          );
                                        }}
                                      />
                                    </label>
                                  </td>
                                ))}
                              </tr>
                            )}
                          </Fragment>
                        ))
                      ) : (
                        <tr className="border-b bg-white dark:bg-gray-800">
                          <td className="p-4" colSpan={5}>
                            No staff member added
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ))
            ) : (
              <div className="flex h-48 w-full flex-row items-center justify-center rounded bg-box text-white dark:bg-gray-800">
                Create team to continue
              </div>
            )}
          </div>
        ) : (
          <Loader />
        )}
      </SectionContent>
    </PageContainer>
  );
}

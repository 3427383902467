import { ThemeProvider } from "@mui/material/styles";
import {
  useAllStaff,
  useAuthStateChanged,
  useCryptoRate,
  useGetAccountBalances,
  useGetAllChatToUser,
  useGetAllTransEse,
  useGetAllTransKap,
  useGetAutomatedTrans,
  useGetStaffTeam,
  useGetTodo,
  useGiftcardRate,
  useGiftcardRateFac,
  useUserProfile,
} from "api";
import {
  useEsetechMetaCallToAction,
  useGetMetaAdsData,
  useKaapoMetaCallToAction,
} from "api/actions/marketing";
import { useEffect } from "react";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useSelector } from "react-redux";
import ErrorBoundary from "shared/ErrorHandle";
import { muiTheme } from "./config/themeConfig";
import "./index.css";
import Navigation from "./navigation";

function App() {
  const state = useSelector((state) => state);
  const { modeTheme } = useSelector((state) => state.util || {});
  useAuthStateChanged();
  useUserProfile();
  useCryptoRate();
  useGetAllTransKap();
  useGetAllTransEse();
  useGetTodo();
  useGiftcardRate();
  useGiftcardRateFac();
  useAllStaff({ type: "enabled" });
  useGetStaffTeam();
  useGetMetaAdsData();
  useKaapoMetaCallToAction();
  useEsetechMetaCallToAction();
  useGetAccountBalances();
  useGetAutomatedTrans();
  useGetAllChatToUser();

  useEffect(() => {
    const storageModeEventHandler = () => {
      if (
        localStorage.theme === "dark" ||
        (!("theme" in localStorage) &&
          window.matchMedia("(prefers-color-scheme: dark)").matches)
      ) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    };

    storageModeEventHandler();

    return () => {};
  }, [modeTheme]);

  return (
    <div className="min-h-screen app bg-background-main dark:bg-gray-800">
      <ThemeProvider theme={muiTheme}>
        <ReactNotifications />
        <ErrorBoundary>
          <Navigation />
        </ErrorBoundary>
      </ThemeProvider>
    </div>
  );
}

export default App;

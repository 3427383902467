import { FullWidthTabs } from "components";
import MetaEngagementKaapo from "pages/Marketing/components/MetaEngagement/MetaEngagementKaapo";
import React, { useState } from "react";
import Campaign from "./Campaign";
import MetaEngagementEsetech from "pages/Marketing/components/MetaEngagement/MetaEngagementEsetech";

const Engagement = () => {
  const tabList = [
    {
      label: "Esetech",
      Comp: MetaEngagementEsetech,
      itemCount: 1 || 0,
      // Icon: "FacebookIcon",
    },
    {
      label: "Kaapo",
      Comp: MetaEngagementKaapo,
      itemCount: 1 || 0,
      // Icon: "FacebookIcon",
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabList[0]);

  return (
    <div>
      <div className="grid h-fit w-full grid-cols-2 gap-5 px-5 py-10">
        <div>
          <FullWidthTabs
            {...{ currentTab, setCurrentTab, tabList }}
            Comp={<currentTab.Comp {...currentTab} />}
          />
        </div>

        <div>
          <Campaign />
        </div>
      </div>
    </div>
  );
};

export default Engagement;

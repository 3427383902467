import React, { useState } from "react";
import { FullWidthTabs } from "components";
import { PageContainer } from "shared";
import Users from "./sub-pages/Users";

const tabList = [
  {
    label: "Esetech Users",
    Icon: "ManageAccounts",
    platform: "esetech",
    Comp: Users,
  },
  {
    label: "Kaapo Users",
    Icon: "ManageAccounts",
    platform: "kaapo",
    Comp: Users,
  },
];

export default function Index() {
  const [currentTab, setCurrentTab] = useState(tabList[0]);

  return (
    <PageContainer>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-1">
        <div className="col-span-1 md:col-span-1">
          <FullWidthTabs
            {...{ currentTab, setCurrentTab, tabList }}
            Comp={<currentTab.Comp {...currentTab} />}
          />
        </div>
      </div>
    </PageContainer>
  );
}

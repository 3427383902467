import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";

import { Button, SectionContent } from "components";
import { useUpdateProfileImage } from "api";
import { upperCase } from "lodash";

export default function ProfileImage() {
  const [images, setImages] = useState([]);
  const onChange = (imageList) => {
    setImages(imageList);
  };
  const { user_details } = useSelector((state) => state.profile);
  const initials = upperCase(user_details?.displayName.split("")[0]);

  // const [isUpdatingUpload, isUpdatingReset, isUpdated, updateImage] =
  //   useUpdateProfileImage();

  const handleUploadImage = (type) => {
    // updateImage({ profileImage: images, type });
  };

  // useEffect(() => {
  //   if (isUpdated) {
  //     setImages([]);
  //   }
  // }, [isUpdated]);

  return (
    <SectionContent>
      <div className="rounded bg-box p-0 shadow-card-box dark:bg-gray-800">
        <div className="flex flex-col items-center justify-center rounded-sm border border-gray-200 bg-white p-4 text-gray-500 dark:border-gray-600 dark:bg-gray-800">
          <ImageUploading
            value={images}
            onChange={onChange}
            dataURLKey="data_url"
            acceptType={["jpg", "jpeg", "png"]}
          >
            {({ imageList, onImageUpload, onImageRemove }) => (
              <div className="flex flex-wrap rounded bg-white p-2 dark:bg-gray-900">
                <div className="relative">
                  <button onClick={onImageUpload}>
                    <div className="bg-background_main_alt flex h-28 w-28 flex-row items-center justify-center rounded-sm p-2 dark:bg-gray-900">
                      {/* <img
                        src={
                          imageList?.[0]?.["data_url"] ||
                          user_details?.avatarUrl
                        }
                        alt="profile-image"
                        className="h-full w-full rounded object-cover"
                      /> */}
                      <div className="flex h-20 w-20 items-center justify-center rounded-full bg-background-main shadow-drawer dark:bg-gray-700">
                        <h5 className="text-4xl font-bold text-primary dark:text-light-primary">
                          {initials}
                        </h5>
                      </div>
                    </div>
                  </button>
                  {imageList.length ? (
                    <div className="absolute -right-3 -top-3">
                      <button
                        className="flex h-8 w-8 items-center justify-center rounded-full bg-slate-50 text-gray-500 shadow-lg"
                        onClick={() => onImageRemove(0)}
                      >
                        <Close sx={{ fontSize: 16 }} />
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </ImageUploading>

          <div className="desc my-4 text-center">
            <h5 className="break-all leading-[0.5] dark:text-white">
              {user_details.displayName}
            </h5>
            <span className="break-all text-xs dark:text-gray-200">
              {user_details.email}
            </span>
          </div>
          <div className="action-buttons flex flex-col space-y-2">
            <Button
              label="Upload Photo"
              gradient
              fullWidth
              handleClick={() => handleUploadImage("upload")}
              // isSubmitting={isUpdatingUpload}
              // disabled={!images.length}
              disabled
            />
            <Button
              label="Reset"
              fullWidth
              handleClick={() => handleUploadImage("reset")}
              variant="outlined"
              color="error"
              // disabled={images.length > 0}
              disabled
              // isSubmitting={isUpdatingReset}
            />
          </div>
        </div>
      </div>
    </SectionContent>
  );
}

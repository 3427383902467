import { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import * as i from "../lib";
import {
  getPendingGiftcardTransKap,
  getActiveGiftcardTransKap,
  getDeferredGiftcardTransKap,
  getPendingWithdrawalFiatTransKap,
  getActiveWithdrawalFiatTransKap,
  getDeferredWithdrawalFiatTransKap,
  getPendingUtilityTransKap,
  getActiveUtilityTransKap,
  getDeferredUtilityTransKap,
  getCompletedGiftcardTransKap,
  getCompletedWithdrawalFiatTransKap,
  getCompletedUtilityTransKap,
  getCompletedFundWalletTransKap,
  getCompletedTransferFiatTransKap,
  getCompletedTransferBonusTransKap,
  getCompletedConvertFiatTransKap,
} from "redux/reducers/transactionKapReducers";

import { notification } from "utils";
import { axiosCall } from "api/helpers";
import { filter, isNull } from "lodash";
import { getWithdrawalFiatBalance } from "redux/reducers/transactionReducers";

const auth = i.getAuth();

const url = (endpoint) => `${i.baseURL_KP}/transaction/${endpoint}`;

const unCompleted = (role, position, whatRole) => {
  return (
    (position === "Customer Care Agent" && role.includes(whatRole)) ||
    ["Supervisor", "Chief Executive Officer", "HR Manager"].includes(position)
  );
};

const completed = (role, position, whatRole) => {
  return (
    position === "Customer Care Agent" ||
    [
      "Supervisor",
      "Chief Executive Officer",
      "Accounting Officer",
      "HR Manager",
    ].includes(position)
  );
};

const unCompletedAlt = (position) => {
  return [
    "Chief Executive Officer",
    "Accounting Officer",
    "HR Manager",
  ].includes(position);
};

const completedTrans =
  (collection, product, whatRole) =>
  (agentId, role, position, startDate, endDate) =>
    completed(role, position, whatRole)
      ? [
          i.collection(i.db2, collection),
          ...(position === "Customer Care Agent"
            ? [i.where("agentId", "==", agentId)]
            : []),
          i.where("product", "==", product),
          i.where("status", "in", ["approved", "declined"]),
          ...(startDate
            ? [
                i.where(
                  "dateCreated",
                  ">=",
                  product === "giftcard" ? new Date(startDate) : startDate
                ),
              ]
            : []),
          ...(endDate
            ? [
                i.where(
                  "dateCreated",
                  "<=",
                  product === "giftcard" ? new Date(endDate) : endDate
                ),
              ]
            : []),
          i.orderBy("dateCreated", "desc"),
          ...(!(startDate && endDate) ? [i.limit(100)] : []),
        ]
      : [];

const completedTransAlt =
  (collection, product) => (agentId, role, position, startDate, endDate) =>
    unCompletedAlt(position)
      ? [
          i.collection(i.db2, collection),
          i.where("product", "==", product),
          i.where("status", "in", ["approved", "declined"]),
          ...(startDate
            ? [
                i.where(
                  "dateCreated",
                  ">=",
                  product === "giftcard" ? new Date(startDate) : startDate
                ),
              ]
            : []),
          ...(endDate
            ? [
                i.where(
                  "dateCreated",
                  "<=",
                  product === "giftcard" ? new Date(endDate) : endDate
                ),
              ]
            : []),
          i.orderBy("dateCreated", "desc"),
        ]
      : [];

const pendingGiftcardQueriesKAP = (agentId, role, position) =>
  unCompleted(role, position, "giftcard")
    ? [
        i.collection(i.db2, "pendingTransGiftcards"),
        i.where("agentId", "==", null),
        i.where("product", "==", "giftcard"),
        i.where("status", "in", ["pending"]),
        i.orderBy("dateCreated", "desc"),
      ]
    : [];

const activeGiftcardQueriesKAP = (agentId, role, position) =>
  unCompleted(role, position, "giftcard")
    ? [
        i.collection(i.db2, "pendingTransGiftcards"),
        ...(position === "Customer Care Agent" && role.includes("giftcard")
          ? [i.where("agentId", "==", agentId)]
          : []),
        i.where("product", "==", "giftcard"),
        i.where("status", "in", ["active"]),
        i.where("isDeferred", "==", false),
        i.orderBy("dateCreated", "desc"),
      ]
    : [];

const deferredGiftcardQueriesKAP = (agentId, role, position) =>
  unCompleted(role, position, "giftcard")
    ? [
        i.collection(i.db2, "pendingTransGiftcards"),
        i.where("agentId", "==", null),
        i.where("product", "==", "giftcard"),
        i.where("isDeferred", "==", true),
        i.where("status", "in", ["active"]),
        i.orderBy("dateCreated", "desc"),
      ]
    : [];

const pendingWithdrawFiatQueriesKAP = (agentId, role, position) =>
  unCompleted(role, position, "payout")
    ? [
        i.collection(i.db2, "pendingTransWithdrawals"),
        i.where("agentId", "==", null),
        i.where("product", "==", "payout"),
        i.where("status", "in", ["pending"]),
        i.where("paymentMethod", "==", "manual"),
        i.where("isAutomatic", "==", false),
        i.orderBy("dateCreated", "desc"),
      ]
    : [];

const activeWithdrawFiatQueriesKAP = (agentId, role, position) =>
  unCompleted(role, position, "payout")
    ? [
        i.collection(i.db2, "pendingTransWithdrawals"),
        ...(position === "Customer Care Agent" && role.includes("payout")
          ? [i.where("agentId", "==", agentId)]
          : []),
        i.where("product", "==", "payout"),
        i.where("status", "in", ["active"]),
        i.where("paymentMethod", "==", "manual"),
        i.where("isAutomatic", "==", false),
        i.where("isDeferred", "==", false),
        i.orderBy("dateCreated", "desc"),
      ]
    : [];

const deferredWithdrawFiatQueriesKAP = (agentId, role, position) =>
  unCompleted(role, position, "payout")
    ? [
        i.collection(i.db2, "pendingTransWithdrawals"),
        i.where("agentId", "==", null),
        i.where("product", "==", "payout"),
        i.where("isDeferred", "==", true),
        i.where("status", "in", ["active"]),
        i.where("paymentMethod", "==", "manual"),
        i.where("isAutomatic", "==", false),
        i.orderBy("dateCreated", "desc"),
      ]
    : [];

const pendingUtilityQueriesKAP = (agentId, role, position) =>
  unCompleted(role, position, "payout")
    ? [
        i.collection(i.db2, "pendingTransUtilities"),
        i.where("agentId", "==", null),
        i.where("product", "==", "utility"),
        i.where("status", "in", ["pending"]),
        i.orderBy("dateCreated", "desc"),
      ]
    : [];

const activeUtilityQueriesKAP = (agentId, role, position) =>
  unCompleted(role, position, "payout")
    ? [
        i.collection(i.db2, "pendingTransUtilities"),
        ...(position === "Customer Care Agent" && role.includes("payout")
          ? [i.where("agentId", "==", agentId)]
          : []),
        i.where("product", "==", "utility"),
        i.where("status", "in", ["active"]),
        i.where("isDeferred", "==", false),
        i.orderBy("dateCreated", "desc"),
      ]
    : [];

const deferredUtilityQueriesKAP = (agentId, role, position) =>
  unCompleted(role, position, "payout")
    ? [
        i.collection(i.db2, "pendingTransUtilities"),
        i.where("agentId", "==", null),
        i.where("product", "==", "utility"),
        i.where("isDeferred", "==", true),
        i.where("status", "in", ["active"]),
        i.orderBy("dateCreated", "desc"),
      ]
    : [];

const completedGiftcardQueriesKAP = completedTrans(
  "transGiftcards",
  "giftcard",
  "giftcard"
);

const completedWithdrawFiatQueriesKAP = completedTrans(
  "transWithdrawals",
  "payout",
  "payout"
);

const completedUtilityQueriesKAP = completedTrans(
  "transUtilities",
  "utility",
  "payout"
);

const completedFundWalletQueriesKAP = completedTransAlt(
  "transDeposits",
  "deposit"
);

const completedTransferFiatQueriesKAP = completedTransAlt(
  "transTransferFiat",
  "transfer-fiat"
);

const completedTransferBonusQueriesKAP = completedTransAlt(
  "transTransferBonus",
  "transfer-bonus"
);

const completedConvertFiatQueriesKAP = completedTransAlt(
  "transConvertFiat",
  "convert-fiat"
);

const useGetUncompletedTrans = (dispatcher, queries) => {
  const { user_details } = useStore().getState().profile || {};
  const { refreshSideInfo } = useStore().getState().util || {};
  const { transInfo } = refreshSideInfo || {};

  const dispatch = useDispatch();

  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    dispatch(dispatcher(null));

    let unsub = () => {};
    const db = i.db2;
    const agentId = userUid;
    try {
      if (userUid && user_details) {
        const { roles, position } = user_details || {};

        const role = Object.entries(roles || {}).reduce((acc, item) => {
          if (item?.[1]) acc.push(item?.[0]);
          return acc;
        }, []);

        const q = queries(agentId, role, position);

        if (q?.length) {
          unsub = i.onSnapshot(i.query(...q), async (querySnapshot) => {
            const t = await Promise.all(
              querySnapshot.docs.map(async (doc) => {
                const { customerId, agentId } = doc.data();
                let f = {};
                const customerRef = await i.getDoc(
                  i.doc(db, "users", customerId)
                );

                let staffRef;
                if (agentId) {
                  staffRef = await i.getDoc(i.doc(db, "staff", agentId));
                }

                if (customerRef.exists()) {
                  const { displayName, email, phoneNumber } =
                    customerRef?.data() || {};

                  let agentObj = {};
                  if (staffRef && staffRef.exists()) {
                    const { displayName, email, phoneNumber } =
                      staffRef?.data() || {};

                    agentObj = {
                      agentDetails: { displayName, email, phoneNumber },
                    };
                  }
                  f = {
                    id: doc.id,
                    ...doc.data(),
                    customerDetails: { displayName, email, phoneNumber },
                    ...agentObj,
                    platform: "kaapo",
                  };
                } else {
                  f = null;
                }

                return f;
              })
            );
            const tResult = filter(t, (i) => !isNull(i));

            dispatch(dispatcher(tResult));
          });
        } else {
          dispatch(dispatcher([]));
        }
      } else {
        dispatch(dispatcher(null));
      }
    } catch (error) {
      console.log(error);
      dispatch(dispatcher(null));
    }
    return () => {
      unsub();
    };
  }, [dispatch, userUid, user_details, transInfo]);
};

const useGetCompletedTrans = (dispatcher, queries) => {
  const { user_details } = useStore().getState().profile || {};
  const { transFilterDate } = useStore().getState().util || {};
  const { startDate, endDate } = transFilterDate || {};

  const dispatch = useDispatch();

  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    dispatch(dispatcher(null));

    let unsub = () => {};
    const db = i.db2;
    const agentId = userUid;
    try {
      if (userUid) {
        const { roles, position } = user_details || {};

        const role = Object.entries(roles || {}).reduce((acc, item) => {
          if (item?.[1]) acc.push(item?.[0]);
          return acc;
        }, []);

        const q = queries(agentId, role, position, startDate, endDate);

        if (q?.length) {
          unsub = i.onSnapshot(i.query(...q), async (querySnapshot) => {
            const t = await Promise.all(
              querySnapshot.docs.map(async (doc) => {
                const { customerId, agentId } = doc.data();
                let f = {};
                const customerRef = await i.getDoc(
                  i.doc(db, "users", customerId)
                );

                let staffRef;
                if (agentId) {
                  staffRef = await i.getDoc(i.doc(db, "staff", agentId));
                }

                if (customerRef.exists()) {
                  const { displayName, email, phoneNumber } =
                    customerRef?.data() || {};

                  let agentObj = {};
                  if (staffRef && staffRef.exists()) {
                    const { displayName, email, phoneNumber } =
                      staffRef?.data() || {};

                    agentObj = {
                      agentDetails: { displayName, email, phoneNumber },
                    };
                  }
                  f = {
                    id: doc.id,
                    ...doc.data(),
                    customerDetails: { displayName, email, phoneNumber },
                    ...agentObj,
                    platform: "kaapo",
                  };
                } else {
                  f = null;
                }

                return f;
              })
            );
            const tResult = filter(t, (i) => !isNull(i));

            dispatch(dispatcher(tResult));
          });
        } else {
          dispatch(dispatcher([]));
        }
      } else {
        dispatch(dispatcher(null));
      }
    } catch (error) {
      console.log(error);

      dispatch(dispatcher(null));
    }
    return unsub;
  }, [dispatch, userUid, user_details, startDate, endDate]);
};

export const useGetAllTransKap = () => {
  useGetUncompletedTrans(getPendingGiftcardTransKap, pendingGiftcardQueriesKAP);
  useGetUncompletedTrans(getActiveGiftcardTransKap, activeGiftcardQueriesKAP);
  useGetUncompletedTrans(
    getDeferredGiftcardTransKap,
    deferredGiftcardQueriesKAP
  );

  useGetUncompletedTrans(
    getPendingWithdrawalFiatTransKap,
    pendingWithdrawFiatQueriesKAP
  );
  useGetUncompletedTrans(
    getActiveWithdrawalFiatTransKap,
    activeWithdrawFiatQueriesKAP
  );
  useGetUncompletedTrans(
    getDeferredWithdrawalFiatTransKap,
    deferredWithdrawFiatQueriesKAP
  );
  useGetUncompletedTrans(getPendingUtilityTransKap, pendingUtilityQueriesKAP);
  useGetUncompletedTrans(getActiveUtilityTransKap, activeUtilityQueriesKAP);
  useGetUncompletedTrans(getDeferredUtilityTransKap, deferredUtilityQueriesKAP);
};

export const useGetAllCompletedTransKap = () => {
  useGetCompletedTrans(
    getCompletedGiftcardTransKap,
    completedGiftcardQueriesKAP
  );
  useGetCompletedTrans(
    getCompletedWithdrawalFiatTransKap,
    completedWithdrawFiatQueriesKAP
  );
  useGetCompletedTrans(getCompletedUtilityTransKap, completedUtilityQueriesKAP);

  useGetCompletedTrans(
    getCompletedFundWalletTransKap,
    completedFundWalletQueriesKAP
  );
  useGetCompletedTrans(
    getCompletedTransferFiatTransKap,
    completedTransferFiatQueriesKAP
  );
  useGetCompletedTrans(
    getCompletedTransferBonusTransKap,
    completedTransferBonusQueriesKAP
  );
  useGetCompletedTrans(
    getCompletedConvertFiatTransKap,
    completedConvertFiatQueriesKAP
  );
};

export const useCheckFiatWithdrawalKAP = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isError, setError] = useState(false);
  const [getData, setData] = useState([]);

  const checkTrans = async (values, id) => {
    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
    };

    try {
      setSubmitting(true);
      setError(false);
      setData(null);

      const trans = await axiosCall(url(id), valueFormat, true);

      console.log(trans);
      setSubmitting(false);
      setSubmitted(true);
      setData(trans?.data?.data);
    } catch (error) {
      console.log(error);
      let message;
      switch (error) {
        case "Transaction was not found":
          message = "Transaction record was not found";
          break;
        case "Transaction not found":
          message = "Transaction record was not found";
          break;
        case "Invalid params":
          message = "Invalid Inputs. Check Inputs";
          break;
        case "address does not exist":
          message = "Address does not exist";
          break;
        default:
          message = error?.message || JSON.stringify(error);
      }
      setError(message);
      setSubmitting(false);
      // notification(
      //   "danger",
      //   error?.message === "invalid params"
      //     ? "Invalid Params"
      //     : "An error occured! Try again later.",
      //   "error"
      // );
    }
  };

  return [
    isSubmitting,
    isSubmitted,
    getData,
    setData,
    checkTrans,
    isError,
    setError,
  ];
};

export const useRevertTransManualKap = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isError, setError] = useState(false);

  const revertTrans = async (values) => {
    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
      platform: "kaapo",
    };

    try {
      setSubmitting(true);
      setError(false);

      const trans = await axiosCall(
        url("revert_trans_manual"),
        valueFormat,
        true,
        "kaapo"
      );

      // console.log(trans?.data?.data, "esetech");
      setSubmitting(false);
      setSubmitted(true);
      notification("success", "Transaction successfully reverted");
    } catch (error) {
      console.log(error);

      setSubmitting(false);
      notification("danger", "An error occured! Try again later.", "error");
    }
  };

  return [revertTrans, isSubmitting, isSubmitted];
};

export const useApproveUtilityManualKap = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isError, setError] = useState(false);

  const approveUtilTrans = async (values) => {
    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
      platform: "kaapo",
    };

    try {
      setSubmitting(true);
      setError(false);

      const trans = await axiosCall(
        url("approve_utility_trans_manual"),
        valueFormat,
        true,
        "kaapo"
      );

      // console.log(trans?.data?.data, "kaapo");
      setSubmitting(false);
      setSubmitted(true);
      notification("success", "Transaction successfully reverted");
    } catch (error) {
      console.log(error);

      setSubmitting(false);
      notification("danger", "An error occured! Try again later.", "error");
    }
  };

  return [approveUtilTrans, isSubmitting, isSubmitted];
};
